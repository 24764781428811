@import url("https://use.typekit.net/rpz7kka.css");
#root,
.modal-open,
.MuiPopover-root {
  /*!
 * https://github.com/arqex/react-datetime
 */
}
#root .four-zero-four-container,
.modal-open .four-zero-four-container,
.MuiPopover-root .four-zero-four-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .four-zero-four-container:not(.compact),
.modal-open .four-zero-four-container:not(.compact),
.MuiPopover-root .four-zero-four-container:not(.compact) {
  height: 100vh;
  width: 100vw;
}
#root .four-zero-four-container .four-zero-four-wrapper,
.modal-open .four-zero-four-container .four-zero-four-wrapper,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper {
  width: 50%;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-header,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-header,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-header i,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-header i,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-header i {
  font-size: 33px;
  margin-right: 15px;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-content,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-content,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-content {
  margin-top: 20px;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-content p,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-content p,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-content p {
  text-align: left !important;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button i,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button i,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button i {
  margin-right: 5px;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button:first-of-type,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button:first-of-type,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button:first-of-type {
  margin-left: 0 !important;
}
#root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button:last-of-type,
.modal-open .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button:last-of-type,
.MuiPopover-root .four-zero-four-container .four-zero-four-wrapper .four-zero-four-button-wrapper button:last-of-type {
  margin-left: 10px !important;
}
@media only screen and (max-width : 1024px) {
  #root .four-zero-four-container .four-zero-four-wrapper,
  .modal-open .four-zero-four-container .four-zero-four-wrapper,
  .MuiPopover-root .four-zero-four-container .four-zero-four-wrapper {
    width: 70%;
  }
}
@media only screen and (max-width : 480px) {
  #root .four-zero-four-container .four-zero-four-wrapper,
  .modal-open .four-zero-four-container .four-zero-four-wrapper,
  .MuiPopover-root .four-zero-four-container .four-zero-four-wrapper {
    width: 90%;
  }
}
#root .four-zero-four-container.compact .four-zero-four-wrapper,
.modal-open .four-zero-four-container.compact .four-zero-four-wrapper,
.MuiPopover-root .four-zero-four-container.compact .four-zero-four-wrapper {
  width: auto;
}
#root .alert,
.modal-open .alert,
.MuiPopover-root .alert,
#root #root .alert-primary,
.modal-open #root .alert-primary,
.MuiPopover-root #root .alert-primary,
#root .modal-open .alert-primary,
.modal-open .modal-open .alert-primary,
.MuiPopover-root .modal-open .alert-primary,
#root .MuiPopover-root .alert-primary,
.modal-open .MuiPopover-root .alert-primary,
.MuiPopover-root .MuiPopover-root .alert-primary,
#root #root .alert-secondary,
.modal-open #root .alert-secondary,
.MuiPopover-root #root .alert-secondary,
#root .modal-open .alert-secondary,
.modal-open .modal-open .alert-secondary,
.MuiPopover-root .modal-open .alert-secondary,
#root .MuiPopover-root .alert-secondary,
.modal-open .MuiPopover-root .alert-secondary,
.MuiPopover-root .MuiPopover-root .alert-secondary,
#root #root .alert-success,
.modal-open #root .alert-success,
.MuiPopover-root #root .alert-success,
#root .modal-open .alert-success,
.modal-open .modal-open .alert-success,
.MuiPopover-root .modal-open .alert-success,
#root .MuiPopover-root .alert-success,
.modal-open .MuiPopover-root .alert-success,
.MuiPopover-root .MuiPopover-root .alert-success,
#root #root .alert-warning,
.modal-open #root .alert-warning,
.MuiPopover-root #root .alert-warning,
#root .modal-open .alert-warning,
.modal-open .modal-open .alert-warning,
.MuiPopover-root .modal-open .alert-warning,
#root .MuiPopover-root .alert-warning,
.modal-open .MuiPopover-root .alert-warning,
.MuiPopover-root .MuiPopover-root .alert-warning,
#root #root .alert-danger,
.modal-open #root .alert-danger,
.MuiPopover-root #root .alert-danger,
#root .modal-open .alert-danger,
.modal-open .modal-open .alert-danger,
.MuiPopover-root .modal-open .alert-danger,
#root .MuiPopover-root .alert-danger,
.modal-open .MuiPopover-root .alert-danger,
.MuiPopover-root .MuiPopover-root .alert-danger,
#root #root .alert-info,
.modal-open #root .alert-info,
.MuiPopover-root #root .alert-info,
#root .modal-open .alert-info,
.modal-open .modal-open .alert-info,
.MuiPopover-root .modal-open .alert-info,
#root .MuiPopover-root .alert-info,
.modal-open .MuiPopover-root .alert-info,
.MuiPopover-root .MuiPopover-root .alert-info,
#root #root .alert-dark,
.modal-open #root .alert-dark,
.MuiPopover-root #root .alert-dark,
#root .modal-open .alert-dark,
.modal-open .modal-open .alert-dark,
.MuiPopover-root .modal-open .alert-dark,
#root .MuiPopover-root .alert-dark,
.modal-open .MuiPopover-root .alert-dark,
.MuiPopover-root .MuiPopover-root .alert-dark,
#root #root .alert-light,
.modal-open #root .alert-light,
.MuiPopover-root #root .alert-light,
#root .modal-open .alert-light,
.modal-open .modal-open .alert-light,
.MuiPopover-root .modal-open .alert-light,
#root .MuiPopover-root .alert-light,
.modal-open .MuiPopover-root .alert-light,
.MuiPopover-root .MuiPopover-root .alert-light {
  padding: 0;
  border-radius: 3px;
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  border: none;
  color: #ffffff;
  font-weight: 600;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .alert a,
.modal-open .alert a,
.MuiPopover-root .alert a,
#root #root .alert-primary a,
.modal-open #root .alert-primary a,
.MuiPopover-root #root .alert-primary a,
#root .modal-open .alert-primary a,
.modal-open .modal-open .alert-primary a,
.MuiPopover-root .modal-open .alert-primary a,
#root .MuiPopover-root .alert-primary a,
.modal-open .MuiPopover-root .alert-primary a,
.MuiPopover-root .MuiPopover-root .alert-primary a,
#root #root .alert-secondary a,
.modal-open #root .alert-secondary a,
.MuiPopover-root #root .alert-secondary a,
#root .modal-open .alert-secondary a,
.modal-open .modal-open .alert-secondary a,
.MuiPopover-root .modal-open .alert-secondary a,
#root .MuiPopover-root .alert-secondary a,
.modal-open .MuiPopover-root .alert-secondary a,
.MuiPopover-root .MuiPopover-root .alert-secondary a,
#root #root .alert-success a,
.modal-open #root .alert-success a,
.MuiPopover-root #root .alert-success a,
#root .modal-open .alert-success a,
.modal-open .modal-open .alert-success a,
.MuiPopover-root .modal-open .alert-success a,
#root .MuiPopover-root .alert-success a,
.modal-open .MuiPopover-root .alert-success a,
.MuiPopover-root .MuiPopover-root .alert-success a,
#root #root .alert-warning a,
.modal-open #root .alert-warning a,
.MuiPopover-root #root .alert-warning a,
#root .modal-open .alert-warning a,
.modal-open .modal-open .alert-warning a,
.MuiPopover-root .modal-open .alert-warning a,
#root .MuiPopover-root .alert-warning a,
.modal-open .MuiPopover-root .alert-warning a,
.MuiPopover-root .MuiPopover-root .alert-warning a,
#root #root .alert-danger a,
.modal-open #root .alert-danger a,
.MuiPopover-root #root .alert-danger a,
#root .modal-open .alert-danger a,
.modal-open .modal-open .alert-danger a,
.MuiPopover-root .modal-open .alert-danger a,
#root .MuiPopover-root .alert-danger a,
.modal-open .MuiPopover-root .alert-danger a,
.MuiPopover-root .MuiPopover-root .alert-danger a,
#root #root .alert-info a,
.modal-open #root .alert-info a,
.MuiPopover-root #root .alert-info a,
#root .modal-open .alert-info a,
.modal-open .modal-open .alert-info a,
.MuiPopover-root .modal-open .alert-info a,
#root .MuiPopover-root .alert-info a,
.modal-open .MuiPopover-root .alert-info a,
.MuiPopover-root .MuiPopover-root .alert-info a,
#root #root .alert-dark a,
.modal-open #root .alert-dark a,
.MuiPopover-root #root .alert-dark a,
#root .modal-open .alert-dark a,
.modal-open .modal-open .alert-dark a,
.MuiPopover-root .modal-open .alert-dark a,
#root .MuiPopover-root .alert-dark a,
.modal-open .MuiPopover-root .alert-dark a,
.MuiPopover-root .MuiPopover-root .alert-dark a,
#root #root .alert-light a,
.modal-open #root .alert-light a,
.MuiPopover-root #root .alert-light a,
#root .modal-open .alert-light a,
.modal-open .modal-open .alert-light a,
.MuiPopover-root .modal-open .alert-light a,
#root .MuiPopover-root .alert-light a,
.modal-open .MuiPopover-root .alert-light a,
.MuiPopover-root .MuiPopover-root .alert-light a {
  color: #ffffff;
  text-decoration: underline;
}
#root .alert a:hover,
.modal-open .alert a:hover,
.MuiPopover-root .alert a:hover,
#root #root .alert-primary a:hover,
.modal-open #root .alert-primary a:hover,
.MuiPopover-root #root .alert-primary a:hover,
#root .modal-open .alert-primary a:hover,
.modal-open .modal-open .alert-primary a:hover,
.MuiPopover-root .modal-open .alert-primary a:hover,
#root .MuiPopover-root .alert-primary a:hover,
.modal-open .MuiPopover-root .alert-primary a:hover,
.MuiPopover-root .MuiPopover-root .alert-primary a:hover,
#root #root .alert-secondary a:hover,
.modal-open #root .alert-secondary a:hover,
.MuiPopover-root #root .alert-secondary a:hover,
#root .modal-open .alert-secondary a:hover,
.modal-open .modal-open .alert-secondary a:hover,
.MuiPopover-root .modal-open .alert-secondary a:hover,
#root .MuiPopover-root .alert-secondary a:hover,
.modal-open .MuiPopover-root .alert-secondary a:hover,
.MuiPopover-root .MuiPopover-root .alert-secondary a:hover,
#root #root .alert-success a:hover,
.modal-open #root .alert-success a:hover,
.MuiPopover-root #root .alert-success a:hover,
#root .modal-open .alert-success a:hover,
.modal-open .modal-open .alert-success a:hover,
.MuiPopover-root .modal-open .alert-success a:hover,
#root .MuiPopover-root .alert-success a:hover,
.modal-open .MuiPopover-root .alert-success a:hover,
.MuiPopover-root .MuiPopover-root .alert-success a:hover,
#root #root .alert-warning a:hover,
.modal-open #root .alert-warning a:hover,
.MuiPopover-root #root .alert-warning a:hover,
#root .modal-open .alert-warning a:hover,
.modal-open .modal-open .alert-warning a:hover,
.MuiPopover-root .modal-open .alert-warning a:hover,
#root .MuiPopover-root .alert-warning a:hover,
.modal-open .MuiPopover-root .alert-warning a:hover,
.MuiPopover-root .MuiPopover-root .alert-warning a:hover,
#root #root .alert-danger a:hover,
.modal-open #root .alert-danger a:hover,
.MuiPopover-root #root .alert-danger a:hover,
#root .modal-open .alert-danger a:hover,
.modal-open .modal-open .alert-danger a:hover,
.MuiPopover-root .modal-open .alert-danger a:hover,
#root .MuiPopover-root .alert-danger a:hover,
.modal-open .MuiPopover-root .alert-danger a:hover,
.MuiPopover-root .MuiPopover-root .alert-danger a:hover,
#root #root .alert-info a:hover,
.modal-open #root .alert-info a:hover,
.MuiPopover-root #root .alert-info a:hover,
#root .modal-open .alert-info a:hover,
.modal-open .modal-open .alert-info a:hover,
.MuiPopover-root .modal-open .alert-info a:hover,
#root .MuiPopover-root .alert-info a:hover,
.modal-open .MuiPopover-root .alert-info a:hover,
.MuiPopover-root .MuiPopover-root .alert-info a:hover,
#root #root .alert-dark a:hover,
.modal-open #root .alert-dark a:hover,
.MuiPopover-root #root .alert-dark a:hover,
#root .modal-open .alert-dark a:hover,
.modal-open .modal-open .alert-dark a:hover,
.MuiPopover-root .modal-open .alert-dark a:hover,
#root .MuiPopover-root .alert-dark a:hover,
.modal-open .MuiPopover-root .alert-dark a:hover,
.MuiPopover-root .MuiPopover-root .alert-dark a:hover,
#root #root .alert-light a:hover,
.modal-open #root .alert-light a:hover,
.MuiPopover-root #root .alert-light a:hover,
#root .modal-open .alert-light a:hover,
.modal-open .modal-open .alert-light a:hover,
.MuiPopover-root .modal-open .alert-light a:hover,
#root .MuiPopover-root .alert-light a:hover,
.modal-open .MuiPopover-root .alert-light a:hover,
.MuiPopover-root .MuiPopover-root .alert-light a:hover {
  color: rgba(255, 255, 255, 0.8);
}
#root .alert.show,
.modal-open .alert.show,
.MuiPopover-root .alert.show,
#root #root .alert-primary.show,
.modal-open #root .alert-primary.show,
.MuiPopover-root #root .alert-primary.show,
#root .modal-open .alert-primary.show,
.modal-open .modal-open .alert-primary.show,
.MuiPopover-root .modal-open .alert-primary.show,
#root .MuiPopover-root .alert-primary.show,
.modal-open .MuiPopover-root .alert-primary.show,
.MuiPopover-root .MuiPopover-root .alert-primary.show,
#root #root .alert-secondary.show,
.modal-open #root .alert-secondary.show,
.MuiPopover-root #root .alert-secondary.show,
#root .modal-open .alert-secondary.show,
.modal-open .modal-open .alert-secondary.show,
.MuiPopover-root .modal-open .alert-secondary.show,
#root .MuiPopover-root .alert-secondary.show,
.modal-open .MuiPopover-root .alert-secondary.show,
.MuiPopover-root .MuiPopover-root .alert-secondary.show,
#root #root .alert-success.show,
.modal-open #root .alert-success.show,
.MuiPopover-root #root .alert-success.show,
#root .modal-open .alert-success.show,
.modal-open .modal-open .alert-success.show,
.MuiPopover-root .modal-open .alert-success.show,
#root .MuiPopover-root .alert-success.show,
.modal-open .MuiPopover-root .alert-success.show,
.MuiPopover-root .MuiPopover-root .alert-success.show,
#root #root .alert-warning.show,
.modal-open #root .alert-warning.show,
.MuiPopover-root #root .alert-warning.show,
#root .modal-open .alert-warning.show,
.modal-open .modal-open .alert-warning.show,
.MuiPopover-root .modal-open .alert-warning.show,
#root .MuiPopover-root .alert-warning.show,
.modal-open .MuiPopover-root .alert-warning.show,
.MuiPopover-root .MuiPopover-root .alert-warning.show,
#root #root .alert-danger.show,
.modal-open #root .alert-danger.show,
.MuiPopover-root #root .alert-danger.show,
#root .modal-open .alert-danger.show,
.modal-open .modal-open .alert-danger.show,
.MuiPopover-root .modal-open .alert-danger.show,
#root .MuiPopover-root .alert-danger.show,
.modal-open .MuiPopover-root .alert-danger.show,
.MuiPopover-root .MuiPopover-root .alert-danger.show,
#root #root .alert-info.show,
.modal-open #root .alert-info.show,
.MuiPopover-root #root .alert-info.show,
#root .modal-open .alert-info.show,
.modal-open .modal-open .alert-info.show,
.MuiPopover-root .modal-open .alert-info.show,
#root .MuiPopover-root .alert-info.show,
.modal-open .MuiPopover-root .alert-info.show,
.MuiPopover-root .MuiPopover-root .alert-info.show,
#root #root .alert-dark.show,
.modal-open #root .alert-dark.show,
.MuiPopover-root #root .alert-dark.show,
#root .modal-open .alert-dark.show,
.modal-open .modal-open .alert-dark.show,
.MuiPopover-root .modal-open .alert-dark.show,
#root .MuiPopover-root .alert-dark.show,
.modal-open .MuiPopover-root .alert-dark.show,
.MuiPopover-root .MuiPopover-root .alert-dark.show,
#root #root .alert-light.show,
.modal-open #root .alert-light.show,
.MuiPopover-root #root .alert-light.show,
#root .modal-open .alert-light.show,
.modal-open .modal-open .alert-light.show,
.MuiPopover-root .modal-open .alert-light.show,
#root .MuiPopover-root .alert-light.show,
.modal-open .MuiPopover-root .alert-light.show,
.MuiPopover-root .MuiPopover-root .alert-light.show {
  opacity: 1;
}
#root .alert .alert-heading,
.modal-open .alert .alert-heading,
.MuiPopover-root .alert .alert-heading,
#root #root .alert-primary .alert-heading,
.modal-open #root .alert-primary .alert-heading,
.MuiPopover-root #root .alert-primary .alert-heading,
#root .modal-open .alert-primary .alert-heading,
.modal-open .modal-open .alert-primary .alert-heading,
.MuiPopover-root .modal-open .alert-primary .alert-heading,
#root .MuiPopover-root .alert-primary .alert-heading,
.modal-open .MuiPopover-root .alert-primary .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-primary .alert-heading,
#root #root .alert-secondary .alert-heading,
.modal-open #root .alert-secondary .alert-heading,
.MuiPopover-root #root .alert-secondary .alert-heading,
#root .modal-open .alert-secondary .alert-heading,
.modal-open .modal-open .alert-secondary .alert-heading,
.MuiPopover-root .modal-open .alert-secondary .alert-heading,
#root .MuiPopover-root .alert-secondary .alert-heading,
.modal-open .MuiPopover-root .alert-secondary .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-secondary .alert-heading,
#root #root .alert-success .alert-heading,
.modal-open #root .alert-success .alert-heading,
.MuiPopover-root #root .alert-success .alert-heading,
#root .modal-open .alert-success .alert-heading,
.modal-open .modal-open .alert-success .alert-heading,
.MuiPopover-root .modal-open .alert-success .alert-heading,
#root .MuiPopover-root .alert-success .alert-heading,
.modal-open .MuiPopover-root .alert-success .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-success .alert-heading,
#root #root .alert-warning .alert-heading,
.modal-open #root .alert-warning .alert-heading,
.MuiPopover-root #root .alert-warning .alert-heading,
#root .modal-open .alert-warning .alert-heading,
.modal-open .modal-open .alert-warning .alert-heading,
.MuiPopover-root .modal-open .alert-warning .alert-heading,
#root .MuiPopover-root .alert-warning .alert-heading,
.modal-open .MuiPopover-root .alert-warning .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-warning .alert-heading,
#root #root .alert-danger .alert-heading,
.modal-open #root .alert-danger .alert-heading,
.MuiPopover-root #root .alert-danger .alert-heading,
#root .modal-open .alert-danger .alert-heading,
.modal-open .modal-open .alert-danger .alert-heading,
.MuiPopover-root .modal-open .alert-danger .alert-heading,
#root .MuiPopover-root .alert-danger .alert-heading,
.modal-open .MuiPopover-root .alert-danger .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-danger .alert-heading,
#root #root .alert-info .alert-heading,
.modal-open #root .alert-info .alert-heading,
.MuiPopover-root #root .alert-info .alert-heading,
#root .modal-open .alert-info .alert-heading,
.modal-open .modal-open .alert-info .alert-heading,
.MuiPopover-root .modal-open .alert-info .alert-heading,
#root .MuiPopover-root .alert-info .alert-heading,
.modal-open .MuiPopover-root .alert-info .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-info .alert-heading,
#root #root .alert-dark .alert-heading,
.modal-open #root .alert-dark .alert-heading,
.MuiPopover-root #root .alert-dark .alert-heading,
#root .modal-open .alert-dark .alert-heading,
.modal-open .modal-open .alert-dark .alert-heading,
.MuiPopover-root .modal-open .alert-dark .alert-heading,
#root .MuiPopover-root .alert-dark .alert-heading,
.modal-open .MuiPopover-root .alert-dark .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-dark .alert-heading,
#root #root .alert-light .alert-heading,
.modal-open #root .alert-light .alert-heading,
.MuiPopover-root #root .alert-light .alert-heading,
#root .modal-open .alert-light .alert-heading,
.modal-open .modal-open .alert-light .alert-heading,
.MuiPopover-root .modal-open .alert-light .alert-heading,
#root .MuiPopover-root .alert-light .alert-heading,
.modal-open .MuiPopover-root .alert-light .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-light .alert-heading {
  padding: 18px;
  background-color: rgba(0, 75, 237, 0.75);
  color: #ffffff;
  font-size: 18px;
  line-height: 21px;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 0;
}
#root .alert .alert-heading a,
.modal-open .alert .alert-heading a,
.MuiPopover-root .alert .alert-heading a,
#root #root .alert-primary .alert-heading a,
.modal-open #root .alert-primary .alert-heading a,
.MuiPopover-root #root .alert-primary .alert-heading a,
#root .modal-open .alert-primary .alert-heading a,
.modal-open .modal-open .alert-primary .alert-heading a,
.MuiPopover-root .modal-open .alert-primary .alert-heading a,
#root .MuiPopover-root .alert-primary .alert-heading a,
.modal-open .MuiPopover-root .alert-primary .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-primary .alert-heading a,
#root #root .alert-secondary .alert-heading a,
.modal-open #root .alert-secondary .alert-heading a,
.MuiPopover-root #root .alert-secondary .alert-heading a,
#root .modal-open .alert-secondary .alert-heading a,
.modal-open .modal-open .alert-secondary .alert-heading a,
.MuiPopover-root .modal-open .alert-secondary .alert-heading a,
#root .MuiPopover-root .alert-secondary .alert-heading a,
.modal-open .MuiPopover-root .alert-secondary .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-secondary .alert-heading a,
#root #root .alert-success .alert-heading a,
.modal-open #root .alert-success .alert-heading a,
.MuiPopover-root #root .alert-success .alert-heading a,
#root .modal-open .alert-success .alert-heading a,
.modal-open .modal-open .alert-success .alert-heading a,
.MuiPopover-root .modal-open .alert-success .alert-heading a,
#root .MuiPopover-root .alert-success .alert-heading a,
.modal-open .MuiPopover-root .alert-success .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-success .alert-heading a,
#root #root .alert-warning .alert-heading a,
.modal-open #root .alert-warning .alert-heading a,
.MuiPopover-root #root .alert-warning .alert-heading a,
#root .modal-open .alert-warning .alert-heading a,
.modal-open .modal-open .alert-warning .alert-heading a,
.MuiPopover-root .modal-open .alert-warning .alert-heading a,
#root .MuiPopover-root .alert-warning .alert-heading a,
.modal-open .MuiPopover-root .alert-warning .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-warning .alert-heading a,
#root #root .alert-danger .alert-heading a,
.modal-open #root .alert-danger .alert-heading a,
.MuiPopover-root #root .alert-danger .alert-heading a,
#root .modal-open .alert-danger .alert-heading a,
.modal-open .modal-open .alert-danger .alert-heading a,
.MuiPopover-root .modal-open .alert-danger .alert-heading a,
#root .MuiPopover-root .alert-danger .alert-heading a,
.modal-open .MuiPopover-root .alert-danger .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-danger .alert-heading a,
#root #root .alert-info .alert-heading a,
.modal-open #root .alert-info .alert-heading a,
.MuiPopover-root #root .alert-info .alert-heading a,
#root .modal-open .alert-info .alert-heading a,
.modal-open .modal-open .alert-info .alert-heading a,
.MuiPopover-root .modal-open .alert-info .alert-heading a,
#root .MuiPopover-root .alert-info .alert-heading a,
.modal-open .MuiPopover-root .alert-info .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-info .alert-heading a,
#root #root .alert-dark .alert-heading a,
.modal-open #root .alert-dark .alert-heading a,
.MuiPopover-root #root .alert-dark .alert-heading a,
#root .modal-open .alert-dark .alert-heading a,
.modal-open .modal-open .alert-dark .alert-heading a,
.MuiPopover-root .modal-open .alert-dark .alert-heading a,
#root .MuiPopover-root .alert-dark .alert-heading a,
.modal-open .MuiPopover-root .alert-dark .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-dark .alert-heading a,
#root #root .alert-light .alert-heading a,
.modal-open #root .alert-light .alert-heading a,
.MuiPopover-root #root .alert-light .alert-heading a,
#root .modal-open .alert-light .alert-heading a,
.modal-open .modal-open .alert-light .alert-heading a,
.MuiPopover-root .modal-open .alert-light .alert-heading a,
#root .MuiPopover-root .alert-light .alert-heading a,
.modal-open .MuiPopover-root .alert-light .alert-heading a,
.MuiPopover-root .MuiPopover-root .alert-light .alert-heading a {
  color: #ffffff;
  text-decoration: underline;
}
#root .alert .alert-body,
.modal-open .alert .alert-body,
.MuiPopover-root .alert .alert-body,
#root #root .alert-primary .alert-body,
.modal-open #root .alert-primary .alert-body,
.MuiPopover-root #root .alert-primary .alert-body,
#root .modal-open .alert-primary .alert-body,
.modal-open .modal-open .alert-primary .alert-body,
.MuiPopover-root .modal-open .alert-primary .alert-body,
#root .MuiPopover-root .alert-primary .alert-body,
.modal-open .MuiPopover-root .alert-primary .alert-body,
.MuiPopover-root .MuiPopover-root .alert-primary .alert-body,
#root #root .alert-secondary .alert-body,
.modal-open #root .alert-secondary .alert-body,
.MuiPopover-root #root .alert-secondary .alert-body,
#root .modal-open .alert-secondary .alert-body,
.modal-open .modal-open .alert-secondary .alert-body,
.MuiPopover-root .modal-open .alert-secondary .alert-body,
#root .MuiPopover-root .alert-secondary .alert-body,
.modal-open .MuiPopover-root .alert-secondary .alert-body,
.MuiPopover-root .MuiPopover-root .alert-secondary .alert-body,
#root #root .alert-success .alert-body,
.modal-open #root .alert-success .alert-body,
.MuiPopover-root #root .alert-success .alert-body,
#root .modal-open .alert-success .alert-body,
.modal-open .modal-open .alert-success .alert-body,
.MuiPopover-root .modal-open .alert-success .alert-body,
#root .MuiPopover-root .alert-success .alert-body,
.modal-open .MuiPopover-root .alert-success .alert-body,
.MuiPopover-root .MuiPopover-root .alert-success .alert-body,
#root #root .alert-warning .alert-body,
.modal-open #root .alert-warning .alert-body,
.MuiPopover-root #root .alert-warning .alert-body,
#root .modal-open .alert-warning .alert-body,
.modal-open .modal-open .alert-warning .alert-body,
.MuiPopover-root .modal-open .alert-warning .alert-body,
#root .MuiPopover-root .alert-warning .alert-body,
.modal-open .MuiPopover-root .alert-warning .alert-body,
.MuiPopover-root .MuiPopover-root .alert-warning .alert-body,
#root #root .alert-danger .alert-body,
.modal-open #root .alert-danger .alert-body,
.MuiPopover-root #root .alert-danger .alert-body,
#root .modal-open .alert-danger .alert-body,
.modal-open .modal-open .alert-danger .alert-body,
.MuiPopover-root .modal-open .alert-danger .alert-body,
#root .MuiPopover-root .alert-danger .alert-body,
.modal-open .MuiPopover-root .alert-danger .alert-body,
.MuiPopover-root .MuiPopover-root .alert-danger .alert-body,
#root #root .alert-info .alert-body,
.modal-open #root .alert-info .alert-body,
.MuiPopover-root #root .alert-info .alert-body,
#root .modal-open .alert-info .alert-body,
.modal-open .modal-open .alert-info .alert-body,
.MuiPopover-root .modal-open .alert-info .alert-body,
#root .MuiPopover-root .alert-info .alert-body,
.modal-open .MuiPopover-root .alert-info .alert-body,
.MuiPopover-root .MuiPopover-root .alert-info .alert-body,
#root #root .alert-dark .alert-body,
.modal-open #root .alert-dark .alert-body,
.MuiPopover-root #root .alert-dark .alert-body,
#root .modal-open .alert-dark .alert-body,
.modal-open .modal-open .alert-dark .alert-body,
.MuiPopover-root .modal-open .alert-dark .alert-body,
#root .MuiPopover-root .alert-dark .alert-body,
.modal-open .MuiPopover-root .alert-dark .alert-body,
.MuiPopover-root .MuiPopover-root .alert-dark .alert-body,
#root #root .alert-light .alert-body,
.modal-open #root .alert-light .alert-body,
.MuiPopover-root #root .alert-light .alert-body,
#root .modal-open .alert-light .alert-body,
.modal-open .modal-open .alert-light .alert-body,
.MuiPopover-root .modal-open .alert-light .alert-body,
#root .MuiPopover-root .alert-light .alert-body,
.modal-open .MuiPopover-root .alert-light .alert-body,
.MuiPopover-root .MuiPopover-root .alert-light .alert-body {
  font-size: 14px;
  padding: 18px;
  width: 100%;
}
#root .alert.alert-sm .alert-body,
.modal-open .alert.alert-sm .alert-body,
.MuiPopover-root .alert.alert-sm .alert-body,
#root #root .alert-primary.alert-sm .alert-body,
.modal-open #root .alert-primary.alert-sm .alert-body,
.MuiPopover-root #root .alert-primary.alert-sm .alert-body,
#root .modal-open .alert-primary.alert-sm .alert-body,
.modal-open .modal-open .alert-primary.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-primary.alert-sm .alert-body,
#root .MuiPopover-root .alert-primary.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-primary.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-primary.alert-sm .alert-body,
#root #root .alert-secondary.alert-sm .alert-body,
.modal-open #root .alert-secondary.alert-sm .alert-body,
.MuiPopover-root #root .alert-secondary.alert-sm .alert-body,
#root .modal-open .alert-secondary.alert-sm .alert-body,
.modal-open .modal-open .alert-secondary.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-secondary.alert-sm .alert-body,
#root .MuiPopover-root .alert-secondary.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-secondary.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-secondary.alert-sm .alert-body,
#root #root .alert-success.alert-sm .alert-body,
.modal-open #root .alert-success.alert-sm .alert-body,
.MuiPopover-root #root .alert-success.alert-sm .alert-body,
#root .modal-open .alert-success.alert-sm .alert-body,
.modal-open .modal-open .alert-success.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-success.alert-sm .alert-body,
#root .MuiPopover-root .alert-success.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-success.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-success.alert-sm .alert-body,
#root #root .alert-warning.alert-sm .alert-body,
.modal-open #root .alert-warning.alert-sm .alert-body,
.MuiPopover-root #root .alert-warning.alert-sm .alert-body,
#root .modal-open .alert-warning.alert-sm .alert-body,
.modal-open .modal-open .alert-warning.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-warning.alert-sm .alert-body,
#root .MuiPopover-root .alert-warning.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-warning.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-warning.alert-sm .alert-body,
#root #root .alert-danger.alert-sm .alert-body,
.modal-open #root .alert-danger.alert-sm .alert-body,
.MuiPopover-root #root .alert-danger.alert-sm .alert-body,
#root .modal-open .alert-danger.alert-sm .alert-body,
.modal-open .modal-open .alert-danger.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-danger.alert-sm .alert-body,
#root .MuiPopover-root .alert-danger.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-danger.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-danger.alert-sm .alert-body,
#root #root .alert-info.alert-sm .alert-body,
.modal-open #root .alert-info.alert-sm .alert-body,
.MuiPopover-root #root .alert-info.alert-sm .alert-body,
#root .modal-open .alert-info.alert-sm .alert-body,
.modal-open .modal-open .alert-info.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-info.alert-sm .alert-body,
#root .MuiPopover-root .alert-info.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-info.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-info.alert-sm .alert-body,
#root #root .alert-dark.alert-sm .alert-body,
.modal-open #root .alert-dark.alert-sm .alert-body,
.MuiPopover-root #root .alert-dark.alert-sm .alert-body,
#root .modal-open .alert-dark.alert-sm .alert-body,
.modal-open .modal-open .alert-dark.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-dark.alert-sm .alert-body,
#root .MuiPopover-root .alert-dark.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-dark.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-dark.alert-sm .alert-body,
#root #root .alert-light.alert-sm .alert-body,
.modal-open #root .alert-light.alert-sm .alert-body,
.MuiPopover-root #root .alert-light.alert-sm .alert-body,
#root .modal-open .alert-light.alert-sm .alert-body,
.modal-open .modal-open .alert-light.alert-sm .alert-body,
.MuiPopover-root .modal-open .alert-light.alert-sm .alert-body,
#root .MuiPopover-root .alert-light.alert-sm .alert-body,
.modal-open .MuiPopover-root .alert-light.alert-sm .alert-body,
.MuiPopover-root .MuiPopover-root .alert-light.alert-sm .alert-body {
  padding: 10px;
}
#root .alert.has-children,
.modal-open .alert.has-children,
.MuiPopover-root .alert.has-children,
#root #root .alert-primary.has-children,
.modal-open #root .alert-primary.has-children,
.MuiPopover-root #root .alert-primary.has-children,
#root .modal-open .alert-primary.has-children,
.modal-open .modal-open .alert-primary.has-children,
.MuiPopover-root .modal-open .alert-primary.has-children,
#root .MuiPopover-root .alert-primary.has-children,
.modal-open .MuiPopover-root .alert-primary.has-children,
.MuiPopover-root .MuiPopover-root .alert-primary.has-children,
#root #root .alert-secondary.has-children,
.modal-open #root .alert-secondary.has-children,
.MuiPopover-root #root .alert-secondary.has-children,
#root .modal-open .alert-secondary.has-children,
.modal-open .modal-open .alert-secondary.has-children,
.MuiPopover-root .modal-open .alert-secondary.has-children,
#root .MuiPopover-root .alert-secondary.has-children,
.modal-open .MuiPopover-root .alert-secondary.has-children,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-children,
#root #root .alert-success.has-children,
.modal-open #root .alert-success.has-children,
.MuiPopover-root #root .alert-success.has-children,
#root .modal-open .alert-success.has-children,
.modal-open .modal-open .alert-success.has-children,
.MuiPopover-root .modal-open .alert-success.has-children,
#root .MuiPopover-root .alert-success.has-children,
.modal-open .MuiPopover-root .alert-success.has-children,
.MuiPopover-root .MuiPopover-root .alert-success.has-children,
#root #root .alert-warning.has-children,
.modal-open #root .alert-warning.has-children,
.MuiPopover-root #root .alert-warning.has-children,
#root .modal-open .alert-warning.has-children,
.modal-open .modal-open .alert-warning.has-children,
.MuiPopover-root .modal-open .alert-warning.has-children,
#root .MuiPopover-root .alert-warning.has-children,
.modal-open .MuiPopover-root .alert-warning.has-children,
.MuiPopover-root .MuiPopover-root .alert-warning.has-children,
#root #root .alert-danger.has-children,
.modal-open #root .alert-danger.has-children,
.MuiPopover-root #root .alert-danger.has-children,
#root .modal-open .alert-danger.has-children,
.modal-open .modal-open .alert-danger.has-children,
.MuiPopover-root .modal-open .alert-danger.has-children,
#root .MuiPopover-root .alert-danger.has-children,
.modal-open .MuiPopover-root .alert-danger.has-children,
.MuiPopover-root .MuiPopover-root .alert-danger.has-children,
#root #root .alert-info.has-children,
.modal-open #root .alert-info.has-children,
.MuiPopover-root #root .alert-info.has-children,
#root .modal-open .alert-info.has-children,
.modal-open .modal-open .alert-info.has-children,
.MuiPopover-root .modal-open .alert-info.has-children,
#root .MuiPopover-root .alert-info.has-children,
.modal-open .MuiPopover-root .alert-info.has-children,
.MuiPopover-root .MuiPopover-root .alert-info.has-children,
#root #root .alert-dark.has-children,
.modal-open #root .alert-dark.has-children,
.MuiPopover-root #root .alert-dark.has-children,
#root .modal-open .alert-dark.has-children,
.modal-open .modal-open .alert-dark.has-children,
.MuiPopover-root .modal-open .alert-dark.has-children,
#root .MuiPopover-root .alert-dark.has-children,
.modal-open .MuiPopover-root .alert-dark.has-children,
.MuiPopover-root .MuiPopover-root .alert-dark.has-children,
#root #root .alert-light.has-children,
.modal-open #root .alert-light.has-children,
.MuiPopover-root #root .alert-light.has-children,
#root .modal-open .alert-light.has-children,
.modal-open .modal-open .alert-light.has-children,
.MuiPopover-root .modal-open .alert-light.has-children,
#root .MuiPopover-root .alert-light.has-children,
.modal-open .MuiPopover-root .alert-light.has-children,
.MuiPopover-root .MuiPopover-root .alert-light.has-children {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .alert.has-icon,
.modal-open .alert.has-icon,
.MuiPopover-root .alert.has-icon,
#root #root .alert-primary.has-icon,
.modal-open #root .alert-primary.has-icon,
.MuiPopover-root #root .alert-primary.has-icon,
#root .modal-open .alert-primary.has-icon,
.modal-open .modal-open .alert-primary.has-icon,
.MuiPopover-root .modal-open .alert-primary.has-icon,
#root .MuiPopover-root .alert-primary.has-icon,
.modal-open .MuiPopover-root .alert-primary.has-icon,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon,
#root #root .alert-secondary.has-icon,
.modal-open #root .alert-secondary.has-icon,
.MuiPopover-root #root .alert-secondary.has-icon,
#root .modal-open .alert-secondary.has-icon,
.modal-open .modal-open .alert-secondary.has-icon,
.MuiPopover-root .modal-open .alert-secondary.has-icon,
#root .MuiPopover-root .alert-secondary.has-icon,
.modal-open .MuiPopover-root .alert-secondary.has-icon,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon,
#root #root .alert-success.has-icon,
.modal-open #root .alert-success.has-icon,
.MuiPopover-root #root .alert-success.has-icon,
#root .modal-open .alert-success.has-icon,
.modal-open .modal-open .alert-success.has-icon,
.MuiPopover-root .modal-open .alert-success.has-icon,
#root .MuiPopover-root .alert-success.has-icon,
.modal-open .MuiPopover-root .alert-success.has-icon,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon,
#root #root .alert-warning.has-icon,
.modal-open #root .alert-warning.has-icon,
.MuiPopover-root #root .alert-warning.has-icon,
#root .modal-open .alert-warning.has-icon,
.modal-open .modal-open .alert-warning.has-icon,
.MuiPopover-root .modal-open .alert-warning.has-icon,
#root .MuiPopover-root .alert-warning.has-icon,
.modal-open .MuiPopover-root .alert-warning.has-icon,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon,
#root #root .alert-danger.has-icon,
.modal-open #root .alert-danger.has-icon,
.MuiPopover-root #root .alert-danger.has-icon,
#root .modal-open .alert-danger.has-icon,
.modal-open .modal-open .alert-danger.has-icon,
.MuiPopover-root .modal-open .alert-danger.has-icon,
#root .MuiPopover-root .alert-danger.has-icon,
.modal-open .MuiPopover-root .alert-danger.has-icon,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon,
#root #root .alert-info.has-icon,
.modal-open #root .alert-info.has-icon,
.MuiPopover-root #root .alert-info.has-icon,
#root .modal-open .alert-info.has-icon,
.modal-open .modal-open .alert-info.has-icon,
.MuiPopover-root .modal-open .alert-info.has-icon,
#root .MuiPopover-root .alert-info.has-icon,
.modal-open .MuiPopover-root .alert-info.has-icon,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon,
#root #root .alert-dark.has-icon,
.modal-open #root .alert-dark.has-icon,
.MuiPopover-root #root .alert-dark.has-icon,
#root .modal-open .alert-dark.has-icon,
.modal-open .modal-open .alert-dark.has-icon,
.MuiPopover-root .modal-open .alert-dark.has-icon,
#root .MuiPopover-root .alert-dark.has-icon,
.modal-open .MuiPopover-root .alert-dark.has-icon,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon,
#root #root .alert-light.has-icon,
.modal-open #root .alert-light.has-icon,
.MuiPopover-root #root .alert-light.has-icon,
#root .modal-open .alert-light.has-icon,
.modal-open .modal-open .alert-light.has-icon,
.MuiPopover-root .modal-open .alert-light.has-icon,
#root .MuiPopover-root .alert-light.has-icon,
.modal-open .MuiPopover-root .alert-light.has-icon,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon {
  display: flex;
  flex-direction: row;
}
#root .alert.has-icon .alert-icon,
.modal-open .alert.has-icon .alert-icon,
.MuiPopover-root .alert.has-icon .alert-icon,
#root #root .alert-primary.has-icon .alert-icon,
.modal-open #root .alert-primary.has-icon .alert-icon,
.MuiPopover-root #root .alert-primary.has-icon .alert-icon,
#root .modal-open .alert-primary.has-icon .alert-icon,
.modal-open .modal-open .alert-primary.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-primary.has-icon .alert-icon,
#root .MuiPopover-root .alert-primary.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-primary.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon .alert-icon,
#root #root .alert-secondary.has-icon .alert-icon,
.modal-open #root .alert-secondary.has-icon .alert-icon,
.MuiPopover-root #root .alert-secondary.has-icon .alert-icon,
#root .modal-open .alert-secondary.has-icon .alert-icon,
.modal-open .modal-open .alert-secondary.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-secondary.has-icon .alert-icon,
#root .MuiPopover-root .alert-secondary.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-secondary.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon .alert-icon,
#root #root .alert-success.has-icon .alert-icon,
.modal-open #root .alert-success.has-icon .alert-icon,
.MuiPopover-root #root .alert-success.has-icon .alert-icon,
#root .modal-open .alert-success.has-icon .alert-icon,
.modal-open .modal-open .alert-success.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-success.has-icon .alert-icon,
#root .MuiPopover-root .alert-success.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-success.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon .alert-icon,
#root #root .alert-warning.has-icon .alert-icon,
.modal-open #root .alert-warning.has-icon .alert-icon,
.MuiPopover-root #root .alert-warning.has-icon .alert-icon,
#root .modal-open .alert-warning.has-icon .alert-icon,
.modal-open .modal-open .alert-warning.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-warning.has-icon .alert-icon,
#root .MuiPopover-root .alert-warning.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-warning.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon .alert-icon,
#root #root .alert-danger.has-icon .alert-icon,
.modal-open #root .alert-danger.has-icon .alert-icon,
.MuiPopover-root #root .alert-danger.has-icon .alert-icon,
#root .modal-open .alert-danger.has-icon .alert-icon,
.modal-open .modal-open .alert-danger.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-danger.has-icon .alert-icon,
#root .MuiPopover-root .alert-danger.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-danger.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon .alert-icon,
#root #root .alert-info.has-icon .alert-icon,
.modal-open #root .alert-info.has-icon .alert-icon,
.MuiPopover-root #root .alert-info.has-icon .alert-icon,
#root .modal-open .alert-info.has-icon .alert-icon,
.modal-open .modal-open .alert-info.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-info.has-icon .alert-icon,
#root .MuiPopover-root .alert-info.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-info.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon .alert-icon,
#root #root .alert-dark.has-icon .alert-icon,
.modal-open #root .alert-dark.has-icon .alert-icon,
.MuiPopover-root #root .alert-dark.has-icon .alert-icon,
#root .modal-open .alert-dark.has-icon .alert-icon,
.modal-open .modal-open .alert-dark.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-dark.has-icon .alert-icon,
#root .MuiPopover-root .alert-dark.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-dark.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon .alert-icon,
#root #root .alert-light.has-icon .alert-icon,
.modal-open #root .alert-light.has-icon .alert-icon,
.MuiPopover-root #root .alert-light.has-icon .alert-icon,
#root .modal-open .alert-light.has-icon .alert-icon,
.modal-open .modal-open .alert-light.has-icon .alert-icon,
.MuiPopover-root .modal-open .alert-light.has-icon .alert-icon,
#root .MuiPopover-root .alert-light.has-icon .alert-icon,
.modal-open .MuiPopover-root .alert-light.has-icon .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon .alert-icon {
  padding-left: 18px;
  padding-top: 13px;
}
#root .alert.has-icon .alert-icon i,
.modal-open .alert.has-icon .alert-icon i,
.MuiPopover-root .alert.has-icon .alert-icon i,
#root #root .alert-primary.has-icon .alert-icon i,
.modal-open #root .alert-primary.has-icon .alert-icon i,
.MuiPopover-root #root .alert-primary.has-icon .alert-icon i,
#root .modal-open .alert-primary.has-icon .alert-icon i,
.modal-open .modal-open .alert-primary.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-primary.has-icon .alert-icon i,
#root .MuiPopover-root .alert-primary.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-primary.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon .alert-icon i,
#root #root .alert-secondary.has-icon .alert-icon i,
.modal-open #root .alert-secondary.has-icon .alert-icon i,
.MuiPopover-root #root .alert-secondary.has-icon .alert-icon i,
#root .modal-open .alert-secondary.has-icon .alert-icon i,
.modal-open .modal-open .alert-secondary.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-secondary.has-icon .alert-icon i,
#root .MuiPopover-root .alert-secondary.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-secondary.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon .alert-icon i,
#root #root .alert-success.has-icon .alert-icon i,
.modal-open #root .alert-success.has-icon .alert-icon i,
.MuiPopover-root #root .alert-success.has-icon .alert-icon i,
#root .modal-open .alert-success.has-icon .alert-icon i,
.modal-open .modal-open .alert-success.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-success.has-icon .alert-icon i,
#root .MuiPopover-root .alert-success.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-success.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon .alert-icon i,
#root #root .alert-warning.has-icon .alert-icon i,
.modal-open #root .alert-warning.has-icon .alert-icon i,
.MuiPopover-root #root .alert-warning.has-icon .alert-icon i,
#root .modal-open .alert-warning.has-icon .alert-icon i,
.modal-open .modal-open .alert-warning.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-warning.has-icon .alert-icon i,
#root .MuiPopover-root .alert-warning.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-warning.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon .alert-icon i,
#root #root .alert-danger.has-icon .alert-icon i,
.modal-open #root .alert-danger.has-icon .alert-icon i,
.MuiPopover-root #root .alert-danger.has-icon .alert-icon i,
#root .modal-open .alert-danger.has-icon .alert-icon i,
.modal-open .modal-open .alert-danger.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-danger.has-icon .alert-icon i,
#root .MuiPopover-root .alert-danger.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-danger.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon .alert-icon i,
#root #root .alert-info.has-icon .alert-icon i,
.modal-open #root .alert-info.has-icon .alert-icon i,
.MuiPopover-root #root .alert-info.has-icon .alert-icon i,
#root .modal-open .alert-info.has-icon .alert-icon i,
.modal-open .modal-open .alert-info.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-info.has-icon .alert-icon i,
#root .MuiPopover-root .alert-info.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-info.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon .alert-icon i,
#root #root .alert-dark.has-icon .alert-icon i,
.modal-open #root .alert-dark.has-icon .alert-icon i,
.MuiPopover-root #root .alert-dark.has-icon .alert-icon i,
#root .modal-open .alert-dark.has-icon .alert-icon i,
.modal-open .modal-open .alert-dark.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-dark.has-icon .alert-icon i,
#root .MuiPopover-root .alert-dark.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-dark.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon .alert-icon i,
#root #root .alert-light.has-icon .alert-icon i,
.modal-open #root .alert-light.has-icon .alert-icon i,
.MuiPopover-root #root .alert-light.has-icon .alert-icon i,
#root .modal-open .alert-light.has-icon .alert-icon i,
.modal-open .modal-open .alert-light.has-icon .alert-icon i,
.MuiPopover-root .modal-open .alert-light.has-icon .alert-icon i,
#root .MuiPopover-root .alert-light.has-icon .alert-icon i,
.modal-open .MuiPopover-root .alert-light.has-icon .alert-icon i,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon .alert-icon i {
  font-size: 32px;
  line-height: 32px;
}
#root .alert.has-icon .alert-content,
.modal-open .alert.has-icon .alert-content,
.MuiPopover-root .alert.has-icon .alert-content,
#root #root .alert-primary.has-icon .alert-content,
.modal-open #root .alert-primary.has-icon .alert-content,
.MuiPopover-root #root .alert-primary.has-icon .alert-content,
#root .modal-open .alert-primary.has-icon .alert-content,
.modal-open .modal-open .alert-primary.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-primary.has-icon .alert-content,
#root .MuiPopover-root .alert-primary.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-primary.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon .alert-content,
#root #root .alert-secondary.has-icon .alert-content,
.modal-open #root .alert-secondary.has-icon .alert-content,
.MuiPopover-root #root .alert-secondary.has-icon .alert-content,
#root .modal-open .alert-secondary.has-icon .alert-content,
.modal-open .modal-open .alert-secondary.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-secondary.has-icon .alert-content,
#root .MuiPopover-root .alert-secondary.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-secondary.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon .alert-content,
#root #root .alert-success.has-icon .alert-content,
.modal-open #root .alert-success.has-icon .alert-content,
.MuiPopover-root #root .alert-success.has-icon .alert-content,
#root .modal-open .alert-success.has-icon .alert-content,
.modal-open .modal-open .alert-success.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-success.has-icon .alert-content,
#root .MuiPopover-root .alert-success.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-success.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon .alert-content,
#root #root .alert-warning.has-icon .alert-content,
.modal-open #root .alert-warning.has-icon .alert-content,
.MuiPopover-root #root .alert-warning.has-icon .alert-content,
#root .modal-open .alert-warning.has-icon .alert-content,
.modal-open .modal-open .alert-warning.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-warning.has-icon .alert-content,
#root .MuiPopover-root .alert-warning.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-warning.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon .alert-content,
#root #root .alert-danger.has-icon .alert-content,
.modal-open #root .alert-danger.has-icon .alert-content,
.MuiPopover-root #root .alert-danger.has-icon .alert-content,
#root .modal-open .alert-danger.has-icon .alert-content,
.modal-open .modal-open .alert-danger.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-danger.has-icon .alert-content,
#root .MuiPopover-root .alert-danger.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-danger.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon .alert-content,
#root #root .alert-info.has-icon .alert-content,
.modal-open #root .alert-info.has-icon .alert-content,
.MuiPopover-root #root .alert-info.has-icon .alert-content,
#root .modal-open .alert-info.has-icon .alert-content,
.modal-open .modal-open .alert-info.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-info.has-icon .alert-content,
#root .MuiPopover-root .alert-info.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-info.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon .alert-content,
#root #root .alert-dark.has-icon .alert-content,
.modal-open #root .alert-dark.has-icon .alert-content,
.MuiPopover-root #root .alert-dark.has-icon .alert-content,
#root .modal-open .alert-dark.has-icon .alert-content,
.modal-open .modal-open .alert-dark.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-dark.has-icon .alert-content,
#root .MuiPopover-root .alert-dark.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-dark.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon .alert-content,
#root #root .alert-light.has-icon .alert-content,
.modal-open #root .alert-light.has-icon .alert-content,
.MuiPopover-root #root .alert-light.has-icon .alert-content,
#root .modal-open .alert-light.has-icon .alert-content,
.modal-open .modal-open .alert-light.has-icon .alert-content,
.MuiPopover-root .modal-open .alert-light.has-icon .alert-content,
#root .MuiPopover-root .alert-light.has-icon .alert-content,
.modal-open .MuiPopover-root .alert-light.has-icon .alert-content,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon .alert-content {
  flex: 1;
}
#root .alert.has-icon .alert-content .alert-heading,
.modal-open .alert.has-icon .alert-content .alert-heading,
.MuiPopover-root .alert.has-icon .alert-content .alert-heading,
#root #root .alert-primary.has-icon .alert-content .alert-heading,
.modal-open #root .alert-primary.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-primary.has-icon .alert-content .alert-heading,
#root .modal-open .alert-primary.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-primary.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-primary.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-primary.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-primary.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon .alert-content .alert-heading,
#root #root .alert-secondary.has-icon .alert-content .alert-heading,
.modal-open #root .alert-secondary.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-secondary.has-icon .alert-content .alert-heading,
#root .modal-open .alert-secondary.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-secondary.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-secondary.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-secondary.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-secondary.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon .alert-content .alert-heading,
#root #root .alert-success.has-icon .alert-content .alert-heading,
.modal-open #root .alert-success.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-success.has-icon .alert-content .alert-heading,
#root .modal-open .alert-success.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-success.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-success.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-success.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-success.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon .alert-content .alert-heading,
#root #root .alert-warning.has-icon .alert-content .alert-heading,
.modal-open #root .alert-warning.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-warning.has-icon .alert-content .alert-heading,
#root .modal-open .alert-warning.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-warning.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-warning.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-warning.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-warning.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon .alert-content .alert-heading,
#root #root .alert-danger.has-icon .alert-content .alert-heading,
.modal-open #root .alert-danger.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-danger.has-icon .alert-content .alert-heading,
#root .modal-open .alert-danger.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-danger.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-danger.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-danger.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-danger.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon .alert-content .alert-heading,
#root #root .alert-info.has-icon .alert-content .alert-heading,
.modal-open #root .alert-info.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-info.has-icon .alert-content .alert-heading,
#root .modal-open .alert-info.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-info.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-info.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-info.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-info.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon .alert-content .alert-heading,
#root #root .alert-dark.has-icon .alert-content .alert-heading,
.modal-open #root .alert-dark.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-dark.has-icon .alert-content .alert-heading,
#root .modal-open .alert-dark.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-dark.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-dark.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-dark.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-dark.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon .alert-content .alert-heading,
#root #root .alert-light.has-icon .alert-content .alert-heading,
.modal-open #root .alert-light.has-icon .alert-content .alert-heading,
.MuiPopover-root #root .alert-light.has-icon .alert-content .alert-heading,
#root .modal-open .alert-light.has-icon .alert-content .alert-heading,
.modal-open .modal-open .alert-light.has-icon .alert-content .alert-heading,
.MuiPopover-root .modal-open .alert-light.has-icon .alert-content .alert-heading,
#root .MuiPopover-root .alert-light.has-icon .alert-content .alert-heading,
.modal-open .MuiPopover-root .alert-light.has-icon .alert-content .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon .alert-content .alert-heading {
  background-color: transparent;
}
#root .alert.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .alert.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .alert.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon .alert-content .alert-heading ~ .alert-body,
#root #root .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open #root .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
#root .modal-open .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-light.has-icon .alert-content .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon .alert-content .alert-heading ~ .alert-body {
  padding-top: 0;
}
#root .alert.has-icon.alert-tight .alert-icon,
.modal-open .alert.has-icon.alert-tight .alert-icon,
.MuiPopover-root .alert.has-icon.alert-tight .alert-icon,
#root #root .alert-primary.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-primary.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-primary.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-primary.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-primary.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-primary.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-icon,
#root #root .alert-secondary.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-secondary.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-secondary.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-secondary.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-secondary.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-secondary.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-icon,
#root #root .alert-success.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-success.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-success.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-success.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-success.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-success.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-success.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-icon,
#root #root .alert-warning.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-warning.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-warning.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-warning.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-warning.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-warning.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-icon,
#root #root .alert-danger.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-danger.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-danger.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-danger.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-danger.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-danger.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-icon,
#root #root .alert-info.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-info.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-info.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-info.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-info.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-info.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-info.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-icon,
#root #root .alert-dark.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-dark.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-dark.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-dark.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-dark.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-dark.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-icon,
#root #root .alert-light.has-icon.alert-tight .alert-icon,
.modal-open #root .alert-light.has-icon.alert-tight .alert-icon,
.MuiPopover-root #root .alert-light.has-icon.alert-tight .alert-icon,
#root .modal-open .alert-light.has-icon.alert-tight .alert-icon,
.modal-open .modal-open .alert-light.has-icon.alert-tight .alert-icon,
.MuiPopover-root .modal-open .alert-light.has-icon.alert-tight .alert-icon,
#root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-icon,
.modal-open .MuiPopover-root .alert-light.has-icon.alert-tight .alert-icon,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-icon {
  padding-left: 12px;
  padding-top: 10px;
}
#root .alert.has-icon.alert-tight .alert-content,
.modal-open .alert.has-icon.alert-tight .alert-content,
.MuiPopover-root .alert.has-icon.alert-tight .alert-content,
#root #root .alert-primary.has-icon.alert-tight .alert-content,
.modal-open #root .alert-primary.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-primary.has-icon.alert-tight .alert-content,
#root .modal-open .alert-primary.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-primary.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-primary.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content,
#root #root .alert-secondary.has-icon.alert-tight .alert-content,
.modal-open #root .alert-secondary.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-secondary.has-icon.alert-tight .alert-content,
#root .modal-open .alert-secondary.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-secondary.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-secondary.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content,
#root #root .alert-success.has-icon.alert-tight .alert-content,
.modal-open #root .alert-success.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-success.has-icon.alert-tight .alert-content,
#root .modal-open .alert-success.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-success.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-success.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content,
#root #root .alert-warning.has-icon.alert-tight .alert-content,
.modal-open #root .alert-warning.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-warning.has-icon.alert-tight .alert-content,
#root .modal-open .alert-warning.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-warning.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-warning.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content,
#root #root .alert-danger.has-icon.alert-tight .alert-content,
.modal-open #root .alert-danger.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-danger.has-icon.alert-tight .alert-content,
#root .modal-open .alert-danger.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-danger.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-danger.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content,
#root #root .alert-info.has-icon.alert-tight .alert-content,
.modal-open #root .alert-info.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-info.has-icon.alert-tight .alert-content,
#root .modal-open .alert-info.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-info.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-info.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content,
#root #root .alert-dark.has-icon.alert-tight .alert-content,
.modal-open #root .alert-dark.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-dark.has-icon.alert-tight .alert-content,
#root .modal-open .alert-dark.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-dark.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-dark.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content,
#root #root .alert-light.has-icon.alert-tight .alert-content,
.modal-open #root .alert-light.has-icon.alert-tight .alert-content,
.MuiPopover-root #root .alert-light.has-icon.alert-tight .alert-content,
#root .modal-open .alert-light.has-icon.alert-tight .alert-content,
.modal-open .modal-open .alert-light.has-icon.alert-tight .alert-content,
.MuiPopover-root .modal-open .alert-light.has-icon.alert-tight .alert-content,
#root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content,
.modal-open .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content {
  padding: 1px;
}
#root .alert.has-icon.alert-tight .alert-content p,
.modal-open .alert.has-icon.alert-tight .alert-content p,
.MuiPopover-root .alert.has-icon.alert-tight .alert-content p,
#root #root .alert-primary.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-primary.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-primary.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-primary.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-primary.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-primary.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content p,
#root #root .alert-secondary.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-secondary.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-secondary.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-secondary.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-secondary.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-secondary.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content p,
#root #root .alert-success.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-success.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-success.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-success.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-success.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-success.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content p,
#root #root .alert-warning.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-warning.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-warning.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-warning.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-warning.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-warning.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content p,
#root #root .alert-danger.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-danger.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-danger.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-danger.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-danger.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-danger.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content p,
#root #root .alert-info.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-info.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-info.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-info.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-info.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-info.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content p,
#root #root .alert-dark.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-dark.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-dark.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-dark.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-dark.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-dark.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content p,
#root #root .alert-light.has-icon.alert-tight .alert-content p,
.modal-open #root .alert-light.has-icon.alert-tight .alert-content p,
.MuiPopover-root #root .alert-light.has-icon.alert-tight .alert-content p,
#root .modal-open .alert-light.has-icon.alert-tight .alert-content p,
.modal-open .modal-open .alert-light.has-icon.alert-tight .alert-content p,
.MuiPopover-root .modal-open .alert-light.has-icon.alert-tight .alert-content p,
#root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content p,
.modal-open .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content p,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content p {
  margin-bottom: 0.5rem;
}
#root .alert.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .alert.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .alert.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-content p:last-of-type,
#root #root .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open #root .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root #root .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
#root .modal-open .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .modal-open .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
#root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-content p:last-of-type {
  margin-bottom: 0;
}
#root .alert.has-icon.alert-tight .alert-heading,
.modal-open .alert.has-icon.alert-tight .alert-heading,
.MuiPopover-root .alert.has-icon.alert-tight .alert-heading,
#root #root .alert-primary.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-primary.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-primary.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-primary.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-primary.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-primary.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-heading,
#root #root .alert-secondary.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-secondary.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-secondary.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-secondary.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-secondary.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-secondary.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-heading,
#root #root .alert-success.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-success.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-success.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-success.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-success.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-success.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-success.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-heading,
#root #root .alert-warning.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-warning.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-warning.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-warning.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-warning.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-warning.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-heading,
#root #root .alert-danger.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-danger.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-danger.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-danger.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-danger.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-danger.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-heading,
#root #root .alert-info.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-info.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-info.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-info.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-info.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-info.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-info.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-heading,
#root #root .alert-dark.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-dark.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-dark.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-dark.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-dark.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-dark.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-heading,
#root #root .alert-light.has-icon.alert-tight .alert-heading,
.modal-open #root .alert-light.has-icon.alert-tight .alert-heading,
.MuiPopover-root #root .alert-light.has-icon.alert-tight .alert-heading,
#root .modal-open .alert-light.has-icon.alert-tight .alert-heading,
.modal-open .modal-open .alert-light.has-icon.alert-tight .alert-heading,
.MuiPopover-root .modal-open .alert-light.has-icon.alert-tight .alert-heading,
#root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-heading,
.modal-open .MuiPopover-root .alert-light.has-icon.alert-tight .alert-heading,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-heading {
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 10px;
}
#root .alert.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .alert.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .alert.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-primary.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-secondary.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-success.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-warning.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-danger.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-info.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-dark.has-icon.alert-tight .alert-heading ~ .alert-body,
#root #root .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open #root .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root #root .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .modal-open .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .modal-open .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .modal-open .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
#root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
.modal-open .MuiPopover-root .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body,
.MuiPopover-root .MuiPopover-root .alert-light.has-icon.alert-tight .alert-heading ~ .alert-body {
  padding: 10px;
  padding-top: 0;
  padding-bottom: 14px;
}
#root .alert .alert-content p:last-of-type,
.modal-open .alert .alert-content p:last-of-type,
.MuiPopover-root .alert .alert-content p:last-of-type,
#root #root .alert-primary .alert-content p:last-of-type,
.modal-open #root .alert-primary .alert-content p:last-of-type,
.MuiPopover-root #root .alert-primary .alert-content p:last-of-type,
#root .modal-open .alert-primary .alert-content p:last-of-type,
.modal-open .modal-open .alert-primary .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-primary .alert-content p:last-of-type,
#root .MuiPopover-root .alert-primary .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-primary .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-primary .alert-content p:last-of-type,
#root #root .alert-secondary .alert-content p:last-of-type,
.modal-open #root .alert-secondary .alert-content p:last-of-type,
.MuiPopover-root #root .alert-secondary .alert-content p:last-of-type,
#root .modal-open .alert-secondary .alert-content p:last-of-type,
.modal-open .modal-open .alert-secondary .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-secondary .alert-content p:last-of-type,
#root .MuiPopover-root .alert-secondary .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-secondary .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-secondary .alert-content p:last-of-type,
#root #root .alert-success .alert-content p:last-of-type,
.modal-open #root .alert-success .alert-content p:last-of-type,
.MuiPopover-root #root .alert-success .alert-content p:last-of-type,
#root .modal-open .alert-success .alert-content p:last-of-type,
.modal-open .modal-open .alert-success .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-success .alert-content p:last-of-type,
#root .MuiPopover-root .alert-success .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-success .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-success .alert-content p:last-of-type,
#root #root .alert-warning .alert-content p:last-of-type,
.modal-open #root .alert-warning .alert-content p:last-of-type,
.MuiPopover-root #root .alert-warning .alert-content p:last-of-type,
#root .modal-open .alert-warning .alert-content p:last-of-type,
.modal-open .modal-open .alert-warning .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-warning .alert-content p:last-of-type,
#root .MuiPopover-root .alert-warning .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-warning .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-warning .alert-content p:last-of-type,
#root #root .alert-danger .alert-content p:last-of-type,
.modal-open #root .alert-danger .alert-content p:last-of-type,
.MuiPopover-root #root .alert-danger .alert-content p:last-of-type,
#root .modal-open .alert-danger .alert-content p:last-of-type,
.modal-open .modal-open .alert-danger .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-danger .alert-content p:last-of-type,
#root .MuiPopover-root .alert-danger .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-danger .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-danger .alert-content p:last-of-type,
#root #root .alert-info .alert-content p:last-of-type,
.modal-open #root .alert-info .alert-content p:last-of-type,
.MuiPopover-root #root .alert-info .alert-content p:last-of-type,
#root .modal-open .alert-info .alert-content p:last-of-type,
.modal-open .modal-open .alert-info .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-info .alert-content p:last-of-type,
#root .MuiPopover-root .alert-info .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-info .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-info .alert-content p:last-of-type,
#root #root .alert-dark .alert-content p:last-of-type,
.modal-open #root .alert-dark .alert-content p:last-of-type,
.MuiPopover-root #root .alert-dark .alert-content p:last-of-type,
#root .modal-open .alert-dark .alert-content p:last-of-type,
.modal-open .modal-open .alert-dark .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-dark .alert-content p:last-of-type,
#root .MuiPopover-root .alert-dark .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-dark .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-dark .alert-content p:last-of-type,
#root #root .alert-light .alert-content p:last-of-type,
.modal-open #root .alert-light .alert-content p:last-of-type,
.MuiPopover-root #root .alert-light .alert-content p:last-of-type,
#root .modal-open .alert-light .alert-content p:last-of-type,
.modal-open .modal-open .alert-light .alert-content p:last-of-type,
.MuiPopover-root .modal-open .alert-light .alert-content p:last-of-type,
#root .MuiPopover-root .alert-light .alert-content p:last-of-type,
.modal-open .MuiPopover-root .alert-light .alert-content p:last-of-type,
.MuiPopover-root .MuiPopover-root .alert-light .alert-content p:last-of-type {
  margin-bottom: 0;
}
#root .alert .alert-close,
.modal-open .alert .alert-close,
.MuiPopover-root .alert .alert-close,
#root #root .alert-primary .alert-close,
.modal-open #root .alert-primary .alert-close,
.MuiPopover-root #root .alert-primary .alert-close,
#root .modal-open .alert-primary .alert-close,
.modal-open .modal-open .alert-primary .alert-close,
.MuiPopover-root .modal-open .alert-primary .alert-close,
#root .MuiPopover-root .alert-primary .alert-close,
.modal-open .MuiPopover-root .alert-primary .alert-close,
.MuiPopover-root .MuiPopover-root .alert-primary .alert-close,
#root #root .alert-secondary .alert-close,
.modal-open #root .alert-secondary .alert-close,
.MuiPopover-root #root .alert-secondary .alert-close,
#root .modal-open .alert-secondary .alert-close,
.modal-open .modal-open .alert-secondary .alert-close,
.MuiPopover-root .modal-open .alert-secondary .alert-close,
#root .MuiPopover-root .alert-secondary .alert-close,
.modal-open .MuiPopover-root .alert-secondary .alert-close,
.MuiPopover-root .MuiPopover-root .alert-secondary .alert-close,
#root #root .alert-success .alert-close,
.modal-open #root .alert-success .alert-close,
.MuiPopover-root #root .alert-success .alert-close,
#root .modal-open .alert-success .alert-close,
.modal-open .modal-open .alert-success .alert-close,
.MuiPopover-root .modal-open .alert-success .alert-close,
#root .MuiPopover-root .alert-success .alert-close,
.modal-open .MuiPopover-root .alert-success .alert-close,
.MuiPopover-root .MuiPopover-root .alert-success .alert-close,
#root #root .alert-warning .alert-close,
.modal-open #root .alert-warning .alert-close,
.MuiPopover-root #root .alert-warning .alert-close,
#root .modal-open .alert-warning .alert-close,
.modal-open .modal-open .alert-warning .alert-close,
.MuiPopover-root .modal-open .alert-warning .alert-close,
#root .MuiPopover-root .alert-warning .alert-close,
.modal-open .MuiPopover-root .alert-warning .alert-close,
.MuiPopover-root .MuiPopover-root .alert-warning .alert-close,
#root #root .alert-danger .alert-close,
.modal-open #root .alert-danger .alert-close,
.MuiPopover-root #root .alert-danger .alert-close,
#root .modal-open .alert-danger .alert-close,
.modal-open .modal-open .alert-danger .alert-close,
.MuiPopover-root .modal-open .alert-danger .alert-close,
#root .MuiPopover-root .alert-danger .alert-close,
.modal-open .MuiPopover-root .alert-danger .alert-close,
.MuiPopover-root .MuiPopover-root .alert-danger .alert-close,
#root #root .alert-info .alert-close,
.modal-open #root .alert-info .alert-close,
.MuiPopover-root #root .alert-info .alert-close,
#root .modal-open .alert-info .alert-close,
.modal-open .modal-open .alert-info .alert-close,
.MuiPopover-root .modal-open .alert-info .alert-close,
#root .MuiPopover-root .alert-info .alert-close,
.modal-open .MuiPopover-root .alert-info .alert-close,
.MuiPopover-root .MuiPopover-root .alert-info .alert-close,
#root #root .alert-dark .alert-close,
.modal-open #root .alert-dark .alert-close,
.MuiPopover-root #root .alert-dark .alert-close,
#root .modal-open .alert-dark .alert-close,
.modal-open .modal-open .alert-dark .alert-close,
.MuiPopover-root .modal-open .alert-dark .alert-close,
#root .MuiPopover-root .alert-dark .alert-close,
.modal-open .MuiPopover-root .alert-dark .alert-close,
.MuiPopover-root .MuiPopover-root .alert-dark .alert-close,
#root #root .alert-light .alert-close,
.modal-open #root .alert-light .alert-close,
.MuiPopover-root #root .alert-light .alert-close,
#root .modal-open .alert-light .alert-close,
.modal-open .modal-open .alert-light .alert-close,
.MuiPopover-root .modal-open .alert-light .alert-close,
#root .MuiPopover-root .alert-light .alert-close,
.modal-open .MuiPopover-root .alert-light .alert-close,
.MuiPopover-root .MuiPopover-root .alert-light .alert-close {
  position: absolute;
  top: 6px;
  right: 10px;
  color: #ffffff;
  opacity: 0.6;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .alert .alert-close:hover,
.modal-open .alert .alert-close:hover,
.MuiPopover-root .alert .alert-close:hover,
#root #root .alert-primary .alert-close:hover,
.modal-open #root .alert-primary .alert-close:hover,
.MuiPopover-root #root .alert-primary .alert-close:hover,
#root .modal-open .alert-primary .alert-close:hover,
.modal-open .modal-open .alert-primary .alert-close:hover,
.MuiPopover-root .modal-open .alert-primary .alert-close:hover,
#root .MuiPopover-root .alert-primary .alert-close:hover,
.modal-open .MuiPopover-root .alert-primary .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-primary .alert-close:hover,
#root #root .alert-secondary .alert-close:hover,
.modal-open #root .alert-secondary .alert-close:hover,
.MuiPopover-root #root .alert-secondary .alert-close:hover,
#root .modal-open .alert-secondary .alert-close:hover,
.modal-open .modal-open .alert-secondary .alert-close:hover,
.MuiPopover-root .modal-open .alert-secondary .alert-close:hover,
#root .MuiPopover-root .alert-secondary .alert-close:hover,
.modal-open .MuiPopover-root .alert-secondary .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-secondary .alert-close:hover,
#root #root .alert-success .alert-close:hover,
.modal-open #root .alert-success .alert-close:hover,
.MuiPopover-root #root .alert-success .alert-close:hover,
#root .modal-open .alert-success .alert-close:hover,
.modal-open .modal-open .alert-success .alert-close:hover,
.MuiPopover-root .modal-open .alert-success .alert-close:hover,
#root .MuiPopover-root .alert-success .alert-close:hover,
.modal-open .MuiPopover-root .alert-success .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-success .alert-close:hover,
#root #root .alert-warning .alert-close:hover,
.modal-open #root .alert-warning .alert-close:hover,
.MuiPopover-root #root .alert-warning .alert-close:hover,
#root .modal-open .alert-warning .alert-close:hover,
.modal-open .modal-open .alert-warning .alert-close:hover,
.MuiPopover-root .modal-open .alert-warning .alert-close:hover,
#root .MuiPopover-root .alert-warning .alert-close:hover,
.modal-open .MuiPopover-root .alert-warning .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-warning .alert-close:hover,
#root #root .alert-danger .alert-close:hover,
.modal-open #root .alert-danger .alert-close:hover,
.MuiPopover-root #root .alert-danger .alert-close:hover,
#root .modal-open .alert-danger .alert-close:hover,
.modal-open .modal-open .alert-danger .alert-close:hover,
.MuiPopover-root .modal-open .alert-danger .alert-close:hover,
#root .MuiPopover-root .alert-danger .alert-close:hover,
.modal-open .MuiPopover-root .alert-danger .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-danger .alert-close:hover,
#root #root .alert-info .alert-close:hover,
.modal-open #root .alert-info .alert-close:hover,
.MuiPopover-root #root .alert-info .alert-close:hover,
#root .modal-open .alert-info .alert-close:hover,
.modal-open .modal-open .alert-info .alert-close:hover,
.MuiPopover-root .modal-open .alert-info .alert-close:hover,
#root .MuiPopover-root .alert-info .alert-close:hover,
.modal-open .MuiPopover-root .alert-info .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-info .alert-close:hover,
#root #root .alert-dark .alert-close:hover,
.modal-open #root .alert-dark .alert-close:hover,
.MuiPopover-root #root .alert-dark .alert-close:hover,
#root .modal-open .alert-dark .alert-close:hover,
.modal-open .modal-open .alert-dark .alert-close:hover,
.MuiPopover-root .modal-open .alert-dark .alert-close:hover,
#root .MuiPopover-root .alert-dark .alert-close:hover,
.modal-open .MuiPopover-root .alert-dark .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-dark .alert-close:hover,
#root #root .alert-light .alert-close:hover,
.modal-open #root .alert-light .alert-close:hover,
.MuiPopover-root #root .alert-light .alert-close:hover,
#root .modal-open .alert-light .alert-close:hover,
.modal-open .modal-open .alert-light .alert-close:hover,
.MuiPopover-root .modal-open .alert-light .alert-close:hover,
#root .MuiPopover-root .alert-light .alert-close:hover,
.modal-open .MuiPopover-root .alert-light .alert-close:hover,
.MuiPopover-root .MuiPopover-root .alert-light .alert-close:hover {
  opacity: 1;
}
#root .alert-primary,
.modal-open .alert-primary,
.MuiPopover-root .alert-primary {
  background-color: rgba(0, 75, 237, 0.75);
}
#root .alert-primary .alert-heading,
.modal-open .alert-primary .alert-heading,
.MuiPopover-root .alert-primary .alert-heading {
  background-color: rgba(0, 75, 237, 0.75);
}
#root .alert-secondary,
.modal-open .alert-secondary,
.MuiPopover-root .alert-secondary {
  background-color: rgba(74, 82, 90, 0.75);
}
#root .alert-secondary .alert-heading,
.modal-open .alert-secondary .alert-heading,
.MuiPopover-root .alert-secondary .alert-heading {
  background-color: rgba(74, 82, 90, 0.75);
}
#root .alert-success,
.modal-open .alert-success,
.MuiPopover-root .alert-success {
  background-color: rgba(61, 220, 151, 0.75);
}
#root .alert-success .alert-heading,
.modal-open .alert-success .alert-heading,
.MuiPopover-root .alert-success .alert-heading {
  background-color: rgba(61, 220, 151, 0.75);
}
#root .alert-warning,
.modal-open .alert-warning,
.MuiPopover-root .alert-warning {
  background-color: rgba(250, 207, 67, 0.75);
  color: #24272b;
}
#root .alert-warning a,
.modal-open .alert-warning a,
.MuiPopover-root .alert-warning a,
#root .alert-warning .btn,
.modal-open .alert-warning .btn,
.MuiPopover-root .alert-warning .btn,
#root .alert-warning i.fal,
.modal-open .alert-warning i.fal,
.MuiPopover-root .alert-warning i.fal {
  color: #24272b;
}
#root .alert-warning .alert-heading,
.modal-open .alert-warning .alert-heading,
.MuiPopover-root .alert-warning .alert-heading {
  background-color: rgba(250, 207, 67, 0.75);
}
#root .alert-warning .learn-more,
.modal-open .alert-warning .learn-more,
.MuiPopover-root .alert-warning .learn-more {
  color: #24272b !important;
  border-color: #24272b !important;
}
#root .alert-warning .learn-more:hover,
.modal-open .alert-warning .learn-more:hover,
.MuiPopover-root .alert-warning .learn-more:hover {
  background-color: #24272b !important;
  color: #ffffff !important;
}
#root .alert-danger,
.modal-open .alert-danger,
.MuiPopover-root .alert-danger {
  background-color: rgba(231, 29, 54, 0.75);
}
#root .alert-danger .alert-heading,
.modal-open .alert-danger .alert-heading,
.MuiPopover-root .alert-danger .alert-heading {
  background-color: rgba(231, 29, 54, 0.75);
}
#root .alert-info,
.modal-open .alert-info,
.MuiPopover-root .alert-info {
  background-color: rgba(17, 169, 240, 0.7);
}
#root .alert-info .btn-info,
.modal-open .alert-info .btn-info,
.MuiPopover-root .alert-info .btn-info {
  background-color: #0d92d0 !important;
  color: #ffffff !important;
}
#root .alert-info .alert-heading,
.modal-open .alert-info .alert-heading,
.MuiPopover-root .alert-info .alert-heading {
  background-color: rgba(17, 169, 240, 0.7);
}
#root .alert-dark,
.modal-open .alert-dark,
.MuiPopover-root .alert-dark {
  background-color: #24272b;
}
#root .alert-dark .alert-heading,
.modal-open .alert-dark .alert-heading,
.MuiPopover-root .alert-dark .alert-heading {
  background-color: #24272b;
}
#root .alert-light,
.modal-open .alert-light,
.MuiPopover-root .alert-light {
  background-color: rgba(227, 230, 233, 0.7);
  color: rgba(36, 39, 43, 0.7);
}
#root .alert-light a,
.modal-open .alert-light a,
.MuiPopover-root .alert-light a,
#root .alert-light .btn,
.modal-open .alert-light .btn,
.MuiPopover-root .alert-light .btn,
#root .alert-light i.fal,
.modal-open .alert-light i.fal,
.MuiPopover-root .alert-light i.fal {
  color: rgba(36, 39, 43, 0.7);
}
#root .alert-light .btn i.fal,
.modal-open .alert-light .btn i.fal,
.MuiPopover-root .alert-light .btn i.fal {
  color: #ffffff;
}
#root .alert-light .alert-heading,
.modal-open .alert-light .alert-heading,
.MuiPopover-root .alert-light .alert-heading {
  background-color: rgba(227, 230, 233, 0.7);
}
#root .inline-error,
.modal-open .inline-error,
.MuiPopover-root .inline-error,
#root .inline-success,
.modal-open .inline-success,
.MuiPopover-root .inline-success {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transform: scale(0);
}
#root .inline-error .alert,
.modal-open .inline-error .alert,
.MuiPopover-root .inline-error .alert,
#root .inline-success .alert,
.modal-open .inline-success .alert,
.MuiPopover-root .inline-success .alert,
#root .inline-error #root .alert-primary,
.modal-open .inline-error #root .alert-primary,
.MuiPopover-root .inline-error #root .alert-primary,
#root .inline-success #root .alert-primary,
.modal-open .inline-success #root .alert-primary,
.MuiPopover-root .inline-success #root .alert-primary,
#root .inline-error .modal-open .alert-primary,
.modal-open .inline-error .modal-open .alert-primary,
.MuiPopover-root .inline-error .modal-open .alert-primary,
#root .inline-success .modal-open .alert-primary,
.modal-open .inline-success .modal-open .alert-primary,
.MuiPopover-root .inline-success .modal-open .alert-primary,
#root .inline-error .MuiPopover-root .alert-primary,
.modal-open .inline-error .MuiPopover-root .alert-primary,
.MuiPopover-root .inline-error .MuiPopover-root .alert-primary,
#root .inline-success .MuiPopover-root .alert-primary,
.modal-open .inline-success .MuiPopover-root .alert-primary,
.MuiPopover-root .inline-success .MuiPopover-root .alert-primary,
#root .inline-error #root .alert-secondary,
.modal-open .inline-error #root .alert-secondary,
.MuiPopover-root .inline-error #root .alert-secondary,
#root .inline-success #root .alert-secondary,
.modal-open .inline-success #root .alert-secondary,
.MuiPopover-root .inline-success #root .alert-secondary,
#root .inline-error .modal-open .alert-secondary,
.modal-open .inline-error .modal-open .alert-secondary,
.MuiPopover-root .inline-error .modal-open .alert-secondary,
#root .inline-success .modal-open .alert-secondary,
.modal-open .inline-success .modal-open .alert-secondary,
.MuiPopover-root .inline-success .modal-open .alert-secondary,
#root .inline-error .MuiPopover-root .alert-secondary,
.modal-open .inline-error .MuiPopover-root .alert-secondary,
.MuiPopover-root .inline-error .MuiPopover-root .alert-secondary,
#root .inline-success .MuiPopover-root .alert-secondary,
.modal-open .inline-success .MuiPopover-root .alert-secondary,
.MuiPopover-root .inline-success .MuiPopover-root .alert-secondary,
#root .inline-error #root .alert-success,
.modal-open .inline-error #root .alert-success,
.MuiPopover-root .inline-error #root .alert-success,
#root .inline-success #root .alert-success,
.modal-open .inline-success #root .alert-success,
.MuiPopover-root .inline-success #root .alert-success,
#root .inline-error .modal-open .alert-success,
.modal-open .inline-error .modal-open .alert-success,
.MuiPopover-root .inline-error .modal-open .alert-success,
#root .inline-success .modal-open .alert-success,
.modal-open .inline-success .modal-open .alert-success,
.MuiPopover-root .inline-success .modal-open .alert-success,
#root .inline-error .MuiPopover-root .alert-success,
.modal-open .inline-error .MuiPopover-root .alert-success,
.MuiPopover-root .inline-error .MuiPopover-root .alert-success,
#root .inline-success .MuiPopover-root .alert-success,
.modal-open .inline-success .MuiPopover-root .alert-success,
.MuiPopover-root .inline-success .MuiPopover-root .alert-success,
#root .inline-error #root .alert-warning,
.modal-open .inline-error #root .alert-warning,
.MuiPopover-root .inline-error #root .alert-warning,
#root .inline-success #root .alert-warning,
.modal-open .inline-success #root .alert-warning,
.MuiPopover-root .inline-success #root .alert-warning,
#root .inline-error .modal-open .alert-warning,
.modal-open .inline-error .modal-open .alert-warning,
.MuiPopover-root .inline-error .modal-open .alert-warning,
#root .inline-success .modal-open .alert-warning,
.modal-open .inline-success .modal-open .alert-warning,
.MuiPopover-root .inline-success .modal-open .alert-warning,
#root .inline-error .MuiPopover-root .alert-warning,
.modal-open .inline-error .MuiPopover-root .alert-warning,
.MuiPopover-root .inline-error .MuiPopover-root .alert-warning,
#root .inline-success .MuiPopover-root .alert-warning,
.modal-open .inline-success .MuiPopover-root .alert-warning,
.MuiPopover-root .inline-success .MuiPopover-root .alert-warning,
#root .inline-error #root .alert-danger,
.modal-open .inline-error #root .alert-danger,
.MuiPopover-root .inline-error #root .alert-danger,
#root .inline-success #root .alert-danger,
.modal-open .inline-success #root .alert-danger,
.MuiPopover-root .inline-success #root .alert-danger,
#root .inline-error .modal-open .alert-danger,
.modal-open .inline-error .modal-open .alert-danger,
.MuiPopover-root .inline-error .modal-open .alert-danger,
#root .inline-success .modal-open .alert-danger,
.modal-open .inline-success .modal-open .alert-danger,
.MuiPopover-root .inline-success .modal-open .alert-danger,
#root .inline-error .MuiPopover-root .alert-danger,
.modal-open .inline-error .MuiPopover-root .alert-danger,
.MuiPopover-root .inline-error .MuiPopover-root .alert-danger,
#root .inline-success .MuiPopover-root .alert-danger,
.modal-open .inline-success .MuiPopover-root .alert-danger,
.MuiPopover-root .inline-success .MuiPopover-root .alert-danger,
#root .inline-error #root .alert-info,
.modal-open .inline-error #root .alert-info,
.MuiPopover-root .inline-error #root .alert-info,
#root .inline-success #root .alert-info,
.modal-open .inline-success #root .alert-info,
.MuiPopover-root .inline-success #root .alert-info,
#root .inline-error .modal-open .alert-info,
.modal-open .inline-error .modal-open .alert-info,
.MuiPopover-root .inline-error .modal-open .alert-info,
#root .inline-success .modal-open .alert-info,
.modal-open .inline-success .modal-open .alert-info,
.MuiPopover-root .inline-success .modal-open .alert-info,
#root .inline-error .MuiPopover-root .alert-info,
.modal-open .inline-error .MuiPopover-root .alert-info,
.MuiPopover-root .inline-error .MuiPopover-root .alert-info,
#root .inline-success .MuiPopover-root .alert-info,
.modal-open .inline-success .MuiPopover-root .alert-info,
.MuiPopover-root .inline-success .MuiPopover-root .alert-info,
#root .inline-error #root .alert-dark,
.modal-open .inline-error #root .alert-dark,
.MuiPopover-root .inline-error #root .alert-dark,
#root .inline-success #root .alert-dark,
.modal-open .inline-success #root .alert-dark,
.MuiPopover-root .inline-success #root .alert-dark,
#root .inline-error .modal-open .alert-dark,
.modal-open .inline-error .modal-open .alert-dark,
.MuiPopover-root .inline-error .modal-open .alert-dark,
#root .inline-success .modal-open .alert-dark,
.modal-open .inline-success .modal-open .alert-dark,
.MuiPopover-root .inline-success .modal-open .alert-dark,
#root .inline-error .MuiPopover-root .alert-dark,
.modal-open .inline-error .MuiPopover-root .alert-dark,
.MuiPopover-root .inline-error .MuiPopover-root .alert-dark,
#root .inline-success .MuiPopover-root .alert-dark,
.modal-open .inline-success .MuiPopover-root .alert-dark,
.MuiPopover-root .inline-success .MuiPopover-root .alert-dark,
#root .inline-error #root .alert-light,
.modal-open .inline-error #root .alert-light,
.MuiPopover-root .inline-error #root .alert-light,
#root .inline-success #root .alert-light,
.modal-open .inline-success #root .alert-light,
.MuiPopover-root .inline-success #root .alert-light,
#root .inline-error .modal-open .alert-light,
.modal-open .inline-error .modal-open .alert-light,
.MuiPopover-root .inline-error .modal-open .alert-light,
#root .inline-success .modal-open .alert-light,
.modal-open .inline-success .modal-open .alert-light,
.MuiPopover-root .inline-success .modal-open .alert-light,
#root .inline-error .MuiPopover-root .alert-light,
.modal-open .inline-error .MuiPopover-root .alert-light,
.MuiPopover-root .inline-error .MuiPopover-root .alert-light,
#root .inline-success .MuiPopover-root .alert-light,
.modal-open .inline-success .MuiPopover-root .alert-light,
.MuiPopover-root .inline-success .MuiPopover-root .alert-light {
  color: #ffffff;
  cursor: default;
}
#root .inline-error.active,
.modal-open .inline-error.active,
.MuiPopover-root .inline-error.active,
#root .inline-success.active,
.modal-open .inline-success.active,
.MuiPopover-root .inline-success.active {
  transform: scale(1);
}
#root .inline-error .btn,
.modal-open .inline-error .btn,
.MuiPopover-root .inline-error .btn,
#root .inline-success .btn,
.modal-open .inline-success .btn,
.MuiPopover-root .inline-success .btn {
  margin-top: -3px;
  margin-left: 10px;
}
#root .inline-error .btn i,
.modal-open .inline-error .btn i,
.MuiPopover-root .inline-error .btn i,
#root .inline-success .btn i,
.modal-open .inline-success .btn i,
.MuiPopover-root .inline-success .btn i {
  color: #ffffff;
  opacity: 0.5;
}
#root .inline-error .btn:hover i,
.modal-open .inline-error .btn:hover i,
.MuiPopover-root .inline-error .btn:hover i,
#root .inline-success .btn:hover i,
.modal-open .inline-success .btn:hover i,
.MuiPopover-root .inline-success .btn:hover i {
  opacity: 1;
}
#root .wizard-step-enter,
.modal-open .wizard-step-enter,
.MuiPopover-root .wizard-step-enter {
  opacity: 0;
  transform: translate(0, 30px);
}
#root .wizard-step-enter-active,
.modal-open .wizard-step-enter-active,
.MuiPopover-root .wizard-step-enter-active {
  opacity: 1;
  transition: all ease-in-out 300ms;
  transform: translate(0, 0);
}
#root .wizard-step-exit,
.modal-open .wizard-step-exit,
.MuiPopover-root .wizard-step-exit {
  opacity: 1;
  transform: translate(0, 0);
}
#root .wizard-step-exit-active,
.modal-open .wizard-step-exit-active,
.MuiPopover-root .wizard-step-exit-active {
  opacity: 0;
  transition: all ease-in-out 300ms;
  transform: translate(0, 30px);
}
#root .wizard-help-step-enter,
.modal-open .wizard-help-step-enter,
.MuiPopover-root .wizard-help-step-enter {
  opacity: 0;
  transform: translate(0, 30px);
}
#root .wizard-help-step-enter-active,
.modal-open .wizard-help-step-enter-active,
.MuiPopover-root .wizard-help-step-enter-active {
  opacity: 1;
  transition: all ease-in-out 300ms;
  transform: translate(0, 0);
}
#root .wizard-help-step-exit,
.modal-open .wizard-help-step-exit,
.MuiPopover-root .wizard-help-step-exit {
  opacity: 1;
  transform: translate(0, 0);
}
#root .wizard-help-step-exit-active,
.modal-open .wizard-help-step-exit-active,
.MuiPopover-root .wizard-help-step-exit-active {
  opacity: 0;
  transition: all ease-in-out 300ms;
  transform: translate(0, 30px);
}
#root .fade-in,
.modal-open .fade-in,
.MuiPopover-root .fade-in {
  -webkit-transition: opacity ease-in-out 0.4s;
  -moz-transition: opacity ease-in-out 0.4s;
  -ms-transition: opacity ease-in-out 0.4s;
  -o-transition: opacity ease-in-out 0.4s;
  opacity: 0;
  pointer-events: none;
}
#root .fade-in.active,
.modal-open .fade-in.active,
.MuiPopover-root .fade-in.active {
  opacity: 1;
  pointer-events: all;
}
#root .publish-rocket,
.modal-open .publish-rocket,
.MuiPopover-root .publish-rocket {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
  background: #004bed;
  border-radius: 70px;
  margin-bottom: 20px;
  overflow: hidden;
}
#root .publish-rocket > i,
.modal-open .publish-rocket > i,
.MuiPopover-root .publish-rocket > i {
  -webkit-transform: translate(-32px, -23px) rotate(-45deg);
  -moz-transform: translate(-32px, -23px) rotate(-45deg);
  -ms-transform: translate(-32px, -23px) rotate(-45deg);
  -o-transform: translate(-32px, -23px) rotate(-45deg);
  transition: all ease-in 1s;
  transition-delay: 1s;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.4) -6px 6px 4px;
  opacity: 1;
  pointer-events: none;
}
#root .publish-rocket .big-thumb i,
.modal-open .publish-rocket .big-thumb i,
.MuiPopover-root .publish-rocket .big-thumb i {
  color: white;
  font-size: 72px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
}
#root .publish-rocket .rocket-smoke,
.modal-open .publish-rocket .rocket-smoke,
.MuiPopover-root .publish-rocket .rocket-smoke {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 20px);
  -moz-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  -o-transform: translate(-50%, 20px);
  background-color: #ffffff;
  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.4) 0 6px 4px;
  transform-origin: bottom;
}
#root .publish-rocket.active > i,
.modal-open .publish-rocket.active > i,
.MuiPopover-root .publish-rocket.active > i {
  -webkit-transform: translate(-32px, -800px) rotate(-45deg);
  -moz-transform: translate(-32px, -800px) rotate(-45deg);
  -ms-transform: translate(-32px, -800px) rotate(-45deg);
  -o-transform: translate(-32px, -800px) rotate(-45deg);
  opacity: 0;
}
#root .publish-rocket.active .big-thumb i,
.modal-open .publish-rocket.active .big-thumb i,
.MuiPopover-root .publish-rocket.active .big-thumb i {
  -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -webkit-animation: ThumbsUp 0.5s 2s linear 1 forwards;
  -moz-animation: ThumbsUp 0.5s 2s linear 1 forwards;
  -ms-animation: ThumbsUp 0.5s 2s linear 1 forwards;
  animation: ThumbsUp 0.5s 2s linear 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs1,
.modal-open .publish-rocket.active .rocket-smoke.rs1,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs1 {
  -webkit-animation: RS1 1s 0.1s ease-out 1 forwards;
  -moz-animation: RS1 1s 0.1s ease-out 1 forwards;
  -ms-animation: RS1 1s 0.1s ease-out 1 forwards;
  animation: RS1 1s 0.1s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs2,
.modal-open .publish-rocket.active .rocket-smoke.rs2,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs2 {
  -webkit-animation: RS2 1s 0.2s ease-out 1 forwards;
  -moz-animation: RS2 1s 0.2s ease-out 1 forwards;
  -ms-animation: RS2 1s 0.2s ease-out 1 forwards;
  animation: RS2 1s 0.2s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs3,
.modal-open .publish-rocket.active .rocket-smoke.rs3,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs3 {
  -webkit-animation: RS1 1s 0.3s ease-out 1 forwards;
  -moz-animation: RS1 1s 0.3s ease-out 1 forwards;
  -ms-animation: RS1 1s 0.3s ease-out 1 forwards;
  animation: RS1 1s 0.3s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs4,
.modal-open .publish-rocket.active .rocket-smoke.rs4,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs4 {
  -webkit-animation: RS3 1s 0.4s ease-out 1 forwards;
  -moz-animation: RS3 1s 0.4s ease-out 1 forwards;
  -ms-animation: RS3 1s 0.4s ease-out 1 forwards;
  animation: RS3 1s 0.4s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs5,
.modal-open .publish-rocket.active .rocket-smoke.rs5,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs5 {
  -webkit-animation: RS4 1s 0.5s ease-out 1 forwards;
  -moz-animation: RS4 1s 0.5s ease-out 1 forwards;
  -ms-animation: RS4 1s 0.5s ease-out 1 forwards;
  animation: RS4 1s 0.5s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs6,
.modal-open .publish-rocket.active .rocket-smoke.rs6,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs6 {
  -webkit-animation: RS1 1s 0.6s ease-out 1 forwards;
  -moz-animation: RS1 1s 0.6s ease-out 1 forwards;
  -ms-animation: RS1 1s 0.6s ease-out 1 forwards;
  animation: RS1 1s 0.6s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs7,
.modal-open .publish-rocket.active .rocket-smoke.rs7,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs7 {
  -webkit-animation: RS2 1s 0.7s ease-out 1 forwards;
  -moz-animation: RS2 1s 0.7s ease-out 1 forwards;
  -ms-animation: RS2 1s 0.7s ease-out 1 forwards;
  animation: RS2 1s 0.7s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs8,
.modal-open .publish-rocket.active .rocket-smoke.rs8,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs8 {
  -webkit-animation: RS3 1s 0.8s ease-out 1 forwards;
  -moz-animation: RS3 1s 0.8s ease-out 1 forwards;
  -ms-animation: RS3 1s 0.8s ease-out 1 forwards;
  animation: RS3 1s 0.8s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs9,
.modal-open .publish-rocket.active .rocket-smoke.rs9,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs9 {
  -webkit-animation: RS4 1s 0.9s ease-out 1 forwards;
  -moz-animation: RS4 1s 0.9s ease-out 1 forwards;
  -ms-animation: RS4 1s 0.9s ease-out 1 forwards;
  animation: RS4 1s 0.9s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rs10,
.modal-open .publish-rocket.active .rocket-smoke.rs10,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rs10 {
  -webkit-animation: RS3 1s 1s ease-out 1 forwards;
  -moz-animation: RS3 1s 1s ease-out 1 forwards;
  -ms-animation: RS3 1s 1s ease-out 1 forwards;
  animation: RS3 1s 1s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr1,
.modal-open .publish-rocket.active .rocket-smoke.rsr1,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr1 {
  -webkit-animation: RSR4 1s 0.1s ease-out 1 forwards;
  -moz-animation: RSR4 1s 0.1s ease-out 1 forwards;
  -ms-animation: RSR4 1s 0.1s ease-out 1 forwards;
  animation: RSR4 1s 0.1s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr2,
.modal-open .publish-rocket.active .rocket-smoke.rsr2,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr2 {
  -webkit-animation: RSR2 1s 0.2s ease-out 1 forwards;
  -moz-animation: RSR2 1s 0.2s ease-out 1 forwards;
  -ms-animation: RSR2 1s 0.2s ease-out 1 forwards;
  animation: RSR2 1s 0.2s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr3,
.modal-open .publish-rocket.active .rocket-smoke.rsr3,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr3 {
  -webkit-animation: RSR3 1s 0.3s ease-out 1 forwards;
  -moz-animation: RSR3 1s 0.3s ease-out 1 forwards;
  -ms-animation: RSR3 1s 0.3s ease-out 1 forwards;
  animation: RSR3 1s 0.3s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr4,
.modal-open .publish-rocket.active .rocket-smoke.rsr4,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr4 {
  -webkit-animation: RSR1 1s 0.4s ease-out 1 forwards;
  -moz-animation: RSR1 1s 0.4s ease-out 1 forwards;
  -ms-animation: RSR1 1s 0.4s ease-out 1 forwards;
  animation: RSR1 1s 0.4s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr5,
.modal-open .publish-rocket.active .rocket-smoke.rsr5,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr5 {
  -webkit-animation: RSR1 1s 0.5s ease-out 1 forwards;
  -moz-animation: RSR1 1s 0.5s ease-out 1 forwards;
  -ms-animation: RSR1 1s 0.5s ease-out 1 forwards;
  animation: RSR1 1s 0.5s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr6,
.modal-open .publish-rocket.active .rocket-smoke.rsr6,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr6 {
  -webkit-animation: RSR4 1s 0.6s ease-out 1 forwards;
  -moz-animation: RSR4 1s 0.6s ease-out 1 forwards;
  -ms-animation: RSR4 1s 0.6s ease-out 1 forwards;
  animation: RSR4 1s 0.6s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr7,
.modal-open .publish-rocket.active .rocket-smoke.rsr7,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr7 {
  -webkit-animation: RSR3 1s 0.7s ease-out 1 forwards;
  -moz-animation: RSR3 1s 0.7s ease-out 1 forwards;
  -ms-animation: RSR3 1s 0.7s ease-out 1 forwards;
  animation: RSR3 1s 0.7s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr8,
.modal-open .publish-rocket.active .rocket-smoke.rsr8,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr8 {
  -webkit-animation: RSR3 1s 0.8s ease-out 1 forwards;
  -moz-animation: RSR3 1s 0.8s ease-out 1 forwards;
  -ms-animation: RSR3 1s 0.8s ease-out 1 forwards;
  animation: RSR3 1s 0.8s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr9,
.modal-open .publish-rocket.active .rocket-smoke.rsr9,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr9 {
  -webkit-animation: RSR2 1s 0.9s ease-out 1 forwards;
  -moz-animation: RSR2 1s 0.9s ease-out 1 forwards;
  -ms-animation: RSR2 1s 0.9s ease-out 1 forwards;
  animation: RSR2 1s 0.9s ease-out 1 forwards;
}
#root .publish-rocket.active .rocket-smoke.rsr10,
.modal-open .publish-rocket.active .rocket-smoke.rsr10,
.MuiPopover-root .publish-rocket.active .rocket-smoke.rsr10 {
  -webkit-animation: RSR3 1s 1s ease-out 1 forwards;
  -moz-animation: RSR3 1s 1s ease-out 1 forwards;
  -ms-animation: RSR3 1s 1s ease-out 1 forwards;
  animation: RSR3 1s 1s ease-out 1 forwards;
}
@keyframes RS1 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 25px) scale(1);
    -moz-transform: translate(-15px, 25px) scale(1);
    -ms-transform: translate(-15px, 25px) scale(1);
    -o-transform: translate(-15px, 25px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-20px, 35px) scale(1.25);
    -moz-transform: translate(-20px, 35px) scale(1.25);
    -ms-transform: translate(-20px, 35px) scale(1.25);
    -o-transform: translate(-20px, 35px) scale(1.25);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-250px, 45px) scale(0.5);
    -moz-transform: translate(-250px, 45px) scale(0.5);
    -ms-transform: translate(-250px, 45px) scale(0.5);
    -o-transform: translate(-250px, 45px) scale(0.5);
  }
}
@keyframes RS2 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-20px, 45px) scale(1.5);
    -moz-transform: translate(-20px, 45px) scale(1.5);
    -ms-transform: translate(-20px, 45px) scale(1.5);
    -o-transform: translate(-20px, 45px) scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-250px, 45px) scale(0.5);
    -moz-transform: translate(-250px, 45px) scale(0.5);
    -ms-transform: translate(-250px, 45px) scale(0.5);
    -o-transform: translate(-250px, 45px) scale(0.5);
  }
}
@keyframes RS3 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-20px, 45px) scale(2);
    -moz-transform: translate(-20px, 45px) scale(2);
    -ms-transform: translate(-20px, 45px) scale(2);
    -o-transform: translate(-20px, 45px) scale(2);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-250px, 45px) scale(0.5);
    -moz-transform: translate(-250px, 45px) scale(0.5);
    -ms-transform: translate(-250px, 45px) scale(0.5);
    -o-transform: translate(-250px, 45px) scale(0.5);
  }
}
@keyframes RS4 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-20px, 45px) scale(2.5);
    -moz-transform: translate(-20px, 45px) scale(2.5);
    -ms-transform: translate(-20px, 45px) scale(2.5);
    -o-transform: translate(-20px, 45px) scale(2.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-250px, 45px) scale(1.2);
    -moz-transform: translate(-250px, 45px) scale(1.2);
    -ms-transform: translate(-250px, 45px) scale(1.2);
    -o-transform: translate(-250px, 45px) scale(1.2);
  }
}
@keyframes RSR1 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-10px, 45px) scale(1.25);
    -moz-transform: translate(-10px, 45px) scale(1.25);
    -ms-transform: translate(-10px, 45px) scale(1.25);
    -o-transform: translate(-10px, 45px) scale(1.25);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(235px, 45px) scale(0.5);
    -moz-transform: translate(235px, 45px) scale(0.5);
    -ms-transform: translate(235px, 45px) scale(0.5);
    -o-transform: translate(235px, 45px) scale(0.5);
  }
}
@keyframes RSR2 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-10px, 45px) scale(1.5);
    -moz-transform: translate(-10px, 45px) scale(1.5);
    -ms-transform: translate(-10px, 45px) scale(1.5);
    -o-transform: translate(-10px, 45px) scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(235px, 45px) scale(0.5);
    -moz-transform: translate(235px, 45px) scale(0.5);
    -ms-transform: translate(235px, 45px) scale(0.5);
    -o-transform: translate(235px, 45px) scale(0.5);
  }
}
@keyframes RSR3 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-10px, 45px) scale(2);
    -moz-transform: translate(-10px, 45px) scale(2);
    -ms-transform: translate(-10px, 45px) scale(2);
    -o-transform: translate(-10px, 45px) scale(2);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(235px, 45px) scale(0.5);
    -moz-transform: translate(235px, 45px) scale(0.5);
    -ms-transform: translate(235px, 45px) scale(0.5);
    -o-transform: translate(235px, 45px) scale(0.5);
  }
}
@keyframes RSR4 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  1% {
    opacity: 0.5;
    -webkit-transform: translate(-15px, 20px) scale(1);
    -moz-transform: translate(-15px, 20px) scale(1);
    -ms-transform: translate(-15px, 20px) scale(1);
    -o-transform: translate(-15px, 20px) scale(1);
  }
  5% {
    opacity: 1;
    -webkit-transform: translate(-10px, 45px) scale(2.5);
    -moz-transform: translate(-10px, 45px) scale(2.5);
    -ms-transform: translate(-10px, 45px) scale(2.5);
    -o-transform: translate(-10px, 45px) scale(2.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(235px, 45px) scale(1.2);
    -moz-transform: translate(235px, 45px) scale(1.2);
    -ms-transform: translate(235px, 45px) scale(1.2);
    -o-transform: translate(235px, 45px) scale(1.2);
  }
}
#root .save-disk,
.modal-open .save-disk,
.MuiPopover-root .save-disk {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
  background: #004bed;
  border-radius: 70px;
  margin-bottom: 20px;
}
#root .save-disk > i,
.modal-open .save-disk > i,
.MuiPopover-root .save-disk > i {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.4) -6px 6px 4px;
  opacity: 1;
  pointer-events: none;
  transform-origin: center center;
  -webkit-transform: rotate(0deg) translate(-29px, -33px);
  -moz-transform: rotate(0deg) translate(-29px, -33px);
  -ms-transform: rotate(0deg) translate(-29px, -33px);
  -o-transform: rotate(0deg) translate(-29px, -33px);
}
#root .submit-plane,
.modal-open .submit-plane,
.MuiPopover-root .submit-plane {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
  background: #004bed;
  border-radius: 70px;
  margin-bottom: 20px;
}
#root .submit-plane > i,
.modal-open .submit-plane > i,
.MuiPopover-root .submit-plane > i {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.4) -6px 6px 4px;
  opacity: 1;
  pointer-events: none;
  transform-origin: center center;
  -webkit-transform: rotate(0deg) translate(-36px, -31px);
  -moz-transform: rotate(0deg) translate(-36px, -31px);
  -ms-transform: rotate(0deg) translate(-36px, -31px);
  -o-transform: rotate(0deg) translate(-36px, -31px);
}
#root .submit-plane .big-thumb i,
.modal-open .submit-plane .big-thumb i,
.MuiPopover-root .submit-plane .big-thumb i {
  color: white;
  font-size: 72px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
}
#root .submit-plane.active,
.modal-open .submit-plane.active,
.MuiPopover-root .submit-plane.active {
  -webkit-animation: PaperPlaneCircle 3s 2s linear 1 forwards;
  -moz-animation: PaperPlaneCircle 3s 2s linear 1 forwards;
  -ms-animation: PaperPlaneCircle 3s 2s linear 1 forwards;
  animation: PaperPlaneCircle 3s 2s linear 1 forwards;
}
#root .submit-plane.active > i,
.modal-open .submit-plane.active > i,
.MuiPopover-root .submit-plane.active > i {
  -webkit-animation: PaperPlane 3s 2s linear 1 forwards;
  -moz-animation: PaperPlane 3s 2s linear 1 forwards;
  -ms-animation: PaperPlane 3s 2s linear 1 forwards;
  animation: PaperPlane 3s 2s linear 1 forwards;
  -webkit-animation-direction: forwards;
  -o-animation-direction: forwards;
  -moz-animation-direction: forwards;
  animation-direction: forwards;
}
#root .submit-plane.active .big-thumb i,
.modal-open .submit-plane.active .big-thumb i,
.MuiPopover-root .submit-plane.active .big-thumb i {
  -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  -webkit-animation: ThumbsUp 0.5s 4.5s linear 1 forwards;
  -moz-animation: ThumbsUp 0.5s 4.5s linear 1 forwards;
  -ms-animation: ThumbsUp 0.5s 4.5s linear 1 forwards;
  animation: ThumbsUp 0.5s 4.5s linear 1 forwards;
}
@keyframes PaperPlane {
  0% {
    -webkit-transform: rotate(0deg) translate(-36px, -31px);
    -moz-transform: rotate(0deg) translate(-36px, -31px);
    -ms-transform: rotate(0deg) translate(-36px, -31px);
    -o-transform: rotate(0deg) translate(-36px, -31px);
  }
  10% {
    color: #004bed;
    -webkit-transform: rotate(140deg) translate(-36px, -122px);
    -moz-transform: rotate(140deg) translate(-36px, -122px);
    -ms-transform: rotate(140deg) translate(-36px, -122px);
    -o-transform: rotate(140deg) translate(-36px, -122px);
  }
  56% {
    color: #004bed;
    -webkit-transform: rotate(140deg) translate(-36px, -122px);
    -moz-transform: rotate(140deg) translate(-36px, -122px);
    -ms-transform: rotate(140deg) translate(-36px, -122px);
    -o-transform: rotate(140deg) translate(-36px, -122px);
  }
  66% {
    color: #004bed;
    opacity: 1;
    -webkit-transform: rotate(50deg) translate(-36px, -152px);
    -moz-transform: rotate(50deg) translate(-36px, -152px);
    -ms-transform: rotate(50deg) translate(-36px, -152px);
    -o-transform: rotate(50deg) translate(-36px, -152px);
  }
  100% {
    color: #004bed;
    opacity: 0;
    -webkit-transform: rotate(0deg) translate(800px, -800px);
    -moz-transform: rotate(0deg) translate(800px, -800px);
    -ms-transform: rotate(0deg) translate(800px, -800px);
    -o-transform: rotate(0deg) translate(800px, -800px);
  }
}
@keyframes PaperPlaneCircle {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  66% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
  }
}
@-webkit-keyframes ThumbsUp {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -moz-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -ms-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -o-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -moz-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -ms-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -o-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
}
@-moz-keyframes ThumbsUp {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -moz-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -ms-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -o-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -moz-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -ms-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -o-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
}
@-o-keyframes ThumbsUp {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -moz-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -ms-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -o-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -moz-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -ms-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -o-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
}
@keyframes ThumbsUp {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -moz-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(10deg) scale(0);
    -o-transform: translate(-50%, -50%) rotate(10deg) scale(0);
  }
  80% {
    -webkit-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -moz-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -ms-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
    -o-transform: translate(-50%, -50%) rotate(-41deg) scale(1.2);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -moz-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -ms-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -o-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
}
@-webkit-keyframes Pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  20% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  22% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  52% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
#root .cart-transition-well,
.modal-open .cart-transition-well,
.MuiPopover-root .cart-transition-well {
  background-color: #ffffff;
  transform: translate(-24px, 0);
  padding: 0 24px;
  border-radius: 16px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0);
}
@media only screen and (max-width : 992px) {
  #root .cart-transition-well,
  .modal-open .cart-transition-well,
  .MuiPopover-root .cart-transition-well {
    transform: translate(-12px, 0);
    padding: 0 12px;
  }
}
#root .cart-transition-well.expand,
.modal-open .cart-transition-well.expand,
.MuiPopover-root .cart-transition-well.expand {
  transform: translate(-24px, -24px);
  padding: 24px;
  box-shadow: 0 -18px 16px -12px rgba(0, 0, 0, 0.15);
  width: calc(100% + 2 * 24px);
}
@media only screen and (max-width : 992px) {
  #root .cart-transition-well.expand,
  .modal-open .cart-transition-well.expand,
  .MuiPopover-root .cart-transition-well.expand {
    transform: translate(-12px, -12px);
    padding: 12px;
    width: calc(100% + 2 * 12px);
  }
}
#root .walker-frame,
.modal-open .walker-frame,
.MuiPopover-root .walker-frame {
  display: block;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  position: relative;
  height: 120px;
  margin: 0 -24px -24px -24px;
  border-radius: 0 0 16px 16px;
}
@media only screen and (max-width : 768px) {
  #root .walker-frame,
  .modal-open .walker-frame,
  .MuiPopover-root .walker-frame {
    margin: 0 -12px -12px -12px;
    border-radius: 0 0 16px 16px;
  }
  #root .walker-frame > img:nth-child(2n + 2),
  .modal-open .walker-frame > img:nth-child(2n + 2),
  .MuiPopover-root .walker-frame > img:nth-child(2n + 2) {
    display: none;
  }
}
#root .walker-frame img,
.modal-open .walker-frame img,
.MuiPopover-root .walker-frame img {
  max-height: 120px;
  position: absolute;
  top: 30px;
  -webkit-animation: walk 48s infinite linear;
  -moz-animation: walk 48s infinite linear;
  -o-animation: walk 48s infinite linear;
  animation: walk 48s infinite linear;
  left: -400px;
}
@media only screen and (max-width : 768px) {
  #root .walker-frame img,
  .modal-open .walker-frame img,
  .MuiPopover-root .walker-frame img {
    -webkit-animation: walk-faster 48s infinite linear;
    -moz-animation: walk-faster 48s infinite linear;
    -o-animation: walk-faster 48s infinite linear;
    animation: walk-faster 48s infinite linear;
  }
}
@-webkit-keyframes walk {
  to {
    left: 250%;
  }
}
@-moz-keyframes walk {
  to {
    left: 250%;
  }
}
@-o-keyframes walk {
  to {
    left: 250%;
  }
}
@keyframes walk {
  to {
    left: 250%;
  }
}
@-webkit-keyframes walk-faster {
  to {
    left: 500%;
  }
}
@-moz-keyframes walk-faster {
  to {
    left: 500%;
  }
}
@-o-keyframes walk-faster {
  to {
    left: 500%;
  }
}
@keyframes walk-faster {
  to {
    left: 500%;
  }
}
@-webkit-keyframes Swipe {
  0% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
  40% {
    -webkit-transform: translate(-30%, 0) rotate(5deg);
    -moz-transform: translate(-30%, 0) rotate(5deg);
    -ms-transform: translate(-30%, 0) rotate(5deg);
    -o-transform: translate(-30%, 0) rotate(5deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(-50%, 0) rotate(15deg);
    -moz-transform: translate(-50%, 0) rotate(15deg);
    -ms-transform: translate(-50%, 0) rotate(15deg);
    -o-transform: translate(-50%, 0) rotate(15deg);
    opacity: 0;
  }
  90% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
}
@-moz-keyframes Swipe {
  0% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
  40% {
    -webkit-transform: translate(-30%, 0) rotate(5deg);
    -moz-transform: translate(-30%, 0) rotate(5deg);
    -ms-transform: translate(-30%, 0) rotate(5deg);
    -o-transform: translate(-30%, 0) rotate(5deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(-50%, 0) rotate(15deg);
    -moz-transform: translate(-50%, 0) rotate(15deg);
    -ms-transform: translate(-50%, 0) rotate(15deg);
    -o-transform: translate(-50%, 0) rotate(15deg);
    opacity: 0;
  }
  90% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
}
@-o-keyframes Swipe {
  0% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
  40% {
    -webkit-transform: translate(-30%, 0) rotate(5deg);
    -moz-transform: translate(-30%, 0) rotate(5deg);
    -ms-transform: translate(-30%, 0) rotate(5deg);
    -o-transform: translate(-30%, 0) rotate(5deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(-50%, 0) rotate(15deg);
    -moz-transform: translate(-50%, 0) rotate(15deg);
    -ms-transform: translate(-50%, 0) rotate(15deg);
    -o-transform: translate(-50%, 0) rotate(15deg);
    opacity: 0;
  }
  90% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
}
@keyframes Swipe {
  0% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
  40% {
    -webkit-transform: translate(-30%, 0) rotate(5deg);
    -moz-transform: translate(-30%, 0) rotate(5deg);
    -ms-transform: translate(-30%, 0) rotate(5deg);
    -o-transform: translate(-30%, 0) rotate(5deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(-50%, 0) rotate(-5deg);
    -moz-transform: translate(-50%, 0) rotate(-5deg);
    -ms-transform: translate(-50%, 0) rotate(-5deg);
    -o-transform: translate(-50%, 0) rotate(-5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(-50%, 0) rotate(15deg);
    -moz-transform: translate(-50%, 0) rotate(15deg);
    -ms-transform: translate(-50%, 0) rotate(15deg);
    -o-transform: translate(-50%, 0) rotate(15deg);
    opacity: 0;
  }
  90% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(50%, 0) rotate(45deg);
    -moz-transform: translate(50%, 0) rotate(45deg);
    -ms-transform: translate(50%, 0) rotate(45deg);
    -o-transform: translate(50%, 0) rotate(45deg);
    opacity: 1;
  }
}
@keyframes Pan {
  0% {
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    -o-transform: translate(0%, 0);
  }
  10% {
    -webkit-transform: translate(20%, 0);
    -moz-transform: translate(20%, 0);
    -ms-transform: translate(20%, 0);
    -o-transform: translate(20%, 0);
  }
  30% {
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    -o-transform: translate(0%, 0);
  }
  40% {
    -webkit-transform: translate(20%, 0);
    -moz-transform: translate(20%, 0);
    -ms-transform: translate(20%, 0);
    -o-transform: translate(20%, 0);
  }
  60% {
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    -o-transform: translate(0%, 0);
  }
}
#root .progress,
.modal-open .progress,
.MuiPopover-root .progress {
  height: 10px;
}
#root .modal-backdrop,
.modal-open .modal-backdrop,
.MuiPopover-root .modal-backdrop {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(6px);
  z-index: 1051;
}
#root .modal-backdrop.show,
.modal-open .modal-backdrop.show,
.MuiPopover-root .modal-backdrop.show {
  opacity: 1 !important;
}
#root .form-control:focus,
.modal-open .form-control:focus,
.MuiPopover-root .form-control:focus {
  box-shadow: none;
}
#root .dropdown-header,
.modal-open .dropdown-header,
.MuiPopover-root .dropdown-header {
  color: #004bed;
}
#root .dropdown-header h4,
.modal-open .dropdown-header h4,
.MuiPopover-root .dropdown-header h4 {
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
}
#root .dropdown-header h5,
.modal-open .dropdown-header h5,
.MuiPopover-root .dropdown-header h5 {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
}
#root .dropdown.btn-block > .btn,
.modal-open .dropdown.btn-block > .btn,
.MuiPopover-root .dropdown.btn-block > .btn {
  width: 100%;
  display: block;
}
#root .dropdown.no-arrow ::after,
.modal-open .dropdown.no-arrow ::after,
.MuiPopover-root .dropdown.no-arrow ::after {
  display: none;
}
#root .dropdown .dropdown-menu,
.modal-open .dropdown .dropdown-menu,
.MuiPopover-root .dropdown .dropdown-menu {
  background-color: #f2f3f5;
  border: solid 1px #e5e6e7;
  box-shadow: 0px 3px 16px 0px #24272b26;
  padding: 0;
}
#root .dropdown .dropdown-menu .dropdown-item,
.modal-open .dropdown .dropdown-menu .dropdown-item,
.MuiPopover-root .dropdown .dropdown-menu .dropdown-item {
  color: #24272b;
  padding-top: 10px;
  padding-bottom: 10px;
}
#root .dropdown .dropdown-menu .dropdown-item:hover,
.modal-open .dropdown .dropdown-menu .dropdown-item:hover,
.MuiPopover-root .dropdown .dropdown-menu .dropdown-item:hover,
#root .dropdown .dropdown-menu .dropdown-item:focus,
.modal-open .dropdown .dropdown-menu .dropdown-item:focus,
.MuiPopover-root .dropdown .dropdown-menu .dropdown-item:focus,
#root .dropdown .dropdown-menu .dropdown-item.react-select__option--is-focused,
.modal-open .dropdown .dropdown-menu .dropdown-item.react-select__option--is-focused,
.MuiPopover-root .dropdown .dropdown-menu .dropdown-item.react-select__option--is-focused {
  color: #24272b !important;
  background-color: #d3dce6;
}
#root .dropdown .dropdown-menu .dropdown-item.disabled,
.modal-open .dropdown .dropdown-menu .dropdown-item.disabled,
.MuiPopover-root .dropdown .dropdown-menu .dropdown-item.disabled {
  color: rgba(4, 14, 53, 0.4);
}
#root .dropdown .dropdown-menu.dropdown-menu-sm .dropdown-item,
.modal-open .dropdown .dropdown-menu.dropdown-menu-sm .dropdown-item,
.MuiPopover-root .dropdown .dropdown-menu.dropdown-menu-sm .dropdown-item {
  padding-top: 5px;
  padding-bottom: 5px;
}
#root .dropdown.p-0 > .btn,
.modal-open .dropdown.p-0 > .btn,
.MuiPopover-root .dropdown.p-0 > .btn {
  padding: 0 !important;
}
#root #section-dropdown ~ .dropdown-menu,
.modal-open #section-dropdown ~ .dropdown-menu,
.MuiPopover-root #section-dropdown ~ .dropdown-menu {
  max-height: 50vh;
  overflow: auto;
}
#root .well-row,
.modal-open .well-row,
.MuiPopover-root .well-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-flow: row wrap;
  margin-right: -12px !important;
  margin-left: -12px !important;
}
#root .well-row [class*="col-"],
.modal-open .well-row [class*="col-"],
.MuiPopover-root .well-row [class*="col-"] {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
#root .well-row [class*="col-"].col-eq-height,
.modal-open .well-row [class*="col-"].col-eq-height,
.MuiPopover-root .well-row [class*="col-"].col-eq-height {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex-grow: 1;
}
#root .well-row [class*="col-"].col-eq-height > *,
.modal-open .well-row [class*="col-"].col-eq-height > *,
.MuiPopover-root .well-row [class*="col-"].col-eq-height > * {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}
#root .input-group .btn,
.modal-open .input-group .btn,
.MuiPopover-root .input-group .btn {
  height: 32px;
  border-radius: 0 3px 3px 0;
}
#root .pill-accordion .card,
.modal-open .pill-accordion .card,
.MuiPopover-root .pill-accordion .card {
  overflow: visible;
  background: none;
  border-radius: 6px;
  border: solid 1px transparent;
  margin: 5px 0;
}
#root .pill-accordion .card .card-header,
.modal-open .pill-accordion .card .card-header,
.MuiPopover-root .pill-accordion .card .card-header {
  padding: 10px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  background-color: #f2f3f5;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
#root .pill-accordion .card .card-header h6,
.modal-open .pill-accordion .card .card-header h6,
.MuiPopover-root .pill-accordion .card .card-header h6 {
  opacity: 0.8;
}
#root .pill-accordion .card .card-header.active,
.modal-open .pill-accordion .card .card-header.active,
.MuiPopover-root .pill-accordion .card .card-header.active {
  color: #004bed;
}
#root .pill-accordion .card .card-header.active h6,
.modal-open .pill-accordion .card .card-header.active h6,
.MuiPopover-root .pill-accordion .card .card-header.active h6 {
  opacity: 1;
  color: #004bed;
}
#root .pill-accordion .card.active,
.modal-open .pill-accordion .card.active,
.MuiPopover-root .pill-accordion .card.active {
  border: solid 1px #e5e6e7;
  margin: 5px 0;
}
#root .pill-accordion .card.active .card-header,
.modal-open .pill-accordion .card.active .card-header,
.MuiPopover-root .pill-accordion .card.active .card-header {
  background-color: transparent;
  margin: 0;
}
#root .pill-accordion .card .card-body,
.modal-open .pill-accordion .card .card-body,
.MuiPopover-root .pill-accordion .card .card-body {
  padding: 10px;
  padding-top: 0;
}
#root .pill-accordion .card .card-body .field,
.modal-open .pill-accordion .card .card-body .field,
.MuiPopover-root .pill-accordion .card .card-body .field {
  margin: 0;
}
#root .rdt,
.modal-open .rdt,
.MuiPopover-root .rdt {
  position: relative;
}
#root .rdtPicker,
.modal-open .rdtPicker,
.MuiPopover-root .rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
#root .time-picker-container .rdtPicker,
.modal-open .time-picker-container .rdtPicker,
.MuiPopover-root .time-picker-container .rdtPicker {
  right: 0;
}
#root .rdtOpen .rdtPicker,
.modal-open .rdtOpen .rdtPicker,
.MuiPopover-root .rdtOpen .rdtPicker {
  display: block;
}
#root .rdtStatic .rdtPicker,
.modal-open .rdtStatic .rdtPicker,
.MuiPopover-root .rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}
#root .rdtPicker .rdtTimeToggle,
.modal-open .rdtPicker .rdtTimeToggle,
.MuiPopover-root .rdtPicker .rdtTimeToggle {
  text-align: center;
}
#root .rdtPicker table,
.modal-open .rdtPicker table,
.MuiPopover-root .rdtPicker table {
  width: 100%;
  margin: 0;
}
#root .rdtPicker td,
.modal-open .rdtPicker td,
.MuiPopover-root .rdtPicker td,
#root .rdtPicker th,
.modal-open .rdtPicker th,
.MuiPopover-root .rdtPicker th {
  text-align: center;
  height: 28px;
}
#root .rdtPicker td,
.modal-open .rdtPicker td,
.MuiPopover-root .rdtPicker td {
  cursor: pointer;
}
#root .rdtPicker td.rdtDay:hover,
.modal-open .rdtPicker td.rdtDay:hover,
.MuiPopover-root .rdtPicker td.rdtDay:hover,
#root .rdtPicker td.rdtHour:hover,
.modal-open .rdtPicker td.rdtHour:hover,
.MuiPopover-root .rdtPicker td.rdtHour:hover,
#root .rdtPicker td.rdtMinute:hover,
.modal-open .rdtPicker td.rdtMinute:hover,
.MuiPopover-root .rdtPicker td.rdtMinute:hover,
#root .rdtPicker td.rdtSecond:hover,
.modal-open .rdtPicker td.rdtSecond:hover,
.MuiPopover-root .rdtPicker td.rdtSecond:hover,
#root .rdtPicker .rdtTimeToggle:hover,
.modal-open .rdtPicker .rdtTimeToggle:hover,
.MuiPopover-root .rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
#root .rdtPicker td.rdtOld,
.modal-open .rdtPicker td.rdtOld,
.MuiPopover-root .rdtPicker td.rdtOld,
#root .rdtPicker td.rdtNew,
.modal-open .rdtPicker td.rdtNew,
.MuiPopover-root .rdtPicker td.rdtNew {
  color: #999999;
}
#root .rdtPicker td.rdtToday,
.modal-open .rdtPicker td.rdtToday,
.MuiPopover-root .rdtPicker td.rdtToday {
  position: relative;
}
#root .rdtPicker td.rdtToday:before,
.modal-open .rdtPicker td.rdtToday:before,
.MuiPopover-root .rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
#root .rdtPicker td.rdtActive,
.modal-open .rdtPicker td.rdtActive,
.MuiPopover-root .rdtPicker td.rdtActive,
#root .rdtPicker td.rdtActive:hover,
.modal-open .rdtPicker td.rdtActive:hover,
.MuiPopover-root .rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
#root .rdtPicker td.rdtActive.rdtToday:before,
.modal-open .rdtPicker td.rdtActive.rdtToday:before,
.MuiPopover-root .rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
#root .rdtPicker td.rdtDisabled,
.modal-open .rdtPicker td.rdtDisabled,
.MuiPopover-root .rdtPicker td.rdtDisabled,
#root .rdtPicker td.rdtDisabled:hover,
.modal-open .rdtPicker td.rdtDisabled:hover,
.MuiPopover-root .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
#root .rdtPicker td span.rdtOld,
.modal-open .rdtPicker td span.rdtOld,
.MuiPopover-root .rdtPicker td span.rdtOld {
  color: #999999;
}
#root .rdtPicker td span.rdtDisabled,
.modal-open .rdtPicker td span.rdtDisabled,
.MuiPopover-root .rdtPicker td span.rdtDisabled,
#root .rdtPicker td span.rdtDisabled:hover,
.modal-open .rdtPicker td span.rdtDisabled:hover,
.MuiPopover-root .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
#root .rdtPicker th,
.modal-open .rdtPicker th,
.MuiPopover-root .rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
#root .rdtPicker .dow,
.modal-open .rdtPicker .dow,
.MuiPopover-root .rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
#root .rdtPicker th.rdtSwitch,
.modal-open .rdtPicker th.rdtSwitch,
.MuiPopover-root .rdtPicker th.rdtSwitch {
  width: 100px;
}
#root .rdtPicker th.rdtNext,
.modal-open .rdtPicker th.rdtNext,
.MuiPopover-root .rdtPicker th.rdtNext,
#root .rdtPicker th.rdtPrev,
.modal-open .rdtPicker th.rdtPrev,
.MuiPopover-root .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}
#root .rdtPrev span,
.modal-open .rdtPrev span,
.MuiPopover-root .rdtPrev span,
#root .rdtNext span,
.modal-open .rdtNext span,
.MuiPopover-root .rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
#root .rdtPicker th.rdtDisabled,
.modal-open .rdtPicker th.rdtDisabled,
.MuiPopover-root .rdtPicker th.rdtDisabled,
#root .rdtPicker th.rdtDisabled:hover,
.modal-open .rdtPicker th.rdtDisabled:hover,
.MuiPopover-root .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
#root .rdtPicker thead tr:first-of-type th,
.modal-open .rdtPicker thead tr:first-of-type th,
.MuiPopover-root .rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
#root .rdtPicker thead tr:first-of-type th:hover,
.modal-open .rdtPicker thead tr:first-of-type th:hover,
.MuiPopover-root .rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}
#root .rdtPicker tfoot,
.modal-open .rdtPicker tfoot,
.MuiPopover-root .rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}
#root .rdtPicker button,
.modal-open .rdtPicker button,
.MuiPopover-root .rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
#root .rdtPicker button:hover,
.modal-open .rdtPicker button:hover,
.MuiPopover-root .rdtPicker button:hover {
  background-color: #eee;
}
#root .rdtPicker thead button,
.modal-open .rdtPicker thead button,
.MuiPopover-root .rdtPicker thead button {
  width: 100%;
  height: 100%;
}
#root td.rdtMonth,
.modal-open td.rdtMonth,
.MuiPopover-root td.rdtMonth,
#root td.rdtYear,
.modal-open td.rdtYear,
.MuiPopover-root td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
#root td.rdtMonth:hover,
.modal-open td.rdtMonth:hover,
.MuiPopover-root td.rdtMonth:hover,
#root td.rdtYear:hover,
.modal-open td.rdtYear:hover,
.MuiPopover-root td.rdtYear:hover {
  background: #eee;
}
#root .rdtCounters,
.modal-open .rdtCounters,
.MuiPopover-root .rdtCounters {
  display: inline-block;
}
#root .rdtCounters > div,
.modal-open .rdtCounters > div,
.MuiPopover-root .rdtCounters > div {
  float: left;
}
#root .rdtCounter,
.modal-open .rdtCounter,
.MuiPopover-root .rdtCounter {
  height: 100px;
}
#root .rdtCounter,
.modal-open .rdtCounter,
.MuiPopover-root .rdtCounter {
  width: 40px;
}
#root .rdtCounterSeparator,
.modal-open .rdtCounterSeparator,
.MuiPopover-root .rdtCounterSeparator {
  line-height: 100px;
}
#root .rdtCounter .rdtBtn,
.modal-open .rdtCounter .rdtBtn,
.MuiPopover-root .rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
#root .rdtCounter .rdtBtn:hover,
.modal-open .rdtCounter .rdtBtn:hover,
.MuiPopover-root .rdtCounter .rdtBtn:hover {
  background: #eee;
}
#root .rdtCounter .rdtCount,
.modal-open .rdtCounter .rdtCount,
.MuiPopover-root .rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}
#root .rdtMilli,
.modal-open .rdtMilli,
.MuiPopover-root .rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}
#root .rdtMilli input,
.modal-open .rdtMilli input,
.MuiPopover-root .rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}
#root .rdtTime td,
.modal-open .rdtTime td,
.MuiPopover-root .rdtTime td {
  cursor: default;
}
#root .btn,
.modal-open .btn,
.MuiPopover-root .btn {
  padding: 10px 20px !important;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 3px;
  outline: none;
  font-size: unset;
}
#root .btn.no-border,
.modal-open .btn.no-border,
.MuiPopover-root .btn.no-border {
  border: none;
}
#root .btn.btn-md,
.modal-open .btn.btn-md,
.MuiPopover-root .btn.btn-md {
  padding: 6px 20px !important;
}
#root .btn.btn-inline,
.modal-open .btn.btn-inline,
.MuiPopover-root .btn.btn-inline {
  margin-top: 7.5px;
}
#root .btn.btn-outline,
.modal-open .btn.btn-outline,
.MuiPopover-root .btn.btn-outline {
  border: solid 2px #24272b;
}
#root .btn.btn-primary,
.modal-open .btn.btn-primary,
.MuiPopover-root .btn.btn-primary {
  background-color: #004bed;
  color: #ffffff !important;
  border: none;
}
#root .btn.btn-primary:active,
.modal-open .btn.btn-primary:active,
.MuiPopover-root .btn.btn-primary:active {
  background-color: rgba(0, 75, 237, 0.25) !important;
}
#root .btn.btn-danger,
.modal-open .btn.btn-danger,
.MuiPopover-root .btn.btn-danger {
  background-color: #e71d36;
  border: none;
  color: #ffffff !important;
}
#root .btn.btn-danger:active,
.modal-open .btn.btn-danger:active,
.MuiPopover-root .btn.btn-danger:active {
  background-color: rgba(231, 29, 54, 0.25) !important;
}
#root .btn.btn-secondary,
.modal-open .btn.btn-secondary,
.MuiPopover-root .btn.btn-secondary {
  background-color: #4a525a;
  color: #ffffff;
  border: none;
}
#root .btn.btn-secondary:active,
.modal-open .btn.btn-secondary:active,
.MuiPopover-root .btn.btn-secondary:active {
  background-color: rgba(74, 82, 90, 0.25) !important;
}
#root .btn.btn-secondary:hover,
.modal-open .btn.btn-secondary:hover,
.MuiPopover-root .btn.btn-secondary:hover {
  color: #ffffff !important;
  background-color: rgba(74, 82, 90, 0.75) !important;
}
#root .btn.btn-success,
.modal-open .btn.btn-success,
.MuiPopover-root .btn.btn-success {
  background-color: #20bf55;
  border: none;
  color: #ffffff !important;
}
#root .btn.btn-success:active,
.modal-open .btn.btn-success:active,
.MuiPopover-root .btn.btn-success:active {
  background-color: rgba(32, 191, 85, 0.25) !important;
}
#root .btn.btn-light,
.modal-open .btn.btn-light,
.MuiPopover-root .btn.btn-light {
  background-color: #f2f3f5;
  border: none;
  color: #231f20;
}
#root .btn.btn-light:active,
.modal-open .btn.btn-light:active,
.MuiPopover-root .btn.btn-light:active,
#root .btn.btn-light:hover,
.modal-open .btn.btn-light:hover,
.MuiPopover-root .btn.btn-light:hover {
  background-color: #d3dce6 !important;
}
#root .btn.btn-warning,
.modal-open .btn.btn-warning,
.MuiPopover-root .btn.btn-warning {
  background-color: #facf43;
  border: none;
}
#root .btn.btn-warning:active,
.modal-open .btn.btn-warning:active,
.MuiPopover-root .btn.btn-warning:active {
  background-color: rgba(250, 207, 67, 0.25) !important;
}
#root .btn.btn-info,
.modal-open .btn.btn-info,
.MuiPopover-root .btn.btn-info {
  background-color: #11a9f0;
  border: none;
  color: #ffffff !important;
}
#root .btn.btn-info:active,
.modal-open .btn.btn-info:active,
.MuiPopover-root .btn.btn-info:active {
  background-color: rgba(17, 169, 240, 0.25) !important;
}
#root .btn.btn-pill,
.modal-open .btn.btn-pill,
.MuiPopover-root .btn.btn-pill {
  background-color: transparent;
  border-radius: 500vh;
  border: 1px solid #ffffff;
  padding: 3px 20px !important;
  color: #ffffff;
  font-weight: 300;
}
#root .btn.btn-dark,
.modal-open .btn.btn-dark,
.MuiPopover-root .btn.btn-dark {
  color: #ffffff;
  padding: 8px 18px !important;
  background-color: #4a525a;
}
#root .btn.btn-dark i,
.modal-open .btn.btn-dark i,
.MuiPopover-root .btn.btn-dark i {
  color: #ffffff;
  font-size: 14px;
}
#root .btn.btn-dark.btn-dark-link:hover,
.modal-open .btn.btn-dark.btn-dark-link:hover,
.MuiPopover-root .btn.btn-dark.btn-dark-link:hover {
  color: #ffffff !important;
}
#root .btn.btn-sm,
.modal-open .btn.btn-sm,
.MuiPopover-root .btn.btn-sm {
  padding: 4px 20px !important;
}
#root .btn.btn-xs,
.modal-open .btn.btn-xs,
.MuiPopover-root .btn.btn-xs {
  padding: 2px 20px !important;
  font-size: 12px;
  line-height: 22px;
}
#root .btn.btn-link,
.modal-open .btn.btn-link,
.MuiPopover-root .btn.btn-link {
  background: none !important;
  color: #004bed !important;
  border: none;
  padding: 2px !important;
  margin: 10px 20px !important;
  border-radius: 0;
  border-bottom: solid 3px transparent;
}
#root .btn.btn-link:hover,
.modal-open .btn.btn-link:hover,
.MuiPopover-root .btn.btn-link:hover,
#root .btn.btn-link:focus,
.modal-open .btn.btn-link:focus,
.MuiPopover-root .btn.btn-link:focus {
  text-decoration: none !important;
  background: none !important;
  border-bottom: solid 3px #004bed;
}
#root .btn.btn-link.btn-sm,
.modal-open .btn.btn-link.btn-sm,
.MuiPopover-root .btn.btn-link.btn-sm {
  margin: 4px 20px !important;
}
#root .btn.btn-link-sm,
.modal-open .btn.btn-link-sm,
.MuiPopover-root .btn.btn-link-sm {
  text-transform: none !important;
  font-weight: 300 !important;
  border-bottom: solid 1px transparent;
  padding-bottom: 0px !important;
}
#root .btn.btn-link-sm:hover,
.modal-open .btn.btn-link-sm:hover,
.MuiPopover-root .btn.btn-link-sm:hover,
#root .btn.btn-link-sm:focus,
.modal-open .btn.btn-link-sm:focus,
.MuiPopover-root .btn.btn-link-sm:focus {
  border-bottom: solid 1px #004bed;
  padding-bottom: 0px !important;
}
#root .btn.btn-square,
.modal-open .btn.btn-square,
.MuiPopover-root .btn.btn-square {
  padding: 8px !important;
}
#root .btn.btn-plain,
.modal-open .btn.btn-plain,
.MuiPopover-root .btn.btn-plain {
  color: #24272b;
}
#root .btn.btn-add,
.modal-open .btn.btn-add,
.MuiPopover-root .btn.btn-add {
  width: 41px;
  height: 41px;
}
#root .btn.payment,
.modal-open .btn.payment,
.MuiPopover-root .btn.payment {
  height: 80px;
}
#root .btn.choice,
.modal-open .btn.choice,
.MuiPopover-root .btn.choice {
  background-color: #f2f3f5;
  text-align: left;
  position: relative;
  -webkit-transition: background-color ease-in-out 0.3s;
  -moz-transition: background-color ease-in-out 0.3s;
  -ms-transition: background-color ease-in-out 0.3s;
  -o-transition: background-color ease-in-out 0.3s;
}
#root .btn.choice h3,
.modal-open .btn.choice h3,
.MuiPopover-root .btn.choice h3 {
  color: #004bed;
  font-weight: 600;
  line-height: 22px;
  padding-top: 10px;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.choice h3 > i,
.modal-open .btn.choice h3 > i,
.MuiPopover-root .btn.choice h3 > i {
  color: #004bed !important;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
  height: 100%;
  float: left;
  margin-top: -2px;
  margin-right: 5px !important;
}
@media only screen and (max-width : 992px) {
  #root .btn.choice h3,
  .modal-open .btn.choice h3,
  .MuiPopover-root .btn.choice h3 {
    font-size: 24px;
  }
  #root .btn.choice h3 > i,
  .modal-open .btn.choice h3 > i,
  .MuiPopover-root .btn.choice h3 > i {
    margin-top: 0;
  }
}
#root .btn.choice h5,
.modal-open .btn.choice h5,
.MuiPopover-root .btn.choice h5 {
  color: #004bed;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.choice h5 > i,
.modal-open .btn.choice h5 > i,
.MuiPopover-root .btn.choice h5 > i {
  color: #004bed !important;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.choice h6,
.modal-open .btn.choice h6,
.MuiPopover-root .btn.choice h6 {
  color: #24272b;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.choice.client h6,
.modal-open .btn.choice.client h6,
.MuiPopover-root .btn.choice.client h6 {
  float: right;
}
#root .btn.choice.client h5,
.modal-open .btn.choice.client h5,
.MuiPopover-root .btn.choice.client h5 {
  padding-top: 1px;
}
#root .btn.choice > div,
.modal-open .btn.choice > div,
.MuiPopover-root .btn.choice > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
}
#root .btn.choice > div h5,
.modal-open .btn.choice > div h5,
.MuiPopover-root .btn.choice > div h5 {
  margin-left: 5px;
}
#root .btn.choice > div > i,
.modal-open .btn.choice > div > i,
.MuiPopover-root .btn.choice > div > i {
  margin-top: 3px;
  font-size: 35px;
  color: #004bed !important;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.choice i,
.modal-open .btn.choice i,
.MuiPopover-root .btn.choice i {
  height: 32px;
  text-align: center;
  width: 1.25em;
}
#root .btn.choice.fa-2x i,
.modal-open .btn.choice.fa-2x i,
.MuiPopover-root .btn.choice.fa-2x i {
  height: 32px;
  text-align: center;
  width: 1.25em;
  margin-bottom: 12px;
}
#root .btn.choice:hover,
.modal-open .btn.choice:hover,
.MuiPopover-root .btn.choice:hover {
  background: #d3dce6 !important;
}
#root .btn.choice.active,
.modal-open .btn.choice.active,
.MuiPopover-root .btn.choice.active {
  background: #004bed !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #ffffff !important;
  border-color: transparent !important;
}
#root .btn.choice.active h3,
.modal-open .btn.choice.active h3,
.MuiPopover-root .btn.choice.active h3 {
  color: #ffffff;
}
#root .btn.choice.active h3 > i,
.modal-open .btn.choice.active h3 > i,
.MuiPopover-root .btn.choice.active h3 > i {
  color: #ffffff !important;
}
#root .btn.choice.active h5,
.modal-open .btn.choice.active h5,
.MuiPopover-root .btn.choice.active h5 {
  color: #ffffff;
}
#root .btn.choice.active h6,
.modal-open .btn.choice.active h6,
.MuiPopover-root .btn.choice.active h6 {
  color: #f2f3f5;
}
#root .btn.choice.active div > i,
.modal-open .btn.choice.active div > i,
.MuiPopover-root .btn.choice.active div > i {
  color: #ffffff !important;
}
#root .btn.choice.disabled,
.modal-open .btn.choice.disabled,
.MuiPopover-root .btn.choice.disabled {
  pointer-events: none;
}
#root .btn.square-choice,
.modal-open .btn.square-choice,
.MuiPopover-root .btn.square-choice {
  background-color: #f2f3f5;
  text-align: center;
  position: relative;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  min-height: 180px;
  padding: 24px !important;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0);
}
#root .btn.square-choice i,
.modal-open .btn.square-choice i,
.MuiPopover-root .btn.square-choice i {
  color: #004bed !important;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
  height: 100%;
  font-size: 36px;
}
#root .btn.square-choice h3,
.modal-open .btn.square-choice h3,
.MuiPopover-root .btn.square-choice h3 {
  color: #004bed;
  font-weight: 600;
  line-height: 28px;
  padding-top: 10px;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
@media only screen and (max-width : 992px) {
  #root .btn.square-choice h3,
  .modal-open .btn.square-choice h3,
  .MuiPopover-root .btn.square-choice h3 {
    font-size: 24px;
  }
  #root .btn.square-choice h3 > i,
  .modal-open .btn.square-choice h3 > i,
  .MuiPopover-root .btn.square-choice h3 > i {
    margin-top: 0;
  }
}
#root .btn.square-choice h5,
.modal-open .btn.square-choice h5,
.MuiPopover-root .btn.square-choice h5 {
  color: #004bed;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.square-choice h5 > i,
.modal-open .btn.square-choice h5 > i,
.MuiPopover-root .btn.square-choice h5 > i {
  color: #004bed !important;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.square-choice h6,
.modal-open .btn.square-choice h6,
.MuiPopover-root .btn.square-choice h6 {
  color: #24272b;
  font-size: 12px;
  line-height: 1.1em;
  margin-top: 5px;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.square-choice.client h6,
.modal-open .btn.square-choice.client h6,
.MuiPopover-root .btn.square-choice.client h6 {
  float: right;
}
#root .btn.square-choice.client h5,
.modal-open .btn.square-choice.client h5,
.MuiPopover-root .btn.square-choice.client h5 {
  padding-top: 1px;
}
#root .btn.square-choice > div,
.modal-open .btn.square-choice > div,
.MuiPopover-root .btn.square-choice > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
}
#root .btn.square-choice > div h5,
.modal-open .btn.square-choice > div h5,
.MuiPopover-root .btn.square-choice > div h5 {
  margin-left: 5px;
}
#root .btn.square-choice > div > i,
.modal-open .btn.square-choice > div > i,
.MuiPopover-root .btn.square-choice > div > i {
  margin-top: 3px;
  font-size: 35px;
  color: #004bed !important;
  -webkit-transition: color ease-in-out 0.3s;
  -moz-transition: color ease-in-out 0.3s;
  -ms-transition: color ease-in-out 0.3s;
  -o-transition: color ease-in-out 0.3s;
}
#root .btn.square-choice i,
.modal-open .btn.square-choice i,
.MuiPopover-root .btn.square-choice i {
  height: 32px;
  text-align: center;
  width: 1.25em;
}
#root .btn.square-choice:hover,
.modal-open .btn.square-choice:hover,
.MuiPopover-root .btn.square-choice:hover,
#root .btn.square-choice.hover,
.modal-open .btn.square-choice.hover,
.MuiPopover-root .btn.square-choice.hover {
  background: #d3dce6 !important;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .btn.square-choice:focus,
.modal-open .btn.square-choice:focus,
.MuiPopover-root .btn.square-choice:focus {
  border: none;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15) !important;
}
#root .btn.square-choice.active,
.modal-open .btn.square-choice.active,
.MuiPopover-root .btn.square-choice.active {
  background: #004bed !important;
  color: #ffffff !important;
  border-color: transparent !important;
}
#root .btn.square-choice.active i,
.modal-open .btn.square-choice.active i,
.MuiPopover-root .btn.square-choice.active i {
  color: #ffffff !important;
}
#root .btn.square-choice.active h3,
.modal-open .btn.square-choice.active h3,
.MuiPopover-root .btn.square-choice.active h3,
#root .btn.square-choice.active h5,
.modal-open .btn.square-choice.active h5,
.MuiPopover-root .btn.square-choice.active h5,
#root .btn.square-choice.active h6,
.modal-open .btn.square-choice.active h6,
.MuiPopover-root .btn.square-choice.active h6 {
  color: #ffffff;
}
#root .btn.square-choice.active div > i,
.modal-open .btn.square-choice.active div > i,
.MuiPopover-root .btn.square-choice.active div > i {
  color: #ffffff !important;
}
#root .btn .btn-spinner,
.modal-open .btn .btn-spinner,
.MuiPopover-root .btn .btn-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -6px;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.2;
  -moz-transition: opacity ease-in-out 0.2;
  -ms-transition: opacity ease-in-out 0.2;
  -o-transition: opacity ease-in-out 0.2;
}
#root .btn .btn-children,
.modal-open .btn .btn-children,
.MuiPopover-root .btn .btn-children {
  opacity: 1;
  -webkit-transition: opacity ease-in-out 0.2;
  -moz-transition: opacity ease-in-out 0.2;
  -ms-transition: opacity ease-in-out 0.2;
  -o-transition: opacity ease-in-out 0.2;
}
#root .btn.busy .btn-spinner,
.modal-open .btn.busy .btn-spinner,
.MuiPopover-root .btn.busy .btn-spinner {
  opacity: 1;
}
#root .btn.busy .btn-children,
.modal-open .btn.busy .btn-children,
.MuiPopover-root .btn.busy .btn-children {
  pointer-events: none;
  opacity: 0;
}
#root .btn.disabled,
.modal-open .btn.disabled,
.MuiPopover-root .btn.disabled {
  cursor: not-allowed;
}
#root .btn h1,
.modal-open .btn h1,
.MuiPopover-root .btn h1,
#root .btn h2,
.modal-open .btn h2,
.MuiPopover-root .btn h2,
#root .btn h3,
.modal-open .btn h3,
.MuiPopover-root .btn h3,
#root .btn h4,
.modal-open .btn h4,
.MuiPopover-root .btn h4,
#root .btn h5,
.modal-open .btn h5,
.MuiPopover-root .btn h5,
#root .btn h6,
.modal-open .btn h6,
.MuiPopover-root .btn h6,
#root .btn .h1,
.modal-open .btn .h1,
.MuiPopover-root .btn .h1,
#root .btn .h2,
.modal-open .btn .h2,
.MuiPopover-root .btn .h2,
#root .btn .h3,
.modal-open .btn .h3,
.MuiPopover-root .btn .h3,
#root .btn .h4,
.modal-open .btn .h4,
.MuiPopover-root .btn .h4,
#root .btn .h5,
.modal-open .btn .h5,
.MuiPopover-root .btn .h5,
#root .btn .h6,
.modal-open .btn .h6,
.MuiPopover-root .btn .h6 {
  white-space: normal;
}
#root .btn.btn-tooltip,
.modal-open .btn.btn-tooltip,
.MuiPopover-root .btn.btn-tooltip {
  background-color: #ffffff !important;
  padding: 5px !important;
  margin-left: 2px;
  height: 24px;
  width: 24px;
}
#root .btn.btn-tooltip i,
.modal-open .btn.btn-tooltip i,
.MuiPopover-root .btn.btn-tooltip i {
  background-color: #24272b !important;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 10px;
  border-radius: 4px;
  height: 14px;
  width: 14px;
}
#root .btn.btn-tooltip:hover,
.modal-open .btn.btn-tooltip:hover,
.MuiPopover-root .btn.btn-tooltip:hover {
  border-radius: 4px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .btn.btn-tooltip:hover i,
.modal-open .btn.btn-tooltip:hover i,
.MuiPopover-root .btn.btn-tooltip:hover i {
  background-color: #004bed !important;
}
#root .btn.widget-btn,
.modal-open .btn.widget-btn,
.MuiPopover-root .btn.widget-btn {
  width: 100%;
  border-radius: 8px !important;
  margin-left: 0;
  font-size: 16px;
}
#root .btn.sidebar-btn,
.modal-open .btn.sidebar-btn,
.MuiPopover-root .btn.sidebar-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  border-radius: 8px;
}
#root .btn-collection,
.modal-open .btn-collection,
.MuiPopover-root .btn-collection {
  display: flex;
  align-items: stretch;
  margin-left: -5px;
  margin-right: -5px;
}
#root .btn-collection > .btn,
.modal-open .btn-collection > .btn,
.MuiPopover-root .btn-collection > .btn,
#root .btn-collection > a,
.modal-open .btn-collection > a,
.MuiPopover-root .btn-collection > a {
  margin-top: 0;
  flex-grow: 1;
  margin: 0 5px;
}
#root .btn-collection > a > .btn,
.modal-open .btn-collection > a > .btn,
.MuiPopover-root .btn-collection > a > .btn {
  height: 100%;
}
#root .btn-collection.two > .btn,
.modal-open .btn-collection.two > .btn,
.MuiPopover-root .btn-collection.two > .btn,
#root .btn-collection.two > a,
.modal-open .btn-collection.two > a,
.MuiPopover-root .btn-collection.two > a {
  width: 50%;
  margin-bottom: 10px;
}
#root .btn-collection.three,
.modal-open .btn-collection.three,
.MuiPopover-root .btn-collection.three {
  flex-wrap: wrap;
  margin-bottom: 0;
}
#root .btn-collection.three > .btn,
.modal-open .btn-collection.three > .btn,
.MuiPopover-root .btn-collection.three > .btn,
#root .btn-collection.three > a,
.modal-open .btn-collection.three > a,
.MuiPopover-root .btn-collection.three > a {
  flex: 1 0 26%;
  margin-bottom: 10px;
}
#root .btn-collection.four,
.modal-open .btn-collection.four,
.MuiPopover-root .btn-collection.four {
  flex-wrap: wrap;
  margin-bottom: 0;
}
#root .btn-collection.four > .btn,
.modal-open .btn-collection.four > .btn,
.MuiPopover-root .btn-collection.four > .btn,
#root .btn-collection.four > a,
.modal-open .btn-collection.four > a,
.MuiPopover-root .btn-collection.four > a {
  flex: 1 0 34%;
  margin-bottom: 10px;
}
@media only screen and (max-width : 768px) {
  #root .btn-collection,
  .modal-open .btn-collection,
  .MuiPopover-root .btn-collection {
    flex-direction: column;
  }
  #root .btn-collection > .btn,
  .modal-open .btn-collection > .btn,
  .MuiPopover-root .btn-collection > .btn,
  #root .btn-collection > a,
  .modal-open .btn-collection > a,
  .MuiPopover-root .btn-collection > a {
    width: 100% !important;
    margin-left: 0 !important;
  }
  #root .btn-collection > .btn:not(:nth-child(3n + 1)),
  .modal-open .btn-collection > .btn:not(:nth-child(3n + 1)),
  .MuiPopover-root .btn-collection > .btn:not(:nth-child(3n + 1)),
  #root .btn-collection > a:not(:nth-child(3n + 1)),
  .modal-open .btn-collection > a:not(:nth-child(3n + 1)),
  .MuiPopover-root .btn-collection > a:not(:nth-child(3n + 1)) {
    margin-left: 10px;
  }
  #root .btn-collection > .btn:first-child,
  .modal-open .btn-collection > .btn:first-child,
  .MuiPopover-root .btn-collection > .btn:first-child,
  #root .btn-collection > a:first-child,
  .modal-open .btn-collection > a:first-child,
  .MuiPopover-root .btn-collection > a:first-child {
    margin-top: 0;
  }
}
#root .btn-collection.disabled,
.modal-open .btn-collection.disabled,
.MuiPopover-root .btn-collection.disabled {
  cursor: not-allowed;
}
#root .btn-collection.disabled > .btn,
.modal-open .btn-collection.disabled > .btn,
.MuiPopover-root .btn-collection.disabled > .btn,
#root .btn-collection.disabled > a,
.modal-open .btn-collection.disabled > a,
.MuiPopover-root .btn-collection.disabled > a {
  pointer-events: none;
  opacity: 0.75;
}
#root .tooltip-button,
.modal-open .tooltip-button,
.MuiPopover-root .tooltip-button {
  z-index: 1030;
  color: #ffffff !important;
}
#root .page-helper,
.modal-open .page-helper,
.MuiPopover-root .page-helper {
  position: fixed;
  bottom: -60px;
  right: 20px;
  display: inline-block;
  z-index: 1031;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .page-helper.active,
.modal-open .page-helper.active,
.MuiPopover-root .page-helper.active {
  bottom: 20px;
}
#root .page-helper .tooltip-button,
.modal-open .page-helper .tooltip-button,
.MuiPopover-root .page-helper .tooltip-button {
  background-color: #004bed !important;
  color: initial !important;
  font-size: 32px !important;
  padding: 10px !important;
  border-radius: 40px;
  border: none;
}
#root .page-helper .tooltip-button i,
.modal-open .page-helper .tooltip-button i,
.MuiPopover-root .page-helper .tooltip-button i {
  font-size: 16px;
  line-height: 20px;
}
#root .button-reveal,
.modal-open .button-reveal,
.MuiPopover-root .button-reveal {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
#root .button-reveal.active,
.modal-open .button-reveal.active,
.MuiPopover-root .button-reveal.active {
  max-height: 151px;
}
#root .btn-link-wrapper,
.modal-open .btn-link-wrapper,
.MuiPopover-root .btn-link-wrapper {
  text-decoration: none !important;
}
#root .btn-back,
.modal-open .btn-back,
.MuiPopover-root .btn-back {
  background-color: #d3dce6 !important;
}
#root .svg-btn,
.modal-open .svg-btn,
.MuiPopover-root .svg-btn {
  position: relative;
  cursor: pointer;
}
#root .svg-btn .hover,
.modal-open .svg-btn .hover,
.MuiPopover-root .svg-btn .hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 9;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
#root .svg-btn .active,
.modal-open .svg-btn .active,
.MuiPopover-root .svg-btn .active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
#root .svg-btn:hover .hover,
.modal-open .svg-btn:hover .hover,
.MuiPopover-root .svg-btn:hover .hover {
  opacity: 1;
}
#root .svg-btn.active .active,
.modal-open .svg-btn.active .active,
.MuiPopover-root .svg-btn.active .active {
  opacity: 1;
}
#root .btn-a,
.modal-open .btn-a,
.MuiPopover-root .btn-a {
  text-decoration: none !important;
}
#root .btn-with-icon .btn-children,
.modal-open .btn-with-icon .btn-children,
.MuiPopover-root .btn-with-icon .btn-children {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#root .btn-with-icon .btn-children .btn-with-icon-content,
.modal-open .btn-with-icon .btn-children .btn-with-icon-content,
.MuiPopover-root .btn-with-icon .btn-children .btn-with-icon-content {
  flex: 1;
}
#root .btn-with-icon .btn-children .btn-with-icon-icon,
.modal-open .btn-with-icon .btn-children .btn-with-icon-icon,
.MuiPopover-root .btn-with-icon .btn-children .btn-with-icon-icon {
  padding-left: 20px;
}
#root .btn-with-icon .btn-children .btn-with-icon-icon i,
.modal-open .btn-with-icon .btn-children .btn-with-icon-icon i,
.MuiPopover-root .btn-with-icon .btn-children .btn-with-icon-icon i {
  color: #24272b;
  height: unset;
}
#root .btn-with-icon.active .btn-with-icon-icon i,
.modal-open .btn-with-icon.active .btn-with-icon-icon i,
.MuiPopover-root .btn-with-icon.active .btn-with-icon-icon i {
  color: #ffffff;
}
#root .icon-btn,
.modal-open .icon-btn,
.MuiPopover-root .icon-btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px !important;
  transition: box-shadow ease-in-out 0.3s;
}
#root .icon-btn:not(:disabled):hover,
.modal-open .icon-btn:not(:disabled):hover,
.MuiPopover-root .icon-btn:not(:disabled):hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
}
#root .icon-btn:not(:disabled).flat #root .icon-btn:not(:disabled):not(:focus),
#root .icon-btn:not(:disabled).flat .modal-open .icon-btn:not(:disabled):not(:focus),
#root .icon-btn:not(:disabled).flat .MuiPopover-root .icon-btn:not(:disabled):not(:focus),
.modal-open .icon-btn:not(:disabled).flat #root .icon-btn:not(:disabled):not(:focus),
.modal-open .icon-btn:not(:disabled).flat .modal-open .icon-btn:not(:disabled):not(:focus),
.modal-open .icon-btn:not(:disabled).flat .MuiPopover-root .icon-btn:not(:disabled):not(:focus),
.MuiPopover-root .icon-btn:not(:disabled).flat #root .icon-btn:not(:disabled):not(:focus),
.MuiPopover-root .icon-btn:not(:disabled).flat .modal-open .icon-btn:not(:disabled):not(:focus),
.MuiPopover-root .icon-btn:not(:disabled).flat .MuiPopover-root .icon-btn:not(:disabled):not(:focus) {
  box-shadow: none;
}
#root .box-shadow-focus-none:focus,
.modal-open .box-shadow-focus-none:focus,
.MuiPopover-root .box-shadow-focus-none:focus {
  box-shadow: none;
}
#root .pill-btn,
.modal-open .pill-btn,
.MuiPopover-root .pill-btn {
  width: auto;
  height: auto;
  padding: 0 !important;
  font-size: inherit;
  color: inherit !important;
}
#root .field-button,
.modal-open .field-button,
.MuiPopover-root .field-button {
  font-weight: 400;
  border: solid 1px #e5e6e7;
  height: 32px;
  margin: 5px 0;
  background-color: #ffffff;
  display: block;
  color: #808080 !important;
  outline: none;
  padding: 9px !important;
  padding-top: 6px !important;
  text-transform: unset;
  text-align: left;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .field-button.dropdown::after,
.modal-open .field-button.dropdown::after,
.MuiPopover-root .field-button.dropdown::after {
  content: "";
}
#root .field-button.btn-block,
.modal-open .field-button.btn-block,
.MuiPopover-root .field-button.btn-block {
  width: 100%;
}
#root .field-button:focus,
.modal-open .field-button:focus,
.MuiPopover-root .field-button:focus,
#root .field-button.active,
.modal-open .field-button.active,
.MuiPopover-root .field-button.active {
  border: solid 1px #4a525a;
  color: #24272b !important;
}
#root .field-button.dropdown::after,
.modal-open .field-button.dropdown::after,
.MuiPopover-root .field-button.dropdown::after {
  font-family: "Font Awesome 6 Pro";
  content: "\f106";
  border: none;
  vertical-align: unset;
  font-weight: 300;
  font-size: 24px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transform: translate(50%, -50%) rotate(-180deg);
}
#root .field-button.list-map-button,
.modal-open .field-button.list-map-button,
.MuiPopover-root .field-button.list-map-button {
  margin-top: 7.5px;
}
#root .field-button.list-map-button.list::after,
.modal-open .field-button.list-map-button.list::after,
.MuiPopover-root .field-button.list-map-button.list::after {
  font-family: "Font Awesome 6 Pro";
  content: "\f03a";
  border: none;
  vertical-align: unset;
  font-weight: 300;
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transform: translate(50%, -50%);
}
#root .field-button.list-map-button.location::after,
.modal-open .field-button.list-map-button.location::after,
.MuiPopover-root .field-button.list-map-button.location::after {
  font-family: "Font Awesome 6 Pro";
  content: "\f3c5";
  border: none;
  vertical-align: unset;
  font-weight: 300;
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transform: translate(50%, -50%);
}
#root .label-btn,
.modal-open .label-btn,
.MuiPopover-root .label-btn {
  width: auto;
  height: auto;
  padding: 0 !important;
  text-transform: none;
  font-weight: inherit;
  font-size: inherit !important;
  margin-bottom: 0;
  color: inherit !important;
}
#root .btn-plain-sidebar,
.modal-open .btn-plain-sidebar,
.MuiPopover-root .btn-plain-sidebar {
  text-transform: none;
  font-weight: 400;
  padding: 0 !important;
  text-align: left;
  font-size: 14px;
}
#root .apple-pay-button,
.modal-open .apple-pay-button,
.MuiPopover-root .apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: initial;
  -apple-pay-button-style: black;
  border-radius: 3px;
  opacity: 1;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
#root .apple-pay-button:hover,
.modal-open .apple-pay-button:hover,
.MuiPopover-root .apple-pay-button:hover {
  opacity: 0.8;
}
#root .apple-pay-button ~ .google-pay-button,
.modal-open .apple-pay-button ~ .google-pay-button,
.MuiPopover-root .apple-pay-button ~ .google-pay-button {
  margin-top: 10px;
}
@supports (-webkit-appearance: -apple-pay-button) {
  #root .apple-pay-button,
  .modal-open .apple-pay-button,
  .MuiPopover-root .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
  }
  #root .apple-pay-button-black,
  .modal-open .apple-pay-button-black,
  .MuiPopover-root .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  #root .apple-pay-button-white,
  .modal-open .apple-pay-button-white,
  .MuiPopover-root .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  #root .apple-pay-button-white-with-line,
  .modal-open .apple-pay-button-white-with-line,
  .MuiPopover-root .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  #root .apple-pay-button,
  .modal-open .apple-pay-button,
  .MuiPopover-root .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  #root .apple-pay-button-black,
  .modal-open .apple-pay-button-black,
  .MuiPopover-root .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  #root .apple-pay-button-white,
  .modal-open .apple-pay-button-white,
  .MuiPopover-root .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  #root .apple-pay-button-white-with-line,
  .modal-open .apple-pay-button-white-with-line,
  .MuiPopover-root .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
}
#root .google-pay-button button,
.modal-open .google-pay-button button,
.MuiPopover-root .google-pay-button button {
  height: 48px;
  width: 100%;
  display: inline-block;
}
#root .btn-group .btn:first-of-type,
.modal-open .btn-group .btn:first-of-type,
.MuiPopover-root .btn-group .btn:first-of-type {
  border-radius: 8px 0 0 8px !important;
}
#root .btn-group .btn:last-of-type,
.modal-open .btn-group .btn:last-of-type,
.MuiPopover-root .btn-group .btn:last-of-type {
  border-radius: 0 8px 8px 0 !important;
}
#root .btn-group .btn.btn-dark,
.modal-open .btn-group .btn.btn-dark,
.MuiPopover-root .btn-group .btn.btn-dark {
  border-radius: 8px !important;
}
#root .tab-group,
.modal-open .tab-group,
.MuiPopover-root .tab-group {
  border-radius: 8px !important;
  background-color: #f2f3f5;
  height: 32px;
}
#root .tab-group .btn,
.modal-open .tab-group .btn,
.MuiPopover-root .tab-group .btn {
  padding: 0 !important;
}
#root .tab-group .btn:first-child,
.modal-open .tab-group .btn:first-child,
.MuiPopover-root .tab-group .btn:first-child {
  border-radius: 8px 0 0 8px !important;
}
#root .tab-group .btn:last-child,
.modal-open .tab-group .btn:last-child,
.MuiPopover-root .tab-group .btn:last-child {
  border-radius: 0 8px 8px 0 !important;
}
#root .tab-group .btn.btn-dark,
.modal-open .tab-group .btn.btn-dark,
.MuiPopover-root .tab-group .btn.btn-dark {
  border-radius: 8px !important;
}
#root .continue-group,
.modal-open .continue-group,
.MuiPopover-root .continue-group {
  margin: 0;
  padding: 0 !important;
}
#root .continue-group .btn,
.modal-open .continue-group .btn,
.MuiPopover-root .continue-group .btn {
  padding: 6px 20px !important;
}
#root .continue-group .btn:first-of-type,
.modal-open .continue-group .btn:first-of-type,
.MuiPopover-root .continue-group .btn:first-of-type {
  border-radius: 3px 0 0 3px !important;
}
#root .continue-group .btn:last-of-type,
.modal-open .continue-group .btn:last-of-type,
.MuiPopover-root .continue-group .btn:last-of-type {
  border-radius: 0 3px 3px 0 !important;
}
#root .continue-group .btn-block,
.modal-open .continue-group .btn-block,
.MuiPopover-root .continue-group .btn-block {
  margin-right: 1px;
}
#root .continue-group .dropdown-toggle,
.modal-open .continue-group .dropdown-toggle,
.MuiPopover-root .continue-group .dropdown-toggle {
  margin-left: 1px;
}
#root .continue-group .dropdown-toggle::after,
.modal-open .continue-group .dropdown-toggle::after,
.MuiPopover-root .continue-group .dropdown-toggle::after {
  font-family: "Font Awesome 6 Pro";
  content: "\f106";
  border: none;
  vertical-align: unset;
  font-weight: 300;
  font-size: 24px;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transform: translate(50%, -50%) rotate(-180deg);
}
#root .continue-group.show .dropdown-toggle::after,
.modal-open .continue-group.show .dropdown-toggle::after,
.MuiPopover-root .continue-group.show .dropdown-toggle::after {
  transform: translate(50%, -50%) rotate(0deg);
}
#root .continue-group .continue-menu,
.modal-open .continue-group .continue-menu,
.MuiPopover-root .continue-group .continue-menu {
  box-shadow: 0px 3px 16px 0px #24272b26;
  border: none;
  width: 100%;
  text-align: center;
  padding: 0;
  border-radius: 3px;
}
#root .continue-group .continue-menu .dropdown-item,
.modal-open .continue-group .continue-menu .dropdown-item,
.MuiPopover-root .continue-group .continue-menu .dropdown-item {
  padding: 10px;
  border-radius: 3px;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .continue-group .continue-menu .dropdown-item:hover,
.modal-open .continue-group .continue-menu .dropdown-item:hover,
.MuiPopover-root .continue-group .continue-menu .dropdown-item:hover,
#root .continue-group .continue-menu .dropdown-item:focus,
.modal-open .continue-group .continue-menu .dropdown-item:focus,
.MuiPopover-root .continue-group .continue-menu .dropdown-item:focus {
  background-color: #004bed;
  color: #ffffff !important;
}
#root .dropdown-item-text,
.modal-open .dropdown-item-text,
.MuiPopover-root .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
  white-space: nowrap;
}
#root .donate-back-button,
.modal-open .donate-back-button,
.MuiPopover-root .donate-back-button {
  width: 164px;
}
#root .btn-facebook,
.modal-open .btn-facebook,
.MuiPopover-root .btn-facebook {
  background-color: #415899;
  color: #ffffff !important;
}
#root .btn-facebook:hover,
.modal-open .btn-facebook:hover,
.MuiPopover-root .btn-facebook:hover {
  color: #ffffff !important;
}
#root .btn-twitter,
.modal-open .btn-twitter,
.MuiPopover-root .btn-twitter {
  background-color: #4ea8ee;
  color: #ffffff !important;
}
#root .btn-twitter:hover,
.modal-open .btn-twitter:hover,
.MuiPopover-root .btn-twitter:hover {
  color: #ffffff !important;
}
@media only screen and (max-width : 575px) {
  #root .btn-twitter,
  .modal-open .btn-twitter,
  .MuiPopover-root .btn-twitter {
    margin-top: 5px;
  }
}
#root .btn-google,
.modal-open .btn-google,
.MuiPopover-root .btn-google {
  background-color: #cb573a;
  color: #ffffff !important;
}
#root .btn-google:hover,
.modal-open .btn-google:hover,
.MuiPopover-root .btn-google:hover {
  color: #ffffff !important;
}
@media only screen and (max-width : 575px) {
  #root .btn-google,
  .modal-open .btn-google,
  .MuiPopover-root .btn-google {
    margin-top: 5px;
  }
}
#root .btn-apple,
.modal-open .btn-apple,
.MuiPopover-root .btn-apple {
  background-color: #000000;
  color: #ffffff !important;
}
#root .btn-apple:hover,
.modal-open .btn-apple:hover,
.MuiPopover-root .btn-apple:hover {
  color: #ffffff !important;
}
@media only screen and (max-width : 575px) {
  #root .btn-apple,
  .modal-open .btn-apple,
  .MuiPopover-root .btn-apple {
    margin-top: 5px;
  }
}
#root .dashboard-btn,
.modal-open .dashboard-btn,
.MuiPopover-root .dashboard-btn {
  background-color: #f2f3f5;
  color: #24272b;
  display: block;
  margin-bottom: 10px;
}
#root .dashboard-btn:hover,
.modal-open .dashboard-btn:hover,
.MuiPopover-root .dashboard-btn:hover {
  background-color: #d3dce6;
  color: #24272b !important;
}
#root .dashboard-btn.invert,
.modal-open .dashboard-btn.invert,
.MuiPopover-root .dashboard-btn.invert {
  background-color: #4a525a;
  color: #ffffff;
}
#root .dashboard-btn.invert:hover,
.modal-open .dashboard-btn.invert:hover,
.MuiPopover-root .dashboard-btn.invert:hover {
  background-color: #d3dce6 !important;
  color: #24272b !important;
}
#root .dashboard-btn:last-child,
.modal-open .dashboard-btn:last-child,
.MuiPopover-root .dashboard-btn:last-child {
  margin-bottom: 0;
}
#root .join-group-button-row .join-group-button,
.modal-open .join-group-button-row .join-group-button,
.MuiPopover-root .join-group-button-row .join-group-button {
  text-align: right;
  margin-top: 8px;
}
@media only screen and (max-width : 768px) {
  #root .join-group-button-row .join-group-button,
  .modal-open .join-group-button-row .join-group-button,
  .MuiPopover-root .join-group-button-row .join-group-button {
    text-align: center;
  }
}
@media only screen and (max-width : 768px) {
  #root .join-group-button-row,
  .modal-open .join-group-button-row,
  .MuiPopover-root .join-group-button-row {
    flex-direction: column-reverse !important;
  }
}
#root #afterpay-button > div,
.modal-open #afterpay-button > div,
.MuiPopover-root #afterpay-button > div {
  background-color: #000000;
  border-radius: 3px;
}
#root .button-trigger,
.modal-open .button-trigger,
.MuiPopover-root .button-trigger {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
#root .sticky-footer-row .btn-dark,
.modal-open .sticky-footer-row .btn-dark,
.MuiPopover-root .sticky-footer-row .btn-dark {
  background-color: #24272b !important;
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -ms-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  opacity: 1;
}
#root .sticky-footer-row .btn-dark:hover,
.modal-open .sticky-footer-row .btn-dark:hover,
.MuiPopover-root .sticky-footer-row .btn-dark:hover {
  opacity: 0.75;
}
#root .btn.btn-dark.afterpay-button,
.modal-open .btn.btn-dark.afterpay-button,
.MuiPopover-root .btn.btn-dark.afterpay-button {
  background-color: black !important;
  padding: 12px 20px !important;
}
#root .btn.btn-dark.afterpay-button:hover,
.modal-open .btn.btn-dark.afterpay-button:hover,
.MuiPopover-root .btn.btn-dark.afterpay-button:hover {
  background-color: #24272b !important;
}
#root .react-calendar,
.modal-open .react-calendar,
.MuiPopover-root .react-calendar {
  width: 100%;
  background-color: #f2f3f5;
  font-family: ff-basic-gothic-pro, sans-serif !important;
  border: none;
  border-radius: 16px;
}
@media only screen and (max-width : 768px) {
  #root .react-calendar,
  .modal-open .react-calendar,
  .MuiPopover-root .react-calendar {
    max-width: unset;
    border-radius: 0;
  }
}
#root .react-calendar .react-calendar__tile,
.modal-open .react-calendar .react-calendar__tile,
.MuiPopover-root .react-calendar .react-calendar__tile {
  border: solid 4px #f2f3f5;
  background-color: #ffffff;
  border-radius: 8px;
  color: #24272b;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  position: relative;
  overflow: hidden;
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: 18px;
  font-weight: 500;
}
#root .react-calendar .react-calendar__tile:disabled,
.modal-open .react-calendar .react-calendar__tile:disabled,
.MuiPopover-root .react-calendar .react-calendar__tile:disabled {
  color: #b0b8bf;
}
#root .react-calendar .react-calendar__tile:hover:not(:disabled, .react-calendar__tile--active),
.modal-open .react-calendar .react-calendar__tile:hover:not(:disabled, .react-calendar__tile--active),
.MuiPopover-root .react-calendar .react-calendar__tile:hover:not(:disabled, .react-calendar__tile--active) {
  border: solid 4px #ffffff;
  z-index: 9;
}
#root .react-calendar .react-calendar__tile .calendar-price-pill,
.modal-open .react-calendar .react-calendar__tile .calendar-price-pill,
.MuiPopover-root .react-calendar .react-calendar__tile .calendar-price-pill {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 9px;
  color: #24272b;
  background-color: #e3e6e9;
  border-radius: 9px;
  padding: 0 4px;
  font-weight: 700;
}
#root .react-calendar .react-calendar__tile .sticky-icon,
.modal-open .react-calendar .react-calendar__tile .sticky-icon,
.MuiPopover-root .react-calendar .react-calendar__tile .sticky-icon {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 8px;
  color: #11a9f0;
}
#root .react-calendar .react-calendar__tile .calendar-sold-out-banner,
.modal-open .react-calendar .react-calendar__tile .calendar-sold-out-banner,
.MuiPopover-root .react-calendar .react-calendar__tile .calendar-sold-out-banner {
  position: absolute;
  width: 300%;
  height: 15px;
  text-align: center;
  top: 50%;
  left: 50%;
  font-size: 6.9px;
  color: #ffffff;
  background-color: #e71d36;
  padding: 0 4px;
  font-weight: 900;
  text-transform: uppercase;
  transform: translate(-50%, -50%) rotate(38.141deg);
}
#root .react-calendar .react-calendar__tile.past-date-with-events,
.modal-open .react-calendar .react-calendar__tile.past-date-with-events,
.MuiPopover-root .react-calendar .react-calendar__tile.past-date-with-events {
  color: #b0b8bf;
}
#root .react-calendar .react-calendar__tile.neighbor-month,
.modal-open .react-calendar .react-calendar__tile.neighbor-month,
.MuiPopover-root .react-calendar .react-calendar__tile.neighbor-month {
  color: #b0b8bf;
  background-color: #f2f3f5;
}
#root .react-calendar .react-calendar__tile.neighbor-month:not(:disabled),
.modal-open .react-calendar .react-calendar__tile.neighbor-month:not(:disabled),
.MuiPopover-root .react-calendar .react-calendar__tile.neighbor-month:not(:disabled) {
  color: #24272b;
}
#root .react-calendar .react-calendar__tile.neighbor-month:not(:disabled):hover,
.modal-open .react-calendar .react-calendar__tile.neighbor-month:not(:disabled):hover,
.MuiPopover-root .react-calendar .react-calendar__tile.neighbor-month:not(:disabled):hover {
  background-color: #ffffff;
}
#root .react-calendar .react-calendar__navigation,
.modal-open .react-calendar .react-calendar__navigation,
.MuiPopover-root .react-calendar .react-calendar__navigation {
  margin-bottom: 0px;
}
#root .react-calendar .react-calendar__navigation__label__labelText,
.modal-open .react-calendar .react-calendar__navigation__label__labelText,
.MuiPopover-root .react-calendar .react-calendar__navigation__label__labelText {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}
#root .react-calendar .react-calendar__navigation__label,
.modal-open .react-calendar .react-calendar__navigation__label,
.MuiPopover-root .react-calendar .react-calendar__navigation__label {
  background-color: #f2f3f5;
}
#root .react-calendar .react-calendar__navigation__label:disabled,
.modal-open .react-calendar .react-calendar__navigation__label:disabled,
.MuiPopover-root .react-calendar .react-calendar__navigation__label:disabled {
  color: #24272b !important;
}
#root .react-calendar .react-calendar__navigation button:enabled:hover,
.modal-open .react-calendar .react-calendar__navigation button:enabled:hover,
.MuiPopover-root .react-calendar .react-calendar__navigation button:enabled:hover,
#root .react-calendar .react-calendar__navigation button:enabled:focus,
.modal-open .react-calendar .react-calendar__navigation button:enabled:focus,
.MuiPopover-root .react-calendar .react-calendar__navigation button:enabled:focus {
  background-color: #e3e6e9;
}
#root .react-calendar .react-calendar__navigation__prev-button,
.modal-open .react-calendar .react-calendar__navigation__prev-button,
.MuiPopover-root .react-calendar .react-calendar__navigation__prev-button {
  border-radius: 16px 0 16px 0;
  color: #24272b;
}
@media only screen and (max-width : 768px) {
  #root .react-calendar .react-calendar__navigation__prev-button,
  .modal-open .react-calendar .react-calendar__navigation__prev-button,
  .MuiPopover-root .react-calendar .react-calendar__navigation__prev-button {
    border-radius: 0 0 16px 0;
  }
}
#root .react-calendar .react-calendar__navigation__prev-button:disabled,
.modal-open .react-calendar .react-calendar__navigation__prev-button:disabled,
.MuiPopover-root .react-calendar .react-calendar__navigation__prev-button:disabled {
  opacity: 0;
}
#root .react-calendar .react-calendar__navigation__next-button,
.modal-open .react-calendar .react-calendar__navigation__next-button,
.MuiPopover-root .react-calendar .react-calendar__navigation__next-button {
  border-radius: 0 16px 0 16px;
  color: #24272b;
}
@media only screen and (max-width : 768px) {
  #root .react-calendar .react-calendar__navigation__next-button,
  .modal-open .react-calendar .react-calendar__navigation__next-button,
  .MuiPopover-root .react-calendar .react-calendar__navigation__next-button {
    border-radius: 0 0 0 16px;
  }
}
#root .react-calendar .react-calendar__navigation__next-button:disabled,
.modal-open .react-calendar .react-calendar__navigation__next-button:disabled,
.MuiPopover-root .react-calendar .react-calendar__navigation__next-button:disabled {
  opacity: 0;
}
#root .react-calendar .react-calendar__month-view__weekdays,
.modal-open .react-calendar .react-calendar__month-view__weekdays,
.MuiPopover-root .react-calendar .react-calendar__month-view__weekdays {
  padding: 0 5px;
  text-transform: none;
}
#root .react-calendar .react-calendar__month-view__days,
.modal-open .react-calendar .react-calendar__month-view__days,
.MuiPopover-root .react-calendar .react-calendar__month-view__days {
  padding: 0 4px 4px 4px;
}
#root .react-calendar abbr[title],
.modal-open .react-calendar abbr[title],
.MuiPopover-root .react-calendar abbr[title] {
  text-decoration: none;
  cursor: default;
  font-size: 14px;
  font-weight: 400;
}
#root .react-calendar .react-calendar__tile--active,
.modal-open .react-calendar .react-calendar__tile--active,
.MuiPopover-root .react-calendar .react-calendar__tile--active {
  background-color: #4a525a;
  color: #ffffff;
}
#root .tss-well,
.modal-open .tss-well,
.MuiPopover-root .tss-well {
  background-color: #f2f3f5 !important;
  box-shadow: none !important;
  border-radius: 16px;
  margin-top: 0 !important;
  cursor: pointer;
  text-decoration: none !important;
}
#root .tss-well ~ .tss-well,
.modal-open .tss-well ~ .tss-well,
.MuiPopover-root .tss-well ~ .tss-well {
  margin-top: 10px !important;
}
#root .tss-well .well-content,
.modal-open .tss-well .well-content,
.MuiPopover-root .tss-well .well-content {
  padding: 8px !important;
}
#root .tss-well .well-content.type-matrix,
.modal-open .tss-well .well-content.type-matrix,
.MuiPopover-root .tss-well .well-content.type-matrix {
  padding: 20px;
}
#root .tss-well .well,
.modal-open .tss-well .well,
.MuiPopover-root .tss-well .well {
  background-color: #f2f3f5;
  border-radius: 14px;
  box-shadow: none;
}
#root .tss-well .well:first-of-type:not(:only-of-type),
.modal-open .tss-well .well:first-of-type:not(:only-of-type),
.MuiPopover-root .tss-well .well:first-of-type:not(:only-of-type) {
  margin-top: 8px;
}
#root .tss-well .well:first-of-type,
.modal-open .tss-well .well:first-of-type,
.MuiPopover-root .tss-well .well:first-of-type {
  margin-top: 0 !important;
}
#root .tss-well .well *,
.modal-open .tss-well .well *,
.MuiPopover-root .tss-well .well * {
  text-decoration: none !important;
}
#root .tss-well .well *:hover,
.modal-open .tss-well .well *:hover,
.MuiPopover-root .tss-well .well *:hover {
  text-decoration: none;
}
#root .tss-well .well .dangerous-sticky,
.modal-open .tss-well .well .dangerous-sticky,
.MuiPopover-root .tss-well .well .dangerous-sticky,
#root .tss-well .well .dangerous-sticky.wysiwyg-plain-text *,
.modal-open .tss-well .well .dangerous-sticky.wysiwyg-plain-text *,
.MuiPopover-root .tss-well .well .dangerous-sticky.wysiwyg-plain-text * {
  color: #ffffff !important;
}
#root .tss-well .tss-well,
.modal-open .tss-well .tss-well,
.MuiPopover-root .tss-well .tss-well {
  background-color: #ffffff !important;
  border-radius: 8px !important;
}
#root .tss-well .tss-well .well-content,
.modal-open .tss-well .tss-well .well-content,
.MuiPopover-root .tss-well .tss-well .well-content {
  padding: 12px !important;
}
#root .tss-well.active,
.modal-open .tss-well.active,
.MuiPopover-root .tss-well.active {
  cursor: default;
}
#root .tss-well.active .well,
.modal-open .tss-well.active .well,
.MuiPopover-root .tss-well.active .well {
  background-color: #ffffff;
}
#root .tss-well .sticky-header,
.modal-open .tss-well .sticky-header,
.MuiPopover-root .tss-well .sticky-header {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
  color: #ffffff !important;
  border-radius: 0 0 8px 8px;
}
#root .tss-well .sticky-header .alert-body,
.modal-open .tss-well .sticky-header .alert-body,
.MuiPopover-root .tss-well .sticky-header .alert-body {
  padding: 8px !important;
}
#root .tss-well .tss-btn,
.modal-open .tss-well .tss-btn,
.MuiPopover-root .tss-well .tss-btn {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 13px !important;
  z-index: 2;
  border: none;
}
#root .tss-well .tss-btn.active,
.modal-open .tss-well .tss-btn.active,
.MuiPopover-root .tss-well .tss-btn.active {
  background-color: #4a525a !important;
  color: #ffffff !important;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .tss-well .tss-btn:hover:not(.sold-out),
.modal-open .tss-well .tss-btn:hover:not(.sold-out),
.MuiPopover-root .tss-well .tss-btn:hover:not(.sold-out) {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.08);
}
#root .tss-well .tss-btn.sold-out,
.modal-open .tss-well .tss-btn.sold-out,
.MuiPopover-root .tss-well .tss-btn.sold-out {
  color: rgba(4, 14, 53, 0.4) !important;
  cursor: not-allowed;
}
#root .tss-well .tss-btn .calendar-sold-out-banner,
.modal-open .tss-well .tss-btn .calendar-sold-out-banner,
.MuiPopover-root .tss-well .tss-btn .calendar-sold-out-banner {
  position: absolute;
  width: 300%;
  height: 14px;
  text-align: center;
  top: 50%;
  right: -150%;
  font-size: 9px;
  color: #ffffff;
  background-color: #e71d36;
  padding: 0 4px;
  font-weight: 900;
  text-transform: uppercase;
  transform: translate(-37px, -44%) rotate(38.141deg);
}
#root .tss-well .tss-btn.remaining,
.modal-open .tss-well .tss-btn.remaining,
.MuiPopover-root .tss-well .tss-btn.remaining {
  padding: 5.5px 13px !important;
}
#root .tss-well .tss-btn.remaining .tss-remaining-shim,
.modal-open .tss-well .tss-btn.remaining .tss-remaining-shim,
.MuiPopover-root .tss-well .tss-btn.remaining .tss-remaining-shim {
  margin-top: -6px;
  color: #24272b !important;
}
#root .tss-well .tab-group,
.modal-open .tss-well .tab-group,
.MuiPopover-root .tss-well .tab-group {
  background-color: #ffffff !important;
  gap: 10px;
}
#root .tss-well .tab-group .widget-btn,
.modal-open .tss-well .tab-group .widget-btn,
.MuiPopover-root .tss-well .tab-group .widget-btn {
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
}
#root .tss-well .widget-btn.btn-plain,
.modal-open .tss-well .widget-btn.btn-plain,
.MuiPopover-root .tss-well .widget-btn.btn-plain {
  background-color: #ffffff !important;
  border-radius: 8px !important;
}
#root .tss-well .widget-btn.btn-dark,
.modal-open .tss-well .widget-btn.btn-dark,
.MuiPopover-root .tss-well .widget-btn.btn-dark {
  background-color: #4a525a !important;
  color: #ffffff !important;
}
@media only screen and (max-width : 768px) {
  #root .tss-well .widget-btn,
  .modal-open .tss-well .widget-btn,
  .MuiPopover-root .tss-well .widget-btn {
    font-size: 12px;
    line-height: 18px;
  }
}
#root .tss-well .tss-time-star,
.modal-open .tss-well .tss-time-star,
.MuiPopover-root .tss-well .tss-time-star {
  position: absolute;
  right: 16px;
  top: 50%;
  color: #11a9f0;
  transform: translate(0px, -50%);
}
#root .tss-well .tss-remaining,
.modal-open .tss-well .tss-remaining,
.MuiPopover-root .tss-well .tss-remaining {
  background-color: #e3e6e9;
  padding: 2px 6px;
  font-size: 8px;
  font-weight: 700;
  line-height: 90%;
  border-radius: 20px;
  display: inline-block;
  margin-top: 5px;
}
@media only screen and (max-width : 768px) {
  #root .tss-calendar-view .tss-calendar-view-page-well > .well-content,
  .modal-open .tss-calendar-view .tss-calendar-view-page-well > .well-content,
  .MuiPopover-root .tss-calendar-view .tss-calendar-view-page-well > .well-content {
    padding: 0 !important;
  }
}
#root .tss-calendar-view .tss-hr,
.modal-open .tss-calendar-view .tss-hr,
.MuiPopover-root .tss-calendar-view .tss-hr {
  text-transform: uppercase;
}
#root .tss-calendar-view .tss-hr .horizontal-rule-label,
.modal-open .tss-calendar-view .tss-hr .horizontal-rule-label,
.MuiPopover-root .tss-calendar-view .tss-hr .horizontal-rule-label {
  background-color: #f2f3f5 !important;
}
#root .tss-calendar-view .attendee-type-header-row,
.modal-open .tss-calendar-view .attendee-type-header-row,
.MuiPopover-root .tss-calendar-view .attendee-type-header-row {
  display: none;
}
#root .tss-calendar-view .attendee-type-card,
.modal-open .tss-calendar-view .attendee-type-card,
.MuiPopover-root .tss-calendar-view .attendee-type-card {
  border: solid 1px #e3e6e9;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 6px 12px !important;
  margin: 4px 0;
  align-items: center;
}
#root .tss-calendar-view .attendee-type-card .attendee-name,
.modal-open .tss-calendar-view .attendee-type-card .attendee-name,
.MuiPopover-root .tss-calendar-view .attendee-type-card .attendee-name {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
}
#root .tss-calendar-view .attendee-type-card .attendee-description,
.modal-open .tss-calendar-view .attendee-type-card .attendee-description,
.MuiPopover-root .tss-calendar-view .attendee-type-card .attendee-description {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 130%;
}
#root .tss-calendar-view .attendee-type-card .price-container .base-price,
.modal-open .tss-calendar-view .attendee-type-card .price-container .base-price,
.MuiPopover-root .tss-calendar-view .attendee-type-card .price-container .base-price {
  font-size: 16px !important;
}
#root .tss-calendar-view .attendee-type-card .price-container .mini,
.modal-open .tss-calendar-view .attendee-type-card .price-container .mini,
.MuiPopover-root .tss-calendar-view .attendee-type-card .price-container .mini {
  letter-spacing: 0.8px;
}
#root .tss-calendar-view .attendee-type-card.multiline,
.modal-open .tss-calendar-view .attendee-type-card.multiline,
.MuiPopover-root .tss-calendar-view .attendee-type-card.multiline {
  align-items: flex-start;
}
#root .tss-calendar-view .attendee-type-card.multiline .form-counter,
.modal-open .tss-calendar-view .attendee-type-card.multiline .form-counter,
.MuiPopover-root .tss-calendar-view .attendee-type-card.multiline .form-counter {
  margin-top: 5px;
}
#root .tss-calendar-view .attendee-type-card.multiline .attendee-name:not(.has-description),
.modal-open .tss-calendar-view .attendee-type-card.multiline .attendee-name:not(.has-description),
.MuiPopover-root .tss-calendar-view .attendee-type-card.multiline .attendee-name:not(.has-description) {
  padding-top: 6px !important;
}
#root .tss-calendar-view .attendee-type-card:not(.multiline) .attendee-quantity-inner-container,
.modal-open .tss-calendar-view .attendee-type-card:not(.multiline) .attendee-quantity-inner-container,
.MuiPopover-root .tss-calendar-view .attendee-type-card:not(.multiline) .attendee-quantity-inner-container {
  margin: 0;
}
@media only screen and (max-width : 768px) {
  #root .tss-calendar-view .sticky-footer::before,
  .modal-open .tss-calendar-view .sticky-footer::before,
  .MuiPopover-root .tss-calendar-view .sticky-footer::before {
    width: 100% !important;
  }
}
#root .droppable,
.modal-open .droppable,
.MuiPopover-root .droppable {
  border: dashed 3px rgba(255, 255, 255, 0);
}
#root .droppable.is-dragging-over,
.modal-open .droppable.is-dragging-over,
.MuiPopover-root .droppable.is-dragging-over {
  border: dashed 3px #e3e6e9;
}
#root .droppable .draggable-wrapper,
.modal-open .droppable .draggable-wrapper,
.MuiPopover-root .droppable .draggable-wrapper {
  padding-left: 5px !important;
}
#root .droppable .draggable-row,
.modal-open .droppable .draggable-row,
.MuiPopover-root .droppable .draggable-row {
  background-color: #f2f3f5;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 0;
  position: relative;
}
#root .droppable .draggable-row.is-dragging,
.modal-open .droppable .draggable-row.is-dragging,
.MuiPopover-root .droppable .draggable-row.is-dragging {
  background-color: #e5e6e7;
}
#root .droppable .draggable-row .draggable-row-content,
.modal-open .droppable .draggable-row .draggable-row-content,
.MuiPopover-root .droppable .draggable-row .draggable-row-content {
  vertical-align: top;
  flex: 1;
  margin-left: 2px;
  font-weight: bold !important;
}
#root .droppable .draggable-row .draggable-row-content p,
.modal-open .droppable .draggable-row .draggable-row-content p,
.MuiPopover-root .droppable .draggable-row .draggable-row-content p {
  margin-bottom: 0;
}
#root .droppable .draggable-row .draggable-row-buttons,
.modal-open .droppable .draggable-row .draggable-row-buttons,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: top;
}
#root .droppable .draggable-row .draggable-row-buttons.pos-absolute,
.modal-open .droppable .draggable-row .draggable-row-buttons.pos-absolute,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons.pos-absolute {
  top: 2px;
  right: 5px;
}
#root .droppable .draggable-row .draggable-row-buttons .draggable-row-handle i,
.modal-open .droppable .draggable-row .draggable-row-buttons .draggable-row-handle i,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .draggable-row-handle i {
  position: relative;
  top: 3px;
}
#root .droppable .draggable-row .draggable-row-buttons .btn-sm,
.modal-open .droppable .draggable-row .draggable-row-buttons .btn-sm,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .btn-sm {
  height: 42px;
  width: 42px;
  position: relative;
  padding: 10px !important;
  margin-right: 3px;
}
#root .droppable .draggable-row .draggable-row-buttons .btn-sm i,
.modal-open .droppable .draggable-row .draggable-row-buttons .btn-sm i,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .btn-sm i {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 1px;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
#root .droppable .draggable-row .draggable-row-buttons .btn-sm:hover,
.modal-open .droppable .draggable-row .draggable-row-buttons .btn-sm:hover,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .btn-sm:hover {
  border-radius: 4px;
  box-shadow: 0.5px 0.5px 6px 0px rgba(0, 0, 0, 0.3);
}
#root .droppable .draggable-row .draggable-row-buttons .btn-xsml,
.modal-open .droppable .draggable-row .draggable-row-buttons .btn-xsml,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .btn-xsml {
  position: relative;
  height: 23px;
  width: 23px;
  margin-top: 6px;
  margin-right: 5px;
}
#root .droppable .draggable-row .draggable-row-buttons .btn-xsml i,
.modal-open .droppable .draggable-row .draggable-row-buttons .btn-xsml i,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .btn-xsml i {
  font-size: 11px;
  margin-top: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
#root .droppable .draggable-row .draggable-row-buttons .btn-xsml:hover,
.modal-open .droppable .draggable-row .draggable-row-buttons .btn-xsml:hover,
.MuiPopover-root .droppable .draggable-row .draggable-row-buttons .btn-xsml:hover {
  border-radius: 4px;
  box-shadow: 0.5px 0.5px 6px 0px rgba(0, 0, 0, 0.3);
}
#root .droppable .draggable-row .field,
.modal-open .droppable .draggable-row .field,
.MuiPopover-root .droppable .draggable-row .field {
  width: 100%;
}
#root ::placeholder,
.modal-open ::placeholder,
.MuiPopover-root ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #808080;
  opacity: 1;
  /* Firefox */
}
#root :-ms-input-placeholder,
.modal-open :-ms-input-placeholder,
.MuiPopover-root :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #808080;
}
#root ::-ms-input-placeholder,
.modal-open ::-ms-input-placeholder,
.MuiPopover-root ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #808080;
}
#root .z-max,
.modal-open .z-max,
.MuiPopover-root .z-max {
  z-index: 1000;
  position: relative;
}
#root .field,
.modal-open .field,
.MuiPopover-root .field {
  text-align: left;
  margin: 7.5px 0;
}
#root .field.field-fw,
.modal-open .field.field-fw,
.MuiPopover-root .field.field-fw {
  width: 100%;
}
#root .field label,
.modal-open .field label,
.MuiPopover-root .field label,
#root .field .label-span,
.modal-open .field .label-span,
.MuiPopover-root .field .label-span {
  display: block;
  text-align: left;
  padding: 2px;
  line-height: 130%;
  font-weight: 400;
  font-size: 12px;
}
#root .field label a,
.modal-open .field label a,
.MuiPopover-root .field label a,
#root .field .label-span a,
.modal-open .field .label-span a,
.MuiPopover-root .field .label-span a {
  color: #004bed;
  font-size: 0.75em;
  cursor: pointer;
}
#root .field label .optional,
.modal-open .field label .optional,
.MuiPopover-root .field label .optional,
#root .field .label-span .optional,
.modal-open .field .label-span .optional,
.MuiPopover-root .field .label-span .optional {
  font-size: 10px;
  font-style: italic;
  color: #4a525a;
}
#root .field input[type="password"],
.modal-open .field input[type="password"],
.MuiPopover-root .field input[type="password"] {
  font-family: initial;
}
#root .field input[type="password"]::placeholder,
.modal-open .field input[type="password"]::placeholder,
.MuiPopover-root .field input[type="password"]::placeholder {
  font-family: ff-basic-gothic-pro, sans-serif !important;
}
#root .field input[type="password"]:-ms-input-placeholder,
.modal-open .field input[type="password"]:-ms-input-placeholder,
.MuiPopover-root .field input[type="password"]:-ms-input-placeholder {
  font-family: ff-basic-gothic-pro, sans-serif !important;
}
#root .field input[type="password"]::-ms-input-placeholder,
.modal-open .field input[type="password"]::-ms-input-placeholder,
.MuiPopover-root .field input[type="password"]::-ms-input-placeholder {
  font-family: ff-basic-gothic-pro, sans-serif !important;
}
#root .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input),
.modal-open .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input),
.MuiPopover-root .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input) {
  background-color: #ffffff;
  display: block;
  width: 100%;
  height: 32px;
  border: solid 1px #e5e6e7;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 3px;
  padding: 9px;
  color: #24272b;
  outline: none;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input):focus,
.modal-open .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input):focus,
.MuiPopover-root .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input):focus,
#root .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input).active,
.modal-open .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input).active,
.MuiPopover-root .field input:not([type="radio"]):not([type="checkbox"]):not(.react-select__input input).active {
  border: solid 1px #4a525a;
}
#root .field input:disabled,
.modal-open .field input:disabled,
.MuiPopover-root .field input:disabled {
  background-color: rgba(4, 14, 53, 0.1) !important;
  color: rgba(4, 14, 53, 0.4) !important;
}
#root .field .react-select__input > input:disabled,
.modal-open .field .react-select__input > input:disabled,
.MuiPopover-root .field .react-select__input > input:disabled {
  opacity: 0 !important;
}
#root .field textarea,
.modal-open .field textarea,
.MuiPopover-root .field textarea {
  background-color: #ffffff;
  width: 100%;
  height: 67px;
  border: solid 1px #e5e6e7;
  padding: 9px;
  color: #24272b;
  outline: none;
  border-radius: 3px;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .field textarea:focus,
.modal-open .field textarea:focus,
.MuiPopover-root .field textarea:focus,
#root .field textarea.active,
.modal-open .field textarea.active,
.MuiPopover-root .field textarea.active {
  border: solid 1px #4a525a;
}
#root .field textarea:disabled,
.modal-open .field textarea:disabled,
.MuiPopover-root .field textarea:disabled {
  background-color: rgba(4, 14, 53, 0.1) !important;
  color: rgba(4, 14, 53, 0.4) !important;
}
#root .field input[type="checkbox"],
.modal-open .field input[type="checkbox"],
.MuiPopover-root .field input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  margin-left: -5px;
}
#root .field input[type="checkbox"]::before,
.modal-open .field input[type="checkbox"]::before,
.MuiPopover-root .field input[type="checkbox"]::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #b0b8bf;
  border-radius: 2px;
  background-color: #ffffff;
}
#root .field input[type="checkbox"]::before:checked,
.modal-open .field input[type="checkbox"]::before:checked,
.MuiPopover-root .field input[type="checkbox"]::before:checked {
  border: none;
  background-color: #004bed;
}
#root .field input[type="checkbox"]::after:checked,
.modal-open .field input[type="checkbox"]::after:checked,
.MuiPopover-root .field input[type="checkbox"]::after:checked {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 2px solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
#root .field input[type="checkbox"]:focus-visible,
.modal-open .field input[type="checkbox"]:focus-visible,
.MuiPopover-root .field input[type="checkbox"]:focus-visible {
  outline: 0;
}
#root .field input[type="checkbox"]:focus-visible::before,
.modal-open .field input[type="checkbox"]:focus-visible::before,
.MuiPopover-root .field input[type="checkbox"]:focus-visible::before {
  border: 2px solid #24272b;
}
#root .field input[type="radio"],
.modal-open .field input[type="radio"],
.MuiPopover-root .field input[type="radio"] {
  width: auto;
}
#root .field input[type="number"]::-webkit-inner-spin-button,
.modal-open .field input[type="number"]::-webkit-inner-spin-button,
.MuiPopover-root .field input[type="number"]::-webkit-inner-spin-button,
#root .field input[type="number"]::-webkit-outer-spin-button,
.modal-open .field input[type="number"]::-webkit-outer-spin-button,
.MuiPopover-root .field input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
#root .field input[type="number"],
.modal-open .field input[type="number"],
.MuiPopover-root .field input[type="number"] {
  -moz-appearance: textfield !important;
}
#root .field .datetime-picker .date-picker,
.modal-open .field .datetime-picker .date-picker,
.MuiPopover-root .field .datetime-picker .date-picker {
  display: inline-block;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  width: calc(100% - 10px);
  outline: none !important;
  position: relative;
}
#root .field .datetime-picker .date-picker:first-child,
.modal-open .field .datetime-picker .date-picker:first-child,
.MuiPopover-root .field .datetime-picker .date-picker:first-child {
  width: 100%;
}
#root .field .datetime-picker .date-picker::before,
.modal-open .field .datetime-picker .date-picker::before,
.MuiPopover-root .field .datetime-picker .date-picker::before {
  left: 14px;
  top: 11px;
  z-index: 1;
  pointer-events: none;
  color: #4a525a;
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  position: absolute;
}
#root .field .datetime-picker .date-picker::after,
.modal-open .field .datetime-picker .date-picker::after,
.MuiPopover-root .field .datetime-picker .date-picker::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 40px;
  width: 1px;
  height: 100%;
  background-color: #e5e6e7;
  z-index: 9;
  display: inline-block;
  pointer-events: none;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .field .datetime-picker .date-picker input,
.modal-open .field .datetime-picker .date-picker input,
.MuiPopover-root .field .datetime-picker .date-picker input {
  padding-left: 47px;
}
#root .field .datetime-picker.date-active .date-picker-container .date-picker::after,
.modal-open .field .datetime-picker.date-active .date-picker-container .date-picker::after,
.MuiPopover-root .field .datetime-picker.date-active .date-picker-container .date-picker::after {
  background-color: #4a525a;
}
#root .field .datetime-picker.time-active .time-picker-container .date-picker::after,
.modal-open .field .datetime-picker.time-active .time-picker-container .date-picker::after,
.MuiPopover-root .field .datetime-picker.time-active .time-picker-container .date-picker::after {
  background-color: #4a525a;
}
#root .field .datetime-picker .date-picker-container,
.modal-open .field .datetime-picker .date-picker-container,
.MuiPopover-root .field .datetime-picker .date-picker-container {
  display: inline-block;
  width: calc(50% - 10px);
  margin-right: 10px;
}
@media only screen and (max-width : 768px) {
  #root .field .datetime-picker .date-picker-container,
  .modal-open .field .datetime-picker .date-picker-container,
  .MuiPopover-root .field .datetime-picker .date-picker-container {
    width: 100%;
    padding-right: 5px;
  }
}
#root .field .datetime-picker .date-picker-container .date-picker,
.modal-open .field .datetime-picker .date-picker-container .date-picker,
.MuiPopover-root .field .datetime-picker .date-picker-container .date-picker {
  margin-right: 0;
}
#root .field .datetime-picker .date-picker-container .date-picker::before,
.modal-open .field .datetime-picker .date-picker-container .date-picker::before,
.MuiPopover-root .field .datetime-picker .date-picker-container .date-picker::before {
  content: "\f783";
}
#root .field .datetime-picker .time-picker-container,
.modal-open .field .datetime-picker .time-picker-container,
.MuiPopover-root .field .datetime-picker .time-picker-container {
  display: inline-block;
  width: calc(50% - 10px);
}
#root .field .datetime-picker .time-picker-container .date-picker,
.modal-open .field .datetime-picker .time-picker-container .date-picker,
.MuiPopover-root .field .datetime-picker .time-picker-container .date-picker {
  margin-left: 5px;
}
#root .field .datetime-picker .time-picker-container .date-picker::before,
.modal-open .field .datetime-picker .time-picker-container .date-picker::before,
.MuiPopover-root .field .datetime-picker .time-picker-container .date-picker::before {
  content: "\f017";
}
@media only screen and (max-width : 768px) {
  #root .field .datetime-picker .time-picker-container,
  .modal-open .field .datetime-picker .time-picker-container,
  .MuiPopover-root .field .datetime-picker .time-picker-container {
    width: 100%;
    padding-right: 5px;
  }
  #root .field .datetime-picker .time-picker-container .date-picker,
  .modal-open .field .datetime-picker .time-picker-container .date-picker,
  .MuiPopover-root .field .datetime-picker .time-picker-container .date-picker {
    margin-left: 0;
    margin-top: 5px;
  }
}
#root .field .datetime-picker .react-datepicker-popper,
.modal-open .field .datetime-picker .react-datepicker-popper,
.MuiPopover-root .field .datetime-picker .react-datepicker-popper {
  z-index: 10;
}
#root .field .datetime-picker .rdtActive,
.modal-open .field .datetime-picker .rdtActive,
.MuiPopover-root .field .datetime-picker .rdtActive {
  background-color: #004bed;
  border-radius: 3px;
}
#root .field .description,
.modal-open .field .description,
.MuiPopover-root .field .description {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 10px;
  line-height: 1.3;
  vertical-align: top;
  margin-top: 5px;
  letter-spacing: 0;
}
#root .field .error,
.modal-open .field .error,
.MuiPopover-root .field .error {
  display: inline-block;
  width: 100%;
  text-align: right;
  color: #e71d36;
  font-size: 10px;
  line-height: 1.3;
  vertical-align: top;
  margin-top: 5px;
  letter-spacing: 0;
}
#root .field.split-error .description,
.modal-open .field.split-error .description,
.MuiPopover-root .field.split-error .description {
  width: 50%;
}
#root .field.split-error .error,
.modal-open .field.split-error .error,
.MuiPopover-root .field.split-error .error {
  width: 50%;
  padding-left: 10px;
}
#root .field .description ~ .error,
.modal-open .field .description ~ .error,
.MuiPopover-root .field .description ~ .error {
  width: 50%;
  padding-left: 10px;
}
#root .field.inline,
.modal-open .field.inline,
.MuiPopover-root .field.inline {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#root .field .MuiSkeleton-rect,
.modal-open .field .MuiSkeleton-rect,
.MuiPopover-root .field .MuiSkeleton-rect {
  height: 41px;
  border-radius: 3px;
}
#root .error,
.modal-open .error,
.MuiPopover-root .error {
  color: #e71d36;
  line-height: 1.3;
}
#root .brushfire-checkbox,
.modal-open .brushfire-checkbox,
.MuiPopover-root .brushfire-checkbox {
  display: flex;
  flex-direction: column;
  margin: 0px 6px;
}
#root .brushfire-checkbox small,
.modal-open .brushfire-checkbox small,
.MuiPopover-root .brushfire-checkbox small {
  margin-top: -8px;
  padding-left: 25px;
  margin-bottom: 10px;
}
#root .brushfire-checkbox ~ .error,
.modal-open .brushfire-checkbox ~ .error,
.MuiPopover-root .brushfire-checkbox ~ .error {
  color: #e71d36;
  font-size: 10px;
  margin-left: 25px;
  margin-top: -10px;
  margin-bottom: 10px;
}
#root .brushfire-checkbox .checkbox.disabled,
.modal-open .brushfire-checkbox .checkbox.disabled,
.MuiPopover-root .brushfire-checkbox .checkbox.disabled {
  opacity: 0.5;
}
#root .field-label-style,
.modal-open .field-label-style,
.MuiPopover-root .field-label-style {
  display: block !important;
  text-align: left !important;
  padding: 2px !important;
  font-size: 12px;
  color: #4a525a !important;
  font-weight: 700;
}
#root .field-label-style > input,
.modal-open .field-label-style > input,
.MuiPopover-root .field-label-style > input {
  margin-right: 10px;
}
#root .field-label-style.disabled,
.modal-open .field-label-style.disabled,
.MuiPopover-root .field-label-style.disabled {
  opacity: 0.5;
}
#root .toggle-height-field label,
.modal-open .toggle-height-field label,
.MuiPopover-root .toggle-height-field label {
  margin-bottom: 7px;
}
#root .toggle-field,
.modal-open .toggle-field,
.MuiPopover-root .toggle-field {
  margin: 7.5px 0;
}
#root .toggle-field .checkbox input[type="checkbox"],
.modal-open .toggle-field .checkbox input[type="checkbox"],
.MuiPopover-root .toggle-field .checkbox input[type="checkbox"] {
  margin-left: 0;
}
#root .margin-top-label,
.modal-open .margin-top-label,
.MuiPopover-root .margin-top-label {
  margin-top: 34.5px;
}
#root .form-uploader,
.modal-open .form-uploader,
.MuiPopover-root .form-uploader {
  padding: 40px;
  text-align: center;
  background-color: #e5e6e7;
  border-radius: 3px;
  position: relative;
  flex-grow: 1;
}
#root .form-uploader .form-options,
.modal-open .form-uploader .form-options,
.MuiPopover-root .form-uploader .form-options {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 0.2s;
  z-index: 3;
  width: 100%;
}
#root .form-uploader .form-options > button,
.modal-open .form-uploader .form-options > button,
.MuiPopover-root .form-uploader .form-options > button {
  margin: 5px;
}
#root .form-uploader .form-uploader-coverall,
.modal-open .form-uploader .form-uploader-coverall,
.MuiPopover-root .form-uploader .form-uploader-coverall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 2;
  border-radius: 3px;
  transition: all ease-in-out 0.2s;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
#root .form-uploader .form-uploader-value,
.modal-open .form-uploader .form-uploader-value,
.MuiPopover-root .form-uploader .form-uploader-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 1;
  border-radius: 3px;
  transition: all ease-in-out 0.2s;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.5);
}
#root .form-uploader:hover .form-options,
.modal-open .form-uploader:hover .form-options,
.MuiPopover-root .form-uploader:hover .form-options {
  opacity: 1;
  pointer-events: all;
}
#root .form-uploader:hover .form-uploader-coverall,
.modal-open .form-uploader:hover .form-uploader-coverall,
.MuiPopover-root .form-uploader:hover .form-uploader-coverall {
  opacity: 1;
}
#root .password-field,
.modal-open .password-field,
.MuiPopover-root .password-field {
  position: relative;
}
#root .password-field .password-toggle,
.modal-open .password-field .password-toggle,
.MuiPopover-root .password-field .password-toggle {
  position: absolute;
  right: 10px;
  top: 10px;
}
#root .password-field.has-label .password-toggle,
.modal-open .password-field.has-label .password-toggle,
.MuiPopover-root .password-field.has-label .password-toggle {
  top: 30px;
}
#root .login-form .password-field,
.modal-open .login-form .password-field,
.MuiPopover-root .login-form .password-field {
  max-height: 0;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
#root .login-form.active .password-field,
.modal-open .login-form.active .password-field,
.MuiPopover-root .login-form.active .password-field {
  max-height: 151px;
}
#root .summary-box,
.modal-open .summary-box,
.MuiPopover-root .summary-box {
  background: linear-gradient(136.43deg, #0876e8 0%, #004bed 100%);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}
#root .summary-box h3,
.modal-open .summary-box h3,
.MuiPopover-root .summary-box h3 {
  margin-top: 0;
  color: #ffffff;
}
#root .summary-box li,
.modal-open .summary-box li,
.MuiPopover-root .summary-box li,
#root .summary-box a,
.modal-open .summary-box a,
.MuiPopover-root .summary-box a {
  color: #ffffff;
}
#root .react-select-container,
.modal-open .react-select-container,
.MuiPopover-root .react-select-container {
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .react-select-container > div:first-of-type,
.modal-open .react-select-container > div:first-of-type,
.MuiPopover-root .react-select-container > div:first-of-type {
  height: 32px;
  border-radius: 3px;
  font-size: 14px;
  border-color: #e5e6e7;
  min-height: fit-content;
}
#root .react-select-container .react-select__single-value,
.modal-open .react-select-container .react-select__single-value,
.MuiPopover-root .react-select-container .react-select__single-value {
  color: #24272b;
}
#root .react-select-container .react-select__indicator-separator,
.modal-open .react-select-container .react-select__indicator-separator,
.MuiPopover-root .react-select-container .react-select__indicator-separator {
  background-color: #e5e6e7 !important;
}
#root .react-select-container .react-select__control,
.modal-open .react-select-container .react-select__control,
.MuiPopover-root .react-select-container .react-select__control {
  background-color: #ffffff !important;
  color: #e71d36 !important;
}
#root .react-select-container .react-select__control.react-select__control--is-focused,
.modal-open .react-select-container .react-select__control.react-select__control--is-focused,
.MuiPopover-root .react-select-container .react-select__control.react-select__control--is-focused {
  box-shadow: #4a525a 0 0 0 1px !important;
}
#root .react-select-container.error-input .react-select__control,
.modal-open .react-select-container.error-input .react-select__control,
.MuiPopover-root .react-select-container.error-input .react-select__control {
  background-color: rgba(231, 29, 54, 0) !important;
  color: #e71d36 !important;
}
#root .react-select-container.error-input .react-select__control.react-select__control--is-focused,
.modal-open .react-select-container.error-input .react-select__control.react-select__control--is-focused,
.MuiPopover-root .react-select-container.error-input .react-select__control.react-select__control--is-focused {
  box-shadow: none !important;
}
#root .react-select-container.error-input > div:first-of-type,
.modal-open .react-select-container.error-input > div:first-of-type,
.MuiPopover-root .react-select-container.error-input > div:first-of-type {
  height: 28px;
  max-height: 32px;
  border: none;
}
#root .react-select-container.error-input .react-select__placeholder,
.modal-open .react-select-container.error-input .react-select__placeholder,
.MuiPopover-root .react-select-container.error-input .react-select__placeholder {
  color: #e71d36 !important;
  opacity: 0.7;
}
#root .react-select-container.error-input .react-select__indicator-separator,
.modal-open .react-select-container.error-input .react-select__indicator-separator,
.MuiPopover-root .react-select-container.error-input .react-select__indicator-separator {
  background-color: #e71d36 !important;
}
#root .react-select-container.error-input .react-select__indicator svg,
.modal-open .react-select-container.error-input .react-select__indicator svg,
.MuiPopover-root .react-select-container.error-input .react-select__indicator svg {
  fill: #e71d36 !important;
}
#root .react-select-container .react-select__indicator svg,
.modal-open .react-select-container .react-select__indicator svg,
.MuiPopover-root .react-select-container .react-select__indicator svg {
  padding: 3px;
}
#root .react-select-container .react-select__clear-indicator,
.modal-open .react-select-container .react-select__clear-indicator,
.MuiPopover-root .react-select-container .react-select__clear-indicator {
  padding: 0;
}
#root .react-select-container .react-select__value-container,
.modal-open .react-select-container .react-select__value-container,
.MuiPopover-root .react-select-container .react-select__value-container {
  padding: 0 8px;
  line-height: 18px;
}
#root .react-select-container .react-select__option:hover,
.modal-open .react-select-container .react-select__option:hover,
.MuiPopover-root .react-select-container .react-select__option:hover,
#root .react-select-container .react-select__option:focus,
.modal-open .react-select-container .react-select__option:focus,
.MuiPopover-root .react-select-container .react-select__option:focus,
#root .react-select-container .react-select__option.react-select__option--is-focused,
.modal-open .react-select-container .react-select__option.react-select__option--is-focused,
.MuiPopover-root .react-select-container .react-select__option.react-select__option--is-focused {
  background-color: #d3dce6;
}
#root .react-select-container .react-select__option.react-select__option--is-selected,
.modal-open .react-select-container .react-select__option.react-select__option--is-selected,
.MuiPopover-root .react-select-container .react-select__option.react-select__option--is-selected {
  background-color: #004bed;
  color: #ffffff;
}
#root .react-select__menu,
.modal-open .react-select__menu,
.MuiPopover-root .react-select__menu {
  background-color: #f2f3f5;
  z-index: 100;
}
#root #section-select,
.modal-open #section-select,
.MuiPopover-root #section-select {
  text-align: center;
}
#root #section-select .react-select__control,
.modal-open #section-select .react-select__control,
.MuiPopover-root #section-select .react-select__control {
  border: none !important;
  background-color: transparent !important;
}
#root #section-select .react-select__value-container .react-select__single-value,
.modal-open #section-select .react-select__value-container .react-select__single-value,
.MuiPopover-root #section-select .react-select__value-container .react-select__single-value {
  margin: auto;
  position: unset;
  transform: unset;
}
#root #section-select .react-select__control.react-select__control--is-focused,
.modal-open #section-select .react-select__control.react-select__control--is-focused,
.MuiPopover-root #section-select .react-select__control.react-select__control--is-focused {
  box-shadow: none !important;
}
#root .chevron-icon,
.modal-open .chevron-icon,
.MuiPopover-root .chevron-icon {
  font-size: 12px;
}
#root .hide-icon-options .react-select__menu-list i.fa,
.modal-open .hide-icon-options .react-select__menu-list i.fa,
.MuiPopover-root .hide-icon-options .react-select__menu-list i.fa,
#root .hide-icon-options .react-select__menu-list i.fal,
.modal-open .hide-icon-options .react-select__menu-list i.fal,
.MuiPopover-root .hide-icon-options .react-select__menu-list i.fal,
#root .hide-icon-options .react-select__menu-list i.fas,
.modal-open .hide-icon-options .react-select__menu-list i.fas,
.MuiPopover-root .hide-icon-options .react-select__menu-list i.fas,
#root .hide-icon-options .react-select__menu-list i.fad,
.modal-open .hide-icon-options .react-select__menu-list i.fad,
.MuiPopover-root .hide-icon-options .react-select__menu-list i.fad,
#root .hide-icon-options .react-select__menu-list i.fab,
.modal-open .hide-icon-options .react-select__menu-list i.fab,
.MuiPopover-root .hide-icon-options .react-select__menu-list i.fab {
  display: none;
}
#root fieldset[disabled] .react-select-container,
.modal-open fieldset[disabled] .react-select-container,
.MuiPopover-root fieldset[disabled] .react-select-container {
  pointer-events: none;
}
#root fieldset[disabled] .react-select-container .react-select__control,
.modal-open fieldset[disabled] .react-select-container .react-select__control,
.MuiPopover-root fieldset[disabled] .react-select-container .react-select__control {
  background-color: rgba(4, 14, 53, 0.1) !important;
  color: rgba(4, 14, 53, 0.4) !important;
}
#root fieldset[disabled] .react-select-container .react-select__value-container,
.modal-open fieldset[disabled] .react-select-container .react-select__value-container,
.MuiPopover-root fieldset[disabled] .react-select-container .react-select__value-container,
#root fieldset[disabled] .react-select-container .react-select__single-value,
.modal-open fieldset[disabled] .react-select-container .react-select__single-value,
.MuiPopover-root fieldset[disabled] .react-select-container .react-select__single-value,
#root fieldset[disabled] .react-select-container .react-select__placeholder,
.modal-open fieldset[disabled] .react-select-container .react-select__placeholder,
.MuiPopover-root fieldset[disabled] .react-select-container .react-select__placeholder {
  color: rgba(4, 14, 53, 0.4) !important;
}
#root .radio input,
.modal-open .radio input,
.MuiPopover-root .radio input {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  appearance: none;
  border: solid 2px #b0b8bf;
  border-radius: 20px;
  width: 18px !important;
  height: 18px;
  vertical-align: middle;
  background-color: transparent;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .radio input:checked,
.modal-open .radio input:checked,
.MuiPopover-root .radio input:checked {
  background-color: #004bed;
}
#root .radio > span,
.modal-open .radio > span,
.MuiPopover-root .radio > span {
  width: calc(100% - 28px);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: unset;
}
#root .checkbox input,
.modal-open .checkbox input,
.MuiPopover-root .checkbox input {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}
#root .checkbox input::before,
.modal-open .checkbox input::before,
.MuiPopover-root .checkbox input::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #b0b8bf;
  border-radius: 2px;
  background-color: #ffffff;
}
#root .checkbox input:checked::before,
.modal-open .checkbox input:checked::before,
.MuiPopover-root .checkbox input:checked::before {
  border: none;
  border-radius: 2px;
  background-color: #004bed;
}
#root .checkbox input:checked::after,
.modal-open .checkbox input:checked::after,
.MuiPopover-root .checkbox input:checked::after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 2px solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
#root .checkbox input.error-input::before,
.modal-open .checkbox input.error-input::before,
.MuiPopover-root .checkbox input.error-input::before,
#root .checkbox input .input-validation-error::before,
.modal-open .checkbox input .input-validation-error::before,
.MuiPopover-root .checkbox input .input-validation-error::before {
  border: 2px solid #e71d36;
}
#root .checkbox input.error-input:checked::before,
.modal-open .checkbox input.error-input:checked::before,
.MuiPopover-root .checkbox input.error-input:checked::before,
#root .checkbox input .input-validation-error:checked::before,
.modal-open .checkbox input .input-validation-error:checked::before,
.MuiPopover-root .checkbox input .input-validation-error:checked::before {
  background-color: #e71d36;
}
#root .checkbox > span,
.modal-open .checkbox > span,
.MuiPopover-root .checkbox > span {
  width: calc(100% - 25px);
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  margin-left: 9px;
}
#root .checkbox-row,
.modal-open .checkbox-row,
.MuiPopover-root .checkbox-row {
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .checkbox-row > input,
.modal-open .checkbox-row > input,
.MuiPopover-root .checkbox-row > input {
  margin: 0 10px 0 0 !important;
  border-radius: 3px;
  -webkit-appearance: none;
  outline: none !important;
  width: 24px;
  height: 24px;
  border: solid #24272b 3px;
  vertical-align: middle;
  position: relative;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .checkbox-row > input::before,
.modal-open .checkbox-row > input::before,
.MuiPopover-root .checkbox-row > input::before {
  content: "";
  width: 3px;
  height: 0px;
  background: #24272b;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 2px;
  transform-origin: top left;
  transform: rotate(-45deg);
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition-delay: 0.2s;
}
#root .checkbox-row > input::after,
.modal-open .checkbox-row > input::after,
.MuiPopover-root .checkbox-row > input::after {
  content: "";
  width: 3px;
  height: 0px;
  background: #24272b;
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 2px;
  transform-origin: top right;
  transform: rotate(-135deg);
  transition-delay: 0s;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
}
#root .checkbox-row > input:checked::before,
.modal-open .checkbox-row > input:checked::before,
.MuiPopover-root .checkbox-row > input:checked::before {
  transition-delay: 0s;
  height: 6px;
}
#root .checkbox-row > input:checked::after,
.modal-open .checkbox-row > input:checked::after,
.MuiPopover-root .checkbox-row > input:checked::after {
  transition-delay: 0.1s;
  height: 12px;
}
#root .checkbox-row .field.edit-inline,
.modal-open .checkbox-row .field.edit-inline,
.MuiPopover-root .checkbox-row .field.edit-inline {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 90px);
  margin: 0 20px 0 0;
}
#root .checkbox-row .field.edit-inline input,
.modal-open .checkbox-row .field.edit-inline input,
.MuiPopover-root .checkbox-row .field.edit-inline input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
#root .checkbox-row .field.edit-inline input:hover,
.modal-open .checkbox-row .field.edit-inline input:hover,
.MuiPopover-root .checkbox-row .field.edit-inline input:hover,
#root .checkbox-row .field.edit-inline input:active,
.modal-open .checkbox-row .field.edit-inline input:active,
.MuiPopover-root .checkbox-row .field.edit-inline input:active,
#root .checkbox-row .field.edit-inline input:focus,
.modal-open .checkbox-row .field.edit-inline input:focus,
.MuiPopover-root .checkbox-row .field.edit-inline input:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
#root .wysiwyg-text,
.modal-open .wysiwyg-text,
.MuiPopover-root .wysiwyg-text {
  line-height: 1.3;
}
#root .wysiwyg-text div,
.modal-open .wysiwyg-text div,
.MuiPopover-root .wysiwyg-text div,
#root .wysiwyg-text p,
.modal-open .wysiwyg-text p,
.MuiPopover-root .wysiwyg-text p,
#root .wysiwyg-text span,
.modal-open .wysiwyg-text span,
.MuiPopover-root .wysiwyg-text span {
  line-height: 1.3;
  overflow-wrap: anywhere;
}
#root .wysiwyg-text-align-left,
.modal-open .wysiwyg-text-align-left,
.MuiPopover-root .wysiwyg-text-align-left {
  text-align: left;
}
#root .wysiwyg-text-align-center,
.modal-open .wysiwyg-text-align-center,
.MuiPopover-root .wysiwyg-text-align-center {
  text-align: center;
}
#root .wysiwyg-text-align-center img,
.modal-open .wysiwyg-text-align-center img,
.MuiPopover-root .wysiwyg-text-align-center img {
  margin: auto;
}
#root .wysiwyg-text-align-right,
.modal-open .wysiwyg-text-align-right,
.MuiPopover-root .wysiwyg-text-align-right {
  text-align: right;
}
#root .wysiwyg-font-size-x-small,
.modal-open .wysiwyg-font-size-x-small,
.MuiPopover-root .wysiwyg-font-size-x-small {
  font-size: 50%;
}
#root .wysiwyg-font-size-small,
.modal-open .wysiwyg-font-size-small,
.MuiPopover-root .wysiwyg-font-size-small {
  font-size: 75%;
}
#root .wysiwyg-font-size-default,
.modal-open .wysiwyg-font-size-default,
.MuiPopover-root .wysiwyg-font-size-default {
  font-size: 100%;
}
#root .wysiwyg-font-size-large,
.modal-open .wysiwyg-font-size-large,
.MuiPopover-root .wysiwyg-font-size-large {
  font-size: 150%;
}
#root .wysiwyg-font-size-x-large,
.modal-open .wysiwyg-font-size-x-large,
.MuiPopover-root .wysiwyg-font-size-x-large {
  font-size: 200%;
}
#root .wysiwyg-font-size-xx-large,
.modal-open .wysiwyg-font-size-xx-large,
.MuiPopover-root .wysiwyg-font-size-xx-large {
  font-size: 300%;
}
#root .wysiwyg-color-default,
.modal-open .wysiwyg-color-default,
.MuiPopover-root .wysiwyg-color-default {
  color: #24272b;
}
#root .wysiwyg-color-blue,
.modal-open .wysiwyg-color-blue,
.MuiPopover-root .wysiwyg-color-blue {
  color: blue;
}
#root .wysiwyg-color-brown,
.modal-open .wysiwyg-color-brown,
.MuiPopover-root .wysiwyg-color-brown {
  color: brown;
}
#root .wysiwyg-color-cyan,
.modal-open .wysiwyg-color-cyan,
.MuiPopover-root .wysiwyg-color-cyan {
  color: cyan;
}
#root .wysiwyg-color-green,
.modal-open .wysiwyg-color-green,
.MuiPopover-root .wysiwyg-color-green {
  color: green;
}
#root .wysiwyg-color-magenta,
.modal-open .wysiwyg-color-magenta,
.MuiPopover-root .wysiwyg-color-magenta {
  color: magenta;
}
#root .wysiwyg-color-orange,
.modal-open .wysiwyg-color-orange,
.MuiPopover-root .wysiwyg-color-orange {
  color: #f67840;
}
#root .wysiwyg-color-purple,
.modal-open .wysiwyg-color-purple,
.MuiPopover-root .wysiwyg-color-purple {
  color: purple;
}
#root .wysiwyg-color-red,
.modal-open .wysiwyg-color-red,
.MuiPopover-root .wysiwyg-color-red {
  color: red;
}
#root .wysiwyg-color-yellow,
.modal-open .wysiwyg-color-yellow,
.MuiPopover-root .wysiwyg-color-yellow {
  color: yellow;
}
#root .radio-grid,
.modal-open .radio-grid,
.MuiPopover-root .radio-grid {
  margin: 0 -5px;
}
#root .radio-grid .btn,
.modal-open .radio-grid .btn,
.MuiPopover-root .radio-grid .btn {
  background-color: #004bed;
  margin-top: 10px;
  color: #ffffff;
}
#root .radio-grid label.container,
.modal-open .radio-grid label.container,
.MuiPopover-root .radio-grid label.container {
  vertical-align: top;
  color: #004bed !important;
  background: #f2f3f5;
  margin: 5px;
  padding: 20px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block !important;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  cursor: pointer;
}
#root .radio-grid label.container:hover,
.modal-open .radio-grid label.container:hover,
.MuiPopover-root .radio-grid label.container:hover {
  background: #d3dce6 !important;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .radio-grid label.container:hover .btn,
.modal-open .radio-grid label.container:hover .btn,
.MuiPopover-root .radio-grid label.container:hover .btn {
  background-color: #ffffff !important;
  color: #004bed !important;
}
#root .radio-grid label.container.checked,
.modal-open .radio-grid label.container.checked,
.MuiPopover-root .radio-grid label.container.checked {
  background: #004bed !important;
  color: #ffffff !important;
}
#root .radio-grid label.container.checked .btn,
.modal-open .radio-grid label.container.checked .btn,
.MuiPopover-root .radio-grid label.container.checked .btn {
  background-color: #ffffff !important;
  color: #004bed !important;
}
#root .radio-grid label.container input,
.modal-open .radio-grid label.container input,
.MuiPopover-root .radio-grid label.container input,
#root .radio-grid label.container p,
.modal-open .radio-grid label.container p,
.MuiPopover-root .radio-grid label.container p {
  display: none;
}
@media only screen and (max-width : 992px) {
  #root .radio-grid label.container h6,
  .modal-open .radio-grid label.container h6,
  .MuiPopover-root .radio-grid label.container h6 {
    font-size: 0.75em;
  }
}
#root .radio-grid.scale label.container.checked,
.modal-open .radio-grid.scale label.container.checked,
.MuiPopover-root .radio-grid.scale label.container.checked {
  transform: scale(1.1);
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .radio-grid.three-wide label.container,
.modal-open .radio-grid.three-wide label.container,
.MuiPopover-root .radio-grid.three-wide label.container {
  width: calc(33.33333% - 10px);
}
#root .radio-grid.four-wide label.container,
.modal-open .radio-grid.four-wide label.container,
.MuiPopover-root .radio-grid.four-wide label.container {
  width: calc(25% - 10px);
}
#root .radio-input label[for],
.modal-open .radio-input label[for],
.MuiPopover-root .radio-input label[for] {
  padding-left: 5px !important;
}
#root .radio-input:not(.radio-grid) label[for],
.modal-open .radio-input:not(.radio-grid) label[for],
.MuiPopover-root .radio-input:not(.radio-grid) label[for] {
  padding-left: 14px !important;
}
#root .react-select__indicator.react-select__dropdown-indicator,
.modal-open .react-select__indicator.react-select__dropdown-indicator,
.MuiPopover-root .react-select__indicator.react-select__dropdown-indicator {
  padding: 4px !important;
}
#root .react-select__indicator-separator,
.modal-open .react-select__indicator-separator,
.MuiPopover-root .react-select__indicator-separator {
  display: none;
}
#root .react-autosuggest__container input,
.modal-open .react-autosuggest__container input,
.MuiPopover-root .react-autosuggest__container input {
  border: solid 1px #e5e6e7;
  -webkit-appearance: none;
  border-radius: 3px;
  padding: 9px;
  color: #24272b;
  outline: none;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .react-autosuggest__container input:focus,
.modal-open .react-autosuggest__container input:focus,
.MuiPopover-root .react-autosuggest__container input:focus,
#root .react-autosuggest__container input.active,
.modal-open .react-autosuggest__container input.active,
.MuiPopover-root .react-autosuggest__container input.active {
  border: solid 1px #4a525a;
}
#root .react-autosuggest__container .react-autosuggest__suggestions-container,
.modal-open .react-autosuggest__container .react-autosuggest__suggestions-container,
.MuiPopover-root .react-autosuggest__container .react-autosuggest__suggestions-container {
  border: none !important;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .react-autosuggest__container ul,
.modal-open .react-autosuggest__container ul,
.MuiPopover-root .react-autosuggest__container ul {
  padding-inline-start: 0;
  margin: 0 !important;
  padding: 0 !important;
  border: none;
}
#root .react-autosuggest__container ul li,
.modal-open .react-autosuggest__container ul li,
.MuiPopover-root .react-autosuggest__container ul li {
  list-style-type: none;
  padding: 9px;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .react-autosuggest__container ul li:hover,
.modal-open .react-autosuggest__container ul li:hover,
.MuiPopover-root .react-autosuggest__container ul li:hover {
  cursor: pointer;
  background-color: #004bed;
  color: #ffffff;
}
#root .react-autosuggest__container .react-autosuggest__suggestions-container--open,
.modal-open .react-autosuggest__container .react-autosuggest__suggestions-container--open,
.MuiPopover-root .react-autosuggest__container .react-autosuggest__suggestions-container--open {
  border: solid 1px #e5e6e7;
  -webkit-appearance: none;
  border-radius: 3px;
  margin-top: 10px;
}
#root .react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion,
.modal-open .react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion,
.MuiPopover-root .react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion {
  border-top: solid 1px #e5e6e7;
  padding-top: 9px;
}
#root .react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion.react-autosuggest__suggestion--first,
.modal-open .react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion.react-autosuggest__suggestion--first,
.MuiPopover-root .react-autosuggest__container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion.react-autosuggest__suggestion--first {
  border-top: none;
}
#root .error-input,
.modal-open .error-input,
.MuiPopover-root .error-input,
#root .input-validation-error,
.modal-open .input-validation-error,
.MuiPopover-root .input-validation-error {
  color: #e71d36 !important;
  border: 2px solid #e71d36 !important;
  background-color: rgba(231, 29, 54, 0.1) !important;
  border-radius: 3px;
}
#root .error-input .brushfire-checkbox:first-child,
.modal-open .error-input .brushfire-checkbox:first-child,
.MuiPopover-root .error-input .brushfire-checkbox:first-child,
#root .input-validation-error .brushfire-checkbox:first-child,
.modal-open .input-validation-error .brushfire-checkbox:first-child,
.MuiPopover-root .input-validation-error .brushfire-checkbox:first-child,
#root .error-input .brushfire-radio:first-child,
.modal-open .error-input .brushfire-radio:first-child,
.MuiPopover-root .error-input .brushfire-radio:first-child,
#root .input-validation-error .brushfire-radio:first-child,
.modal-open .input-validation-error .brushfire-radio:first-child,
.MuiPopover-root .input-validation-error .brushfire-radio:first-child,
#root .error-input fieldset,
.modal-open .error-input fieldset,
.MuiPopover-root .error-input fieldset,
#root .input-validation-error fieldset,
.modal-open .input-validation-error fieldset,
.MuiPopover-root .input-validation-error fieldset {
  margin-top: 3px;
}
#root .form-row,
.modal-open .form-row,
.MuiPopover-root .form-row {
  background-color: #f2f3f5;
  padding: 10px;
  border-radius: 3px;
  margin: 5px 0;
  flex-wrap: nowrap;
}
#root .form-row .form-row-field,
.modal-open .form-row .form-row-field,
.MuiPopover-root .form-row .form-row-field {
  vertical-align: top;
}
#root .form-row .form-row-buttons,
.modal-open .form-row .form-row-buttons,
.MuiPopover-root .form-row .form-row-buttons {
  vertical-align: top;
  padding-left: 10px;
}
#root .form-row .field,
.modal-open .form-row .field,
.MuiPopover-root .form-row .field {
  width: 100%;
}
@media only screen and (max-width : 575px) {
  #root .xs-show-label .sr-only,
  .modal-open .xs-show-label .sr-only,
  .MuiPopover-root .xs-show-label .sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: initial;
    clip: none;
    white-space: wrap;
    border: 0;
  }
}
#root .checkbox-toggle input[type="checkbox"],
.modal-open .checkbox-toggle input[type="checkbox"],
.MuiPopover-root .checkbox-toggle input[type="checkbox"] {
  width: 24px;
  border-radius: 20px;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: #4a525a;
  height: 16px;
  position: relative;
  outline: none;
  vertical-align: middle;
  margin-top: -2px;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .checkbox-toggle input[type="checkbox"]::before,
.modal-open .checkbox-toggle input[type="checkbox"]::before,
.MuiPopover-root .checkbox-toggle input[type="checkbox"]::before {
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: #ffffff;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 1px;
}
#root .checkbox-toggle input[type="checkbox"]:checked,
.modal-open .checkbox-toggle input[type="checkbox"]:checked,
.MuiPopover-root .checkbox-toggle input[type="checkbox"]:checked {
  background: #004bed;
}
#root .checkbox-toggle input[type="checkbox"]:checked::before,
.modal-open .checkbox-toggle input[type="checkbox"]:checked::before,
.MuiPopover-root .checkbox-toggle input[type="checkbox"]:checked::before {
  left: 9px;
}
#root .checkbox-toggle input[type="checkbox"]:disabled,
.modal-open .checkbox-toggle input[type="checkbox"]:disabled,
.MuiPopover-root .checkbox-toggle input[type="checkbox"]:disabled {
  opacity: 0.25;
}
#root .sticky-input,
.modal-open .sticky-input,
.MuiPopover-root .sticky-input {
  position: relative;
}
#root .sticky-input p.mask,
.modal-open .sticky-input p.mask,
.MuiPopover-root .sticky-input p.mask {
  position: absolute;
  left: 10px;
  top: 31px;
  z-index: 2;
  pointer-events: none;
  letter-spacing: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0);
  display: flex;
  width: calc(100% - 20px);
  overflow: hidden;
}
#root .sticky-input p.mask.hidden-label,
.modal-open .sticky-input p.mask.hidden-label,
.MuiPopover-root .sticky-input p.mask.hidden-label {
  top: 12px;
}
#root .sticky-input p.mask .prefix,
.modal-open .sticky-input p.mask .prefix,
.MuiPopover-root .sticky-input p.mask .prefix,
#root .sticky-input p.mask .suffix,
.modal-open .sticky-input p.mask .suffix,
.MuiPopover-root .sticky-input p.mask .suffix {
  color: #9b9b9b;
}
#root .sticky-input p.mask .show,
.modal-open .sticky-input p.mask .show,
.MuiPopover-root .sticky-input p.mask .show {
  color: #0876e8;
}
#root .sticky-input-children p,
.modal-open .sticky-input-children p,
.MuiPopover-root .sticky-input-children p {
  display: inline-block;
}
#root .form-counter,
.modal-open .form-counter,
.MuiPopover-root .form-counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media only screen and (max-width : 768px) {
  #root .form-counter,
  .modal-open .form-counter,
  .MuiPopover-root .form-counter {
    justify-content: center;
    margin-top: 20px;
  }
}
#root .form-counter input,
.modal-open .form-counter input,
.MuiPopover-root .form-counter input {
  border: none !important;
  font-size: 24px !important;
  background-color: transparent !important;
  padding: 4px 2px 3px !important;
  -webkit-padding-before: 2px !important;
  /* happens on chrome as well, making the number a bit higher than other browsers */
  flex: 1;
  width: 48px !important;
  height: 30px !important;
}
@media only screen and (max-width : 768px) {
  #root .form-counter input,
  .modal-open .form-counter input,
  .MuiPopover-root .form-counter input {
    width: auto !important;
  }
}
#root .form-counter input:not(:focus),
.modal-open .form-counter input:not(:focus),
.MuiPopover-root .form-counter input:not(:focus) {
  border: none !important;
}
#root .form-counter input:disabled,
.modal-open .form-counter input:disabled,
.MuiPopover-root .form-counter input:disabled {
  background: transparent !important;
}
@media only screen and (max-width : 768px) {
  #root .form-counter small,
  .modal-open .form-counter small,
  .MuiPopover-root .form-counter small {
    width: 100%;
  }
}
#root .credit-card-input-group .credit-card-icon,
.modal-open .credit-card-input-group .credit-card-icon,
.MuiPopover-root .credit-card-input-group .credit-card-icon {
  position: absolute;
  right: 10px;
  display: flex;
  top: 22%;
  align-items: center;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
#root .credit-card-input-group .credit-card-icon.active,
.modal-open .credit-card-input-group .credit-card-icon.active,
.MuiPopover-root .credit-card-input-group .credit-card-icon.active {
  opacity: 1;
}
#root .credit-card-input-group .credit-card-icon i,
.modal-open .credit-card-input-group .credit-card-icon i,
.MuiPopover-root .credit-card-input-group .credit-card-icon i {
  font-size: 1.5em;
}
#root .credit-card-input-group .error-icon,
.modal-open .credit-card-input-group .error-icon,
.MuiPopover-root .credit-card-input-group .error-icon {
  color: #e71d36;
}
#root .item-list-field > .field,
.modal-open .item-list-field > .field,
.MuiPopover-root .item-list-field > .field {
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
#root .item-list-field > .field > label,
.modal-open .item-list-field > .field > label,
.MuiPopover-root .item-list-field > .field > label {
  flex: 1;
  padding: 8px 10px !important;
}
#root .item-list-field > .field .error,
.modal-open .item-list-field > .field .error,
.MuiPopover-root .item-list-field > .field .error {
  text-align: left;
}
#root .item-list-field > .field .react-select-container,
.modal-open .item-list-field > .field .react-select-container,
.MuiPopover-root .item-list-field > .field .react-select-container,
#root .item-list-field > .field > input,
.modal-open .item-list-field > .field > input,
.MuiPopover-root .item-list-field > .field > input {
  width: 16.66666667% !important;
}
@media only screen and (max-width : 768px) {
  #root .item-list-field > .field .react-select-container,
  .modal-open .item-list-field > .field .react-select-container,
  .MuiPopover-root .item-list-field > .field .react-select-container,
  #root .item-list-field > .field > input,
  .modal-open .item-list-field > .field > input,
  .MuiPopover-root .item-list-field > .field > input {
    width: 33.33333333% !important;
  }
}
#root .email-suggestion,
.modal-open .email-suggestion,
.MuiPopover-root .email-suggestion {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#root .email-suggestion .btn,
.modal-open .email-suggestion .btn,
.MuiPopover-root .email-suggestion .btn {
  font-size: 12px;
  margin: 5px 0 5px 5px !important;
  padding: 0 !important;
}
#root .field-section-header,
.modal-open .field-section-header,
.MuiPopover-root .field-section-header {
  border-bottom: 1px solid #e5e6e7;
  font-size: 24px;
  margin: 1em 0 0.5em 0;
}
#root .question-wrapper > .field-section-header:first-child,
.modal-open .question-wrapper > .field-section-header:first-child,
.MuiPopover-root .question-wrapper > .field-section-header:first-child {
  margin-top: 12px;
}
#root .tall-select,
.modal-open .tall-select,
.MuiPopover-root .tall-select {
  font-size: 16px;
  color: #b0b8bf;
}
#root .tall-select .react-select-container > div:first-of-type,
.modal-open .tall-select .react-select-container > div:first-of-type,
.MuiPopover-root .tall-select .react-select-container > div:first-of-type {
  height: 60px !important;
  max-height: unset;
}
#root .tall-select .react-select__value-container,
.modal-open .tall-select .react-select__value-container,
.MuiPopover-root .tall-select .react-select__value-container {
  overflow-y: unset;
  height: 60px !important;
}
#root .tall-select .react-select__single-value,
.modal-open .tall-select .react-select__single-value,
.MuiPopover-root .tall-select .react-select__single-value {
  text-overflow: unset;
  white-space: normal;
  overflow: unset;
}
#root .tall-select .react-select__option,
.modal-open .tall-select .react-select__option,
.MuiPopover-root .tall-select .react-select__option {
  color: #24272b;
}
#root .tall-select .react-select__option:hover,
.modal-open .tall-select .react-select__option:hover,
.MuiPopover-root .tall-select .react-select__option:hover,
#root .tall-select .react-select__option:focus,
.modal-open .tall-select .react-select__option:focus,
.MuiPopover-root .tall-select .react-select__option:focus,
#root .tall-select .react-select__option.react-select__option--is-focused,
.modal-open .tall-select .react-select__option.react-select__option--is-focused,
.MuiPopover-root .tall-select .react-select__option.react-select__option--is-focused {
  background-color: #d3dce6;
}
#root .tall-select .react-select__option.react-select__option--is-selected,
.modal-open .tall-select .react-select__option.react-select__option--is-selected,
.MuiPopover-root .tall-select .react-select__option.react-select__option--is-selected {
  background-color: #004bed;
}
#root .tall-select small,
.modal-open .tall-select small,
.MuiPopover-root .tall-select small {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#root .custom-price-input,
.modal-open .custom-price-input,
.MuiPopover-root .custom-price-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .custom-price-input .field,
.modal-open .custom-price-input .field,
.MuiPopover-root .custom-price-input .field {
  margin-top: 0;
}
@media only screen and (max-width : 768px) {
  #root .custom-price-input,
  .modal-open .custom-price-input,
  .MuiPopover-root .custom-price-input {
    align-items: flex-end;
  }
  #root .custom-price-input .field,
  .modal-open .custom-price-input .field,
  .MuiPopover-root .custom-price-input .field {
    align-items: flex-end;
  }
}
#root .access-code-input,
.modal-open .access-code-input,
.MuiPopover-root .access-code-input {
  margin-bottom: 1em;
}
#root .access-code-input .field,
.modal-open .access-code-input .field,
.MuiPopover-root .access-code-input .field {
  margin: 0;
  width: 50%;
  flex: 1;
}
#root .access-code-input .btn,
.modal-open .access-code-input .btn,
.MuiPopover-root .access-code-input .btn {
  padding: 0.3rem 0.75rem !important;
}
#root .access-code-input .error,
.modal-open .access-code-input .error,
.MuiPopover-root .access-code-input .error {
  text-align: left;
}
#root .access-code-input input,
.modal-open .access-code-input input,
.MuiPopover-root .access-code-input input {
  text-transform: uppercase;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#root .date-range,
.modal-open .date-range,
.MuiPopover-root .date-range {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 3px 16px 0px #24272b26;
}
#root .attendee-amount,
.modal-open .attendee-amount,
.MuiPopover-root .attendee-amount {
  width: 100%;
}
#root .attendee-amount .progress,
.modal-open .attendee-amount .progress,
.MuiPopover-root .attendee-amount .progress {
  margin-top: 4px;
}
@media only screen and (max-width : 768px) {
  #root .attendee-amount small.mini,
  .modal-open .attendee-amount small.mini,
  .MuiPopover-root .attendee-amount small.mini {
    text-align: right !important;
  }
}
#root .widget-fieldset[disabled] .btn,
.modal-open .widget-fieldset[disabled] .btn,
.MuiPopover-root .widget-fieldset[disabled] .btn {
  pointer-events: none;
}
#root .widget-fieldset[disabled] .btn.never-disabled,
.modal-open .widget-fieldset[disabled] .btn.never-disabled,
.MuiPopover-root .widget-fieldset[disabled] .btn.never-disabled {
  pointer-events: auto;
}
#root .react-select--is-disabled,
.modal-open .react-select--is-disabled,
.MuiPopover-root .react-select--is-disabled {
  opacity: 0.5;
}
#root .stripe-field,
.modal-open .stripe-field,
.MuiPopover-root .stripe-field {
  border: solid 1px #e5e6e7;
  border-radius: 3px;
  padding: 9px;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  background-color: #ffffff;
}
#root .stripe-field.focus,
.modal-open .stripe-field.focus,
.MuiPopover-root .stripe-field.focus {
  border: solid 1px #4a525a;
}
#root .stripe-field.has-error,
.modal-open .stripe-field.has-error,
.MuiPopover-root .stripe-field.has-error {
  background-color: rgba(231, 29, 54, 0.1) !important;
  border: 2px #e71d36 solid;
}
#root .country-region-picker,
.modal-open .country-region-picker,
.MuiPopover-root .country-region-picker {
  border: solid 1px #e5e6e7;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  margin-top: 7.5px;
}
#root .country-region-picker .field,
.modal-open .country-region-picker .field,
.MuiPopover-root .country-region-picker .field,
#root .country-region-picker .react-select-container > div:first-of-type,
.modal-open .country-region-picker .react-select-container > div:first-of-type,
.MuiPopover-root .country-region-picker .react-select-container > div:first-of-type,
#root .country-region-picker input:not(.error-input),
.modal-open .country-region-picker input:not(.error-input),
.MuiPopover-root .country-region-picker input:not(.error-input) {
  border: none !important;
}
#root .country-region-picker .field,
.modal-open .country-region-picker .field,
.MuiPopover-root .country-region-picker .field,
#root .country-region-picker .react-select-container > div:first-of-type,
.modal-open .country-region-picker .react-select-container > div:first-of-type,
.MuiPopover-root .country-region-picker .react-select-container > div:first-of-type,
#root .country-region-picker input,
.modal-open .country-region-picker input,
.MuiPopover-root .country-region-picker input {
  margin: 0;
}
#root .country-region-picker .react-select__control,
.modal-open .country-region-picker .react-select__control,
.MuiPopover-root .country-region-picker .react-select__control {
  min-height: 30px !important;
}
#root .country-region-picker .react-select-container,
.modal-open .country-region-picker .react-select-container,
.MuiPopover-root .country-region-picker .react-select-container {
  height: 30px;
}
#root .country-region-picker .country-region-picker-country,
.modal-open .country-region-picker .country-region-picker-country,
.MuiPopover-root .country-region-picker .country-region-picker-country {
  width: 32px;
  height: 30px;
}
#root .country-region-picker .country-region-picker-country .react-select__indicators,
.modal-open .country-region-picker .country-region-picker-country .react-select__indicators,
.MuiPopover-root .country-region-picker .country-region-picker-country .react-select__indicators {
  display: none;
}
#root .country-region-picker .country-region-picker-country .react-select-container > div:first-of-type,
.modal-open .country-region-picker .country-region-picker-country .react-select-container > div:first-of-type,
.MuiPopover-root .country-region-picker .country-region-picker-country .react-select-container > div:first-of-type {
  height: 30px !important;
}
#root .country-region-picker .country-region-picker-divider,
.modal-open .country-region-picker .country-region-picker-divider,
.MuiPopover-root .country-region-picker .country-region-picker-divider {
  display: inline-block;
  width: 1px;
  position: relative;
  background-color: #ffffff;
}
#root .country-region-picker .country-region-picker-divider::after,
.modal-open .country-region-picker .country-region-picker-divider::after,
.MuiPopover-root .country-region-picker .country-region-picker-divider::after {
  content: "";
  display: inline-block;
  left: 0;
  top: 7px;
  width: 1px;
  height: 16px;
  border-right: solid 1px #e5e6e7;
  position: absolute;
}
#root .country-region-picker .country-region-picker-region,
.modal-open .country-region-picker .country-region-picker-region,
.MuiPopover-root .country-region-picker .country-region-picker-region {
  width: calc(100% - 32px - 1px);
  height: 30px !important;
}
#root .country-region-picker .country-region-picker-region .react-select-container > div:first-of-type,
.modal-open .country-region-picker .country-region-picker-region .react-select-container > div:first-of-type,
.MuiPopover-root .country-region-picker .country-region-picker-region .react-select-container > div:first-of-type {
  height: 30px !important;
}
#root .country-region-picker .country-region-picker-region.text-input input,
.modal-open .country-region-picker .country-region-picker-region.text-input input,
.MuiPopover-root .country-region-picker .country-region-picker-region.text-input input {
  height: 30px !important;
}
#root .country-region-picker .country-region-picker-region .react-select__value-container,
.modal-open .country-region-picker .country-region-picker-region .react-select__value-container,
.MuiPopover-root .country-region-picker .country-region-picker-region .react-select__value-container {
  height: 30px !important;
}
#root .country-region-picker.active .country-region-picker-country,
.modal-open .country-region-picker.active .country-region-picker-country,
.MuiPopover-root .country-region-picker.active .country-region-picker-country {
  width: 100%;
}
#root .country-region-picker.active .country-region-picker-country::after,
.modal-open .country-region-picker.active .country-region-picker-country::after,
.MuiPopover-root .country-region-picker.active .country-region-picker-country::after {
  display: none;
}
#root .country-region-picker.active .country-region-picker-region,
.modal-open .country-region-picker.active .country-region-picker-region,
.MuiPopover-root .country-region-picker.active .country-region-picker-region {
  width: 0;
  overflow: hidden;
  opacity: 0;
}
#root .country-region-picker.active .country-region-picker-divider,
.modal-open .country-region-picker.active .country-region-picker-divider,
.MuiPopover-root .country-region-picker.active .country-region-picker-divider {
  display: none;
}
#root .country-region-picker.focus,
.modal-open .country-region-picker.focus,
.MuiPopover-root .country-region-picker.focus {
  border: solid 1px #4a525a;
}
@media only screen and (max-width : 768px) {
  #root .country-region-picker,
  .modal-open .country-region-picker,
  .MuiPopover-root .country-region-picker {
    margin-bottom: 5px;
  }
}
#root .country-region-picker.error-input input,
.modal-open .country-region-picker.error-input input,
.MuiPopover-root .country-region-picker.error-input input {
  background-color: rgba(231, 29, 54, 0) !important;
}
#root .country-region-picker.error-input .react-select__control,
.modal-open .country-region-picker.error-input .react-select__control,
.MuiPopover-root .country-region-picker.error-input .react-select__control {
  background-color: rgba(231, 29, 54, 0) !important;
  color: #e71d36 !important;
}
#root .country-region-picker.error-input .react-select__control.react-select__control--is-focused,
.modal-open .country-region-picker.error-input .react-select__control.react-select__control--is-focused,
.MuiPopover-root .country-region-picker.error-input .react-select__control.react-select__control--is-focused {
  box-shadow: none !important;
}
#root .country-region-picker.error-input > div:first-of-type,
.modal-open .country-region-picker.error-input > div:first-of-type,
.MuiPopover-root .country-region-picker.error-input > div:first-of-type {
  height: 28px;
  max-height: 32px;
  border: none;
}
#root .country-region-picker.error-input .react-select__placeholder,
.modal-open .country-region-picker.error-input .react-select__placeholder,
.MuiPopover-root .country-region-picker.error-input .react-select__placeholder {
  color: #e71d36 !important;
  opacity: 0.7;
}
#root .country-region-picker.error-input .react-select__indicator-separator,
.modal-open .country-region-picker.error-input .react-select__indicator-separator,
.MuiPopover-root .country-region-picker.error-input .react-select__indicator-separator {
  background-color: #e71d36 !important;
}
#root .country-region-picker.error-input .react-select__indicator svg,
.modal-open .country-region-picker.error-input .react-select__indicator svg,
.MuiPopover-root .country-region-picker.error-input .react-select__indicator svg {
  fill: #e71d36 !important;
}
#root .country-region-picker.error-input .country-region-picker-divider,
.modal-open .country-region-picker.error-input .country-region-picker-divider,
.MuiPopover-root .country-region-picker.error-input .country-region-picker-divider {
  background-color: rgba(231, 29, 54, 0.1);
}
#root .autocomplete-field,
.modal-open .autocomplete-field,
.MuiPopover-root .autocomplete-field {
  position: absolute;
  width: calc(100% - 10px);
  opacity: 0;
}
#root .autocomplete-field.active,
.modal-open .autocomplete-field.active,
.MuiPopover-root .autocomplete-field.active {
  opacity: 1;
  background-color: #ffffff;
}
#root .form-slider input,
.modal-open .form-slider input,
.MuiPopover-root .form-slider input {
  min-width: 50px;
  text-align: center;
  border: none;
  font-size: 20px !important;
  background-color: transparent !important;
  padding: 2px 2px 3px !important;
  border: none !important;
}
#root .form-slider input:disabled,
.modal-open .form-slider input:disabled,
.MuiPopover-root .form-slider input:disabled {
  background: transparent !important;
}
#root .form-slider .slider-min input,
.modal-open .form-slider .slider-min input,
.MuiPopover-root .form-slider .slider-min input {
  text-align: left;
}
#root .form-slider .slider-max input,
.modal-open .form-slider .slider-max input,
.MuiPopover-root .form-slider .slider-max input {
  text-align: right;
}
#root .form-slider .MuiSlider-thumb,
.modal-open .form-slider .MuiSlider-thumb,
.MuiPopover-root .form-slider .MuiSlider-thumb {
  background-color: #004bed;
}
#root .form-slider .MuiSlider-thumb.Mui-focusVisible,
.modal-open .form-slider .MuiSlider-thumb.Mui-focusVisible,
.MuiPopover-root .form-slider .MuiSlider-thumb.Mui-focusVisible,
#root .form-slider .MuiSlider-thumb:hover,
.modal-open .form-slider .MuiSlider-thumb:hover,
.MuiPopover-root .form-slider .MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(0, 75, 237, 0.16);
}
#root .form-slider .MuiSlider-thumb.MuiSlider-active,
.modal-open .form-slider .MuiSlider-thumb.MuiSlider-active,
.MuiPopover-root .form-slider .MuiSlider-thumb.MuiSlider-active {
  box-shadow: 0px 0px 0px 14px rgba(0, 75, 237, 0.16);
}
#root .form-slider .MuiSlider-track,
.modal-open .form-slider .MuiSlider-track,
.MuiPopover-root .form-slider .MuiSlider-track {
  background-color: #004bed;
}
#root .form-slider .MuiSlider-rail,
.modal-open .form-slider .MuiSlider-rail,
.MuiPopover-root .form-slider .MuiSlider-rail {
  background-color: #004bed;
}
#root .brushfire-radio .brushfire-accept-field input,
.modal-open .brushfire-radio .brushfire-accept-field input,
.MuiPopover-root .brushfire-radio .brushfire-accept-field input {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  appearance: none;
  border: solid 2px #b0b8bf;
  border-radius: 20px;
  width: 18px !important;
  height: 18px;
  vertical-align: middle;
  background-color: transparent;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .brushfire-radio .brushfire-accept-field input:checked,
.modal-open .brushfire-radio .brushfire-accept-field input:checked,
.MuiPopover-root .brushfire-radio .brushfire-accept-field input:checked {
  background-color: #004bed;
}
#root .brushfire-accept-field input,
.modal-open .brushfire-accept-field input,
.MuiPopover-root .brushfire-accept-field input {
  position: relative;
  cursor: pointer;
  margin-right: calc(10px - 4px);
  appearance: none;
  border: solid 2px #b0b8bf;
  border-radius: 20px;
  width: 18px !important;
  height: 18px;
  vertical-align: middle;
  background-color: transparent;
  -webkit-transition: all ease-in-out 0.15s;
  -moz-transition: all ease-in-out 0.15s;
  -ms-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
}
#root .brushfire-accept-field input:checked,
.modal-open .brushfire-accept-field input:checked,
.MuiPopover-root .brushfire-accept-field input:checked {
  background-color: #004bed;
}
#root .brushfire-accept-field label,
.modal-open .brushfire-accept-field label,
.MuiPopover-root .brushfire-accept-field label {
  display: block !important;
  text-align: left !important;
  padding: 2px !important;
  font-size: 14px !important;
  color: #4a525a !important;
  font-weight: 400;
}
#root .brushfire-accept-field span,
.modal-open .brushfire-accept-field span,
.MuiPopover-root .brushfire-accept-field span {
  width: calc(100% - 28px);
  display: inline;
  vertical-align: middle !important;
  font-size: 14px;
}
#root .sq-card-wrapper.sq-error .sq-card-iframe-container,
.modal-open .sq-card-wrapper.sq-error .sq-card-iframe-container,
.MuiPopover-root .sq-card-wrapper.sq-error .sq-card-iframe-container {
  max-height: 48px;
  background-color: rgba(231, 29, 54, 0.1);
}
@media only screen and (max-width : 768px) {
  #root .sq-card-wrapper.sq-error .sq-card-iframe-container,
  .modal-open .sq-card-wrapper.sq-error .sq-card-iframe-container,
  .MuiPopover-root .sq-card-wrapper.sq-error .sq-card-iframe-container {
    max-height: none;
  }
}
#root .sq-card-iframe-container,
.modal-open .sq-card-iframe-container,
.MuiPopover-root .sq-card-iframe-container {
  max-height: 48px;
}
@media only screen and (max-width : 768px) {
  #root .sq-card-iframe-container,
  .modal-open .sq-card-iframe-container,
  .MuiPopover-root .sq-card-iframe-container {
    max-height: none;
  }
}
#root .sq-card-wrapper .sq-card-message.sq-card-message-no-error,
.modal-open .sq-card-wrapper .sq-card-message.sq-card-message-no-error,
.MuiPopover-root .sq-card-wrapper .sq-card-message.sq-card-message-no-error {
  display: none;
  visibility: hidden;
}
@media only screen and (max-width : 768px) {
  #root .sq-card-component,
  .modal-open .sq-card-component,
  .MuiPopover-root .sq-card-component {
    margin: 0;
    width: 100%;
  }
}
#root .field label,
.modal-open .field label,
.MuiPopover-root .field label,
#root .field ~ label,
.modal-open .field ~ label,
.MuiPopover-root .field ~ label {
  display: block !important;
  text-align: left !important;
  padding: 2px 2px 2px 0 !important;
  font-size: 12px;
  color: #4a525a !important;
  font-weight: 700;
  margin-bottom: 0;
}
#root .field label span,
.modal-open .field label span,
.MuiPopover-root .field label span,
#root .field ~ label span,
.modal-open .field ~ label span,
.MuiPopover-root .field ~ label span {
  font-weight: 400;
}
#root .field .field-name-label,
.modal-open .field .field-name-label,
.MuiPopover-root .field .field-name-label {
  display: flex !important;
  text-align: left;
  padding: 2px 2px 0px 0px;
  font-size: 12px;
  color: #4a525a;
  font-weight: 700;
  margin: 0;
  margin-bottom: -5px !important;
  justify-content: space-between;
}
#root .field .field-name-label-clear,
.modal-open .field .field-name-label-clear,
.MuiPopover-root .field .field-name-label-clear {
  margin-left: 10px;
}
#root .react-select-menu-buffer,
.modal-open .react-select-menu-buffer,
.MuiPopover-root .react-select-menu-buffer {
  height: 300px;
}
#root .react-select-menu-buffer.extra,
.modal-open .react-select-menu-buffer.extra,
.MuiPopover-root .react-select-menu-buffer.extra {
  height: 400px;
}
#root .select-buffer-disabled .react-select-menu-buffer,
.modal-open .select-buffer-disabled .react-select-menu-buffer,
.MuiPopover-root .select-buffer-disabled .react-select-menu-buffer {
  height: 0px;
}
#root .sq-card-message,
.modal-open .sq-card-message,
.MuiPopover-root .sq-card-message {
  display: none;
}
#root .sq-card-message.sq-visibile,
.modal-open .sq-card-message.sq-visibile,
.MuiPopover-root .sq-card-message.sq-visibile {
  display: block;
}
#root .sq-card-message.sq-card-message-error,
.modal-open .sq-card-message.sq-card-message-error,
.MuiPopover-root .sq-card-message.sq-card-message-error {
  display: inline-block;
  width: 100%;
  text-align: right;
  color: #e71d36;
  font-size: 10px;
  line-height: 1.3;
  vertical-align: top;
  margin-top: 5px;
  letter-spacing: 0;
}
#root .sq-card-message.sq-card-message-error::before,
.modal-open .sq-card-message.sq-card-message-error::before,
.MuiPopover-root .sq-card-message.sq-card-message-error::before {
  display: none;
}
#root .has-square-error .sq-card-message.sq-card-message-error,
.modal-open .has-square-error .sq-card-message.sq-card-message-error,
.MuiPopover-root .has-square-error .sq-card-message.sq-card-message-error {
  display: none;
}
#root .type-change-row,
.modal-open .type-change-row,
.MuiPopover-root .type-change-row {
  padding: 8px;
  border-radius: 6px;
  background-color: #f2f3f5;
  margin-top: 10px;
}
#root .type-change-row:first-child,
.modal-open .type-change-row:first-child,
.MuiPopover-root .type-change-row:first-child {
  margin-top: 0;
}
#root .type-change-row.current,
.modal-open .type-change-row.current,
.MuiPopover-root .type-change-row.current {
  background-color: #e3e6e9;
}
#root .type-change-row.selected,
.modal-open .type-change-row.selected,
.MuiPopover-root .type-change-row.selected {
  background-color: #4a525a;
  color: #ffffff;
}
#root .type-change-row h4,
.modal-open .type-change-row h4,
.MuiPopover-root .type-change-row h4 {
  font-size: 18px;
}
#root .type-change-row .btn,
.modal-open .type-change-row .btn,
.MuiPopover-root .type-change-row .btn {
  min-width: 112px;
  box-sizing: border-box;
}
#root .type-change-row .btn.btn-outline,
.modal-open .type-change-row .btn.btn-outline,
.MuiPopover-root .type-change-row .btn.btn-outline {
  border: solid 2px #24272b;
  padding: 3px 20px !important;
}
#root .type-change-row .btn.btn-dark,
.modal-open .type-change-row .btn.btn-dark,
.MuiPopover-root .type-change-row .btn.btn-dark {
  background-color: #24272b;
}
#root .signature-status,
.modal-open .signature-status,
.MuiPopover-root .signature-status {
  margin-top: 15px;
}
#root .field-with-notes,
.modal-open .field-with-notes,
.MuiPopover-root .field-with-notes {
  display: flex;
}
#root .field-with-notes > .field-wrap,
.modal-open .field-with-notes > .field-wrap,
.MuiPopover-root .field-with-notes > .field-wrap {
  display: inline-block;
  width: calc(100% - 30px);
}
#root .field-with-notes > .field-notes,
.modal-open .field-with-notes > .field-notes,
.MuiPopover-root .field-with-notes > .field-notes {
  display: inline-block;
  width: 30px;
  align-items: flex-start;
  margin-top: 31px;
}
#root .field-with-notes > .field-notes .btn-tooltip,
.modal-open .field-with-notes > .field-notes .btn-tooltip,
.MuiPopover-root .field-with-notes > .field-notes .btn-tooltip {
  z-index: 99;
}
#root .field-with-notes > .field-notes:hover .btn-tooltip,
.modal-open .field-with-notes > .field-notes:hover .btn-tooltip,
.MuiPopover-root .field-with-notes > .field-notes:hover .btn-tooltip {
  z-index: 1030;
}
#root .field-with-notes.no-label > .field-notes,
.modal-open .field-with-notes.no-label > .field-notes,
.MuiPopover-root .field-with-notes.no-label > .field-notes {
  margin-top: 0px;
}
#root .input-copy-wrap,
.modal-open .input-copy-wrap,
.MuiPopover-root .input-copy-wrap {
  position: relative;
}
#root .input-copy-wrap .input-copy-button,
.modal-open .input-copy-wrap .input-copy-button,
.MuiPopover-root .input-copy-wrap .input-copy-button {
  background-color: #4a525a;
  color: #ffffff;
  padding: 2px 4px !important;
  font-size: 10px;
  position: absolute;
  bottom: 6px;
  right: 6px;
}
#root .text-array-row,
.modal-open .text-array-row,
.MuiPopover-root .text-array-row {
  position: relative;
}
#root .text-array-row > input,
.modal-open .text-array-row > input,
.MuiPopover-root .text-array-row > input {
  padding-right: 34px !important;
}
#root .text-array-row > .btn,
.modal-open .text-array-row > .btn,
.MuiPopover-root .text-array-row > .btn {
  position: absolute;
  right: 5px;
  top: 4px;
}
#root .wallets-disabled,
.modal-open .wallets-disabled,
.MuiPopover-root .wallets-disabled {
  pointer-events: none;
  opacity: 0.5;
}
#root .alert-checkbox .alert-body > div,
.modal-open .alert-checkbox .alert-body > div,
.MuiPopover-root .alert-checkbox .alert-body > div {
  height: unset !important;
}
#root .alert-checkbox .brushfire-checkbox,
.modal-open .alert-checkbox .brushfire-checkbox,
.MuiPopover-root .alert-checkbox .brushfire-checkbox {
  margin: 0;
}
#root .alert-checkbox .brushfire-checkbox label,
.modal-open .alert-checkbox .brushfire-checkbox label,
.MuiPopover-root .alert-checkbox .brushfire-checkbox label {
  margin-bottom: 0;
}
#root .alert-checkbox .brushfire-checkbox label > span,
.modal-open .alert-checkbox .brushfire-checkbox label > span,
.MuiPopover-root .alert-checkbox .brushfire-checkbox label > span {
  width: unset;
}
#root .hs-form,
.modal-open .hs-form,
.MuiPopover-root .hs-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}
#root .hs-form .hs-form-field,
.modal-open .hs-form .hs-form-field,
.MuiPopover-root .hs-form .hs-form-field {
  width: 100%;
}
#root .hs-form .hs-form-field:nth-child(1),
.modal-open .hs-form .hs-form-field:nth-child(1),
.MuiPopover-root .hs-form .hs-form-field:nth-child(1),
#root .hs-form .hs-form-field:nth-child(2),
.modal-open .hs-form .hs-form-field:nth-child(2),
.MuiPopover-root .hs-form .hs-form-field:nth-child(2),
#root .hs-form .hs-form-field:nth-child(3),
.modal-open .hs-form .hs-form-field:nth-child(3),
.MuiPopover-root .hs-form .hs-form-field:nth-child(3),
#root .hs-form .hs-form-field:nth-child(4),
.modal-open .hs-form .hs-form-field:nth-child(4),
.MuiPopover-root .hs-form .hs-form-field:nth-child(4) {
  flex: 1 1 calc(50% - 1em);
}
#root .hs-form .hs-form-field label,
.modal-open .hs-form .hs-form-field label,
.MuiPopover-root .hs-form .hs-form-field label {
  display: block !important;
  text-align: left !important;
  padding: 2px 2px 2px 0 !important;
  font-size: 12px;
  color: #4a525a !important;
  font-weight: 700;
  margin-bottom: 0;
}
#root .hs-form .hs-form-field label span,
.modal-open .hs-form .hs-form-field label span,
.MuiPopover-root .hs-form .hs-form-field label span {
  font-weight: 700;
}
#root .hs-form .hs-form-field .hs-input.error,
.modal-open .hs-form .hs-form-field .hs-input.error,
.MuiPopover-root .hs-form .hs-form-field .hs-input.error {
  color: #e71d36 !important;
  border: 2px solid #e71d36 !important;
  background-color: rgba(231, 29, 54, 0.1) !important;
  border-radius: 3px;
  margin-top: 0 !important;
  text-align: left !important;
}
#root .hs-form .hs-form-field select,
.modal-open .hs-form .hs-form-field select,
.MuiPopover-root .hs-form .hs-form-field select {
  width: 100%;
  height: 32px;
  text-align: left !important;
  font-size: 14px !important;
  padding: 0 9px !important;
  border-color: #e5e6e7;
}
#root .hs-form .hs-form-field select:focus,
.modal-open .hs-form .hs-form-field select:focus,
.MuiPopover-root .hs-form .hs-form-field select:focus {
  border-color: #4a525a;
}
#root .hs-form .hs-form-field select option,
.modal-open .hs-form .hs-form-field select option,
.MuiPopover-root .hs-form .hs-form-field select option {
  color: #24272b;
}
#root .hs-form .hs-form-field select option:first-of-type,
.modal-open .hs-form .hs-form-field select option:first-of-type,
.MuiPopover-root .hs-form .hs-form-field select option:first-of-type {
  color: #808080;
}
#root .hs-form .hs-form-field .no-list,
.modal-open .hs-form .hs-form-field .no-list,
.MuiPopover-root .hs-form .hs-form-field .no-list {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  text-align: right;
}
#root .hs-form .hs-form-field .no-list label,
.modal-open .hs-form .hs-form-field .no-list label,
.MuiPopover-root .hs-form .hs-form-field .no-list label {
  display: inline-block;
  width: 100%;
  text-align: right !important;
  color: #e71d36 !important;
  font-size: 10px !important;
  line-height: 1.3;
  vertical-align: top;
  margin-top: 5px !important;
  letter-spacing: 0;
  font-weight: 400;
}
#root .hs-form .hs-button,
.modal-open .hs-form .hs-button,
.MuiPopover-root .hs-form .hs-button {
  visibility: hidden;
}
#root .hs-form-wrapper.loading,
.modal-open .hs-form-wrapper.loading,
.MuiPopover-root .hs-form-wrapper.loading {
  opacity: 0.5;
  pointer-events: none;
}
#root header,
.modal-open header,
.MuiPopover-root header {
  position: fixed;
  top: 0;
  z-index: 1050;
  width: 100%;
  height: 54px;
  color: #24272b;
}
#root header .page-links,
.modal-open header .page-links,
.MuiPopover-root header .page-links {
  margin: 0;
  line-height: 54px;
  height: 54px;
  display: block;
  padding: 0;
}
#root header .page-links.left,
.modal-open header .page-links.left,
.MuiPopover-root header .page-links.left {
  text-align: left;
  float: left;
}
#root header .page-links.left .hamburger,
.modal-open header .page-links.left .hamburger,
.MuiPopover-root header .page-links.left .hamburger {
  display: none;
  line-height: 28px;
  font-size: 28px;
  margin: 0;
}
#root header .page-links.left .hamburger.app-hamburger,
.modal-open header .page-links.left .hamburger.app-hamburger,
.MuiPopover-root header .page-links.left .hamburger.app-hamburger {
  display: block;
}
#root header .page-links.left .hamburger .in-drawer,
.modal-open header .page-links.left .hamburger .in-drawer,
.MuiPopover-root header .page-links.left .hamburger .in-drawer {
  display: none;
}
.drawer-open #root header .page-links.left .hamburger .in-drawer,
.drawer-open .modal-open header .page-links.left .hamburger .in-drawer,
.drawer-open .MuiPopover-root header .page-links.left .hamburger .in-drawer {
  display: inline-block;
}
#root header .page-links.left .hamburger .out-drawer,
.modal-open header .page-links.left .hamburger .out-drawer,
.MuiPopover-root header .page-links.left .hamburger .out-drawer {
  display: inline-block;
}
.drawer-open #root header .page-links.left .hamburger .out-drawer,
.drawer-open .modal-open header .page-links.left .hamburger .out-drawer,
.drawer-open .MuiPopover-root header .page-links.left .hamburger .out-drawer {
  display: none;
}
#root header .page-links.left .logo-holder,
.modal-open header .page-links.left .logo-holder,
.MuiPopover-root header .page-links.left .logo-holder {
  margin: 0;
  margin-right: 12px;
  padding: 0;
}
@media only screen and (max-width : 1024px) {
  #root header .page-links.left .logo-holder,
  .modal-open header .page-links.left .logo-holder,
  .MuiPopover-root header .page-links.left .logo-holder {
    margin: 0;
    margin-right: 6px;
  }
}
@media only screen and (max-width : 480px) {
  #root header .page-links.left .logo-holder,
  .modal-open header .page-links.left .logo-holder,
  .MuiPopover-root header .page-links.left .logo-holder {
    padding-top: 0;
    margin-right: 0;
    margin-left: 2px;
  }
}
#root header .page-links.left .logo-holder img,
.modal-open header .page-links.left .logo-holder img,
.MuiPopover-root header .page-links.left .logo-holder img {
  width: 150px;
  margin-top: -2px;
}
#root header .page-links.left .logo-shim,
.modal-open header .page-links.left .logo-shim,
.MuiPopover-root header .page-links.left .logo-shim {
  margin-left: 16px;
}
#root header .page-links.right,
.modal-open header .page-links.right,
.MuiPopover-root header .page-links.right {
  padding-right: 17px;
  padding-top: 5px;
  text-align: right;
  float: right;
}
@media only screen and (max-width : 480px) {
  #root header .page-links.right,
  .modal-open header .page-links.right,
  .MuiPopover-root header .page-links.right {
    padding-right: 10px;
    padding-top: 7px;
  }
}
#root header .page-links.right .cart,
.modal-open header .page-links.right .cart,
.MuiPopover-root header .page-links.right .cart {
  padding: 8px;
  background-color: transparent;
  border-radius: 3px;
  color: #ffffff;
  position: relative;
  top: -2px;
  border: 2px solid #ffffff;
}
@media only screen and (max-width : 768px) {
  #root header .page-links.right .cart,
  .modal-open header .page-links.right .cart,
  .MuiPopover-root header .page-links.right .cart {
    margin-right: 12px;
  }
}
@media only screen and (max-width : 480px) {
  #root header .page-links.right .cart,
  .modal-open header .page-links.right .cart,
  .MuiPopover-root header .page-links.right .cart {
    margin-right: 0px;
    margin-left: 0;
  }
}
#root header .page-links.right .cart .cart-badge,
.modal-open header .page-links.right .cart .cart-badge,
.MuiPopover-root header .page-links.right .cart .cart-badge {
  left: 100%;
  transform: translateX(-50%);
}
#root header .page-links.right .cart:hover,
.modal-open header .page-links.right .cart:hover,
.MuiPopover-root header .page-links.right .cart:hover {
  color: #3c3c3c !important;
  background-color: #ffffff;
  border: 2px solid #ffffff;
}
#root header .page-links.right .cart i:hover,
.modal-open header .page-links.right .cart i:hover,
.MuiPopover-root header .page-links.right .cart i:hover,
#root header .page-links.right .cart span:hover,
.modal-open header .page-links.right .cart span:hover,
.MuiPopover-root header .page-links.right .cart span:hover {
  color: #3c3c3c;
}
#root header .page-links.right .user-icon-holder,
.modal-open header .page-links.right .user-icon-holder,
.MuiPopover-root header .page-links.right .user-icon-holder {
  margin: 0;
  top: -2px;
  margin-right: 0;
  margin-left: 12px;
}
@media only screen and (max-width : 1024px) {
  #root header .page-links.right .user-icon-holder,
  .modal-open header .page-links.right .user-icon-holder,
  .MuiPopover-root header .page-links.right .user-icon-holder {
    margin-right: 0;
    margin-left: 6px;
  }
}
@media only screen and (max-width : 480px) {
  #root header .page-links.right .user-icon-holder,
  .modal-open header .page-links.right .user-icon-holder,
  .MuiPopover-root header .page-links.right .user-icon-holder {
    margin: 0;
    margin-left: 0px;
  }
}
#root header .page-links.right .user-icon-holder .avatar,
.modal-open header .page-links.right .user-icon-holder .avatar,
.MuiPopover-root header .page-links.right .user-icon-holder .avatar {
  margin: 0;
  float: left;
}
#root header .page-links.right .user-icon-holder .avatar img,
.modal-open header .page-links.right .user-icon-holder .avatar img,
.MuiPopover-root header .page-links.right .user-icon-holder .avatar img {
  width: 44px;
  height: auto;
  margin: 0;
  margin-top: -5px;
  transition: 0.2s ease-out;
  border-color: #ffffff;
}
#root header .page-links.right .user-icon-holder .left-text,
.modal-open header .page-links.right .user-icon-holder .left-text,
.MuiPopover-root header .page-links.right .user-icon-holder .left-text {
  margin: 0;
  margin-left: 6px;
  text-align: left;
  display: inline-block;
  color: #ffffff;
}
@media only screen and (max-width : 1024px) {
  #root header .page-links.right .user-icon-holder .left-text,
  .modal-open header .page-links.right .user-icon-holder .left-text,
  .MuiPopover-root header .page-links.right .user-icon-holder .left-text {
    display: none;
  }
}
#root header .page-links.right .user-icon-holder .left-text.one-line,
.modal-open header .page-links.right .user-icon-holder .left-text.one-line,
.MuiPopover-root header .page-links.right .user-icon-holder .left-text.one-line {
  margin-top: 10px;
}
#root header .page-links.right .user-icon-holder:hover .avatar img,
.modal-open header .page-links.right .user-icon-holder:hover .avatar img,
.MuiPopover-root header .page-links.right .user-icon-holder:hover .avatar img {
  border-color: #ffffff;
}
#root header .page-links li,
.modal-open header .page-links li,
.MuiPopover-root header .page-links li {
  display: inline-block;
  vertical-align: middle;
}
#root header .page-links li .btn-icon,
.modal-open header .page-links li .btn-icon,
.MuiPopover-root header .page-links li .btn-icon {
  font-size: 30px;
}
#root header .page-links li a,
.modal-open header .page-links li a,
.MuiPopover-root header .page-links li a {
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  color: #24272b;
  padding-bottom: 4px;
  margin: 0 12px;
}
@media only screen and (max-width : 1024px) {
  #root header .page-links li a,
  .modal-open header .page-links li a,
  .MuiPopover-root header .page-links li a {
    margin: 0 5px;
  }
}
#root header .page-links li a:hover,
.modal-open header .page-links li a:hover,
.MuiPopover-root header .page-links li a:hover {
  text-decoration: none !important;
  color: #24272b;
  border-bottom: 2px solid #24272b;
  margin-top: 2px;
}
#root header .signout-dropdown,
.modal-open header .signout-dropdown,
.MuiPopover-root header .signout-dropdown {
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 51px;
  padding: 0px;
  padding-bottom: 20px;
  padding-top: 19px;
  text-align: center;
  display: none;
  z-index: 1031;
}
@media only screen and (max-width : 768px) {
  #root header .signout-dropdown,
  .modal-open header .signout-dropdown,
  .MuiPopover-root header .signout-dropdown {
    min-width: 90px;
    right: 0;
  }
}
#root header .signout-dropdown a,
.modal-open header .signout-dropdown a,
.MuiPopover-root header .signout-dropdown a {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  padding-bottom: 4px;
}
#root header .signout-dropdown a:hover,
.modal-open header .signout-dropdown a:hover,
.MuiPopover-root header .signout-dropdown a:hover {
  color: #ffffff;
  border-bottom: 2px solid #004bed;
  text-decoration: none;
}
#root header .authbar,
.modal-open header .authbar,
.MuiPopover-root header .authbar {
  position: absolute;
  z-index: 1030;
  top: 54px;
  width: 100%;
  text-align: center;
  background-color: rgba(210, 68, 69, 0.95);
  font-size: 15px;
  padding: 10px;
}
#root header .authbar a,
.modal-open header .authbar a,
.MuiPopover-root header .authbar a {
  text-decoration: underline;
  color: #ffffff;
}
#root header .alertbar,
.modal-open header .alertbar,
.MuiPopover-root header .alertbar {
  position: absolute;
  z-index: 1030;
  top: 54px;
  width: 100%;
  text-align: center;
  background-color: rgba(60, 60, 60, 0.5);
  font-size: 11px;
  padding: 2px;
}
#root header .alertbar.loggedinas,
.modal-open header .alertbar.loggedinas,
.MuiPopover-root header .alertbar.loggedinas {
  top: 95px;
}
#root header .alertbar.impersonating,
.modal-open header .alertbar.impersonating,
.MuiPopover-root header .alertbar.impersonating {
  background-color: #004bed;
  padding: 10px;
  font-size: 15px;
}
#root header .alertbar a,
.modal-open header .alertbar a,
.MuiPopover-root header .alertbar a {
  text-decoration: underline;
  color: #ffffff;
}
#root header .alertbar span,
.modal-open header .alertbar span,
.MuiPopover-root header .alertbar span {
  float: right;
  margin-right: 20px;
  line-height: 20px;
  font-size: 20px;
}
#root header .get-started,
.modal-open header .get-started,
.MuiPopover-root header .get-started {
  width: 100%;
  position: fixed;
  right: 0;
  background-color: rgba(60, 60, 60, 0.95);
  height: 0vh;
  margin-top: 54px;
  -webkit-transition-duration: 0.5s;
  transition: height 0.5s ease;
  display: block;
  overflow: hidden;
  border-top: none;
}
#root header .get-started.drop-menu-active,
.modal-open header .get-started.drop-menu-active,
.MuiPopover-root header .get-started.drop-menu-active {
  height: calc(100vh - 54px);
  border-top: 4px solid #004bed;
}
#root header .get-started ul,
.modal-open header .get-started ul,
.MuiPopover-root header .get-started ul {
  text-align: left;
  padding: 0;
  margin: 0;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}
#root header .get-started ul .drop-link,
.modal-open header .get-started ul .drop-link,
.MuiPopover-root header .get-started ul .drop-link {
  border-bottom: 2px solid rgba(240, 240, 240, 0.5);
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  line-height: 24px;
  font-size: 24px;
}
#root header .get-started ul .drop-link a,
.modal-open header .get-started ul .drop-link a,
.MuiPopover-root header .get-started ul .drop-link a {
  text-decoration: none;
  color: #ffffff;
  margin-left: 30px;
  display: block;
  padding: 4% 28px;
}
#root header .get-started ul .drop-link a .item-icon,
.modal-open header .get-started ul .drop-link a .item-icon,
.MuiPopover-root header .get-started ul .drop-link a .item-icon {
  margin-left: -40px;
  margin-right: 10px;
}
#root header .get-started ul .drop-link a:active,
.modal-open header .get-started ul .drop-link a:active,
.MuiPopover-root header .get-started ul .drop-link a:active {
  color: #004bed;
}
#root header .get-started ul .drop-link:last-child,
.modal-open header .get-started ul .drop-link:last-child,
.MuiPopover-root header .get-started ul .drop-link:last-child {
  border: none;
}
#root header .avatar-container > .page-links .signout-icon,
.modal-open header .avatar-container > .page-links .signout-icon,
.MuiPopover-root header .avatar-container > .page-links .signout-icon {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  margin: 0;
  width: 0;
  height: 40px;
  overflow: hidden;
  margin-left: 10px;
}
#root header .avatar-container > .page-links .signout-icon i,
.modal-open header .avatar-container > .page-links .signout-icon i,
.MuiPopover-root header .avatar-container > .page-links .signout-icon i {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  color: #ffffff;
  margin-top: 12px;
}
#root header .avatar-container > .page-links .signout-icon a,
.modal-open header .avatar-container > .page-links .signout-icon a,
.MuiPopover-root header .avatar-container > .page-links .signout-icon a {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  margin: 0;
  text-align: center;
}
#root header .avatar-container > .page-links .signout-icon a:hover,
.modal-open header .avatar-container > .page-links .signout-icon a:hover,
.MuiPopover-root header .avatar-container > .page-links .signout-icon a:hover {
  text-decoration: none;
  border-bottom: none;
  margin-top: 0 !important;
}
#root header .avatar-container > .page-links:hover .signout-icon,
.modal-open header .avatar-container > .page-links:hover .signout-icon,
.MuiPopover-root header .avatar-container > .page-links:hover .signout-icon {
  width: 40px;
  border-radius: 40px;
}
#root header .avatar-container > .page-links:hover .signout-icon a:hover,
.modal-open header .avatar-container > .page-links:hover .signout-icon a:hover,
.MuiPopover-root header .avatar-container > .page-links:hover .signout-icon a:hover {
  background-color: #ffffff;
}
#root header .avatar-container > .page-links:hover .signout-icon a:hover i,
.modal-open header .avatar-container > .page-links:hover .signout-icon a:hover i,
.MuiPopover-root header .avatar-container > .page-links:hover .signout-icon a:hover i {
  color: #24272b;
}
@media only screen and (max-width : 768px) {
  #root .main,
  .modal-open .main,
  .MuiPopover-root .main {
    padding: 62px 0 20px 0;
  }
  .pattern #root .main,
  .pattern .modal-open .main,
  .pattern .MuiPopover-root .main {
    background-color: transparent;
  }
  #root header,
  .modal-open header,
  .MuiPopover-root header {
    position: absolute;
  }
}
@media only screen and (min-width: 768px) {
  #root .translated-ltr header,
  .modal-open .translated-ltr header,
  .MuiPopover-root .translated-ltr header,
  #root .translated-rtl header,
  .modal-open .translated-rtl header,
  .MuiPopover-root .translated-rtl header {
    top: 39px;
  }
}
#root .main.loggedinas,
.modal-open .main.loggedinas,
.MuiPopover-root .main.loggedinas {
  padding-top: 120px;
}
#root .main.impersonating,
.modal-open .main.impersonating,
.MuiPopover-root .main.impersonating {
  padding-top: 120px;
}
#root .main.impersonating.loggedinas,
.modal-open .main.impersonating.loggedinas,
.MuiPopover-root .main.impersonating.loggedinas {
  padding-top: 161px;
}
#root .main.not-impersonating,
.modal-open .main.not-impersonating,
.MuiPopover-root .main.not-impersonating {
  padding-top: 98px;
}
#root .main.not-impersonating.loggedinas,
.modal-open .main.not-impersonating.loggedinas,
.MuiPopover-root .main.not-impersonating.loggedinas {
  padding-top: 139px;
}
#root .no-hover:hover,
.modal-open .no-hover:hover,
.MuiPopover-root .no-hover:hover {
  color: #ffffff !important;
  border: none !important;
  cursor: default !important;
}
#root .no-hover-underline:hover,
.modal-open .no-hover-underline:hover,
.MuiPopover-root .no-hover-underline:hover {
  border: none !important;
  text-decoration: none !important;
}
#root .no-margin-top,
.modal-open .no-margin-top,
.MuiPopover-root .no-margin-top {
  margin-top: 0 !important;
}
@media only screen and (max-width : 826px) {
  #root .hide-826,
  .modal-open .hide-826,
  .MuiPopover-root .hide-826 {
    display: none !important;
  }
}
#root .homepage-header,
.modal-open .homepage-header,
.MuiPopover-root .homepage-header {
  position: absolute;
  background: transparent;
  -webkit-font-smoothing: antialiased;
}
#root .transparent-bg,
.modal-open .transparent-bg,
.MuiPopover-root .transparent-bg {
  background-color: transparent !important;
}
#root .avatar-in-ring,
.modal-open .avatar-in-ring,
.MuiPopover-root .avatar-in-ring {
  border-width: 2px;
  border-radius: 50%;
  border-style: solid;
  padding: 2px;
  background-color: transparent;
}
#root .avatar-in-ring.light,
.modal-open .avatar-in-ring.light,
.MuiPopover-root .avatar-in-ring.light {
  border-color: #ffffff;
}
#root .avatar-in-ring.dark,
.modal-open .avatar-in-ring.dark,
.MuiPopover-root .avatar-in-ring.dark {
  border-color: #24272b;
}
#root .sectioned-pills,
.modal-open .sectioned-pills,
.MuiPopover-root .sectioned-pills {
  border-radius: 40px;
  border: solid 1px #e5e6e7;
  display: inline-flex;
  flex-direction: row;
  margin-top: 3px;
  padding: 5px 0;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  background-color: #ffffff;
  color: #24272b;
}
#root .sectioned-pills .pill-timer,
.modal-open .sectioned-pills .pill-timer,
.MuiPopover-root .sectioned-pills .pill-timer {
  min-width: 60px;
  text-align: center;
}
#root .sectioned-pills .sectioned-pills-section,
.modal-open .sectioned-pills .sectioned-pills-section,
.MuiPopover-root .sectioned-pills .sectioned-pills-section {
  padding: 0 10px;
  border-right: solid 1px #e5e6e7;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .sectioned-pills .sectioned-pills-section:last-child,
.modal-open .sectioned-pills .sectioned-pills-section:last-child,
.MuiPopover-root .sectioned-pills .sectioned-pills-section:last-child {
  border-right: none;
}
#root .sectioned-pills .sectioned-pills-section .badge,
.modal-open .sectioned-pills .sectioned-pills-section .badge,
.MuiPopover-root .sectioned-pills .sectioned-pills-section .badge {
  background: #24272b;
  border-radius: 30px;
  color: #ffffff;
  transform: translate(-5px, -10px);
  position: absolute;
}
#root .sectioned-pills:hover,
.modal-open .sectioned-pills:hover,
.MuiPopover-root .sectioned-pills:hover {
  background-color: #d3dce6;
  border: solid 1px #4a525a;
  cursor: pointer;
}
#root .sectioned-pills:hover .sectioned-pills-section,
.modal-open .sectioned-pills:hover .sectioned-pills-section,
.MuiPopover-root .sectioned-pills:hover .sectioned-pills-section {
  border-color: #4a525a;
}
#root .pill.light,
.modal-open .pill.light,
.MuiPopover-root .pill.light {
  background-color: #ffffff;
  border: solid 1px #b0b8bf;
}
#root .pill.round,
.modal-open .pill.round,
.MuiPopover-root .pill.round {
  border-radius: 20px;
  padding: 1px 5px 3.33333333px 10px;
}
#root .pill.round span,
.modal-open .pill.round span,
.MuiPopover-root .pill.round span {
  padding-right: 7.5px;
}
#root .pill.round i,
.modal-open .pill.round i,
.MuiPopover-root .pill.round i {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  opacity: 1;
  font-size: 10px;
}
#root .pill.round i:hover,
.modal-open .pill.round i:hover,
.MuiPopover-root .pill.round i:hover {
  opacity: 0.6;
  cursor: pointer;
}
#root .pill.round .btn,
.modal-open .pill.round .btn,
.MuiPopover-root .pill.round .btn {
  vertical-align: middle;
  margin-top: 3px;
}
#root .pill.pill-info,
.modal-open .pill.pill-info,
.MuiPopover-root .pill.pill-info {
  color: #ffffff;
  background-color: #11a9f0;
}
#root .pill.pill-danger,
.modal-open .pill.pill-danger,
.MuiPopover-root .pill.pill-danger {
  opacity: 0.75;
  color: #ffffff;
  background-color: #e71d36;
}
#root .pill.pill-success,
.modal-open .pill.pill-success,
.MuiPopover-root .pill.pill-success {
  opacity: 0.75;
  color: #ffffff;
  background-color: #20bf55;
}
@media print {
  #root .pill,
  .modal-open .pill,
  .MuiPopover-root .pill {
    padding: 0 !important;
  }
  #root .pill span,
  .modal-open .pill span,
  .MuiPopover-root .pill span {
    padding-right: 0;
  }
}
#root .price-pill,
.modal-open .price-pill,
.MuiPopover-root .price-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 100px;
  color: #ffffff;
}
#root .page-body,
.modal-open .page-body,
.MuiPopover-root .page-body {
  display: inline-block;
  width: 100%;
  max-width: 1024px;
  display: inline-flex;
  flex-direction: column;
}
#root .page-body.md,
.modal-open .page-body.md,
.MuiPopover-root .page-body.md {
  max-width: 768px;
}
#root .p,
.modal-open .p,
.MuiPopover-root .p {
  padding: 10px !important;
}
#root .p-lg,
.modal-open .p-lg,
.MuiPopover-root .p-lg {
  padding: 18px !important;
}
#root .p-xl,
.modal-open .p-xl,
.MuiPopover-root .p-xl {
  padding: 24px !important;
}
#root .p-0,
.modal-open .p-0,
.MuiPopover-root .p-0 {
  padding: 0 !important;
}
#root .f-left,
.modal-open .f-left,
.MuiPopover-root .f-left {
  float: left;
}
#root .f-right,
.modal-open .f-right,
.MuiPopover-root .f-right {
  float: right;
}
#root .f-none,
.modal-open .f-none,
.MuiPopover-root .f-none {
  float: none !important;
}
#root .flex-break,
.modal-open .flex-break,
.MuiPopover-root .flex-break {
  flex-basis: 100%;
  height: 0;
}
#root .flex-1,
.modal-open .flex-1,
.MuiPopover-root .flex-1 {
  flex: 1;
}
#root .d-b,
.modal-open .d-b,
.MuiPopover-root .d-b {
  display: block;
}
#root .d-ib,
.modal-open .d-ib,
.MuiPopover-root .d-ib {
  display: inline-block;
}
#root .pos-absolute,
.modal-open .pos-absolute,
.MuiPopover-root .pos-absolute {
  position: absolute;
}
#root .pos-relative,
.modal-open .pos-relative,
.MuiPopover-root .pos-relative {
  position: relative;
}
#root .b-top,
.modal-open .b-top,
.MuiPopover-root .b-top {
  border: none;
  border-top: solid 1px #e5e6e7 !important;
  border-width: 1px 0 0 0;
}
#root .b-top.b-dashed,
.modal-open .b-top.b-dashed,
.MuiPopover-root .b-top.b-dashed {
  border-style: dashed !important;
}
#root .b-right,
.modal-open .b-right,
.MuiPopover-root .b-right {
  border-right: solid 1px #e5e6e7 !important;
  border-width: 0 1px 0 0;
}
#root .b-bottom,
.modal-open .b-bottom,
.MuiPopover-root .b-bottom {
  border-bottom: solid 1px #e5e6e7 !important;
  border-width: 0 0 1px 0;
}
#root .b-left,
.modal-open .b-left,
.MuiPopover-root .b-left {
  border-left: solid 1px #e5e6e7 !important;
  border-width: 0 0 0 1px;
}
#root .b-0,
.modal-open .b-0,
.MuiPopover-root .b-0 {
  border-width: 0 !important;
}
#root .mw-600,
.modal-open .mw-600,
.MuiPopover-root .mw-600 {
  max-width: 600px;
  margin: 0 auto;
}
#root .push-down,
.modal-open .push-down,
.MuiPopover-root .push-down {
  margin-top: 10px;
  margin-bottom: -10px;
}
#root .new-client,
.modal-open .new-client,
.MuiPopover-root .new-client {
  max-width: 850px;
  width: 100%;
  display: inline-block;
  text-align: left;
}
#root .hidden,
.modal-open .hidden,
.MuiPopover-root .hidden {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: -100vw;
  left: -100vh;
}
#root .hidden-xs,
.modal-open .hidden-xs,
.MuiPopover-root .hidden-xs {
  display: inherit;
}
@media only screen and (max-width : 575px) {
  #root .hidden-xs,
  .modal-open .hidden-xs,
  .MuiPopover-root .hidden-xs {
    display: none !important;
  }
}
#root .visible-xs,
.modal-open .visible-xs,
.MuiPopover-root .visible-xs {
  display: none !important;
}
@media only screen and (max-width : 575px) {
  #root .visible-xs,
  .modal-open .visible-xs,
  .MuiPopover-root .visible-xs {
    display: inherit !important;
  }
}
#root .m-top,
.modal-open .m-top,
.MuiPopover-root .m-top {
  margin-top: 10px !important;
}
#root .m-right,
.modal-open .m-right,
.MuiPopover-root .m-right {
  margin-right: 10px !important;
}
#root .m-bottom,
.modal-open .m-bottom,
.MuiPopover-root .m-bottom {
  margin-bottom: 10px !important;
}
#root .m-left,
.modal-open .m-left,
.MuiPopover-root .m-left {
  margin-left: 10px !important;
}
#root .m-top-sm,
.modal-open .m-top-sm,
.MuiPopover-root .m-top-sm {
  margin-top: 5px !important;
}
#root .m-right-sm,
.modal-open .m-right-sm,
.MuiPopover-root .m-right-sm {
  margin-right: 5px !important;
}
#root .m-bottom-sm,
.modal-open .m-bottom-sm,
.MuiPopover-root .m-bottom-sm {
  margin-bottom: 5px !important;
}
#root .m-left-sm,
.modal-open .m-left-sm,
.MuiPopover-root .m-left-sm {
  margin-left: 5px !important;
}
#root .m-top-medium,
.modal-open .m-top-medium,
.MuiPopover-root .m-top-medium {
  margin-top: 20px !important;
}
#root .m-right-medium,
.modal-open .m-right-medium,
.MuiPopover-root .m-right-medium {
  margin-right: 20px !important;
}
#root .m-bottom-medium,
.modal-open .m-bottom-medium,
.MuiPopover-root .m-bottom-medium {
  margin-bottom: 20px !important;
}
#root .m-left-medium,
.modal-open .m-left-medium,
.MuiPopover-root .m-left-medium {
  margin-left: 20px !important;
}
#root .m-top-large,
.modal-open .m-top-large,
.MuiPopover-root .m-top-large {
  margin-top: 40px !important;
}
#root .m-right-large,
.modal-open .m-right-large,
.MuiPopover-root .m-right-large {
  margin-right: 40px !important;
}
#root .m-bottom-large,
.modal-open .m-bottom-large,
.MuiPopover-root .m-bottom-large {
  margin-bottom: 40px !important;
}
#root .m-left-large,
.modal-open .m-left-large,
.MuiPopover-root .m-left-large {
  margin-left: 40px !important;
}
#root .m-top-x-large,
.modal-open .m-top-x-large,
.MuiPopover-root .m-top-x-large {
  margin-top: 60px !important;
}
#root .m-right-x-large,
.modal-open .m-right-x-large,
.MuiPopover-root .m-right-x-large {
  margin-right: 60px !important;
}
#root .m-bottom-x-large,
.modal-open .m-bottom-x-large,
.MuiPopover-root .m-bottom-x-large {
  margin-bottom: 60px !important;
}
#root .m-left-x-large,
.modal-open .m-left-x-large,
.MuiPopover-root .m-left-x-large {
  margin-left: 60px !important;
}
#root .m-0,
.modal-open .m-0,
.MuiPopover-root .m-0 {
  margin: 0 !important;
}
#root .m-top-0,
.modal-open .m-top-0,
.MuiPopover-root .m-top-0 {
  margin-top: 0 !important;
}
#root .m-right-0,
.modal-open .m-right-0,
.MuiPopover-root .m-right-0 {
  margin-right: 0 !important;
}
#root .m-bottom-0,
.modal-open .m-bottom-0,
.MuiPopover-root .m-bottom-0 {
  margin-bottom: 0 !important;
}
#root .m-left-0,
.modal-open .m-left-0,
.MuiPopover-root .m-left-0 {
  margin-left: 0 !important;
}
@media only screen and (max-width : 768px) {
  #root .m-top-mobile,
  .modal-open .m-top-mobile,
  .MuiPopover-root .m-top-mobile {
    margin-top: 10px;
  }
}
@media only screen and (max-width : 768px) {
  #root .m-right-mobile,
  .modal-open .m-right-mobile,
  .MuiPopover-root .m-right-mobile {
    margin-right: 10px;
  }
}
@media only screen and (max-width : 768px) {
  #root .m-bottom-mobile,
  .modal-open .m-bottom-mobile,
  .MuiPopover-root .m-bottom-mobile {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width : 768px) {
  #root .m-left-mobile,
  .modal-open .m-left-mobile,
  .MuiPopover-root .m-left-mobile {
    margin-left: 10px;
  }
}
#root .m-bottom-pull,
.modal-open .m-bottom-pull,
.MuiPopover-root .m-bottom-pull {
  margin-bottom: -5px !important;
}
@media only screen and (max-width : 768px) {
  #root .mt-0-sm,
  .modal-open .mt-0-sm,
  .MuiPopover-root .mt-0-sm {
    margin-top: 0 !important;
  }
}
#root .m-top-well,
.modal-open .m-top-well,
.MuiPopover-root .m-top-well {
  margin-top: 24px !important;
}
@media only screen and (max-width : 768px) {
  #root .m-top-well,
  .modal-open .m-top-well,
  .MuiPopover-root .m-top-well {
    margin-top: 12px !important;
  }
}
#root .m-right-well,
.modal-open .m-right-well,
.MuiPopover-root .m-right-well {
  margin-right: 24px !important;
}
@media only screen and (max-width : 768px) {
  #root .m-right-well,
  .modal-open .m-right-well,
  .MuiPopover-root .m-right-well {
    margin-right: 12px !important;
  }
}
#root .m-bottom-well,
.modal-open .m-bottom-well,
.MuiPopover-root .m-bottom-well {
  margin-bottom: 24px !important;
}
@media only screen and (max-width : 768px) {
  #root .m-bottom-well,
  .modal-open .m-bottom-well,
  .MuiPopover-root .m-bottom-well {
    margin-bottom: 12px !important;
  }
}
#root .m-left-well,
.modal-open .m-left-well,
.MuiPopover-root .m-left-well {
  margin-left: 24px !important;
}
@media only screen and (max-width : 768px) {
  #root .m-left-well,
  .modal-open .m-left-well,
  .MuiPopover-root .m-left-well {
    margin-left: 12px !important;
  }
}
#root .padded,
.modal-open .padded,
.MuiPopover-root .padded {
  padding: 10px;
}
#root .padded-medium,
.modal-open .padded-medium,
.MuiPopover-root .padded-medium {
  padding: 20px;
}
#root .padded-small,
.modal-open .padded-small,
.MuiPopover-root .padded-small {
  padding: 5px !important;
}
#root .p-top,
.modal-open .p-top,
.MuiPopover-root .p-top {
  padding-top: 10px !important;
}
#root .p-right,
.modal-open .p-right,
.MuiPopover-root .p-right {
  padding-right: 10px !important;
}
#root .p-bottom,
.modal-open .p-bottom,
.MuiPopover-root .p-bottom {
  padding-bottom: 10px !important;
}
#root .p-left,
.modal-open .p-left,
.MuiPopover-root .p-left {
  padding-left: 10px !important;
}
#root .p-top-sm,
.modal-open .p-top-sm,
.MuiPopover-root .p-top-sm {
  padding-top: 5px !important;
}
#root .p-right-sm,
.modal-open .p-right-sm,
.MuiPopover-root .p-right-sm {
  padding-right: 5px !important;
}
#root .p-bottom-sm,
.modal-open .p-bottom-sm,
.MuiPopover-root .p-bottom-sm {
  padding-bottom: 5px !important;
}
#root .p-left-sm,
.modal-open .p-left-sm,
.MuiPopover-root .p-left-sm {
  padding-left: 5px !important;
}
#root .p-right-well,
.modal-open .p-right-well,
.MuiPopover-root .p-right-well {
  padding-right: 24px;
}
#root .p-top-icon-3 > i,
.modal-open .p-top-icon-3 > i,
.MuiPopover-root .p-top-icon-3 > i {
  padding-top: 3.00003px !important;
}
@media only screen and (max-width : 768px) {
  #root .border-radius-sm-0,
  .modal-open .border-radius-sm-0,
  .MuiPopover-root .border-radius-sm-0 {
    border-radius: 0 !important;
  }
}
@media only screen and (max-width : 1024px) {
  #root .border-radius-tablet-0,
  .modal-open .border-radius-tablet-0,
  .MuiPopover-root .border-radius-tablet-0 {
    border-radius: 0 !important;
  }
}
#root .faded,
.modal-open .faded,
.MuiPopover-root .faded {
  opacity: 0.5;
}
#root .w-100,
.modal-open .w-100,
.MuiPopover-root .w-100 {
  width: 100%;
}
#root .ta-left,
.modal-open .ta-left,
.MuiPopover-root .ta-left {
  text-align: left !important;
}
#root .ta-center,
.modal-open .ta-center,
.MuiPopover-root .ta-center {
  text-align: center !important;
}
#root .ta-right,
.modal-open .ta-right,
.MuiPopover-root .ta-right {
  text-align: right !important;
}
#root .ta-right-sm,
.modal-open .ta-right-sm,
.MuiPopover-root .ta-right-sm {
  text-align: right !important;
}
@media only screen and (max-width : 768px) {
  #root .ta-right-sm,
  .modal-open .ta-right-sm,
  .MuiPopover-root .ta-right-sm {
    text-align: auto !important;
  }
}
#root va-top,
.modal-open va-top,
.MuiPopover-root va-top {
  vertical-align: top;
}
#root .va-middle,
.modal-open .va-middle,
.MuiPopover-root .va-middle {
  vertical-align: middle;
}
#root .invisible,
.modal-open .invisible,
.MuiPopover-root .invisible {
  opacity: 0;
}
#root .example-enter,
.modal-open .example-enter,
.MuiPopover-root .example-enter {
  opacity: 0.01;
}
#root .container.wizard,
.modal-open .container.wizard,
.MuiPopover-root .container.wizard {
  max-width: 768px;
}
#root .bold,
.modal-open .bold,
.MuiPopover-root .bold {
  font-weight: 700 !important;
}
#root .header-hyperlink-black,
.modal-open .header-hyperlink-black,
.MuiPopover-root .header-hyperlink-black {
  text-decoration: underline;
  color: #24272b !important;
}
#root .header-hyperlink-black:hover,
.modal-open .header-hyperlink-black:hover,
.MuiPopover-root .header-hyperlink-black:hover {
  border: none !important;
  color: #24272b !important;
}
#root .blur-bg,
.modal-open .blur-bg,
.MuiPopover-root .blur-bg {
  backdrop-filter: blur(4px);
}
#root .MuiBackdrop-root,
.modal-open .MuiBackdrop-root,
.MuiPopover-root .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(4px);
}
#root .alt,
.modal-open .alt,
.MuiPopover-root .alt {
  color: #004bed;
  font-weight: 300;
  text-transform: uppercase;
}
#root .row,
.modal-open .row,
.MuiPopover-root .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-flow: row wrap;
  margin-right: -5px;
  margin-left: -5px;
}
#root .row [class*="col-"],
.modal-open .row [class*="col-"],
.MuiPopover-root .row [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
#root .row [class*="col-"].col-eq-height,
.modal-open .row [class*="col-"].col-eq-height,
.MuiPopover-root .row [class*="col-"].col-eq-height {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  flex-grow: 1;
}
#root .row [class*="col-"].col-eq-height > *,
.modal-open .row [class*="col-"].col-eq-height > *,
.MuiPopover-root .row [class*="col-"].col-eq-height > * {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}
#root .hide,
.modal-open .hide,
.MuiPopover-root .hide {
  display: none !important;
}
@media only screen and (max-width : 1024px) {
  #root .hide-on-mobile,
  .modal-open .hide-on-mobile,
  .MuiPopover-root .hide-on-mobile {
    display: none;
  }
}
#root .show-on-mobile,
.modal-open .show-on-mobile,
.MuiPopover-root .show-on-mobile {
  display: none;
}
@media only screen and (max-width : 1024px) {
  #root .show-on-mobile,
  .modal-open .show-on-mobile,
  .MuiPopover-root .show-on-mobile {
    display: initial;
  }
}
#root .show-sm,
.modal-open .show-sm,
.MuiPopover-root .show-sm {
  display: none;
}
@media only screen and (max-width : 768px) {
  #root .show-sm,
  .modal-open .show-sm,
  .MuiPopover-root .show-sm {
    display: initial;
  }
}
@media only screen and (max-width : 992px) {
  #root .hide-md,
  .modal-open .hide-md,
  .MuiPopover-root .hide-md {
    display: none !important;
  }
}
#root .align-items-start,
.modal-open .align-items-start,
.MuiPopover-root .align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
#root .align-items-end,
.modal-open .align-items-end,
.MuiPopover-root .align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
#root .align-items-center,
.modal-open .align-items-center,
.MuiPopover-root .align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
#root .align-items-baseline,
.modal-open .align-items-baseline,
.MuiPopover-root .align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
#root .align-items-stretch,
.modal-open .align-items-stretch,
.MuiPopover-root .align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
#root .align-content-start,
.modal-open .align-content-start,
.MuiPopover-root .align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
#root .align-content-end,
.modal-open .align-content-end,
.MuiPopover-root .align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
#root .align-content-center,
.modal-open .align-content-center,
.MuiPopover-root .align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
#root .align-content-between,
.modal-open .align-content-between,
.MuiPopover-root .align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
#root .align-content-around,
.modal-open .align-content-around,
.MuiPopover-root .align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
#root .align-content-stretch,
.modal-open .align-content-stretch,
.MuiPopover-root .align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
#root .align-self-auto,
.modal-open .align-self-auto,
.MuiPopover-root .align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
#root .align-self-start,
.modal-open .align-self-start,
.MuiPopover-root .align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
#root .align-self-end,
.modal-open .align-self-end,
.MuiPopover-root .align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
#root .align-self-center,
.modal-open .align-self-center,
.MuiPopover-root .align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
#root .align-self-baseline,
.modal-open .align-self-baseline,
.MuiPopover-root .align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
#root .align-self-stretch,
.modal-open .align-self-stretch,
.MuiPopover-root .align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
#root i.fa.giant,
.modal-open i.fa.giant,
.MuiPopover-root i.fa.giant,
#root i.fas.giant,
.modal-open i.fas.giant,
.MuiPopover-root i.fas.giant,
#root i.fal.giant,
.modal-open i.fal.giant,
.MuiPopover-root i.fal.giant,
#root i.fab.giant,
.modal-open i.fab.giant,
.MuiPopover-root i.fab.giant,
#root i.fad.giant,
.modal-open i.fad.giant,
.MuiPopover-root i.fad.giant {
  font-size: 70px;
}
#root .absolute-center,
.modal-open .absolute-center,
.MuiPopover-root .absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  width: 100%;
  max-width: 800px;
}
#root .padded,
.modal-open .padded,
.MuiPopover-root .padded {
  padding: 10px;
}
#root .well,
.modal-open .well,
.MuiPopover-root .well {
  background-color: #ffffff;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-top: 20px;
}
@media only screen and (max-width : 768px) {
  #root .well,
  .modal-open .well,
  .MuiPopover-root .well {
    margin-top: 0;
  }
}
#root .well .well-content,
.modal-open .well .well-content,
.MuiPopover-root .well .well-content {
  padding: 24px 24px 24px;
}
@media only screen and (max-width : 768px) {
  #root .well .well-content,
  .modal-open .well .well-content,
  .MuiPopover-root .well .well-content {
    padding: 12px;
  }
}
#root .well .well-header,
.modal-open .well .well-header,
.MuiPopover-root .well .well-header {
  background-color: #004bed;
  padding: 24px;
  width: 100%;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  font-size: 32px;
  line-height: 39px;
}
#root .well .well-header p,
.modal-open .well .well-header p,
.MuiPopover-root .well .well-header p {
  margin: 0;
}
#root .well .well-inset,
.modal-open .well .well-inset,
.MuiPopover-root .well .well-inset {
  background-color: #f2f3f5;
  margin: -24px;
  margin-top: 0;
  padding: 24px;
  border-radius: 0 0 3px 3px;
}
#root .well.well-lg,
.modal-open .well.well-lg,
.MuiPopover-root .well.well-lg {
  max-width: 1024px;
  width: 100%;
  display: inline-block;
  border-radius: 16px;
}
@media only screen and (max-width : 768px) {
  #root .well.well-lg,
  .modal-open .well.well-lg,
  .MuiPopover-root .well.well-lg {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}
#root .well.well-md,
.modal-open .well.well-md,
.MuiPopover-root .well.well-md {
  max-width: 768px;
  width: 100%;
  display: inline-block;
  border-radius: 16px;
}
@media only screen and (max-width : 768px) {
  #root .well.well-md,
  .modal-open .well.well-md,
  .MuiPopover-root .well.well-md {
    width: 100%;
  }
}
@media print {
  #root .well.well-md,
  .modal-open .well.well-md,
  .MuiPopover-root .well.well-md {
    max-width: 100%;
  }
}
#root .well.well-sm .well-content,
.modal-open .well.well-sm .well-content,
.MuiPopover-root .well.well-sm .well-content {
  padding: 12px;
}
#root .well.well-sm .well-content .well-inset,
.modal-open .well.well-sm .well-content .well-inset,
.MuiPopover-root .well.well-sm .well-content .well-inset {
  margin: -12px;
  margin-top: 0;
  padding: 12px;
}
#root .well.well-xs,
.modal-open .well.well-xs,
.MuiPopover-root .well.well-xs {
  max-width: 300px;
  width: 100%;
  display: inline-block;
  border-radius: 16px;
}
@media only screen and (max-width : 768px) {
  #root .well.well-xs,
  .modal-open .well.well-xs,
  .MuiPopover-root .well.well-xs {
    width: 100%;
  }
}
@media print {
  #root .well.well-xs,
  .modal-open .well.well-xs,
  .MuiPopover-root .well.well-xs {
    max-width: 100%;
  }
}
#root .well.gray,
.modal-open .well.gray,
.MuiPopover-root .well.gray,
#root .well .well-gray,
.modal-open .well .well-gray,
.MuiPopover-root .well .well-gray {
  margin-top: 0;
  background-color: #f2f3f5;
}
#root .well.gray label,
.modal-open .well.gray label,
.MuiPopover-root .well.gray label,
#root .well .well-gray label,
.modal-open .well .well-gray label,
.MuiPopover-root .well .well-gray label,
#root .well.gray h6,
.modal-open .well.gray h6,
.MuiPopover-root .well.gray h6,
#root .well .well-gray h6,
.modal-open .well .well-gray h6,
.MuiPopover-root .well .well-gray h6,
#root .well.gray .description,
.modal-open .well.gray .description,
.MuiPopover-root .well.gray .description,
#root .well .well-gray .description,
.modal-open .well .well-gray .description,
.MuiPopover-root .well .well-gray .description,
#root .well.gray .black,
.modal-open .well.gray .black,
.MuiPopover-root .well.gray .black,
#root .well .well-gray .black,
.modal-open .well .well-gray .black,
.MuiPopover-root .well .well-gray .black {
  color: #24272b;
}
#root .well.d-flex,
.modal-open .well.d-flex,
.MuiPopover-root .well.d-flex {
  align-items: stretch;
}
#root .well.d-flex > .well-content,
.modal-open .well.d-flex > .well-content,
.MuiPopover-root .well.d-flex > .well-content {
  display: flex;
  width: 100%;
}
#root .well.well-outline,
.modal-open .well.well-outline,
.MuiPopover-root .well.well-outline {
  border: solid 1.5pt #e5e6e7;
  box-shadow: none;
}
#root .well.well-flat,
.modal-open .well.well-flat,
.MuiPopover-root .well.well-flat {
  box-shadow: none;
}
#root .well.well-flat.well-light,
.modal-open .well.well-flat.well-light,
.MuiPopover-root .well.well-flat.well-light {
  background-color: #e5e6e7;
}
#root .well.well-flat.well-outline,
.modal-open .well.well-flat.well-outline,
.MuiPopover-root .well.well-flat.well-outline {
  border: solid 1px #e5e6e7;
}
#root .well.well-sidebar,
.modal-open .well.well-sidebar,
.MuiPopover-root .well.well-sidebar {
  margin: 0.5em 0 0.5em 0;
  border-radius: 8px;
}
#root .well.well-sidebar .well-content,
.modal-open .well.well-sidebar .well-content,
.MuiPopover-root .well.well-sidebar .well-content {
  padding: 12px;
}
#root .well.well-sidebar .well-content .well-inset,
.modal-open .well.well-sidebar .well-content .well-inset,
.MuiPopover-root .well.well-sidebar .well-content .well-inset {
  margin: -12px;
  margin-top: 0;
  padding: 12px;
  border-radius: 0 0 8px 8px;
}
#root .well-hat,
.modal-open .well-hat,
.MuiPopover-root .well-hat {
  display: flex;
  background-color: #f2f3f5;
  margin-top: calc(24px * -1);
  margin-left: calc(24px * -1);
  margin-right: calc(24px * -1);
  margin-bottom: 24px;
  padding: 12px 22px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width : 768px) {
  #root .well-hat,
  .modal-open .well-hat,
  .MuiPopover-root .well-hat {
    margin-top: calc(12px * -1);
    margin-left: calc(12px * -1);
    margin-right: calc(12px * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 24px 12px;
    flex-direction: column;
  }
}
#root .well-hat .well-hat-title,
.modal-open .well-hat .well-hat-title,
.MuiPopover-root .well-hat .well-hat-title {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #24272b;
}
@media only screen and (max-width : 768px) {
  #root .well-hat .well-hat-title,
  .modal-open .well-hat .well-hat-title,
  .MuiPopover-root .well-hat .well-hat-title {
    margin-bottom: 8px;
    text-align: center;
  }
}
#root .well-hat .well-hat-link,
.modal-open .well-hat .well-hat-link,
.MuiPopover-root .well-hat .well-hat-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
#root .well-hat .well-hat-link > a,
.modal-open .well-hat .well-hat-link > a,
.MuiPopover-root .well-hat .well-hat-link > a,
#root .well-hat .well-hat-link > button,
.modal-open .well-hat .well-hat-link > button,
.MuiPopover-root .well-hat .well-hat-link > button {
  color: #24272b;
  text-decoration: underline;
  font-weight: bold;
  font-size: 1rem;
}
#root .well-hat .well-hat-link > a:hover,
.modal-open .well-hat .well-hat-link > a:hover,
.MuiPopover-root .well-hat .well-hat-link > a:hover,
#root .well-hat .well-hat-link > button:hover,
.modal-open .well-hat .well-hat-link > button:hover,
.MuiPopover-root .well-hat .well-hat-link > button:hover {
  color: #24272b;
}
#root .section-divider,
.modal-open .section-divider,
.MuiPopover-root .section-divider {
  color: #004bed;
  font-size: 24px;
  display: block;
  border-bottom: solid 3px #004bed;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
#root .col-full-height,
.modal-open .col-full-height,
.MuiPopover-root .col-full-height {
  display: flex;
}
#root .col-full-height .col-gray,
.modal-open .col-full-height .col-gray,
.MuiPopover-root .col-full-height .col-gray {
  background-color: #f2f3f5;
  flex: 1;
  padding: 8%;
  position: relative;
  border-radius: 14px;
}
@media only screen and (max-width : 575px) {
  #root .xs-hide,
  .modal-open .xs-hide,
  .MuiPopover-root .xs-hide {
    display: none;
  }
}
#root .text-copy,
.modal-open .text-copy,
.MuiPopover-root .text-copy {
  background-color: #f2f3f5;
  margin: 10px 0;
  position: relative;
  border-radius: 3px;
}
#root .text-copy .text-copy-text,
.modal-open .text-copy .text-copy-text,
.MuiPopover-root .text-copy .text-copy-text {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  padding-right: 50px;
}
#root .text-copy .btn,
.modal-open .text-copy .btn,
.MuiPopover-root .text-copy .btn {
  position: absolute;
  top: -2px;
  right: -2px;
}
#root .text-copy.code-copy,
.modal-open .text-copy.code-copy,
.MuiPopover-root .text-copy.code-copy {
  background-color: #4a525a;
}
#root .text-copy.code-copy .text-copy-text,
.modal-open .text-copy.code-copy .text-copy-text,
.MuiPopover-root .text-copy.code-copy .text-copy-text {
  padding: 0;
  padding-right: 0;
}
#root .text-copy.code-copy code,
.modal-open .text-copy.code-copy code,
.MuiPopover-root .text-copy.code-copy code {
  padding-right: 60px;
}
#root .text-copy.code-copy .btn,
.modal-open .text-copy.code-copy .btn,
.MuiPopover-root .text-copy.code-copy .btn {
  background-color: #4a525a;
  color: #ffffff;
  top: 0px;
  right: 0px;
  padding: 12px 10px;
}
#root .well-separator,
.modal-open .well-separator,
.MuiPopover-root .well-separator {
  border-top: 4px solid #f7f7f7;
  margin-left: calc(24px * -1);
  margin-right: calc(24px * -1);
}
@media only screen and (max-width : 768px) {
  #root .well-separator,
  .modal-open .well-separator,
  .MuiPopover-root .well-separator {
    margin-left: calc(12px * -1);
    margin-right: calc(12px * -1);
  }
}
#root .thirds .third,
.modal-open .thirds .third,
.MuiPopover-root .thirds .third {
  display: inline-block;
  width: calc(33.3333% - 4px);
  padding-right: 6px;
  box-sizing: initial;
}
#root .thirds .third:last-child,
.modal-open .thirds .third:last-child,
.MuiPopover-root .thirds .third:last-child {
  padding-right: 0;
}
#root .register-well > .well-content,
.modal-open .register-well > .well-content,
.MuiPopover-root .register-well > .well-content {
  padding-bottom: 10px;
}
#root .fade-section-container.sticky-footer.show,
.modal-open .fade-section-container.sticky-footer.show,
.MuiPopover-root .fade-section-container.sticky-footer.show {
  padding: 10px 0 0;
  position: sticky;
  bottom: 10px;
  z-index: 99;
}
#root .fade-section-container.sticky-footer .sticky-footer::before,
.modal-open .fade-section-container.sticky-footer .sticky-footer::before,
.MuiPopover-root .fade-section-container.sticky-footer .sticky-footer::before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  border-top: solid 1px #e5e6e7;
  height: calc(100% +  10px );
  width: calc(100% + 24px*2);
  max-width: 1024px;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -10px;
  pointer-events: none;
  opacity: 1;
  border-radius: 0 0 16px 16px;
}
@media only screen and (max-width : 768px) {
  #root .fade-section-container.sticky-footer .sticky-footer::before,
  .modal-open .fade-section-container.sticky-footer .sticky-footer::before,
  .MuiPopover-root .fade-section-container.sticky-footer .sticky-footer::before {
    width: calc(100% + 12px*2);
  }
}
#root .fade-section-container.sticky-footer .sticky-footer.fixed-sticky,
.modal-open .fade-section-container.sticky-footer .sticky-footer.fixed-sticky,
.MuiPopover-root .fade-section-container.sticky-footer .sticky-footer.fixed-sticky {
  z-index: 20;
  bottom: 10px;
}
#root .fade-section-container.sticky-footer .sticky-footer.fixed-sticky::before,
.modal-open .fade-section-container.sticky-footer .sticky-footer.fixed-sticky::before,
.MuiPopover-root .fade-section-container.sticky-footer .sticky-footer.fixed-sticky::before {
  position: fixed;
  width: calc(100% + 24px);
  bottom: 0;
  opacity: 1;
  pointer-events: all;
}
@media only screen and (max-width: 1064px) {
  #root .page-body.widget .sticky-footer .sticky-inner-container::before,
  .modal-open .page-body.widget .sticky-footer .sticky-inner-container::before,
  .MuiPopover-root .page-body.widget .sticky-footer .sticky-inner-container::before {
    max-width: calc(100vw - 10px*4);
  }
}
#root .sticky-header,
.modal-open .sticky-header,
.MuiPopover-root .sticky-header {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 6px !important;
}
@media only screen and (max-width : 768px) {
  #root .sticky-header,
  .modal-open .sticky-header,
  .MuiPopover-root .sticky-header {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -12px;
    padding: -6px !important;
  }
}
#root .dangerous-sticky div,
.modal-open .dangerous-sticky div,
.MuiPopover-root .dangerous-sticky div {
  text-align: left !important;
}
#root .dangerous-sticky hr,
.modal-open .dangerous-sticky hr,
.MuiPopover-root .dangerous-sticky hr {
  display: none;
}
#root .dangerous-sticky ul,
.modal-open .dangerous-sticky ul,
.MuiPopover-root .dangerous-sticky ul,
#root .dangerous-sticky ol,
.modal-open .dangerous-sticky ol,
.MuiPopover-root .dangerous-sticky ol {
  padding: 0;
  list-style-type: none;
}
#root .dangerous-sticky.wysiwyg-plain-text *,
.modal-open .dangerous-sticky.wysiwyg-plain-text *,
.MuiPopover-root .dangerous-sticky.wysiwyg-plain-text * {
  font-size: 16px !important;
  list-style: none;
  color: #ffffff;
}
#root .event-info-column,
.modal-open .event-info-column,
.MuiPopover-root .event-info-column {
  position: relative;
}
#root .details-footer-container,
.modal-open .details-footer-container,
.MuiPopover-root .details-footer-container {
  height: 75px;
}
#root .details-footer-nav,
.modal-open .details-footer-nav,
.MuiPopover-root .details-footer-nav {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
}
#root .details-footer-nav.no-event-description,
.modal-open .details-footer-nav.no-event-description,
.MuiPopover-root .details-footer-nav.no-event-description {
  position: static;
  display: block;
}
#root .details-footer-nav.no-event-description .details-inner-well,
.modal-open .details-footer-nav.no-event-description .details-inner-well,
.MuiPopover-root .details-footer-nav.no-event-description .details-inner-well {
  flex-direction: column;
}
#root .details-footer-nav.no-event-description .details-inner-well a:not(:last-child),
.modal-open .details-footer-nav.no-event-description .details-inner-well a:not(:last-child),
.MuiPopover-root .details-footer-nav.no-event-description .details-inner-well a:not(:last-child) {
  margin-bottom: 1em;
}
#root .details-footer-nav .details-footer-header,
.modal-open .details-footer-nav .details-footer-header,
.MuiPopover-root .details-footer-nav .details-footer-header {
  width: 100%;
}
#root .details-footer-nav .well-details-footer,
.modal-open .details-footer-nav .well-details-footer,
.MuiPopover-root .details-footer-nav .well-details-footer {
  margin: 0.5em 0 0.5em 0;
  border-radius: 8px;
  margin-right: 1em;
}
#root .details-footer-nav .well-details-footer .well-content,
.modal-open .details-footer-nav .well-details-footer .well-content,
.MuiPopover-root .details-footer-nav .well-details-footer .well-content {
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  display: flex;
}
#root .details-footer-nav .well-details-footer .well-content i,
.modal-open .details-footer-nav .well-details-footer .well-content i,
.MuiPopover-root .details-footer-nav .well-details-footer .well-content i {
  margin-right: 4px;
}
@media only screen and (max-width : 768px) {
  #root .details-footer-nav,
  .modal-open .details-footer-nav,
  .MuiPopover-root .details-footer-nav {
    flex-direction: column;
    position: initial;
  }
  #root .details-footer-nav.no-event-description,
  .modal-open .details-footer-nav.no-event-description,
  .MuiPopover-root .details-footer-nav.no-event-description {
    padding: 0;
  }
  #root .details-footer-nav .well-details-footer .well-content,
  .modal-open .details-footer-nav .well-details-footer .well-content,
  .MuiPopover-root .details-footer-nav .well-details-footer .well-content {
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;
  }
  #root .details-footer-nav .well-details-footer .well-content a i,
  .modal-open .details-footer-nav .well-details-footer .well-content a i,
  .MuiPopover-root .details-footer-nav .well-details-footer .well-content a i {
    width: 20px;
  }
}
#root .attendee-type-header-row,
.modal-open .attendee-type-header-row,
.MuiPopover-root .attendee-type-header-row {
  padding-left: 5px;
}
#root .attendee-type-header-row.has-grouping-text-headers,
.modal-open .attendee-type-header-row.has-grouping-text-headers,
.MuiPopover-root .attendee-type-header-row.has-grouping-text-headers {
  margin-bottom: 5px !important;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-header-row,
  .modal-open .attendee-type-header-row,
  .MuiPopover-root .attendee-type-header-row {
    display: none;
  }
}
#root .quantity-text,
.modal-open .quantity-text,
.MuiPopover-root .quantity-text {
  display: inline-block;
  max-width: 96px;
  width: 100%;
  text-align: center;
}
#root .attendee-type-card,
.modal-open .attendee-type-card,
.MuiPopover-root .attendee-type-card {
  margin: 5px 0;
  border-top: solid 1px #e5e6e7;
  padding-top: 5px;
  min-height: 40px;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card,
  .modal-open .attendee-type-card,
  .MuiPopover-root .attendee-type-card {
    padding: 12px !important;
    margin: 4px 0 !important;
  }
  #root .attendee-type-card:not(:first-child),
  .modal-open .attendee-type-card:not(:first-child),
  .MuiPopover-root .attendee-type-card:not(:first-child) {
    margin-top: 10px;
  }
}
#root .attendee-type-card .attendee-pricing,
.modal-open .attendee-type-card .attendee-pricing,
.MuiPopover-root .attendee-type-card .attendee-pricing,
#root .attendee-type-card .attendee-quantity,
.modal-open .attendee-type-card .attendee-quantity,
.MuiPopover-root .attendee-type-card .attendee-quantity {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-pricing .groups-identifier,
  .modal-open .attendee-type-card .attendee-pricing .groups-identifier,
  .MuiPopover-root .attendee-type-card .attendee-pricing .groups-identifier,
  #root .attendee-type-card .attendee-quantity .groups-identifier,
  .modal-open .attendee-type-card .attendee-quantity .groups-identifier,
  .MuiPopover-root .attendee-type-card .attendee-quantity .groups-identifier {
    margin: auto;
  }
}
#root .attendee-type-card .attendee-pricing .form-counter,
.modal-open .attendee-type-card .attendee-pricing .form-counter,
.MuiPopover-root .attendee-type-card .attendee-pricing .form-counter,
#root .attendee-type-card .attendee-quantity .form-counter,
.modal-open .attendee-type-card .attendee-quantity .form-counter,
.MuiPopover-root .attendee-type-card .attendee-quantity .form-counter {
  flex-wrap: nowrap;
}
#root .attendee-type-card .attendee-quantity,
.modal-open .attendee-type-card .attendee-quantity,
.MuiPopover-root .attendee-type-card .attendee-quantity {
  flex-direction: column;
}
#root .attendee-type-card .attendee-pricing,
.modal-open .attendee-type-card .attendee-pricing,
.MuiPopover-root .attendee-type-card .attendee-pricing {
  justify-content: center;
  height: 100%;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-pricing,
  .modal-open .attendee-type-card .attendee-pricing,
  .MuiPopover-root .attendee-type-card .attendee-pricing {
    font-weight: 400;
    font-size: 10px;
    padding: 0 !important;
  }
}
#root .attendee-type-card .attendee-pricing .price-container,
.modal-open .attendee-type-card .attendee-pricing .price-container,
.MuiPopover-root .attendee-type-card .attendee-pricing .price-container {
  display: flex;
  width: 100%;
  align-items: center;
}
#root .attendee-type-card .attendee-pricing .price-container .base-price,
.modal-open .attendee-type-card .attendee-pricing .price-container .base-price,
.MuiPopover-root .attendee-type-card .attendee-pricing .price-container .base-price {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-pricing .price-container .base-price,
  .modal-open .attendee-type-card .attendee-pricing .price-container .base-price,
  .MuiPopover-root .attendee-type-card .attendee-pricing .price-container .base-price {
    font-size: 16px;
  }
}
#root .attendee-type-card .attendee-pricing .price-container .fee-dollar,
.modal-open .attendee-type-card .attendee-pricing .price-container .fee-dollar,
.MuiPopover-root .attendee-type-card .attendee-pricing .price-container .fee-dollar {
  display: flex;
  font-size: 12px;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-pricing .price-container,
  .modal-open .attendee-type-card .attendee-pricing .price-container,
  .MuiPopover-root .attendee-type-card .attendee-pricing .price-container {
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 !important;
  }
}
#root .attendee-type-card .attendee-quantity-inner-container,
.modal-open .attendee-type-card .attendee-quantity-inner-container,
.MuiPopover-root .attendee-type-card .attendee-quantity-inner-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: -5px;
  align-content: flex-end;
}
#root .attendee-type-card .attendee-quantity-inner-container .groups-identifier,
.modal-open .attendee-type-card .attendee-quantity-inner-container .groups-identifier,
.MuiPopover-root .attendee-type-card .attendee-quantity-inner-container .groups-identifier {
  width: 96px;
}
#root .attendee-type-card .attendee-quantity-inner-container.align-end,
.modal-open .attendee-type-card .attendee-quantity-inner-container.align-end,
.MuiPopover-root .attendee-type-card .attendee-quantity-inner-container.align-end {
  align-items: end;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-quantity-inner-container,
  .modal-open .attendee-type-card .attendee-quantity-inner-container,
  .MuiPopover-root .attendee-type-card .attendee-quantity-inner-container {
    align-content: space-around;
  }
}
#root .attendee-type-card .attendee-quantity-inner-container .field,
.modal-open .attendee-type-card .attendee-quantity-inner-container .field,
.MuiPopover-root .attendee-type-card .attendee-quantity-inner-container .field {
  margin: 0;
}
#root .attendee-type-card .attendee-quantity-inner-container > .btn-block ~ .groups-identifier,
.modal-open .attendee-type-card .attendee-quantity-inner-container > .btn-block ~ .groups-identifier,
.MuiPopover-root .attendee-type-card .attendee-quantity-inner-container > .btn-block ~ .groups-identifier {
  width: 100%;
  text-align: center;
}
#root .attendee-type-card .attendee-quantity .virtual-kind-quantity,
.modal-open .attendee-type-card .attendee-quantity .virtual-kind-quantity,
.MuiPopover-root .attendee-type-card .attendee-quantity .virtual-kind-quantity {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px;
  width: 100px;
  text-align: center;
  display: inline-block;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-quantity .virtual-kind-quantity,
  .modal-open .attendee-type-card .attendee-quantity .virtual-kind-quantity,
  .MuiPopover-root .attendee-type-card .attendee-quantity .virtual-kind-quantity {
    width: 100%;
    font-weight: 400;
  }
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-quantity,
  .modal-open .attendee-type-card .attendee-quantity,
  .MuiPopover-root .attendee-type-card .attendee-quantity {
    justify-content: center;
  }
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-type,
  .modal-open .attendee-type-card .attendee-type,
  .MuiPopover-root .attendee-type-card .attendee-type {
    align-self: flex-start;
    padding: 0;
  }
}
#root .attendee-type-card .attendee-type .attendee-name,
.modal-open .attendee-type-card .attendee-type .attendee-name,
.MuiPopover-root .attendee-type-card .attendee-type .attendee-name {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
#root .attendee-type-card .attendee-type .attendee-name .badge,
.modal-open .attendee-type-card .attendee-type .attendee-name .badge,
.MuiPopover-root .attendee-type-card .attendee-type .attendee-name .badge {
  margin-left: 5px;
  height: fit-content;
  font-size: 60%;
}
#root .attendee-type-card .attendee-type .attendee-name .badge.your-group-badge,
.modal-open .attendee-type-card .attendee-type .attendee-name .badge.your-group-badge,
.MuiPopover-root .attendee-type-card .attendee-type .attendee-name .badge.your-group-badge {
  background: #11a9f0;
}
#root .attendee-type-card .attendee-type .attendee-name .badge.your-group-badge.badge-light,
.modal-open .attendee-type-card .attendee-type .attendee-name .badge.your-group-badge.badge-light,
.MuiPopover-root .attendee-type-card .attendee-type .attendee-name .badge.your-group-badge.badge-light {
  background: #f2f3f5;
  color: rgba(36, 39, 43, 0.5);
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-type .attendee-name,
  .modal-open .attendee-type-card .attendee-type .attendee-name,
  .MuiPopover-root .attendee-type-card .attendee-type .attendee-name {
    font-size: 16px;
    display: block;
  }
}
#root .attendee-type-card .attendee-description,
.modal-open .attendee-type-card .attendee-description,
.MuiPopover-root .attendee-type-card .attendee-description {
  width: 75%;
  font-size: 14px;
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card .attendee-description,
  .modal-open .attendee-type-card .attendee-description,
  .MuiPopover-root .attendee-type-card .attendee-description {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width : 768px) {
  #root .attendee-type-card,
  .modal-open .attendee-type-card,
  .MuiPopover-root .attendee-type-card {
    border-top: none;
    background-color: #f2f3f5;
    margin-bottom: 0.5em;
    padding: 12px;
    border-radius: 8px;
  }
}
#root .attendee-type-card.disabled-group,
.modal-open .attendee-type-card.disabled-group,
.MuiPopover-root .attendee-type-card.disabled-group {
  color: #b0b8bf;
}
#root .attendee-type-card.disabled-group .badge,
.modal-open .attendee-type-card.disabled-group .badge,
.MuiPopover-root .attendee-type-card.disabled-group .badge {
  background: #b0b8bf;
}
#root .attendee-type-card.disabled-group .progress,
.modal-open .attendee-type-card.disabled-group .progress,
.MuiPopover-root .attendee-type-card.disabled-group .progress {
  opacity: 0.5;
}
#root .attendee-cart-header,
.modal-open .attendee-cart-header,
.MuiPopover-root .attendee-cart-header {
  padding: 16px 16px 16px 0px;
}
@media only screen and (max-width : 768px) {
  #root .attendee-cart-header,
  .modal-open .attendee-cart-header,
  .MuiPopover-root .attendee-cart-header {
    padding: 16px 0;
  }
}
#root .attendee-cart-header .right-action-buttons,
.modal-open .attendee-cart-header .right-action-buttons,
.MuiPopover-root .attendee-cart-header .right-action-buttons {
  margin-left: auto;
}
#root .attendee-tab-icon,
.modal-open .attendee-tab-icon,
.MuiPopover-root .attendee-tab-icon {
  margin-right: 2px;
}
#root .cart-item-detail,
.modal-open .cart-item-detail,
.MuiPopover-root .cart-item-detail {
  width: 230px;
  padding: 10px;
  border: solid 2px #e5e6e7;
  border-radius: 3px;
}
#root .fade-section,
.modal-open .fade-section,
.MuiPopover-root .fade-section {
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
}
#root .fade-section.mask,
.modal-open .fade-section.mask,
.MuiPopover-root .fade-section.mask {
  overflow: auto;
}
#root .fade-section.show,
.modal-open .fade-section.show,
.MuiPopover-root .fade-section.show {
  opacity: 1;
}
#root .matrix-group.has-grouping-text-headers .attendee-type-card:first-child,
.modal-open .matrix-group.has-grouping-text-headers .attendee-type-card:first-child,
.MuiPopover-root .matrix-group.has-grouping-text-headers .attendee-type-card:first-child {
  border-top: none;
  margin: 0px;
  padding: 5px 0;
  padding-top: 3px;
}
#root .matrix-group.has-grouping-text-headers .attendee-type-card,
.modal-open .matrix-group.has-grouping-text-headers .attendee-type-card,
.MuiPopover-root .matrix-group.has-grouping-text-headers .attendee-type-card {
  border-top: solid 1px #e5e6e7;
  margin-top: 10px;
  padding-top: 9px;
}
@media only screen and (max-width : 768px) {
  #root .matrix-group.has-grouping-text-headers .attendee-type-card,
  .modal-open .matrix-group.has-grouping-text-headers .attendee-type-card,
  .MuiPopover-root .matrix-group.has-grouping-text-headers .attendee-type-card {
    margin: 10px 0 !important;
  }
}
#root .matrix-group .grouping-text-header,
.modal-open .matrix-group .grouping-text-header,
.MuiPopover-root .matrix-group .grouping-text-header {
  border-radius: 2px;
  background: #e3e6e9;
  text-transform: uppercase;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
}
#root .matrix-group .grouping-text-header.your-group,
.modal-open .matrix-group .grouping-text-header.your-group,
.MuiPopover-root .matrix-group .grouping-text-header.your-group {
  background: #11a9f0;
  color: white;
}
#root .attendee-type-group-container,
.modal-open .attendee-type-group-container,
.MuiPopover-root .attendee-type-group-container {
  margin: 0.5em 0;
}
#root .auth-result-page,
.modal-open .auth-result-page,
.MuiPopover-root .auth-result-page {
  background-color: #ffffff !important;
}
#root .auth-result-page .auth-result-dots,
.modal-open .auth-result-page .auth-result-dots,
.MuiPopover-root .auth-result-page .auth-result-dots {
  width: 0px;
  display: inline-block;
}
#root .payment-confirmation-iframe,
.modal-open .payment-confirmation-iframe,
.MuiPopover-root .payment-confirmation-iframe {
  border: none;
  background-color: #ffffff;
  width: 100%;
  height: 400px;
}
#root .details-address,
.modal-open .details-address,
.MuiPopover-root .details-address {
  margin-left: 27px;
  font-size: 12px;
}
#root .details-sidebar-item,
.modal-open .details-sidebar-item,
.MuiPopover-root .details-sidebar-item {
  display: flex;
  width: 100%;
}
#root .details-sidebar-item > i,
.modal-open .details-sidebar-item > i,
.MuiPopover-root .details-sidebar-item > i {
  flex: 0 0 1.25em;
  margin-right: 8px;
}
#root .details-sidebar-item:not(:first-child),
.modal-open .details-sidebar-item:not(:first-child),
.MuiPopover-root .details-sidebar-item:not(:first-child) {
  margin-top: 5px;
}
#root .ellipsis,
.modal-open .ellipsis,
.MuiPopover-root .ellipsis {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
}
#root .map-list-switch-text,
.modal-open .map-list-switch-text,
.MuiPopover-root .map-list-switch-text {
  color: #808080 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  width: calc(100% - 20px);
  font-size: 14px;
  line-height: 18px;
}
#root .gm-style-iw,
.modal-open .gm-style-iw,
.MuiPopover-root .gm-style-iw {
  background-color: #ffffff;
}
#root .gm-style-iw button,
.modal-open .gm-style-iw button,
.MuiPopover-root .gm-style-iw button {
  top: 4px !important;
  right: 4px !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 3px !important;
}
#root .gm-style-iw button:hover,
.modal-open .gm-style-iw button:hover,
.MuiPopover-root .gm-style-iw button:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25) !important;
}
#root .gm-style-iw .btn,
.modal-open .gm-style-iw .btn,
.MuiPopover-root .gm-style-iw .btn {
  border-radius: 0 0 3px 3px !important;
}
#root .gm-style-iw img,
.modal-open .gm-style-iw img,
.MuiPopover-root .gm-style-iw img {
  top: -3px !important;
  left: -3px !important;
  position: absolute !important;
}
#root .gm-style-iw-chr,
.modal-open .gm-style-iw-chr,
.MuiPopover-root .gm-style-iw-chr {
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
}
#root .gm-style-iw-chr button,
.modal-open .gm-style-iw-chr button,
.MuiPopover-root .gm-style-iw-chr button {
  z-index: 9999999;
}
#root .map-tooltip,
.modal-open .map-tooltip,
.MuiPopover-root .map-tooltip {
  background-color: #ffffff;
}
#root .gm-style .gm-style-iw-t::after,
.modal-open .gm-style .gm-style-iw-t::after,
.MuiPopover-root .gm-style .gm-style-iw-t::after {
  background: #004bed !important;
}
#root .gm-style-iw-tc::after,
.modal-open .gm-style-iw-tc::after,
.MuiPopover-root .gm-style-iw-tc::after {
  background-color: #ffffff;
}
#root .gm-style-iw-c,
.modal-open .gm-style-iw-c,
.MuiPopover-root .gm-style-iw-c {
  padding: 0 !important;
}
#root .gm-style-iw-d,
.modal-open .gm-style-iw-d,
.MuiPopover-root .gm-style-iw-d {
  overflow: auto !important;
}
#root .gm-ui-hover-effect,
.modal-open .gm-ui-hover-effect,
.MuiPopover-root .gm-ui-hover-effect {
  -webkit-transition: box-shadow ease-in-out 0.3s;
  -moz-transition: box-shadow ease-in-out 0.3s;
  -ms-transition: box-shadow ease-in-out 0.3s;
  -o-transition: box-shadow ease-in-out 0.3s;
  color: #24272b;
}
#root .gm-ui-hover-effect img,
.modal-open .gm-ui-hover-effect img,
.MuiPopover-root .gm-ui-hover-effect img {
  display: none;
}
#root .gm-ui-hover-effect::before,
.modal-open .gm-ui-hover-effect::before,
.MuiPopover-root .gm-ui-hover-effect::before {
  content: "\f00d";
  font-family: "Font Awesome 6 Pro";
}
#root .gm-ui-hover-effect:hover,
.modal-open .gm-ui-hover-effect:hover,
.MuiPopover-root .gm-ui-hover-effect:hover {
  box-shadow: 0 3px 8px 0 rgb(0 0 0%) !important;
}
#root .gm-style,
.modal-open .gm-style,
.MuiPopover-root .gm-style {
  all: unset !important;
}
#root .gm-ui-hover-effect > span,
.modal-open .gm-ui-hover-effect > span,
.MuiPopover-root .gm-ui-hover-effect > span {
  display: none !important;
}
#root .zoom-control,
.modal-open .zoom-control,
.MuiPopover-root .zoom-control {
  position: absolute;
  bottom: 20px;
  right: 0;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}
#root .zoom-button,
.modal-open .zoom-button,
.MuiPopover-root .zoom-button {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 2px;
}
#root .zoom-button:hover,
.modal-open .zoom-button:hover,
.MuiPopover-root .zoom-button:hover {
  background-color: #e6e6e6;
}
#root .cart-section,
.modal-open .cart-section,
.MuiPopover-root .cart-section {
  z-index: 1;
  background-color: #ffffff;
}
#root .cart-section > div,
.modal-open .cart-section > div,
.MuiPopover-root .cart-section > div {
  min-height: 100%;
}
#root .cart-section > div > .fade-in,
.modal-open .cart-section > div > .fade-in,
.MuiPopover-root .cart-section > div > .fade-in {
  min-height: 100%;
}
#root .cart-section > div > .fade-in .cart,
.modal-open .cart-section > div > .fade-in .cart,
.MuiPopover-root .cart-section > div > .fade-in .cart {
  min-height: 100%;
}
#root .cart-section::before,
.modal-open .cart-section::before,
.MuiPopover-root .cart-section::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 2px;
  height: 100%;
  background: #e5e6e7;
  left: 300px;
  transition: left ease-in-out 0.5s, opacity ease-in-out 0.3s;
  opacity: 0;
}
#root .cart-section.cartShown::before,
.modal-open .cart-section.cartShown::before,
.MuiPopover-root .cart-section.cartShown::before {
  opacity: 1;
}
#root .cart-section.cart-results::before,
.modal-open .cart-section.cart-results::before,
.MuiPopover-root .cart-section.cart-results::before {
  opacity: 0;
}
#root .cart-section.shifted::before,
.modal-open .cart-section.shifted::before,
.MuiPopover-root .cart-section.shifted::before {
  left: 598px;
}
@media only screen and (max-width : 992px) {
  #root .cart-section.shifted::before,
  .modal-open .cart-section.shifted::before,
  .MuiPopover-root .cart-section.shifted::before {
    left: 498px;
  }
}
@media only screen and (max-width : 768px) {
  #root .cart-section::before,
  .modal-open .cart-section::before,
  .MuiPopover-root .cart-section::before {
    display: none;
  }
}
#root .cart-shadow::before,
.modal-open .cart-shadow::before,
.MuiPopover-root .cart-shadow::before {
  content: "";
  width: calc(100% + 2 * 24px);
  height: 100%;
  padding: 0 24px;
  margin: 0 -24px 0 -24px;
  display: inline-block;
  box-shadow: 0 -18px 16px -12px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  position: absolute;
  z-index: 8;
  pointer-events: none;
  bottom: 0;
}
@media only screen and (max-width : 768px) {
  #root .cart-shadow::before,
  .modal-open .cart-shadow::before,
  .MuiPopover-root .cart-shadow::before {
    width: calc(100% + 2 * 12px);
    padding: 0 12px;
    margin: 0 -12px 0 -12px;
    border-radius: 0;
  }
}
#root .cart-shadow.showCart::before,
.modal-open .cart-shadow.showCart::before,
.MuiPopover-root .cart-shadow.showCart::before {
  background-color: #ffffff;
  height: calc(100% + 24px);
  padding: 24px;
}
@media only screen and (max-width : 768px) {
  #root .cart-shadow.showCart::before,
  .modal-open .cart-shadow.showCart::before,
  .MuiPopover-root .cart-shadow.showCart::before {
    height: calc(100% + 12px);
    padding: 12px;
  }
}
#root .cart-shadow.showCart.cartShown::before,
.modal-open .cart-shadow.showCart.cartShown::before,
.MuiPopover-root .cart-shadow.showCart.cartShown::before {
  background-color: transparent;
}
#root .cart-shadow.cartIsHiding::before,
.modal-open .cart-shadow.cartIsHiding::before,
.MuiPopover-root .cart-shadow.cartIsHiding::before {
  background-color: #ffffff;
}
#root .order-results,
.modal-open .order-results,
.MuiPopover-root .order-results {
  padding: 12px 10%;
}
#root .order-results .order-results-number,
.modal-open .order-results .order-results-number,
.MuiPopover-root .order-results .order-results-number {
  text-align: right;
  text-align: left;
  margin-bottom: 10px;
}
#root .btn.sticky-footer-button,
.modal-open .btn.sticky-footer-button,
.MuiPopover-root .btn.sticky-footer-button {
  padding: 6px 20px !important;
}
#root .btn.sticky-footer-button.btn-dark,
.modal-open .btn.sticky-footer-button.btn-dark,
.MuiPopover-root .btn.sticky-footer-button.btn-dark {
  padding: 5px 20px !important;
}
#root .sticky-footer-row,
.modal-open .sticky-footer-row,
.MuiPopover-root .sticky-footer-row {
  min-height: 33px;
}
#root .cart-mobile-summary,
.modal-open .cart-mobile-summary,
.MuiPopover-root .cart-mobile-summary {
  padding-bottom: 300px;
}
#root .clear-cart-cancel,
.modal-open .clear-cart-cancel,
.MuiPopover-root .clear-cart-cancel {
  margin: 0 0 -20px 0;
  padding: 0;
}
#root #dropdown-copy-button,
.modal-open #dropdown-copy-button,
.MuiPopover-root #dropdown-copy-button {
  padding: 4px 20px !important;
}
#root .developer-row,
.modal-open .developer-row,
.MuiPopover-root .developer-row {
  padding: 10px !important;
}
#root .developer-row .developer-row-icon-buttons,
.modal-open .developer-row .developer-row-icon-buttons,
.MuiPopover-root .developer-row .developer-row-icon-buttons {
  display: flex;
}
#root .developer-row .developer-row-icon-buttons > .btn,
.modal-open .developer-row .developer-row-icon-buttons > .btn,
.MuiPopover-root .developer-row .developer-row-icon-buttons > .btn {
  align-items: unset;
}
#root .developer-row .developer-row-icon-buttons > a > .btn,
.modal-open .developer-row .developer-row-icon-buttons > a > .btn,
.MuiPopover-root .developer-row .developer-row-icon-buttons > a > .btn {
  align-items: unset;
}
#root .developer-event-picker .react-select__value-container,
.modal-open .developer-event-picker .react-select__value-container,
.MuiPopover-root .developer-event-picker .react-select__value-container {
  line-height: 12px;
}
#root .brushfire-button-choices,
.modal-open .brushfire-button-choices,
.MuiPopover-root .brushfire-button-choices {
  background-color: #f2f3f5;
  padding: 2.5px;
}
#root .brushfire-button-choices .brushfire-button-choice,
.modal-open .brushfire-button-choices .brushfire-button-choice,
.MuiPopover-root .brushfire-button-choices .brushfire-button-choice {
  display: inline-block;
  width: 33.3333%;
  padding: 2.5px;
  background-color: #f2f3f5;
  border: solid 2px #f2f3f5;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .brushfire-button-choices .brushfire-button-choice.active,
.modal-open .brushfire-button-choices .brushfire-button-choice.active,
.MuiPopover-root .brushfire-button-choices .brushfire-button-choice.active {
  border: solid 2px #004bed;
  border-radius: 8px;
}
#root .widget-preview,
.modal-open .widget-preview,
.MuiPopover-root .widget-preview {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: none;
}
#root .developer-checkbox .checkbox > span,
.modal-open .developer-checkbox .checkbox > span,
.MuiPopover-root .developer-checkbox .checkbox > span {
  width: calc(100% - 44px);
  vertical-align: unset;
  margin-left: 2px;
}
#root blockquote,
.modal-open blockquote,
.MuiPopover-root blockquote {
  background: #f2f3f5;
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
}
#root blockquote.info,
.modal-open blockquote.info,
.MuiPopover-root blockquote.info {
  border-left: solid 4px #11a9f0;
}
#root code.block,
.modal-open code.block,
.MuiPopover-root code.block {
  display: block;
  background-color: #4a525a;
  color: rgba(255, 255, 255, 0.8) !important;
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
}
#root code pre,
.modal-open code pre,
.MuiPopover-root code pre {
  display: block;
  color: rgba(255, 255, 255, 0.8) !important;
  tab-size: 20px;
  line-height: 1.2em;
  margin-bottom: 0;
}
#root .api-docs,
.modal-open .api-docs,
.MuiPopover-root .api-docs {
  line-height: 1.1;
}
#root .api-docs h3:not(.question-header),
.modal-open .api-docs h3:not(.question-header),
.MuiPopover-root .api-docs h3:not(.question-header) {
  font-size: 2em;
  margin-top: 2em;
  text-transform: uppercase;
  letter-spacing: 1.2;
  margin-bottom: 10px;
}
#root .sign-block,
.modal-open .sign-block,
.MuiPopover-root .sign-block {
  margin-left: 3px;
}
#root .sign-block *,
.modal-open .sign-block *,
.MuiPopover-root .sign-block * {
  padding-top: 4px;
}
@media only screen and (max-width : 768px) {
  #root .sign-block,
  .modal-open .sign-block,
  .MuiPopover-root .sign-block {
    margin: 3px 0;
    display: block;
  }
}
#root .sign-block-sm,
.modal-open .sign-block-sm,
.MuiPopover-root .sign-block-sm {
  display: block;
  margin: 3px 0;
}
#root .sign-block-sm *,
.modal-open .sign-block-sm *,
.MuiPopover-root .sign-block-sm * {
  padding-top: 4px;
}
#root .skip-all-attendees,
.modal-open .skip-all-attendees,
.MuiPopover-root .skip-all-attendees {
  display: flex;
  justify-content: center;
}
#root .skip-all-attendees span,
.modal-open .skip-all-attendees span,
.MuiPopover-root .skip-all-attendees span {
  white-space: nowrap;
}
#root a,
.modal-open a,
.MuiPopover-root a {
  color: #004bed;
}
#root a.no-underline,
.modal-open a.no-underline,
.MuiPopover-root a.no-underline {
  text-decoration: none;
}
#root a:hover,
.modal-open a:hover,
.MuiPopover-root a:hover {
  color: #548aff !important;
}
#root h1,
.modal-open h1,
.MuiPopover-root h1,
#root h2,
.modal-open h2,
.MuiPopover-root h2,
#root h3,
.modal-open h3,
.MuiPopover-root h3,
#root h4,
.modal-open h4,
.MuiPopover-root h4,
#root h5,
.modal-open h5,
.MuiPopover-root h5,
#root h6,
.modal-open h6,
.MuiPopover-root h6,
#root .h1,
.modal-open .h1,
.MuiPopover-root .h1,
#root .h2,
.modal-open .h2,
.MuiPopover-root .h2,
#root .h3,
.modal-open .h3,
.MuiPopover-root .h3,
#root .h4,
.modal-open .h4,
.MuiPopover-root .h4,
#root .h5,
.modal-open .h5,
.MuiPopover-root .h5,
#root .h6,
.modal-open .h6,
.MuiPopover-root .h6,
#root p,
.modal-open p,
.MuiPopover-root p,
#root small,
.modal-open small,
.MuiPopover-root small {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}
#root h1:not(p),
.modal-open h1:not(p),
.MuiPopover-root h1:not(p),
#root h2:not(p),
.modal-open h2:not(p),
.MuiPopover-root h2:not(p),
#root h3:not(p),
.modal-open h3:not(p),
.MuiPopover-root h3:not(p),
#root h4:not(p),
.modal-open h4:not(p),
.MuiPopover-root h4:not(p),
#root h5:not(p),
.modal-open h5:not(p),
.MuiPopover-root h5:not(p),
#root h6:not(p),
.modal-open h6:not(p),
.MuiPopover-root h6:not(p),
#root .h1:not(p),
.modal-open .h1:not(p),
.MuiPopover-root .h1:not(p),
#root .h2:not(p),
.modal-open .h2:not(p),
.MuiPopover-root .h2:not(p),
#root .h3:not(p),
.modal-open .h3:not(p),
.MuiPopover-root .h3:not(p),
#root .h4:not(p),
.modal-open .h4:not(p),
.MuiPopover-root .h4:not(p),
#root .h5:not(p),
.modal-open .h5:not(p),
.MuiPopover-root .h5:not(p),
#root .h6:not(p),
.modal-open .h6:not(p),
.MuiPopover-root .h6:not(p),
#root p:not(p),
.modal-open p:not(p),
.MuiPopover-root p:not(p),
#root small:not(p),
.modal-open small:not(p),
.MuiPopover-root small:not(p) {
  margin-bottom: 0;
}
#root p,
.modal-open p,
.MuiPopover-root p {
  line-height: 1.2;
}
#root .mini,
.modal-open .mini,
.MuiPopover-root .mini {
  font-family: ff-basic-gothic-pro, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}
#root .mini.bold,
.modal-open .mini.bold,
.MuiPopover-root .mini.bold {
  font-weight: 600 !important;
}
#root .cursor-default,
.modal-open .cursor-default,
.MuiPopover-root .cursor-default {
  cursor: default;
}
#root .cursor-pointer,
.modal-open .cursor-pointer,
.MuiPopover-root .cursor-pointer {
  cursor: pointer;
}
#root .cursor-move,
.modal-open .cursor-move,
.MuiPopover-root .cursor-move {
  cursor: move !important;
}
#root .word-break,
.modal-open .word-break,
.MuiPopover-root .word-break {
  word-break: break-all;
  word-break: break-word;
}
#root h1.heading,
.modal-open h1.heading,
.MuiPopover-root h1.heading {
  font-size: 24px !important;
  line-height: 29px;
  font-weight: bold;
  color: #004bed;
}
#root h1,
.modal-open h1,
.MuiPopover-root h1,
#root .h1,
.modal-open .h1,
.MuiPopover-root .h1 {
  font-size: 36px;
  line-height: 1.2;
}
#root h1.bold,
.modal-open h1.bold,
.MuiPopover-root h1.bold,
#root .h1.bold,
.modal-open .h1.bold,
.MuiPopover-root .h1.bold {
  font-weight: 600 !important;
}
#root h1.display,
.modal-open h1.display,
.MuiPopover-root h1.display,
#root .h1.display,
.modal-open .h1.display,
.MuiPopover-root .h1.display {
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
}
@media only screen and (max-width : 768px) {
  #root h1,
  .modal-open h1,
  .MuiPopover-root h1,
  #root .h1,
  .modal-open .h1,
  .MuiPopover-root .h1 {
    font-size: 28px;
    line-height: 1.1;
  }
}
#root h2,
.modal-open h2,
.MuiPopover-root h2,
#root .h2,
.modal-open .h2,
.MuiPopover-root .h2 {
  line-height: 1.1;
  font-size: 32px;
}
#root h2.bold,
.modal-open h2.bold,
.MuiPopover-root h2.bold,
#root .h2.bold,
.modal-open .h2.bold,
.MuiPopover-root .h2.bold {
  font-weight: 700 !important;
}
@media only screen and (max-width : 768px) {
  #root h2,
  .modal-open h2,
  .MuiPopover-root h2,
  #root .h2,
  .modal-open .h2,
  .MuiPopover-root .h2 {
    font-size: 24px;
    line-height: 1.2;
  }
}
#root h3,
.modal-open h3,
.MuiPopover-root h3,
#root .h3,
.modal-open .h3,
.MuiPopover-root .h3 {
  font-size: 24px;
  line-height: 1.2;
}
#root h3.bold,
.modal-open h3.bold,
.MuiPopover-root h3.bold,
#root .h3.bold,
.modal-open .h3.bold,
.MuiPopover-root .h3.bold {
  font-weight: 600 !important;
}
#root h3.display,
.modal-open h3.display,
.MuiPopover-root h3.display,
#root .h3.display,
.modal-open .h3.display,
.MuiPopover-root .h3.display {
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 28.8px;
}
@media only screen and (max-width : 768px) {
  #root h3,
  .modal-open h3,
  .MuiPopover-root h3,
  #root .h3,
  .modal-open .h3,
  .MuiPopover-root .h3 {
    font-size: 20px;
  }
}
#root h4,
.modal-open h4,
.MuiPopover-root h4,
#root .h4,
.modal-open .h4,
.MuiPopover-root .h4 {
  font-size: 20px;
  line-height: 1.2;
}
#root h4.bold,
.modal-open h4.bold,
.MuiPopover-root h4.bold,
#root .h4.bold,
.modal-open .h4.bold,
.MuiPopover-root .h4.bold {
  font-weight: 600 !important;
}
@media only screen and (max-width : 768px) {
  #root h4,
  .modal-open h4,
  .MuiPopover-root h4,
  #root .h4,
  .modal-open .h4,
  .MuiPopover-root .h4 {
    font-size: 18px;
  }
}
#root h5,
.modal-open h5,
.MuiPopover-root h5,
#root .h5,
.modal-open .h5,
.MuiPopover-root .h5 {
  font-size: 18px;
  line-height: 1.2;
}
#root h5.bold,
.modal-open h5.bold,
.MuiPopover-root h5.bold,
#root .h5.bold,
.modal-open .h5.bold,
.MuiPopover-root .h5.bold {
  font-weight: 600 !important;
}
@media only screen and (max-width : 768px) {
  #root h5,
  .modal-open h5,
  .MuiPopover-root h5,
  #root .h5,
  .modal-open .h5,
  .MuiPopover-root .h5 {
    font-size: 17px;
  }
}
#root h6,
.modal-open h6,
.MuiPopover-root h6,
#root .h6,
.modal-open .h6,
.MuiPopover-root .h6 {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 0;
}
#root h6.bold,
.modal-open h6.bold,
.MuiPopover-root h6.bold,
#root .h6.bold,
.modal-open .h6.bold,
.MuiPopover-root .h6.bold {
  font-weight: 600 !important;
}
@media only screen and (max-width : 768px) {
  #root h6,
  .modal-open h6,
  .MuiPopover-root h6,
  #root .h6,
  .modal-open .h6,
  .MuiPopover-root .h6 {
    font-size: 15px;
  }
}
#root small,
.modal-open small,
.MuiPopover-root small {
  font-size: 12px;
  line-height: 15px;
}
#root small.bold,
.modal-open small.bold,
.MuiPopover-root small.bold {
  font-weight: 600 !important;
}
#root .disclaimer,
.modal-open .disclaimer,
.MuiPopover-root .disclaimer {
  text-align: center;
  font-size: 10px;
  line-height: 10.4px;
  display: block;
}
#root .tt-uppercase,
.modal-open .tt-uppercase,
.MuiPopover-root .tt-uppercase {
  text-transform: uppercase;
}
#root .light,
.modal-open .light,
.MuiPopover-root .light {
  font-weight: 300;
}
#root .normal,
.modal-open .normal,
.MuiPopover-root .normal {
  font-weight: 400;
}
#root .semi-bold,
.modal-open .semi-bold,
.MuiPopover-root .semi-bold {
  font-weight: 600;
}
#root .bold,
.modal-open .bold,
.MuiPopover-root .bold {
  font-weight: 700;
}
#root .link,
.modal-open .link,
.MuiPopover-root .link {
  text-decoration: underline;
}
#root .break-word,
.modal-open .break-word,
.MuiPopover-root .break-word {
  word-break: break-word;
}
#root .attendee-type-header,
.modal-open .attendee-type-header,
.MuiPopover-root .attendee-type-header {
  font-weight: 400;
  font-size: 14px;
}
#root .unstyled-link,
.modal-open .unstyled-link,
.MuiPopover-root .unstyled-link {
  color: #24272b;
  padding: 1px;
}
#root .unstyled-link:hover,
.modal-open .unstyled-link:hover,
.MuiPopover-root .unstyled-link:hover {
  text-decoration: none;
  color: #24272b !important;
}
#root .unstyled-link .warning,
.modal-open .unstyled-link .warning,
.MuiPopover-root .unstyled-link .warning {
  color: #facf43;
}
#root .primary,
.modal-open .primary,
.MuiPopover-root .primary {
  color: #004bed;
}
#root .secondary,
.modal-open .secondary,
.MuiPopover-root .secondary {
  color: #4a525a;
}
#root .warning,
.modal-open .warning,
.MuiPopover-root .warning {
  color: #facf43;
}
#root .success,
.modal-open .success,
.MuiPopover-root .success {
  color: #20bf55;
}
#root .info,
.modal-open .info,
.MuiPopover-root .info {
  color: #11a9f0;
}
#root .danger,
.modal-open .danger,
.MuiPopover-root .danger {
  color: #e71d36;
}
#root .dark,
.modal-open .dark,
.MuiPopover-root .dark {
  color: #24272b;
}
#root .light,
.modal-open .light,
.MuiPopover-root .light {
  color: #e3e6e9;
}
#root .gray,
.modal-open .gray,
.MuiPopover-root .gray {
  color: #b0b8bf;
}
#root .ink,
.modal-open .ink,
.MuiPopover-root .ink {
  color: #24272b;
}
#root .strikethrough,
.modal-open .strikethrough,
.MuiPopover-root .strikethrough {
  text-decoration: line-through;
  opacity: 0.5;
}
#root .mega,
.modal-open .mega,
.MuiPopover-root .mega {
  font-size: 60px;
  font-weight: 700;
}
#root html,
.modal-open html,
.MuiPopover-root html {
  scroll-behavior: smooth;
}
#root .coverall,
.modal-open .coverall,
.MuiPopover-root .coverall {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .coverall.active,
.modal-open .coverall.active,
.MuiPopover-root .coverall.active {
  opacity: 1;
  pointer-events: all;
}
#root .pointer,
.modal-open .pointer,
.MuiPopover-root .pointer {
  cursor: pointer;
}
#root .preview-overlay,
.modal-open .preview-overlay,
.MuiPopover-root .preview-overlay {
  position: relative;
}
#root .preview-overlay::before,
.modal-open .preview-overlay::before,
.MuiPopover-root .preview-overlay::before {
  content: "";
  background-image: url("https://brushfiretech.brushfire.com/img/bg-preview-tile.png");
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .preview-overlay.active::before,
.modal-open .preview-overlay.active::before,
.MuiPopover-root .preview-overlay.active::before {
  pointer-events: all;
  opacity: 1;
}
#root .preview-overlay.active *,
.modal-open .preview-overlay.active *,
.MuiPopover-root .preview-overlay.active * {
  pointer-events: none;
}
#root img:not([width]),
.modal-open img:not([width]),
.MuiPopover-root img:not([width]) {
  max-width: 100%;
}
#root .wysiwyg-text img,
.modal-open .wysiwyg-text img,
.MuiPopover-root .wysiwyg-text img {
  max-width: 100%;
}
#root .wysiwyg-text b,
.modal-open .wysiwyg-text b,
.MuiPopover-root .wysiwyg-text b,
#root .wysiwyg-text strong,
.modal-open .wysiwyg-text strong,
.MuiPopover-root .wysiwyg-text strong {
  font-weight: bold;
}
#root .modal-backdrop.show,
.modal-open .modal-backdrop.show,
.MuiPopover-root .modal-backdrop.show {
  opacity: 0.3;
}
#root .modal.show,
.modal-open .modal.show,
.MuiPopover-root .modal.show {
  opacity: 1;
}
#root .modal.show .modal-dialog,
.modal-open .modal.show .modal-dialog,
.MuiPopover-root .modal.show .modal-dialog {
  transform: translate(0, 0);
}
#root .modal .modal-header .close,
.modal-open .modal .modal-header .close,
.MuiPopover-root .modal .modal-header .close {
  margin-top: -33px;
}
#root .file-image-preview,
.modal-open .file-image-preview,
.MuiPopover-root .file-image-preview {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
}
#root .file-name,
.modal-open .file-name,
.MuiPopover-root .file-name {
  padding: 10px;
  display: inline-block;
  width: calc(100% - 120px - (2 * 10px));
  vertical-align: middle;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-line;
}
#root .loader-screen,
.modal-open .loader-screen,
.MuiPopover-root .loader-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.5);
}
#root .loader-screen i,
.modal-open .loader-screen i,
.MuiPopover-root .loader-screen i {
  color: #004bed;
}
#root .loader-screen h2,
.modal-open .loader-screen h2,
.MuiPopover-root .loader-screen h2 {
  color: #24272b;
  font-weight: 200;
}
#root .loader-screen.loader-screen-solid,
.modal-open .loader-screen.loader-screen-solid,
.MuiPopover-root .loader-screen.loader-screen-solid {
  background-color: #ffffff;
}
#root .rounded-image,
.modal-open .rounded-image,
.MuiPopover-root .rounded-image {
  border-radius: 3px;
  overflow: hidden;
}
#root .area-reveal,
.modal-open .area-reveal,
.MuiPopover-root .area-reveal {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
#root .area-reveal.active,
.modal-open .area-reveal.active,
.MuiPopover-root .area-reveal.active {
  max-height: 1200px;
}
#root .edit-list .row > *,
.modal-open .edit-list .row > *,
.MuiPopover-root .edit-list .row > * {
  opacity: 1;
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -ms-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
}
#root .edit-list .row:not(:first-child)::before,
.modal-open .edit-list .row:not(:first-child)::before,
.MuiPopover-root .edit-list .row:not(:first-child)::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e5e6e7;
}
#root .dropdown-toggle.no-arrow::after,
.modal-open .dropdown-toggle.no-arrow::after,
.MuiPopover-root .dropdown-toggle.no-arrow::after {
  display: none;
}
#root .box-shadow,
.modal-open .box-shadow,
.MuiPopover-root .box-shadow {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .pill,
.modal-open .pill,
.MuiPopover-root .pill {
  padding: 2px 10px;
  background-color: #e5e6e7;
  border-radius: 3px;
  font-size: 11px;
  vertical-align: middle;
  color: #4a525a;
  display: inline-flex;
  margin-top: -4px;
}
#root .pill span,
.modal-open .pill span,
.MuiPopover-root .pill span {
  padding-top: 2px;
}
#root .label,
.modal-open .label,
.MuiPopover-root .label {
  background-color: #b0b8bf;
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 3px;
  color: #4a525a !important;
  font-size: 14px;
  vertical-align: middle;
  float: right;
}
#root .label.label-danger,
.modal-open .label.label-danger,
.MuiPopover-root .label.label-danger {
  background-color: #e71d36;
  color: #ffffff;
}
#root .field-paragraph-label,
.modal-open .field-paragraph-label,
.MuiPopover-root .field-paragraph-label {
  display: block;
  text-align: left;
  padding: 2px 2px 2px 0px;
  font-size: 12px;
  color: #4a525a;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px !important;
}
#root .no-results,
.modal-open .no-results,
.MuiPopover-root .no-results {
  text-align: center;
  background-color: #f2f3f5;
  padding: 20px;
  border-radius: 3px;
}
#root .no-results > i,
.modal-open .no-results > i,
.MuiPopover-root .no-results > i {
  opacity: 0.5;
}
#root .no-results > div,
.modal-open .no-results > div,
.MuiPopover-root .no-results > div {
  opacity: 0.75;
}
#root .cart,
.modal-open .cart,
.MuiPopover-root .cart {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width : 768px) {
  #root .cart,
  .modal-open .cart,
  .MuiPopover-root .cart {
    display: flex;
    flex-flow: column;
  }
}
#root .cart .cart-mobile-review .cart-tab,
.modal-open .cart .cart-mobile-review .cart-tab,
.MuiPopover-root .cart .cart-mobile-review .cart-tab,
#root .dashboard .cart-mobile-review .cart-tab,
.modal-open .dashboard .cart-mobile-review .cart-tab,
.MuiPopover-root .dashboard .cart-mobile-review .cart-tab {
  padding: 10px;
}
#root .cart.cart-questions .cart-left,
.modal-open .cart.cart-questions .cart-left,
.MuiPopover-root .cart.cart-questions .cart-left,
#root .dashboard.cart-questions .cart-left,
.modal-open .dashboard.cart-questions .cart-left,
.MuiPopover-root .dashboard.cart-questions .cart-left,
#root .cart.cart-ast .cart-left,
.modal-open .cart.cart-ast .cart-left,
.MuiPopover-root .cart.cart-ast .cart-left,
#root .dashboard.cart-ast .cart-left,
.modal-open .dashboard.cart-ast .cart-left,
.MuiPopover-root .dashboard.cart-ast .cart-left {
  max-width: 300px;
}
@media only screen and (max-width : 768px) {
  #root .cart.cart-questions .cart-left,
  .modal-open .cart.cart-questions .cart-left,
  .MuiPopover-root .cart.cart-questions .cart-left,
  #root .dashboard.cart-questions .cart-left,
  .modal-open .dashboard.cart-questions .cart-left,
  .MuiPopover-root .dashboard.cart-questions .cart-left,
  #root .cart.cart-ast .cart-left,
  .modal-open .cart.cart-ast .cart-left,
  .MuiPopover-root .cart.cart-ast .cart-left,
  #root .dashboard.cart-ast .cart-left,
  .modal-open .dashboard.cart-ast .cart-left,
  .MuiPopover-root .dashboard.cart-ast .cart-left {
    order: 2;
    top: 10px;
    max-width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
  }
}
#root .cart.cart-ast .cart-right,
.modal-open .cart.cart-ast .cart-right,
.MuiPopover-root .cart.cart-ast .cart-right,
#root .dashboard.cart-ast .cart-right,
.modal-open .dashboard.cart-ast .cart-right,
.MuiPopover-root .dashboard.cart-ast .cart-right {
  padding-left: 10px;
}
@media only screen and (max-width : 768px) {
  #root .cart.cart-ast .cart-right,
  .modal-open .cart.cart-ast .cart-right,
  .MuiPopover-root .cart.cart-ast .cart-right,
  #root .dashboard.cart-ast .cart-right,
  .modal-open .dashboard.cart-ast .cart-right,
  .MuiPopover-root .dashboard.cart-ast .cart-right {
    order: 1;
    padding-left: 0;
  }
}
#root .cart.cart-checkout .cart-left,
.modal-open .cart.cart-checkout .cart-left,
.MuiPopover-root .cart.cart-checkout .cart-left,
#root .dashboard.cart-checkout .cart-left,
.modal-open .dashboard.cart-checkout .cart-left,
.MuiPopover-root .dashboard.cart-checkout .cart-left {
  max-width: 600px;
}
@media only screen and (max-width : 992px) {
  #root .cart.cart-checkout .cart-left,
  .modal-open .cart.cart-checkout .cart-left,
  .MuiPopover-root .cart.cart-checkout .cart-left,
  #root .dashboard.cart-checkout .cart-left,
  .modal-open .dashboard.cart-checkout .cart-left,
  .MuiPopover-root .dashboard.cart-checkout .cart-left {
    max-width: 500px;
  }
}
@media only screen and (max-width : 768px) {
  #root .cart.cart-checkout .cart-left,
  .modal-open .cart.cart-checkout .cart-left,
  .MuiPopover-root .cart.cart-checkout .cart-left,
  #root .dashboard.cart-checkout .cart-left,
  .modal-open .dashboard.cart-checkout .cart-left,
  .MuiPopover-root .dashboard.cart-checkout .cart-left {
    max-width: 100%;
  }
}
#root .cart a.cart-tab,
.modal-open .cart a.cart-tab,
.MuiPopover-root .cart a.cart-tab,
#root .dashboard a.cart-tab,
.modal-open .dashboard a.cart-tab,
.MuiPopover-root .dashboard a.cart-tab {
  color: inherit;
}
#root .cart a.cart-tab:hover,
.modal-open .cart a.cart-tab:hover,
.MuiPopover-root .cart a.cart-tab:hover,
#root .dashboard a.cart-tab:hover,
.modal-open .dashboard a.cart-tab:hover,
.MuiPopover-root .dashboard a.cart-tab:hover {
  color: inherit !important;
  text-decoration: none;
}
#root .cart .cart-tab,
.modal-open .cart .cart-tab,
.MuiPopover-root .cart .cart-tab,
#root .dashboard .cart-tab,
.modal-open .dashboard .cart-tab,
.MuiPopover-root .dashboard .cart-tab {
  position: relative;
  padding: 10px 0;
  padding-right: 18px;
  margin-right: -2px;
  border: 2px solid transparent;
  border-right: none;
  border-radius: 6px 0 0 6px;
  display: block;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0);
  clip-path: inset(-10px 0 -22px -16px);
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-tab,
  .modal-open .cart .cart-tab,
  .MuiPopover-root .cart .cart-tab,
  #root .dashboard .cart-tab,
  .modal-open .dashboard .cart-tab,
  .MuiPopover-root .dashboard .cart-tab {
    border-radius: 8px;
    margin-top: 5px;
    background-color: #f2f3f5;
    margin-right: 0;
  }
}
#root .cart .cart-tab.dashboard-tab,
.modal-open .cart .cart-tab.dashboard-tab,
.MuiPopover-root .cart .cart-tab.dashboard-tab,
#root .dashboard .cart-tab.dashboard-tab,
.modal-open .dashboard .cart-tab.dashboard-tab,
.MuiPopover-root .dashboard .cart-tab.dashboard-tab {
  background-color: #f2f3f5;
  padding: 10px;
  border-radius: 3px;
  margin-top: 5px;
  cursor: default;
}
#root .cart .cart-tab .bold,
.modal-open .cart .cart-tab .bold,
.MuiPopover-root .cart .cart-tab .bold,
#root .dashboard .cart-tab .bold,
.modal-open .dashboard .cart-tab .bold,
.MuiPopover-root .dashboard .cart-tab .bold {
  font-weight: 600 !important;
  line-height: 17.28px !important;
}
#root .cart .cart-tab .p-left.ta-right,
.modal-open .cart .cart-tab .p-left.ta-right,
.MuiPopover-root .cart .cart-tab .p-left.ta-right,
#root .dashboard .cart-tab .p-left.ta-right,
.modal-open .dashboard .cart-tab .p-left.ta-right,
.MuiPopover-root .dashboard .cart-tab .p-left.ta-right {
  font-size: 14px !important;
  line-height: 16.8px !important;
}
#root .cart .cart-tab.pointer,
.modal-open .cart .cart-tab.pointer,
.MuiPopover-root .cart .cart-tab.pointer,
#root .dashboard .cart-tab.pointer,
.modal-open .dashboard .cart-tab.pointer,
.MuiPopover-root .dashboard .cart-tab.pointer {
  cursor: pointer;
}
#root .cart .cart-tab .strikeout,
.modal-open .cart .cart-tab .strikeout,
.MuiPopover-root .cart .cart-tab .strikeout,
#root .dashboard .cart-tab .strikeout,
.modal-open .dashboard .cart-tab .strikeout,
.MuiPopover-root .dashboard .cart-tab .strikeout {
  text-decoration: line-through;
  color: #4a525a;
  font-size: 12px;
  margin-top: 5px;
}
#root .cart .cart-tab .pill,
.modal-open .cart .cart-tab .pill,
.MuiPopover-root .cart .cart-tab .pill,
#root .dashboard .cart-tab .pill,
.modal-open .dashboard .cart-tab .pill,
.MuiPopover-root .dashboard .cart-tab .pill {
  padding: 2px 4px;
}
#root .cart .cart-tab .pill .mini,
.modal-open .cart .cart-tab .pill .mini,
.MuiPopover-root .cart .cart-tab .pill .mini,
#root .dashboard .cart-tab .pill .mini,
.modal-open .dashboard .cart-tab .pill .mini,
.MuiPopover-root .dashboard .cart-tab .pill .mini {
  font-size: 8px;
}
#root .cart .cart-tab.tab-disabled,
.modal-open .cart .cart-tab.tab-disabled,
.MuiPopover-root .cart .cart-tab.tab-disabled,
#root .dashboard .cart-tab.tab-disabled,
.modal-open .dashboard .cart-tab.tab-disabled,
.MuiPopover-root .dashboard .cart-tab.tab-disabled {
  color: rgba(4, 14, 53, 0.4);
}
#root .cart .cart-tab.tab-disabled .icon-badge,
.modal-open .cart .cart-tab.tab-disabled .icon-badge,
.MuiPopover-root .cart .cart-tab.tab-disabled .icon-badge,
#root .dashboard .cart-tab.tab-disabled .icon-badge,
.modal-open .dashboard .cart-tab.tab-disabled .icon-badge,
.MuiPopover-root .dashboard .cart-tab.tab-disabled .icon-badge,
#root .cart .cart-tab.tab-disabled .pill,
.modal-open .cart .cart-tab.tab-disabled .pill,
.MuiPopover-root .cart .cart-tab.tab-disabled .pill,
#root .dashboard .cart-tab.tab-disabled .pill,
.modal-open .dashboard .cart-tab.tab-disabled .pill,
.MuiPopover-root .dashboard .cart-tab.tab-disabled .pill {
  opacity: 0.25;
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-tab.tab-disabled,
  .modal-open .cart .cart-tab.tab-disabled,
  .MuiPopover-root .cart .cart-tab.tab-disabled,
  #root .dashboard .cart-tab.tab-disabled,
  .modal-open .dashboard .cart-tab.tab-disabled,
  .MuiPopover-root .dashboard .cart-tab.tab-disabled {
    border-radius: 8px;
    background-color: #f2f3f5;
  }
}
#root .cart .cart-tab.tab-disabled .strikeout,
.modal-open .cart .cart-tab.tab-disabled .strikeout,
.MuiPopover-root .cart .cart-tab.tab-disabled .strikeout,
#root .dashboard .cart-tab.tab-disabled .strikeout,
.modal-open .dashboard .cart-tab.tab-disabled .strikeout,
.MuiPopover-root .dashboard .cart-tab.tab-disabled .strikeout {
  color: rgba(4, 14, 53, 0.4);
}
#root .cart .cart-tab.active,
.modal-open .cart .cart-tab.active,
.MuiPopover-root .cart .cart-tab.active,
#root .dashboard .cart-tab.active,
.modal-open .dashboard .cart-tab.active,
.MuiPopover-root .dashboard .cart-tab.active {
  clip-path: unset;
  border: solid 2px #e5e6e7;
  border-right: none;
  background-color: #ffffff;
  padding-left: 22px;
}
#root .cart .cart-tab.active .status-wrapper,
.modal-open .cart .cart-tab.active .status-wrapper,
.MuiPopover-root .cart .cart-tab.active .status-wrapper,
#root .dashboard .cart-tab.active .status-wrapper,
.modal-open .dashboard .cart-tab.active .status-wrapper,
.MuiPopover-root .dashboard .cart-tab.active .status-wrapper {
  display: none;
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-tab.active,
  .modal-open .cart .cart-tab.active,
  .MuiPopover-root .cart .cart-tab.active,
  #root .dashboard .cart-tab.active,
  .modal-open .dashboard .cart-tab.active,
  .MuiPopover-root .dashboard .cart-tab.active {
    border: 0;
    background-color: #004bed;
    color: #ffffff;
    padding-left: 10px;
  }
  #root .cart .cart-tab.active .btn,
  .modal-open .cart .cart-tab.active .btn,
  .MuiPopover-root .cart .cart-tab.active .btn,
  #root .dashboard .cart-tab.active .btn,
  .modal-open .dashboard .cart-tab.active .btn,
  .MuiPopover-root .dashboard .cart-tab.active .btn {
    color: #ffffff;
  }
  #root .cart .cart-tab.active .status-wrapper,
  .modal-open .cart .cart-tab.active .status-wrapper,
  .MuiPopover-root .cart .cart-tab.active .status-wrapper,
  #root .dashboard .cart-tab.active .status-wrapper,
  .modal-open .dashboard .cart-tab.active .status-wrapper,
  .MuiPopover-root .dashboard .cart-tab.active .status-wrapper {
    display: block;
  }
}
#root .cart .cart-tab.active::before,
.modal-open .cart .cart-tab.active::before,
.MuiPopover-root .cart .cart-tab.active::before,
#root .dashboard .cart-tab.active::before,
.modal-open .dashboard .cart-tab.active::before,
.MuiPopover-root .dashboard .cart-tab.active::before {
  content: "";
  display: inline-block;
  width: 10px;
  right: -10px;
  height: calc(100% + 20px);
  top: -10px;
  position: absolute;
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-tab,
  .modal-open .cart .cart-tab,
  .MuiPopover-root .cart .cart-tab,
  #root .dashboard .cart-tab,
  .modal-open .dashboard .cart-tab,
  .MuiPopover-root .dashboard .cart-tab {
    padding: 10px;
  }
}
#root .cart .cart-left,
.modal-open .cart .cart-left,
.MuiPopover-root .cart .cart-left,
#root .dashboard .cart-left,
.modal-open .dashboard .cart-left,
.MuiPopover-root .dashboard .cart-left {
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  width: 100%;
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-left,
  .modal-open .cart .cart-left,
  .MuiPopover-root .cart .cart-left,
  #root .dashboard .cart-left,
  .modal-open .dashboard .cart-left,
  .MuiPopover-root .dashboard .cart-left {
    border-right: 2px solid transparent;
    border-radius: 3px;
  }
}
#root .cart .cart-left .cart-tab-sticky,
.modal-open .cart .cart-left .cart-tab-sticky,
.MuiPopover-root .cart .cart-left .cart-tab-sticky,
#root .dashboard .cart-left .cart-tab-sticky,
.modal-open .dashboard .cart-left .cart-tab-sticky,
.MuiPopover-root .dashboard .cart-left .cart-tab-sticky {
  z-index: 5;
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-left .cart-tab-sticky,
  .modal-open .cart .cart-left .cart-tab-sticky,
  .MuiPopover-root .cart .cart-left .cart-tab-sticky,
  #root .dashboard .cart-left .cart-tab-sticky,
  .modal-open .dashboard .cart-left .cart-tab-sticky,
  .MuiPopover-root .dashboard .cart-left .cart-tab-sticky {
    position: unset !important;
  }
}
#root .cart .cart-left .sticky-inner-container,
.modal-open .cart .cart-left .sticky-inner-container,
.MuiPopover-root .cart .cart-left .sticky-inner-container,
#root .dashboard .cart-left .sticky-inner-container,
.modal-open .dashboard .cart-left .sticky-inner-container,
.MuiPopover-root .dashboard .cart-left .sticky-inner-container {
  bottom: unset;
}
#root .cart .cart-left .sticky-inner-container.bottom,
.modal-open .cart .cart-left .sticky-inner-container.bottom,
.MuiPopover-root .cart .cart-left .sticky-inner-container.bottom,
#root .dashboard .cart-left .sticky-inner-container.bottom,
.modal-open .dashboard .cart-left .sticky-inner-container.bottom,
.MuiPopover-root .dashboard .cart-left .sticky-inner-container.bottom {
  bottom: 75px;
}
#root .cart .cart-left .sticky-inner-container.top,
.modal-open .cart .cart-left .sticky-inner-container.top,
.MuiPopover-root .cart .cart-left .sticky-inner-container.top,
#root .dashboard .cart-left .sticky-inner-container.top,
.modal-open .dashboard .cart-left .sticky-inner-container.top,
.MuiPopover-root .dashboard .cart-left .sticky-inner-container.top {
  top: 10px;
  bottom: unset;
}
#root .cart .cart-left .sticky-inner-container.fixed-sticky,
.modal-open .cart .cart-left .sticky-inner-container.fixed-sticky,
.MuiPopover-root .cart .cart-left .sticky-inner-container.fixed-sticky,
#root .dashboard .cart-left .sticky-inner-container.fixed-sticky,
.modal-open .dashboard .cart-left .sticky-inner-container.fixed-sticky,
.MuiPopover-root .dashboard .cart-left .sticky-inner-container.fixed-sticky {
  z-index: 9;
}
#root .cart .cart-left .sticky-inner-container.fixed-sticky .cart-tab,
.modal-open .cart .cart-left .sticky-inner-container.fixed-sticky .cart-tab,
.MuiPopover-root .cart .cart-left .sticky-inner-container.fixed-sticky .cart-tab,
#root .dashboard .cart-left .sticky-inner-container.fixed-sticky .cart-tab,
.modal-open .dashboard .cart-left .sticky-inner-container.fixed-sticky .cart-tab,
.MuiPopover-root .dashboard .cart-left .sticky-inner-container.fixed-sticky .cart-tab {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-left .sticky-inner-container,
  .modal-open .cart .cart-left .sticky-inner-container,
  .MuiPopover-root .cart .cart-left .sticky-inner-container,
  #root .dashboard .cart-left .sticky-inner-container,
  .modal-open .dashboard .cart-left .sticky-inner-container,
  .MuiPopover-root .dashboard .cart-left .sticky-inner-container {
    position: static !important;
  }
}
#root .cart.cart-checkout .cart-right,
.modal-open .cart.cart-checkout .cart-right,
.MuiPopover-root .cart.cart-checkout .cart-right,
#root .dashboard.cart-checkout .cart-right,
.modal-open .dashboard.cart-checkout .cart-right,
.MuiPopover-root .dashboard.cart-checkout .cart-right {
  padding-left: 24px;
}
@media only screen and (max-width : 768px) {
  #root .cart.cart-checkout .cart-right,
  .modal-open .cart.cart-checkout .cart-right,
  .MuiPopover-root .cart.cart-checkout .cart-right,
  #root .dashboard.cart-checkout .cart-right,
  .modal-open .dashboard.cart-checkout .cart-right,
  .MuiPopover-root .dashboard.cart-checkout .cart-right {
    padding: 0;
  }
}
#root .cart.cart-questions,
.modal-open .cart.cart-questions,
.MuiPopover-root .cart.cart-questions,
#root .dashboard.cart-questions,
.modal-open .dashboard.cart-questions,
.MuiPopover-root .dashboard.cart-questions,
#root .cart.cart-checkout,
.modal-open .cart.cart-checkout,
.MuiPopover-root .cart.cart-checkout,
#root .dashboard.cart-checkout,
.modal-open .dashboard.cart-checkout,
.MuiPopover-root .dashboard.cart-checkout {
  position: relative;
}
#root .cart.cart-questions .cart-right,
.modal-open .cart.cart-questions .cart-right,
.MuiPopover-root .cart.cart-questions .cart-right,
#root .dashboard.cart-questions .cart-right,
.modal-open .dashboard.cart-questions .cart-right,
.MuiPopover-root .dashboard.cart-questions .cart-right,
#root .cart.cart-checkout .cart-right,
.modal-open .cart.cart-checkout .cart-right,
.MuiPopover-root .cart.cart-checkout .cart-right,
#root .dashboard.cart-checkout .cart-right,
.modal-open .dashboard.cart-checkout .cart-right,
.MuiPopover-root .dashboard.cart-checkout .cart-right {
  padding-left: 24px;
}
#root .cart .cart-right,
.modal-open .cart .cart-right,
.MuiPopover-root .cart .cart-right,
#root .dashboard .cart-right,
.modal-open .dashboard .cart-right,
.MuiPopover-root .dashboard .cart-right {
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  flex: 1;
}
#root .cart .cart-right > form,
.modal-open .cart .cart-right > form,
.MuiPopover-root .cart .cart-right > form,
#root .dashboard .cart-right > form,
.modal-open .dashboard .cart-right > form,
.MuiPopover-root .dashboard .cart-right > form {
  width: 100%;
}
#root .cart .cart-right .cart-question-form,
.modal-open .cart .cart-right .cart-question-form,
.MuiPopover-root .cart .cart-right .cart-question-form,
#root .dashboard .cart-right .cart-question-form,
.modal-open .dashboard .cart-right .cart-question-form,
.MuiPopover-root .dashboard .cart-right .cart-question-form {
  display: none;
}
#root .cart .cart-right .cart-question-form.active,
.modal-open .cart .cart-right .cart-question-form.active,
.MuiPopover-root .cart .cart-right .cart-question-form.active,
#root .dashboard .cart-right .cart-question-form.active,
.modal-open .dashboard .cart-right .cart-question-form.active,
.MuiPopover-root .dashboard .cart-right .cart-question-form.active {
  display: block;
}
#root .cart .cart-right .sticky-container.past-threshold,
.modal-open .cart .cart-right .sticky-container.past-threshold,
.MuiPopover-root .cart .cart-right .sticky-container.past-threshold,
#root .dashboard .cart-right .sticky-container.past-threshold,
.modal-open .dashboard .cart-right .sticky-container.past-threshold,
.MuiPopover-root .dashboard .cart-right .sticky-container.past-threshold {
  align-items: flex-end;
  display: flex;
}
#root .cart .cart-right .sticky-container.past-threshold .fixed-sticky.bottom,
.modal-open .cart .cart-right .sticky-container.past-threshold .fixed-sticky.bottom,
.MuiPopover-root .cart .cart-right .sticky-container.past-threshold .fixed-sticky.bottom,
#root .dashboard .cart-right .sticky-container.past-threshold .fixed-sticky.bottom,
.modal-open .dashboard .cart-right .sticky-container.past-threshold .fixed-sticky.bottom,
.MuiPopover-root .dashboard .cart-right .sticky-container.past-threshold .fixed-sticky.bottom {
  bottom: unset !important;
  position: static !important;
}
#root .cart .cart-right .sticky-inner-container,
.modal-open .cart .cart-right .sticky-inner-container,
.MuiPopover-root .cart .cart-right .sticky-inner-container,
#root .dashboard .cart-right .sticky-inner-container,
.modal-open .dashboard .cart-right .sticky-inner-container,
.MuiPopover-root .dashboard .cart-right .sticky-inner-container {
  bottom: unset;
}
#root .cart .cart-right .sticky-inner-container.bottom,
.modal-open .cart .cart-right .sticky-inner-container.bottom,
.MuiPopover-root .cart .cart-right .sticky-inner-container.bottom,
#root .dashboard .cart-right .sticky-inner-container.bottom,
.modal-open .dashboard .cart-right .sticky-inner-container.bottom,
.MuiPopover-root .dashboard .cart-right .sticky-inner-container.bottom {
  bottom: 75px;
}
#root .cart .cart-right .sticky-inner-container.top,
.modal-open .cart .cart-right .sticky-inner-container.top,
.MuiPopover-root .cart .cart-right .sticky-inner-container.top,
#root .dashboard .cart-right .sticky-inner-container.top,
.modal-open .dashboard .cart-right .sticky-inner-container.top,
.MuiPopover-root .dashboard .cart-right .sticky-inner-container.top {
  top: 10px;
  bottom: unset;
}
#root .cart .cart-right .sticky-inner-container.fixed-sticky,
.modal-open .cart .cart-right .sticky-inner-container.fixed-sticky,
.MuiPopover-root .cart .cart-right .sticky-inner-container.fixed-sticky,
#root .dashboard .cart-right .sticky-inner-container.fixed-sticky,
.modal-open .dashboard .cart-right .sticky-inner-container.fixed-sticky,
.MuiPopover-root .dashboard .cart-right .sticky-inner-container.fixed-sticky {
  z-index: 9;
}
#root .cart .cart-right .promo-form-wrap .promo-form,
.modal-open .cart .cart-right .promo-form-wrap .promo-form,
.MuiPopover-root .cart .cart-right .promo-form-wrap .promo-form,
#root .dashboard .cart-right .promo-form-wrap .promo-form,
.modal-open .dashboard .cart-right .promo-form-wrap .promo-form,
.MuiPopover-root .dashboard .cart-right .promo-form-wrap .promo-form {
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-bottom: 10px;
}
#root .cart .cart-right .promo-form-wrap .promo-form .field,
.modal-open .cart .cart-right .promo-form-wrap .promo-form .field,
.MuiPopover-root .cart .cart-right .promo-form-wrap .promo-form .field,
#root .dashboard .cart-right .promo-form-wrap .promo-form .field,
.modal-open .dashboard .cart-right .promo-form-wrap .promo-form .field,
.MuiPopover-root .dashboard .cart-right .promo-form-wrap .promo-form .field {
  margin: 0;
  flex: 1;
}
#root .cart .cart-right .promo-form-wrap .promo-form input,
.modal-open .cart .cart-right .promo-form-wrap .promo-form input,
.MuiPopover-root .cart .cart-right .promo-form-wrap .promo-form input,
#root .dashboard .cart-right .promo-form-wrap .promo-form input,
.modal-open .dashboard .cart-right .promo-form-wrap .promo-form input,
.MuiPopover-root .dashboard .cart-right .promo-form-wrap .promo-form input {
  padding: 4px 8px;
  font-size: 12px;
  vertical-align: top;
  text-transform: uppercase;
}
#root .cart .cart-right .promo-form-wrap .promo-form .btn,
.modal-open .cart .cart-right .promo-form-wrap .promo-form .btn,
.MuiPopover-root .cart .cart-right .promo-form-wrap .promo-form .btn,
#root .dashboard .cart-right .promo-form-wrap .promo-form .btn,
.modal-open .dashboard .cart-right .promo-form-wrap .promo-form .btn,
.MuiPopover-root .dashboard .cart-right .promo-form-wrap .promo-form .btn {
  padding: 4px 12px !important;
  margin-left: 8px;
  font-size: 14px;
  height: 32px;
  vertical-align: top;
}
#root .cart .cart-right.cart-right-assigned,
.modal-open .cart .cart-right.cart-right-assigned,
.MuiPopover-root .cart .cart-right.cart-right-assigned,
#root .dashboard .cart-right.cart-right-assigned,
.modal-open .dashboard .cart-right.cart-right-assigned,
.MuiPopover-root .dashboard .cart-right.cart-right-assigned {
  width: calc(100% - 300px);
  overflow: visible;
}
@media only screen and (max-width : 768px) {
  #root .cart .cart-right.cart-right-assigned,
  .modal-open .cart .cart-right.cart-right-assigned,
  .MuiPopover-root .cart .cart-right.cart-right-assigned,
  #root .dashboard .cart-right.cart-right-assigned,
  .modal-open .dashboard .cart-right.cart-right-assigned,
  .MuiPopover-root .dashboard .cart-right.cart-right-assigned {
    width: auto;
  }
}
#root .cart .cart-right.cart-right-assigned .ast-section,
.modal-open .cart .cart-right.cart-right-assigned .ast-section,
.MuiPopover-root .cart .cart-right.cart-right-assigned .ast-section,
#root .dashboard .cart-right.cart-right-assigned .ast-section,
.modal-open .dashboard .cart-right.cart-right-assigned .ast-section,
.MuiPopover-root .dashboard .cart-right.cart-right-assigned .ast-section {
  overflow: auto;
}
#root .cart-mobile-questions .status-wrapper,
.modal-open .cart-mobile-questions .status-wrapper,
.MuiPopover-root .cart-mobile-questions .status-wrapper {
  display: none;
}
#root .status-indicator,
.modal-open .status-indicator,
.MuiPopover-root .status-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  margin-top: 2px;
  margin-right: 5px;
}
#root .prereg-icon,
.modal-open .prereg-icon,
.MuiPopover-root .prereg-icon {
  opacity: 0.75;
}
#root .show-arrow,
.modal-open .show-arrow,
.MuiPopover-root .show-arrow {
  display: none;
}
#root .red,
.modal-open .red,
.MuiPopover-root .red {
  background-color: red;
}
#root .cart-tab-hover,
.modal-open .cart-tab-hover,
.MuiPopover-root .cart-tab-hover {
  position: absolute;
  border-radius: 6px 0 0 6px;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgba(74, 82, 90, 0.9);
  opacity: 0.85;
  color: white;
}
#root .cart-tab-hover .cart-tab-hover-btn,
.modal-open .cart-tab-hover .cart-tab-hover-btn,
.MuiPopover-root .cart-tab-hover .cart-tab-hover-btn {
  color: white;
}
#root .cart-tab-hover .cart-tab-hover-btn i,
.modal-open .cart-tab-hover .cart-tab-hover-btn i,
.MuiPopover-root .cart-tab-hover .cart-tab-hover-btn i {
  font-size: 24px;
}
#root .cart-form-overlay,
.modal-open .cart-form-overlay,
.MuiPopover-root .cart-form-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% - 0px);
  padding: 120px 80px;
  opacity: 0;
  pointer-events: none;
  display: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .cart-form-overlay.explaination,
.modal-open .cart-form-overlay.explaination,
.MuiPopover-root .cart-form-overlay.explaination {
  text-align: center;
}
#root .cart-form-overlay.explaination > .well,
.modal-open .cart-form-overlay.explaination > .well,
.MuiPopover-root .cart-form-overlay.explaination > .well {
  max-width: 300px;
  text-align: center;
  display: inline-block;
}
#root .cart-form-overlay.active,
.modal-open .cart-form-overlay.active,
.MuiPopover-root .cart-form-overlay.active {
  display: inline-block;
  opacity: 1;
  pointer-events: all;
}
#root .cart-form-overlay.active .well,
.modal-open .cart-form-overlay.active .well,
.MuiPopover-root .cart-form-overlay.active .well {
  position: sticky;
  top: 120px;
}
#root .event-card-results,
.modal-open .event-card-results,
.MuiPopover-root .event-card-results,
#root .event-card-order-results,
.modal-open .event-card-order-results,
.MuiPopover-root .event-card-order-results {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 10px;
  opacity: 1;
  transition: opacity ease-in-out 0.3s;
}
#root .event-card-results.fetching,
.modal-open .event-card-results.fetching,
.MuiPopover-root .event-card-results.fetching,
#root .event-card-order-results.fetching,
.modal-open .event-card-order-results.fetching,
.MuiPopover-root .event-card-order-results.fetching {
  opacity: 0.5;
}
@media only screen and (max-width : 768px) {
  #root .event-card-results,
  .modal-open .event-card-results,
  .MuiPopover-root .event-card-results,
  #root .event-card-order-results,
  .modal-open .event-card-order-results,
  .MuiPopover-root .event-card-order-results {
    grid-template-columns: 1fr;
  }
}
#root .event-card-results .event-card,
.modal-open .event-card-results .event-card,
.MuiPopover-root .event-card-results .event-card,
#root .event-card-order-results .event-card,
.modal-open .event-card-order-results .event-card,
.MuiPopover-root .event-card-order-results .event-card {
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  box-shadow: 0px 3px 16px 0px #24272b26;
}
#root .event-card-results .event-card:hover,
.modal-open .event-card-results .event-card:hover,
.MuiPopover-root .event-card-results .event-card:hover,
#root .event-card-order-results .event-card:hover,
.modal-open .event-card-order-results .event-card:hover,
.MuiPopover-root .event-card-order-results .event-card:hover,
#root .event-card-results .event-card:focus,
.modal-open .event-card-results .event-card:focus,
.MuiPopover-root .event-card-results .event-card:focus,
#root .event-card-order-results .event-card:focus,
.modal-open .event-card-order-results .event-card:focus,
.MuiPopover-root .event-card-order-results .event-card:focus {
  transform: scale(1.05);
}
@media only screen and (max-width : 768px) {
  #root .event-card-results .event-card:hover,
  .modal-open .event-card-results .event-card:hover,
  .MuiPopover-root .event-card-results .event-card:hover,
  #root .event-card-order-results .event-card:hover,
  .modal-open .event-card-order-results .event-card:hover,
  .MuiPopover-root .event-card-order-results .event-card:hover,
  #root .event-card-results .event-card:focus,
  .modal-open .event-card-results .event-card:focus,
  .MuiPopover-root .event-card-results .event-card:focus,
  #root .event-card-order-results .event-card:focus,
  .modal-open .event-card-order-results .event-card:focus,
  .MuiPopover-root .event-card-order-results .event-card:focus {
    transform: scale(1);
  }
}
#root .event-card-results .event-card:hover,
.modal-open .event-card-results .event-card:hover,
.MuiPopover-root .event-card-results .event-card:hover,
#root .event-card-order-results .event-card:hover,
.modal-open .event-card-order-results .event-card:hover,
.MuiPopover-root .event-card-order-results .event-card:hover {
  cursor: pointer;
}
#root .event-card-results .event-card:focus,
.modal-open .event-card-results .event-card:focus,
.MuiPopover-root .event-card-results .event-card:focus,
#root .event-card-order-results .event-card:focus,
.modal-open .event-card-order-results .event-card:focus,
.MuiPopover-root .event-card-order-results .event-card:focus {
  outline: #004bed 3px;
}
#root .event-card-results .event-card .event-card-image,
.modal-open .event-card-results .event-card .event-card-image,
.MuiPopover-root .event-card-results .event-card .event-card-image,
#root .event-card-order-results .event-card .event-card-image,
.modal-open .event-card-order-results .event-card .event-card-image,
.MuiPopover-root .event-card-order-results .event-card .event-card-image {
  height: 165px;
  background-color: #e3e6e9;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 6px 6px 0 0;
}
@media only screen and (max-width : 1024px) {
  #root .event-card-results .event-card .event-card-image,
  .modal-open .event-card-results .event-card .event-card-image,
  .MuiPopover-root .event-card-results .event-card .event-card-image,
  #root .event-card-order-results .event-card .event-card-image,
  .modal-open .event-card-order-results .event-card .event-card-image,
  .MuiPopover-root .event-card-order-results .event-card .event-card-image {
    height: calc((100vw - 24px)*0.170);
  }
}
@media only screen and (max-width : 768px) {
  #root .event-card-results .event-card .event-card-image,
  .modal-open .event-card-results .event-card .event-card-image,
  .MuiPopover-root .event-card-results .event-card .event-card-image,
  #root .event-card-order-results .event-card .event-card-image,
  .modal-open .event-card-order-results .event-card .event-card-image,
  .MuiPopover-root .event-card-order-results .event-card .event-card-image {
    height: calc((100vw - 24px)*0.522);
  }
}
#root .event-card-results .event-card .event-card-image .event-card-feature,
.modal-open .event-card-results .event-card .event-card-image .event-card-feature,
.MuiPopover-root .event-card-results .event-card .event-card-image .event-card-feature,
#root .event-card-order-results .event-card .event-card-image .event-card-feature,
.modal-open .event-card-order-results .event-card .event-card-image .event-card-feature,
.MuiPopover-root .event-card-order-results .event-card .event-card-image .event-card-feature {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  text-transform: uppercase;
}
#root .event-card-results .event-card .event-card-image .event-card-feature.info,
.modal-open .event-card-results .event-card .event-card-image .event-card-feature.info,
.MuiPopover-root .event-card-results .event-card .event-card-image .event-card-feature.info,
#root .event-card-order-results .event-card .event-card-image .event-card-feature.info,
.modal-open .event-card-order-results .event-card .event-card-image .event-card-feature.info,
.MuiPopover-root .event-card-order-results .event-card .event-card-image .event-card-feature.info {
  background-color: #4a525a;
  color: #ffffff;
}
#root .event-card-results .event-card .event-card-image .event-card-feature.danger,
.modal-open .event-card-results .event-card .event-card-image .event-card-feature.danger,
.MuiPopover-root .event-card-results .event-card .event-card-image .event-card-feature.danger,
#root .event-card-order-results .event-card .event-card-image .event-card-feature.danger,
.modal-open .event-card-order-results .event-card .event-card-image .event-card-feature.danger,
.MuiPopover-root .event-card-order-results .event-card .event-card-image .event-card-feature.danger {
  background-color: #e71d36;
  color: #ffffff;
}
#root .event-card-results .event-card .event-card-text,
.modal-open .event-card-results .event-card .event-card-text,
.MuiPopover-root .event-card-results .event-card .event-card-text,
#root .event-card-order-results .event-card .event-card-text,
.modal-open .event-card-order-results .event-card .event-card-text,
.MuiPopover-root .event-card-order-results .event-card .event-card-text {
  padding: 10px;
}
#root .event-card-results .event-card .event-card-image-loading,
.modal-open .event-card-results .event-card .event-card-image-loading,
.MuiPopover-root .event-card-results .event-card .event-card-image-loading,
#root .event-card-order-results .event-card .event-card-image-loading,
.modal-open .event-card-order-results .event-card .event-card-image-loading,
.MuiPopover-root .event-card-order-results .event-card .event-card-image-loading {
  height: 165px !important;
  transform: scale(1) !important;
}
#root .event-card-results .event-card .event-card-title-loading,
.modal-open .event-card-results .event-card .event-card-title-loading,
.MuiPopover-root .event-card-results .event-card .event-card-title-loading,
#root .event-card-order-results .event-card .event-card-title-loading,
.modal-open .event-card-order-results .event-card .event-card-title-loading,
.MuiPopover-root .event-card-order-results .event-card .event-card-title-loading {
  height: 40px !important;
  width: 60% !important;
  border-radius: 3px;
}
#root .event-card-results .event-card .event-card-date-loading,
.modal-open .event-card-results .event-card .event-card-date-loading,
.MuiPopover-root .event-card-results .event-card .event-card-date-loading,
#root .event-card-order-results .event-card .event-card-date-loading,
.modal-open .event-card-order-results .event-card .event-card-date-loading,
.MuiPopover-root .event-card-order-results .event-card .event-card-date-loading {
  width: 40% !important;
  border-radius: 3px;
}
#root .event-card-order-results,
.modal-open .event-card-order-results,
.MuiPopover-root .event-card-order-results {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#root .event-card-order-results .event-card,
.modal-open .event-card-order-results .event-card,
.MuiPopover-root .event-card-order-results .event-card {
  width: 100%;
  min-width: 230px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(calc(100% - 20px) / 3);
}
@media only screen and (max-width : 768px) {
  #root .event-card-order-results .event-card,
  .modal-open .event-card-order-results .event-card,
  .MuiPopover-root .event-card-order-results .event-card {
    min-width: 100%;
    flex: 0 0 100%;
  }
}
#root .event-map-card-results,
.modal-open .event-map-card-results,
.MuiPopover-root .event-map-card-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
#root .event-map-card-results .event-map-card,
.modal-open .event-map-card-results .event-map-card,
.MuiPopover-root .event-map-card-results .event-map-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 71px;
  padding-left: 3px;
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  margin-bottom: 5px;
  box-shadow: 0px 3px 16px 0px #24272b26;
}
#root .event-map-card-results .event-map-card:hover,
.modal-open .event-map-card-results .event-map-card:hover,
.MuiPopover-root .event-map-card-results .event-map-card:hover,
#root .event-map-card-results .event-map-card:focus,
.modal-open .event-map-card-results .event-map-card:focus,
.MuiPopover-root .event-map-card-results .event-map-card:focus {
  transform: scale(1.02);
}
@media only screen and (max-width : 768px) {
  #root .event-map-card-results .event-map-card:hover,
  .modal-open .event-map-card-results .event-map-card:hover,
  .MuiPopover-root .event-map-card-results .event-map-card:hover,
  #root .event-map-card-results .event-map-card:focus,
  .modal-open .event-map-card-results .event-map-card:focus,
  .MuiPopover-root .event-map-card-results .event-map-card:focus {
    transform: scale(1);
  }
}
#root .event-map-card-results .event-map-card:hover,
.modal-open .event-map-card-results .event-map-card:hover,
.MuiPopover-root .event-map-card-results .event-map-card:hover {
  cursor: pointer;
}
#root .event-map-card-results .event-map-card .event-map-card-image-container,
.modal-open .event-map-card-results .event-map-card .event-map-card-image-container,
.MuiPopover-root .event-map-card-results .event-map-card .event-map-card-image-container {
  height: 100%;
  min-width: 125px;
  max-width: 125px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
@media only screen and (max-width : 768px) {
  #root .event-map-card-results .event-map-card .event-map-card-image-container,
  .modal-open .event-map-card-results .event-map-card .event-map-card-image-container,
  .MuiPopover-root .event-map-card-results .event-map-card .event-map-card-image-container {
    min-height: 71px;
  }
}
#root .event-map-card-results .event-map-card .event-map-card-image-container .event-map-card-image,
.modal-open .event-map-card-results .event-map-card .event-map-card-image-container .event-map-card-image,
.MuiPopover-root .event-map-card-results .event-map-card .event-map-card-image-container .event-map-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
#root .event-map-card-results .event-map-card .event-map-card-text-container,
.modal-open .event-map-card-results .event-map-card .event-map-card-text-container,
.MuiPopover-root .event-map-card-results .event-map-card .event-map-card-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
}
@media only screen and (max-width : 768px) {
  #root .event-map-card-results .event-map-card .event-map-card-text-container .event-map-card-text .event-map-card-title,
  .modal-open .event-map-card-results .event-map-card .event-map-card-text-container .event-map-card-text .event-map-card-title,
  .MuiPopover-root .event-map-card-results .event-map-card .event-map-card-text-container .event-map-card-text .event-map-card-title {
    font-size: small;
  }
}
#root .event-map-card-results .event-map-card .event-map-card-text-container .event-distance,
.modal-open .event-map-card-results .event-map-card .event-map-card-text-container .event-distance,
.MuiPopover-root .event-map-card-results .event-map-card .event-map-card-text-container .event-distance {
  align-content: center;
  padding-right: 20px;
}
#root .event-map-card-results .event-card-image-loading,
.modal-open .event-map-card-results .event-card-image-loading,
.MuiPopover-root .event-map-card-results .event-card-image-loading {
  height: 165px !important;
  transform: scale(1) !important;
  min-width: 125px;
  max-width: 125px;
  margin-right: 10px;
}
#root .event-map-card-results .event-card-title-loading,
.modal-open .event-map-card-results .event-card-title-loading,
.MuiPopover-root .event-map-card-results .event-card-title-loading {
  height: 40px !important;
  width: 60% !important;
  border-radius: 3px;
}
#root .event-map-card-results .event-card-date-loading,
.modal-open .event-map-card-results .event-card-date-loading,
.MuiPopover-root .event-map-card-results .event-card-date-loading {
  width: 40% !important;
  border-radius: 3px;
}
#root .your-group-header,
.modal-open .your-group-header,
.MuiPopover-root .your-group-header {
  background-color: #11a9f0;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
}
#root .your-group-header p,
.modal-open .your-group-header p,
.MuiPopover-root .your-group-header p {
  flex: 1;
  padding: 10px 20px;
  vertical-align: top;
  margin-bottom: 0;
  font-weight: 600;
}
@media only screen and (max-width : 768px) {
  #root .your-group-header p,
  .modal-open .your-group-header p,
  .MuiPopover-root .your-group-header p {
    padding: 12px 0 0;
  }
}
#root .your-group-header .btn,
.modal-open .your-group-header .btn,
.MuiPopover-root .your-group-header .btn {
  color: #ffffff;
  text-decoration: underline;
  text-transform: initial;
  font-size: 14px;
  font-weight: 400;
  border-width: 0;
}
@media only screen and (max-width : 768px) {
  #root .your-group-header .btn,
  .modal-open .your-group-header .btn,
  .MuiPopover-root .your-group-header .btn {
    padding: 6px 0 8px !important;
  }
}
#root .your-group-header.gray,
.modal-open .your-group-header.gray,
.MuiPopover-root .your-group-header.gray {
  background-color: #f2f3f5;
  color: #4a525a;
}
#root .your-group-header.gray .btn,
.modal-open .your-group-header.gray .btn,
.MuiPopover-root .your-group-header.gray .btn {
  color: #4a525a;
}
@media only screen and (max-width : 768px) {
  #root .your-group-header,
  .modal-open .your-group-header,
  .MuiPopover-root .your-group-header {
    border-radius: 8px;
    flex-direction: column;
  }
}
#root #event-list-date-picker,
.modal-open #event-list-date-picker,
.MuiPopover-root #event-list-date-picker {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  width: calc(100% + 24px);
}
#root #event-list-date-picker .DateRangePicker,
.modal-open #event-list-date-picker .DateRangePicker,
.MuiPopover-root #event-list-date-picker .DateRangePicker {
  width: 100%;
}
#root #event-list-date-picker .DateRangePickerInput,
.modal-open #event-list-date-picker .DateRangePickerInput,
.MuiPopover-root #event-list-date-picker .DateRangePickerInput {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
#root #event-list-date-picker .DateInput,
.modal-open #event-list-date-picker .DateInput,
.MuiPopover-root #event-list-date-picker .DateInput {
  width: initial;
  background: none !important;
}
#root #event-list-date-picker .DateInput input:not(:focus),
.modal-open #event-list-date-picker .DateInput input:not(:focus),
.MuiPopover-root #event-list-date-picker .DateInput input:not(:focus) {
  border: none;
}
#root #event-list-date-picker .DateInput input:focus,
.modal-open #event-list-date-picker .DateInput input:focus,
.MuiPopover-root #event-list-date-picker .DateInput input:focus {
  border: none;
}
#root #event-list-date-picker .DateInput_input,
.modal-open #event-list-date-picker .DateInput_input,
.MuiPopover-root #event-list-date-picker .DateInput_input {
  font-family: ff-basic-gothic-pro, sans-serif !important;
  font-size: 14px;
  height: 30px !important;
  font-weight: 400 !important;
  border-radius: 3px !important;
  border-color: #e5e6e7 !important;
}
#root #event-list-date-picker .DateRangePickerInput__showClearDates,
.modal-open #event-list-date-picker .DateRangePickerInput__showClearDates,
.MuiPopover-root #event-list-date-picker .DateRangePickerInput__showClearDates {
  padding-right: 0px;
}
#root #event-list-date-picker .DateRangePickerInput_clearDates,
.modal-open #event-list-date-picker .DateRangePickerInput_clearDates,
.MuiPopover-root #event-list-date-picker .DateRangePickerInput_clearDates {
  visibility: visible;
  transform: none;
  position: relative;
}
#root #event-list-date-picker .CalendarDay__selected,
.modal-open #event-list-date-picker .CalendarDay__selected,
.MuiPopover-root #event-list-date-picker .CalendarDay__selected,
#root #event-list-date-picker .CalendarDay__selected:active,
.modal-open #event-list-date-picker .CalendarDay__selected:active,
.MuiPopover-root #event-list-date-picker .CalendarDay__selected:active,
#root #event-list-date-picker .CalendarDay__selected:hover,
.modal-open #event-list-date-picker .CalendarDay__selected:hover,
.MuiPopover-root #event-list-date-picker .CalendarDay__selected:hover {
  background: #b0b8bf;
  border: 1px double #b0b8bf;
  color: #24272b;
}
#root #event-list-date-picker .CalendarDay__hovered_span,
.modal-open #event-list-date-picker .CalendarDay__hovered_span,
.MuiPopover-root #event-list-date-picker .CalendarDay__hovered_span,
#root #event-list-date-picker .CalendarDay__hovered_span:hover,
.modal-open #event-list-date-picker .CalendarDay__hovered_span:hover,
.MuiPopover-root #event-list-date-picker .CalendarDay__hovered_span:hover,
#root #event-list-date-picker .CalendarDay__selected_span,
.modal-open #event-list-date-picker .CalendarDay__selected_span,
.MuiPopover-root #event-list-date-picker .CalendarDay__selected_span {
  background: #e5e6e7;
  border: 1px double #cacdcf;
  color: #24272b;
}
#root #event-list-date-picker .DayPickerKeyboardShortcuts_show__bottomRight::before,
.modal-open #event-list-date-picker .DayPickerKeyboardShortcuts_show__bottomRight::before,
.MuiPopover-root #event-list-date-picker .DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #b0b8bf;
}
#root #event-list-date-picker .clear-dates-btn,
.modal-open #event-list-date-picker .clear-dates-btn,
.MuiPopover-root #event-list-date-picker .clear-dates-btn {
  position: relative;
  right: 30px;
}
#root #event-list-date-picker #end-date,
.modal-open #event-list-date-picker #end-date,
.MuiPopover-root #event-list-date-picker #end-date {
  padding-right: 40px;
}
#root #event-list-date-picker .DayPicker_weekHeader,
.modal-open #event-list-date-picker .DayPicker_weekHeader,
.MuiPopover-root #event-list-date-picker .DayPicker_weekHeader {
  top: 55px !important;
}
#root #event-list-date-picker .DateRangePickerInput__withBorder,
.modal-open #event-list-date-picker .DateRangePickerInput__withBorder,
.MuiPopover-root #event-list-date-picker .DateRangePickerInput__withBorder {
  border-radius: 3px;
  border-color: #e5e6e7;
}
#root .event-list-spinner,
.modal-open .event-list-spinner,
.MuiPopover-root .event-list-spinner {
  display: flex;
  justify-content: center;
  font-size: 50px;
  align-items: center;
}
#root .error-page .error-page-well,
.modal-open .error-page .error-page-well,
.MuiPopover-root .error-page .error-page-well {
  text-align: left !important;
}
#root .error-page .error-page-well .error-page-header,
.modal-open .error-page .error-page-well .error-page-header,
.MuiPopover-root .error-page .error-page-well .error-page-header {
  background-color: #004bed;
  color: #ffffff;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
#root .error-page .error-page-well .error-page-content,
.modal-open .error-page .error-page-well .error-page-content,
.MuiPopover-root .error-page .error-page-well .error-page-content {
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  color: #4c4c4c;
  border-left: 5px solid #eee;
}
#root .error-page .error-badge h2,
.modal-open .error-page .error-badge h2,
.MuiPopover-root .error-page .error-badge h2 {
  font-size: 96px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 14px;
  color: #4a525a;
  font-weight: 500;
}
#root .error-page .error-page-logo,
.modal-open .error-page .error-page-logo,
.MuiPopover-root .error-page .error-page-logo {
  display: inline-block;
  margin: 20px auto;
}
#root .error-page .error-page-logo path,
.modal-open .error-page .error-page-logo path,
.MuiPopover-root .error-page .error-page-logo path {
  -webkit-transition: fill ease-in-out 0.4s;
  -moz-transition: fill ease-in-out 0.4s;
  -ms-transition: fill ease-in-out 0.4s;
  -o-transition: fill ease-in-out 0.4s;
}
#root .icon-badge,
.modal-open .icon-badge,
.MuiPopover-root .icon-badge {
  background-color: #004bed;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  margin-left: 10px;
}
#root .icon-badge i,
.modal-open .icon-badge i,
.MuiPopover-root .icon-badge i {
  font-size: 10px;
  color: #ffffff;
  margin-top: 5px;
  vertical-align: top;
}
#root .dots-9,
.modal-open .dots-9,
.MuiPopover-root .dots-9 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  clip-path: inset(-45px);
  box-shadow: -60px 15px, -60px 15px, -60px 15px;
  transform: translateY(-15px);
  animation: d9 2s infinite ease-in-out;
  color: #4a525a;
}
#root .dots-9.white,
.modal-open .dots-9.white,
.MuiPopover-root .dots-9.white {
  color: #ffffff;
}
#root .dots-9.dots-button,
.modal-open .dots-9.dots-button,
.MuiPopover-root .dots-9.dots-button {
  transform: scale(0.7) translateY(-15px);
}
#root .dots-9.dots-iconButton,
.modal-open .dots-9.dots-iconButton,
.MuiPopover-root .dots-9.dots-iconButton {
  transform: scale(0.2) translateY(-15px);
}
@keyframes d9 {
  16.67% {
    box-shadow: -60px 15px, -60px 15px, 19px 15px;
  }
  33.33% {
    box-shadow: -60px 15px, 0px 15px, 19px 15px;
  }
  40%,
  60% {
    box-shadow: -19px 15px, 0px 15px, 19px 15px;
  }
  66.67% {
    box-shadow: -19px 15px, 0px 15px, 60px 15px;
  }
  83.33% {
    box-shadow: -19px 15px, 60px 15px, 60px 15px;
  }
  100% {
    box-shadow: 60px 15px, 60px 15px, 60px 15px;
  }
}
#root .global-loader,
.modal-open .global-loader,
.MuiPopover-root .global-loader {
  background: transparent;
  backdrop-filter: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root .global-loader.higher .absolute-center,
.modal-open .global-loader.higher .absolute-center,
.MuiPopover-root .global-loader.higher .absolute-center {
  z-index: 1002;
}
#root .global-loader-container,
.modal-open .global-loader-container,
.MuiPopover-root .global-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #ffffff;
  margin: 1em auto;
}
#root .horizontal-rule,
.modal-open .horizontal-rule,
.MuiPopover-root .horizontal-rule {
  position: relative;
  text-align: center;
  margin: 1px 0;
}
#root .horizontal-rule::before,
.modal-open .horizontal-rule::before,
.MuiPopover-root .horizontal-rule::before {
  content: "";
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: #e5e6e7;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: 0;
}
#root .horizontal-rule .horizontal-rule-label,
.modal-open .horizontal-rule .horizontal-rule-label,
.MuiPopover-root .horizontal-rule .horizontal-rule-label {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  display: inline-block;
  padding: 20px 15px;
  color: #4a525a;
}
#root .horizontal-rule.smaller .horizontal-rule-label,
.modal-open .horizontal-rule.smaller .horizontal-rule-label,
.MuiPopover-root .horizontal-rule.smaller .horizontal-rule-label {
  padding: 10px 15px;
}
#root .horizontal-rule.tight .horizontal-rule-label,
.modal-open .horizontal-rule.tight .horizontal-rule-label,
.MuiPopover-root .horizontal-rule.tight .horizontal-rule-label {
  padding: 5px 15px;
}
#root .horizontal-rule.tss-hr .horizontal-rule-label,
.modal-open .horizontal-rule.tss-hr .horizontal-rule-label,
.MuiPopover-root .horizontal-rule.tss-hr .horizontal-rule-label {
  background-color: #f2f3f5;
}
#root .widget-fieldset .col-sm-2 .field-button,
.modal-open .widget-fieldset .col-sm-2 .field-button,
.MuiPopover-root .widget-fieldset .col-sm-2 .field-button {
  padding: 4px !important;
}
#root .widget-fieldset .col-sm-2 .field-button .btn-children,
.modal-open .widget-fieldset .col-sm-2 .field-button .btn-children,
.MuiPopover-root .widget-fieldset .col-sm-2 .field-button .btn-children {
  font-size: 14px;
  padding-left: 5px;
  line-height: 18.2px;
  margin-bottom: 2px;
}
#root .widget-fieldset .DateRangePickerInput_arrow_svg,
.modal-open .widget-fieldset .DateRangePickerInput_arrow_svg,
.MuiPopover-root .widget-fieldset .DateRangePickerInput_arrow_svg {
  fill: #808080;
}
#root .widget-fieldset .clear-dates-btn,
.modal-open .widget-fieldset .clear-dates-btn,
.MuiPopover-root .widget-fieldset .clear-dates-btn {
  color: #808080 !important;
}
#root .widget-fieldset .field-button.location::after,
.modal-open .widget-fieldset .field-button.location::after,
.MuiPopover-root .widget-fieldset .field-button.location::after {
  right: 14px;
}
#root .question-attendee-header,
.modal-open .question-attendee-header,
.MuiPopover-root .question-attendee-header {
  display: none;
  border-radius: 8px;
}
@media only screen and (max-width : 768px) {
  #root .question-attendee-header,
  .modal-open .question-attendee-header,
  .MuiPopover-root .question-attendee-header {
    display: flex;
    background: #f2f3f5;
    padding: 16px 10px;
    align-items: center;
  }
}
#root .question-attendee-header > .btn,
.modal-open .question-attendee-header > .btn,
.MuiPopover-root .question-attendee-header > .btn {
  margin-right: 12px;
}
#root .question-attendee-header .attendee-wrapper-show,
.modal-open .question-attendee-header .attendee-wrapper-show,
.MuiPopover-root .question-attendee-header .attendee-wrapper-show {
  flex: 1;
}
#root .question-attendee-header .attendee-wrapper-show > .d-flex,
.modal-open .question-attendee-header .attendee-wrapper-show > .d-flex,
.MuiPopover-root .question-attendee-header .attendee-wrapper-show > .d-flex {
  align-items: center;
}
#root .question-attendee-header .question-attendee-header-back,
.modal-open .question-attendee-header .question-attendee-header-back,
.MuiPopover-root .question-attendee-header .question-attendee-header-back {
  margin-bottom: 1px;
}
#root .color-disabled,
.modal-open .color-disabled,
.MuiPopover-root .color-disabled {
  color: rgba(4, 14, 53, 0.4) !important;
}
#root .cart .carousel-root,
.modal-open .cart .carousel-root,
.MuiPopover-root .cart .carousel-root {
  width: 100%;
}
#root .carousel .slide .cart-carousel-item,
.modal-open .carousel .slide .cart-carousel-item,
.MuiPopover-root .carousel .slide .cart-carousel-item {
  text-align: left;
}
#root .carousel .control-arrow,
.modal-open .carousel .control-arrow,
.MuiPopover-root .carousel .control-arrow {
  pointer-events: none;
  display: none;
}
#root .carousel .promo-form-wrap .promo-form,
.modal-open .carousel .promo-form-wrap .promo-form,
.MuiPopover-root .carousel .promo-form-wrap .promo-form {
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-bottom: 10px;
}
#root .carousel .promo-form-wrap .promo-form .field,
.modal-open .carousel .promo-form-wrap .promo-form .field,
.MuiPopover-root .carousel .promo-form-wrap .promo-form .field {
  margin: 0;
  flex: 1;
}
#root .carousel .promo-form-wrap .promo-form input,
.modal-open .carousel .promo-form-wrap .promo-form input,
.MuiPopover-root .carousel .promo-form-wrap .promo-form input {
  padding: 4px 8px;
  font-size: 12px;
  vertical-align: top;
  text-transform: uppercase;
}
#root .carousel .promo-form-wrap .promo-form .btn,
.modal-open .carousel .promo-form-wrap .promo-form .btn,
.MuiPopover-root .carousel .promo-form-wrap .promo-form .btn {
  padding: 4px 12px !important;
  margin-left: 8px;
  font-size: 14px;
  height: 32px;
  vertical-align: top;
}
#root .event-list-well,
.modal-open .event-list-well,
.MuiPopover-root .event-list-well {
  min-height: calc(100vh - 10px*4);
}
#root .disable-fader,
.modal-open .disable-fader,
.MuiPopover-root .disable-fader {
  opacity: 1;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
}
#root .disable-fader.active,
.modal-open .disable-fader.active,
.MuiPopover-root .disable-fader.active {
  opacity: 0.5;
  cursor: progress !important;
}
#root .disable-fader.active > *,
.modal-open .disable-fader.active > *,
.MuiPopover-root .disable-fader.active > * {
  pointer-events: none;
}
#root .payment-method-image,
.modal-open .payment-method-image,
.MuiPopover-root .payment-method-image {
  width: 32px !important;
  box-shadow: 0px 3px 16px 0px #24272b26;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -4px;
}
#root .payment-method-icon,
.modal-open .payment-method-icon,
.MuiPopover-root .payment-method-icon {
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -4px;
  font-size: 1.3rem;
}
#root .header-info-container.has-wrench,
.modal-open .header-info-container.has-wrench,
.MuiPopover-root .header-info-container.has-wrench {
  padding-right: 40px;
}
#root .manage-link,
.modal-open .manage-link,
.MuiPopover-root .manage-link {
  position: absolute;
  top: 0;
  right: 0;
  /* background: blue; */
  width: 50px;
  height: 50px;
  border: solid;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #11a9f0 transparent transparent;
  border-radius: 0 16px 0 0;
  z-index: 0;
}
@media only screen and (max-width : 768px) {
  #root .manage-link,
  .modal-open .manage-link,
  .MuiPopover-root .manage-link {
    border-radius: 0;
  }
}
#root .manage-link i,
.modal-open .manage-link i,
.MuiPopover-root .manage-link i {
  position: absolute;
  right: -40px;
  top: 10px;
  color: #ffffff;
  z-index: 1;
}
#root .manage-link::before,
.modal-open .manage-link::before,
.MuiPopover-root .manage-link::before {
  content: "";
  position: absolute;
  top: 0;
  right: -50px;
  /* background: blue; */
  width: 50px;
  height: 50px;
  border: solid;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 50px;
  border-color: transparent transparent transparent #ffffff;
  z-index: 0;
}
@media only screen and (max-width : 768px) {
  #root .dashboard-section.has-selected-attendee .cart-tab:not(.active),
  .modal-open .dashboard-section.has-selected-attendee .cart-tab:not(.active),
  .MuiPopover-root .dashboard-section.has-selected-attendee .cart-tab:not(.active) {
    display: none;
  }
  #root .dashboard-section.has-selected-attendee .cart-tab.active,
  .modal-open .dashboard-section.has-selected-attendee .cart-tab.active,
  .MuiPopover-root .dashboard-section.has-selected-attendee .cart-tab.active {
    border: 0;
    background-color: #f2f3f5;
    color: #24272b;
    padding-left: 10px;
  }
  #root .dashboard-section.has-selected-attendee .cart-tab.active .btn,
  .modal-open .dashboard-section.has-selected-attendee .cart-tab.active .btn,
  .MuiPopover-root .dashboard-section.has-selected-attendee .cart-tab.active .btn {
    color: #ffffff;
  }
  #root .dashboard-section.has-selected-attendee .cart-tab.active .status-wrapper,
  .modal-open .dashboard-section.has-selected-attendee .cart-tab.active .status-wrapper,
  .MuiPopover-root .dashboard-section.has-selected-attendee .cart-tab.active .status-wrapper {
    display: block;
  }
  #root .dashboard-section.has-selected-attendee .cart-tab.active::before,
  .modal-open .dashboard-section.has-selected-attendee .cart-tab.active::before,
  .MuiPopover-root .dashboard-section.has-selected-attendee .cart-tab.active::before {
    content: "";
    display: inline-block;
    width: 10px;
    right: -10px;
    height: calc(100% + 20px);
    top: -10px;
    position: absolute;
  }
}
@media only screen and (max-width : 768px) {
  #root .dashboard-section .cart-right,
  .modal-open .dashboard-section .cart-right,
  .MuiPopover-root .dashboard-section .cart-right {
    width: 100%;
    padding-left: 0 !important;
  }
}
#root #section-dropdown,
.modal-open #section-dropdown,
.MuiPopover-root #section-dropdown {
  padding: 3px 12px !important;
  font-size: 12px;
  margin-bottom: 2px;
}
#root #section-dropdown::after,
.modal-open #section-dropdown::after,
.MuiPopover-root #section-dropdown::after {
  display: none;
}
#root .account-hero,
.modal-open .account-hero,
.MuiPopover-root .account-hero {
  color: #ffffff;
  padding: 20px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
}
@media only screen and (max-width : 768px) {
  #root .account-hero,
  .modal-open .account-hero,
  .MuiPopover-root .account-hero {
    justify-content: center;
  }
}
#root .lookup-well .well-content,
.modal-open .lookup-well .well-content,
.MuiPopover-root .lookup-well .well-content {
  padding: 0;
  display: flex;
}
@media only screen and (max-width : 768px) {
  #root .lookup-well .well-content,
  .modal-open .lookup-well .well-content,
  .MuiPopover-root .lookup-well .well-content {
    flex-direction: column;
  }
}
#root .lookup-well .well-content .lookup-hero,
.modal-open .lookup-well .well-content .lookup-hero,
.MuiPopover-root .lookup-well .well-content .lookup-hero {
  background: url("https://brushfirecontent.blob.core.windows.net/0000/Media/lookuphero.jpg");
  background-size: cover;
  width: 250px;
  display: flex;
  align-items: flex-end;
  border-radius: 16px 0 0 16px;
}
@media only screen and (max-width : 768px) {
  #root .lookup-well .well-content .lookup-hero,
  .modal-open .lookup-well .well-content .lookup-hero,
  .MuiPopover-root .lookup-well .well-content .lookup-hero {
    width: auto;
    border-radius: 16px 16px 0 0;
  }
}
#root .lookup-well .well-content .lookup-form-wrap,
.modal-open .lookup-well .well-content .lookup-form-wrap,
.MuiPopover-root .lookup-well .well-content .lookup-form-wrap {
  flex: 1;
  padding: 24px;
}
@media only screen and (max-width : 768px) {
  #root .lookup-well .well-content .lookup-form-wrap,
  .modal-open .lookup-well .well-content .lookup-form-wrap,
  .MuiPopover-root .lookup-well .well-content .lookup-form-wrap {
    padding: 12px;
  }
}
@media only screen and (max-width : 768px) {
  #root .your-attendee-header,
  .modal-open .your-attendee-header,
  .MuiPopover-root .your-attendee-header {
    flex-direction: column;
  }
  #root .your-attendee-header > div,
  .modal-open .your-attendee-header > div,
  .MuiPopover-root .your-attendee-header > div {
    display: block;
  }
  #root .your-attendee-header > div:first-child,
  .modal-open .your-attendee-header > div:first-child,
  .MuiPopover-root .your-attendee-header > div:first-child {
    margin-bottom: 2.5px;
  }
  #root .your-attendee-header > div:last-child,
  .modal-open .your-attendee-header > div:last-child,
  .MuiPopover-root .your-attendee-header > div:last-child {
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 -2.5px !important;
  }
  #root .your-attendee-header > div:last-child > a,
  .modal-open .your-attendee-header > div:last-child > a,
  .MuiPopover-root .your-attendee-header > div:last-child > a {
    margin: 2.5px !important;
  }
}
#root .video-wrapper,
.modal-open .video-wrapper,
.MuiPopover-root .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
#root .video-wrapper iframe,
.modal-open .video-wrapper iframe,
.MuiPopover-root .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#root .widget-credit,
.modal-open .widget-credit,
.MuiPopover-root .widget-credit {
  color: #ffffff;
}
#root .widget-credit a,
.modal-open .widget-credit a,
.MuiPopover-root .widget-credit a {
  color: #ffffff;
}
#root .profile-avatar,
.modal-open .profile-avatar,
.MuiPopover-root .profile-avatar {
  border-radius: 32px;
  border: solid 2px #24272b;
  margin-top: 6px;
}
#root .checkout-disclaimer,
.modal-open .checkout-disclaimer,
.MuiPopover-root .checkout-disclaimer {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.08);
  position: sticky;
  bottom: 72px;
}
@media only screen and (max-width : 768px) {
  #root .checkout-disclaimer,
  .modal-open .checkout-disclaimer,
  .MuiPopover-root .checkout-disclaimer {
    display: none;
  }
}
#root .checkout-disclaimer-footer,
.modal-open .checkout-disclaimer-footer,
.MuiPopover-root .checkout-disclaimer-footer {
  display: none;
}
@media only screen and (max-width : 768px) {
  #root .checkout-disclaimer-footer,
  .modal-open .checkout-disclaimer-footer,
  .MuiPopover-root .checkout-disclaimer-footer {
    display: block;
  }
}
#root .payment-icons i,
.modal-open .payment-icons i,
.MuiPopover-root .payment-icons i {
  color: #4a525a;
}
#root .attendee-order-container,
.modal-open .attendee-order-container,
.MuiPopover-root .attendee-order-container {
  display: flex;
  flex-wrap: wrap;
}
#root .attendee-order-container .attendee-order-row,
.modal-open .attendee-order-container .attendee-order-row,
.MuiPopover-root .attendee-order-container .attendee-order-row {
  display: flex;
}
#root .attendee-order-container .attendee-order-row:not(.end),
.modal-open .attendee-order-container .attendee-order-row:not(.end),
.MuiPopover-root .attendee-order-container .attendee-order-row:not(.end) {
  flex: 1;
}
@media only screen and (max-width : 768px) {
  #root .attendee-order-container,
  .modal-open .attendee-order-container,
  .MuiPopover-root .attendee-order-container {
    flex-direction: column;
  }
  #root .attendee-order-container .attendee-order-row.end,
  .modal-open .attendee-order-container .attendee-order-row.end,
  .MuiPopover-root .attendee-order-container .attendee-order-row.end {
    justify-content: flex-end;
  }
}
#root .payment-input-container,
.modal-open .payment-input-container,
.MuiPopover-root .payment-input-container {
  width: 140px;
}
#root .attendee-total-row,
.modal-open .attendee-total-row,
.MuiPopover-root .attendee-total-row {
  padding: 10px;
  border-top: solid 1px #e5e6e7;
}
#root .avatar-container .signout-dropdown,
.modal-open .avatar-container .signout-dropdown,
.MuiPopover-root .avatar-container .signout-dropdown {
  position: fixed;
  right: 0;
  top: 54px;
  display: inline-block;
  width: 160px;
  opacity: 0;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  pointer-events: none;
  padding: 0;
}
#root .avatar-container .signout-dropdown a,
.modal-open .avatar-container .signout-dropdown a,
.MuiPopover-root .avatar-container .signout-dropdown a {
  padding: 20px;
  display: block;
  text-decoration: none;
  color: #ffffff;
  border-bottom: none;
}
#root .avatar-container .signout-dropdown a:hover,
.modal-open .avatar-container .signout-dropdown a:hover,
.MuiPopover-root .avatar-container .signout-dropdown a:hover {
  border-bottom: none;
}
#root .avatar-container:hover .signout-dropdown,
.modal-open .avatar-container:hover .signout-dropdown,
.MuiPopover-root .avatar-container:hover .signout-dropdown {
  opacity: 1;
  pointer-events: all;
}
#root .avatar-container:hover .signout-dropdown:hover,
.modal-open .avatar-container:hover .signout-dropdown:hover,
.MuiPopover-root .avatar-container:hover .signout-dropdown:hover {
  background-color: #4a525a;
}
#root .event-breadcrumb,
.modal-open .event-breadcrumb,
.MuiPopover-root .event-breadcrumb {
  font-size: 12px;
  display: flex;
  justify-items: space-between;
}
#root .event-breadcrumb .pipe,
.modal-open .event-breadcrumb .pipe,
.MuiPopover-root .event-breadcrumb .pipe {
  margin: 0 5px;
}
#root .header-aware-anchor,
.modal-open .header-aware-anchor,
.MuiPopover-root .header-aware-anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
#root .bg-off-white,
.modal-open .bg-off-white,
.MuiPopover-root .bg-off-white {
  background-color: #f2f3f5;
}
#root .venue-map [data-price],
.modal-open .venue-map [data-price],
.MuiPopover-root .venue-map [data-price],
#root .venue-map [data-section],
.modal-open .venue-map [data-section],
.MuiPopover-root .venue-map [data-section],
#root .venue-map [data-typeid],
.modal-open .venue-map [data-typeid],
.MuiPopover-root .venue-map [data-typeid] {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  color: #24272b;
}
#root .venue-map [data-price]:not(.section-soldout):hover,
.modal-open .venue-map [data-price]:not(.section-soldout):hover,
.MuiPopover-root .venue-map [data-price]:not(.section-soldout):hover,
#root .venue-map [data-section]:not(.section-soldout):hover,
.modal-open .venue-map [data-section]:not(.section-soldout):hover,
.MuiPopover-root .venue-map [data-section]:not(.section-soldout):hover,
#root .venue-map [data-typeid]:not(.section-soldout):hover,
.modal-open .venue-map [data-typeid]:not(.section-soldout):hover,
.MuiPopover-root .venue-map [data-typeid]:not(.section-soldout):hover {
  fill: #ffffff;
  opacity: 0.2;
  cursor: pointer;
}
#root .venue-map,
.modal-open .venue-map,
.MuiPopover-root .venue-map {
  overflow: auto;
}
#root .venue-map .alert,
.modal-open .venue-map .alert,
.MuiPopover-root .venue-map .alert,
#root .venue-map #root .alert-primary,
.modal-open .venue-map #root .alert-primary,
.MuiPopover-root .venue-map #root .alert-primary,
#root .venue-map .modal-open .alert-primary,
.modal-open .venue-map .modal-open .alert-primary,
.MuiPopover-root .venue-map .modal-open .alert-primary,
#root .venue-map .MuiPopover-root .alert-primary,
.modal-open .venue-map .MuiPopover-root .alert-primary,
.MuiPopover-root .venue-map .MuiPopover-root .alert-primary,
#root .venue-map #root .alert-secondary,
.modal-open .venue-map #root .alert-secondary,
.MuiPopover-root .venue-map #root .alert-secondary,
#root .venue-map .modal-open .alert-secondary,
.modal-open .venue-map .modal-open .alert-secondary,
.MuiPopover-root .venue-map .modal-open .alert-secondary,
#root .venue-map .MuiPopover-root .alert-secondary,
.modal-open .venue-map .MuiPopover-root .alert-secondary,
.MuiPopover-root .venue-map .MuiPopover-root .alert-secondary,
#root .venue-map #root .alert-success,
.modal-open .venue-map #root .alert-success,
.MuiPopover-root .venue-map #root .alert-success,
#root .venue-map .modal-open .alert-success,
.modal-open .venue-map .modal-open .alert-success,
.MuiPopover-root .venue-map .modal-open .alert-success,
#root .venue-map .MuiPopover-root .alert-success,
.modal-open .venue-map .MuiPopover-root .alert-success,
.MuiPopover-root .venue-map .MuiPopover-root .alert-success,
#root .venue-map #root .alert-warning,
.modal-open .venue-map #root .alert-warning,
.MuiPopover-root .venue-map #root .alert-warning,
#root .venue-map .modal-open .alert-warning,
.modal-open .venue-map .modal-open .alert-warning,
.MuiPopover-root .venue-map .modal-open .alert-warning,
#root .venue-map .MuiPopover-root .alert-warning,
.modal-open .venue-map .MuiPopover-root .alert-warning,
.MuiPopover-root .venue-map .MuiPopover-root .alert-warning,
#root .venue-map #root .alert-danger,
.modal-open .venue-map #root .alert-danger,
.MuiPopover-root .venue-map #root .alert-danger,
#root .venue-map .modal-open .alert-danger,
.modal-open .venue-map .modal-open .alert-danger,
.MuiPopover-root .venue-map .modal-open .alert-danger,
#root .venue-map .MuiPopover-root .alert-danger,
.modal-open .venue-map .MuiPopover-root .alert-danger,
.MuiPopover-root .venue-map .MuiPopover-root .alert-danger,
#root .venue-map #root .alert-info,
.modal-open .venue-map #root .alert-info,
.MuiPopover-root .venue-map #root .alert-info,
#root .venue-map .modal-open .alert-info,
.modal-open .venue-map .modal-open .alert-info,
.MuiPopover-root .venue-map .modal-open .alert-info,
#root .venue-map .MuiPopover-root .alert-info,
.modal-open .venue-map .MuiPopover-root .alert-info,
.MuiPopover-root .venue-map .MuiPopover-root .alert-info,
#root .venue-map #root .alert-dark,
.modal-open .venue-map #root .alert-dark,
.MuiPopover-root .venue-map #root .alert-dark,
#root .venue-map .modal-open .alert-dark,
.modal-open .venue-map .modal-open .alert-dark,
.MuiPopover-root .venue-map .modal-open .alert-dark,
#root .venue-map .MuiPopover-root .alert-dark,
.modal-open .venue-map .MuiPopover-root .alert-dark,
.MuiPopover-root .venue-map .MuiPopover-root .alert-dark,
#root .venue-map #root .alert-light,
.modal-open .venue-map #root .alert-light,
.MuiPopover-root .venue-map #root .alert-light,
#root .venue-map .modal-open .alert-light,
.modal-open .venue-map .modal-open .alert-light,
.MuiPopover-root .venue-map .modal-open .alert-light,
#root .venue-map .MuiPopover-root .alert-light,
.modal-open .venue-map .MuiPopover-root .alert-light,
.MuiPopover-root .venue-map .MuiPopover-root .alert-light {
  padding: 10px;
}
#root .venue-map .section-soldout,
.modal-open .venue-map .section-soldout,
.MuiPopover-root .venue-map .section-soldout {
  cursor: pointer;
  fill: #000000;
  opacity: 0.75;
}
#root .ast-section-header,
.modal-open .ast-section-header,
.MuiPopover-root .ast-section-header {
  align-items: center;
}
#root .ast-section .ast-rows,
.modal-open .ast-section .ast-rows,
.MuiPopover-root .ast-section .ast-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
  opacity: 1;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  -webkit-transition: transform ease-in-out 0.3s;
  -moz-transition: transform ease-in-out 0.3s;
  -ms-transition: transform ease-in-out 0.3s;
  -o-transition: transform ease-in-out 0.3s;
  touch-action: pan-x pan-y;
  transform-origin: 0 0;
}
#root .ast-section .ast-rows.faded,
.modal-open .ast-section .ast-rows.faded,
.MuiPopover-root .ast-section .ast-rows.faded {
  opacity: 0.5;
}
#root .ast-section .ast-rows.faded .seat,
.modal-open .ast-section .ast-rows.faded .seat,
.MuiPopover-root .ast-section .ast-rows.faded .seat {
  pointer-events: none;
}
#root .ast-section .ast-rows .ast-rows-center,
.modal-open .ast-section .ast-rows .ast-rows-center,
.MuiPopover-root .ast-section .ast-rows .ast-rows-center {
  flex: 1;
  display: flex;
}
#root .ast-section .ast-rows .row-wrap,
.modal-open .ast-section .ast-rows .row-wrap,
.MuiPopover-root .ast-section .ast-rows .row-wrap {
  margin-top: 30px;
}
#root .ast-section .ast-rows .ast-row-name,
.modal-open .ast-section .ast-rows .ast-row-name,
.MuiPopover-root .ast-section .ast-rows .ast-row-name {
  height: 28px;
  display: flex;
  padding-top: 6px;
  justify-content: center;
}
#root .ast-section .ast-rows .ast-row-name a,
.modal-open .ast-section .ast-rows .ast-row-name a,
.MuiPopover-root .ast-section .ast-rows .ast-row-name a {
  text-decoration: underline;
  cursor: pointer;
}
#root .ast-section .ast-rows .ast-row,
.modal-open .ast-section .ast-rows .ast-row,
.MuiPopover-root .ast-section .ast-rows .ast-row {
  display: flex;
}
#root .ast-section .ast-rows .ast-row .ast-seat-block,
.modal-open .ast-section .ast-rows .ast-row .ast-seat-block,
.MuiPopover-root .ast-section .ast-rows .ast-row .ast-seat-block {
  padding: 2px;
}
#root .ast-section .ast-rows .ast-row .ast-seat-block:not(:first-child),
.modal-open .ast-section .ast-rows .ast-row .ast-seat-block:not(:first-child),
.MuiPopover-root .ast-section .ast-rows .ast-row .ast-seat-block:not(:first-child) {
  margin-left: -2px;
}
#root .ast-section .ast-rows .ast-row:not(:first-child) .ast-seat-block,
.modal-open .ast-section .ast-rows .ast-row:not(:first-child) .ast-seat-block,
.MuiPopover-root .ast-section .ast-rows .ast-row:not(:first-child) .ast-seat-block {
  margin-top: -2px;
}
#root .ast-section .ast-rows .ast-row .seat,
.modal-open .ast-section .ast-rows .ast-row .seat,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat {
  margin: 0px;
  border: solid 1px #24272b;
  border-radius: 3px;
  display: flex;
  width: 26px !important;
  height: 26px !important;
  font-size: 9px;
  font-weight: 700;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
#root .ast-section .ast-rows .ast-row .seat.empty-seat,
.modal-open .ast-section .ast-rows .ast-row .seat.empty-seat,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.empty-seat {
  border-color: transparent;
  cursor: default;
}
#root .ast-section .ast-rows .ast-row .seat:hover:not(.empty-seat, .unavailable),
.modal-open .ast-section .ast-rows .ast-row .seat:hover:not(.empty-seat, .unavailable),
.MuiPopover-root .ast-section .ast-rows .ast-row .seat:hover:not(.empty-seat, .unavailable) {
  opacity: 0.8;
}
#root .ast-section .ast-rows .ast-row .seat:hover:not(.empty-seat, .unavailable):not(.my-seat),
.modal-open .ast-section .ast-rows .ast-row .seat:hover:not(.empty-seat, .unavailable):not(.my-seat),
.MuiPopover-root .ast-section .ast-rows .ast-row .seat:hover:not(.empty-seat, .unavailable):not(.my-seat) {
  background-color: rgba(255, 255, 255, 0.2);
}
#root .ast-section .ast-rows .ast-row .seat.unavailable,
.modal-open .ast-section .ast-rows .ast-row .seat.unavailable,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.unavailable {
  border-color: #767e89;
  opacity: 0.75;
  cursor: not-allowed;
}
#root .ast-section .ast-rows .ast-row .seat.unavailable::before,
.modal-open .ast-section .ast-rows .ast-row .seat.unavailable::before,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.unavailable::before {
  content: "";
  width: 26px;
  height: 26px;
  position: absolute;
  background-color: black;
  opacity: 0.75;
  display: inline-block;
  z-index: 0;
}
#root .ast-section .ast-rows .ast-row .seat.filtered:not(.selected, .my-seat, .unavailable, .empty-seat)::before,
.modal-open .ast-section .ast-rows .ast-row .seat.filtered:not(.selected, .my-seat, .unavailable, .empty-seat)::before,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.filtered:not(.selected, .my-seat, .unavailable, .empty-seat)::before {
  content: "";
  width: 26px;
  height: 26px;
  position: absolute;
  background-color: black;
  opacity: 0.25;
  display: inline-block;
  z-index: 0;
}
#root .ast-section .ast-rows .ast-row .seat.selected,
.modal-open .ast-section .ast-rows .ast-row .seat.selected,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.selected {
  background-color: #20bf55;
  border-color: #20bf55;
  color: #ffffff;
}
#root .ast-section .ast-rows .ast-row .seat.my-seat,
.modal-open .ast-section .ast-rows .ast-row .seat.my-seat,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.my-seat {
  background-color: #20bf55;
  border-color: #20bf55;
  color: #ffffff;
  opacity: 1;
}
#root .ast-section .ast-rows .ast-row .seat i,
.modal-open .ast-section .ast-rows .ast-row .seat i,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat i {
  font-size: 12px;
}
#root .ast-section .ast-rows .ast-row .seat.locked i.fa-lock,
.modal-open .ast-section .ast-rows .ast-row .seat.locked i.fa-lock,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.locked i.fa-lock {
  position: absolute;
  left: -3px;
  bottom: -3px;
  background: #24272b;
  padding: 3px;
  border-radius: 20px;
  font-size: 6.5px;
  color: #ffffff;
}
#root .ast-section .ast-rows .ast-row .seat.locked::before,
.modal-open .ast-section .ast-rows .ast-row .seat.locked::before,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.locked::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  background-image: repeating-linear-gradient(-45deg, transparent 0 1.5px, #4a525a 1.5px 3px);
  display: inline-block;
  z-index: 0;
}
#root .ast-section .ast-rows .ast-row .seat.locked span,
.modal-open .ast-section .ast-rows .ast-row .seat.locked span,
.MuiPopover-root .ast-section .ast-rows .ast-row .seat.locked span {
  z-index: 1;
  position: relative;
}
#root .ast-section .aisle-after,
.modal-open .ast-section .aisle-after,
.MuiPopover-root .ast-section .aisle-after {
  margin-bottom: 26px !important;
}
#root .ast-section .row-header,
.modal-open .ast-section .row-header,
.MuiPopover-root .ast-section .row-header {
  height: 30px;
  font-weight: bold;
}
#root .ast-section .stage,
.modal-open .ast-section .stage,
.MuiPopover-root .ast-section .stage {
  max-width: 100px;
}
#root .ast-section .stage.stage-bottom,
.modal-open .ast-section .stage.stage-bottom,
.MuiPopover-root .ast-section .stage.stage-bottom {
  margin-top: 10px;
}
#root .ast-filters,
.modal-open .ast-filters,
.MuiPopover-root .ast-filters {
  padding-right: 10px;
}
#root .ast-filters > .well,
.modal-open .ast-filters > .well,
.MuiPopover-root .ast-filters > .well {
  position: sticky;
  top: 10px;
}
#root .ast-filters .form-counter,
.modal-open .ast-filters .form-counter,
.MuiPopover-root .ast-filters .form-counter {
  justify-content: center;
}
#root .well-inset,
.modal-open .well-inset,
.MuiPopover-root .well-inset {
  padding: 0 !important;
}
#root .well-inset .well-sidebar,
.modal-open .well-inset .well-sidebar,
.MuiPopover-root .well-inset .well-sidebar {
  padding-right: 10px;
  margin: 0;
}
#root .well.ast-color-block,
.modal-open .well.ast-color-block,
.MuiPopover-root .well.ast-color-block {
  box-shadow: none;
  border-radius: 0;
  border-top: 2px solid #e5e6e7;
}
#root .well.ast-color-block > .well-content,
.modal-open .well.ast-color-block > .well-content,
.MuiPopover-root .well.ast-color-block > .well-content {
  padding: 0 !important;
  display: flex;
}
#root .well.ast-color-block > .well-content .bar,
.modal-open .well.ast-color-block > .well-content .bar,
.MuiPopover-root .well.ast-color-block > .well-content .bar {
  width: 5px;
  margin-right: 10px;
}
#root .well.ast-color-block > .well-content > div:not(.bar),
.modal-open .well.ast-color-block > .well-content > div:not(.bar),
.MuiPopover-root .well.ast-color-block > .well-content > div:not(.bar) {
  flex: 1;
  padding: 10px 0;
}
#root .well.ast-color-block > .well-content h6,
.modal-open .well.ast-color-block > .well-content h6,
.MuiPopover-root .well.ast-color-block > .well-content h6 {
  margin: 2.5px 0;
}
#root .well.ast-color-block > .well-content h6:first-child,
.modal-open .well.ast-color-block > .well-content h6:first-child,
.MuiPopover-root .well.ast-color-block > .well-content h6:first-child {
  flex: 1;
}
#root .well.ast-color-block > .well-content h6 .icon-btn,
.modal-open .well.ast-color-block > .well-content h6 .icon-btn,
.MuiPopover-root .well.ast-color-block > .well-content h6 .icon-btn {
  display: inline-flex;
  vertical-align: top;
  margin-left: 2px;
}
#root .well.ast-color-block:last-child,
.modal-open .well.ast-color-block:last-child,
.MuiPopover-root .well.ast-color-block:last-child {
  border-radius: 0 0 8px 8px;
}
#root .well.ast-color-block:last-child .bar,
.modal-open .well.ast-color-block:last-child .bar,
.MuiPopover-root .well.ast-color-block:last-child .bar {
  border-radius: 0 0 0 8px;
}
#root .instructions,
.modal-open .instructions,
.MuiPopover-root .instructions {
  text-align: center;
  margin-bottom: 0;
  font-size: 75%;
}
#root .seat-selection-container,
.modal-open .seat-selection-container,
.MuiPopover-root .seat-selection-container {
  padding: 20px;
}
@media screen and (max-width: 770px) {
  #root .seat-selection-container,
  .modal-open .seat-selection-container,
  .MuiPopover-root .seat-selection-container {
    padding: 20px 0 20px 0;
  }
}
#root .ast-coachmark,
.modal-open .ast-coachmark,
.MuiPopover-root .ast-coachmark {
  position: absolute;
  background: white;
  box-shadow: 0px 3px 16px 0px #24272b26;
  padding: 20px;
  z-index: 9;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 3px;
}
#root .ast-coachmark.swipe i,
.modal-open .ast-coachmark.swipe i,
.MuiPopover-root .ast-coachmark.swipe i {
  -webkit-animation: Swipe 1s 2s linear infinite forwards;
  -moz-animation: Swipe 1s 2s linear infinite forwards;
  -ms-animation: Swipe 1s 2s linear infinite forwards;
  animation: Swipe 1s 2s linear infinite forwards;
}
#root .ast-coachmark:not(.swipe) i,
.modal-open .ast-coachmark:not(.swipe) i,
.MuiPopover-root .ast-coachmark:not(.swipe) i {
  -webkit-animation: Pan 1s 2s linear infinite forwards;
  -moz-animation: Pan 1s 2s linear infinite forwards;
  -ms-animation: Pan 1s 2s linear infinite forwards;
  animation: Pan 1s 2s linear infinite forwards;
}
#root .area-header,
.modal-open .area-header,
.MuiPopover-root .area-header {
  width: 100%;
}
#root .area-header,
.modal-open .area-header,
.MuiPopover-root .area-header {
  max-width: 1024px;
}
#root .area-banner,
.modal-open .area-banner,
.MuiPopover-root .area-banner {
  border-radius: 16px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
}
#root .area-banner > img,
.modal-open .area-banner > img,
.MuiPopover-root .area-banner > img,
#root .area-banner > a > img,
.modal-open .area-banner > a > img,
.MuiPopover-root .area-banner > a > img {
  width: 100%;
}
#root .header-logo-image,
.modal-open .header-logo-image,
.MuiPopover-root .header-logo-image {
  max-width: 300px !important;
  max-height: 100px !important;
}
#root html.dark .area-banner,
.modal-open html.dark .area-banner,
.MuiPopover-root html.dark .area-banner {
  border-radius: 16px;
  overflow: hidden;
  background-color: #24272b;
}
#root .queue-dashboard,
.modal-open .queue-dashboard,
.MuiPopover-root .queue-dashboard {
  overflow: hidden;
}
#root .queue-dashboard nav,
.modal-open .queue-dashboard nav,
.MuiPopover-root .queue-dashboard nav {
  background-color: #004bed;
  left: 0;
  bottom: 0;
  width: 300px;
  height: calc(100vh - 100px);
  border-radius: 0 40px 0 0;
  padding: 24px 24px 24px 0;
  -webkit-transition: left ease-in-out 0.4s;
  -moz-transition: left ease-in-out 0.4s;
  -ms-transition: left ease-in-out 0.4s;
  -o-transition: left ease-in-out 0.4s;
}
@media only screen and (max-width : 1024px) {
  #root .queue-dashboard nav,
  .modal-open .queue-dashboard nav,
  .MuiPopover-root .queue-dashboard nav {
    left: -100vw;
    width: 100vw;
    border-radius: 0;
    height: 100vh;
    z-index: 99999;
  }
}
#root .queue-dashboard nav ul,
.modal-open .queue-dashboard nav ul,
.MuiPopover-root .queue-dashboard nav ul {
  list-style: none;
  padding: 0;
}
#root .queue-dashboard nav ul li,
.modal-open .queue-dashboard nav ul li,
.MuiPopover-root .queue-dashboard nav ul li {
  padding: 0;
  margin-top: 10px;
}
#root .queue-dashboard nav ul li:first-child,
.modal-open .queue-dashboard nav ul li:first-child,
.MuiPopover-root .queue-dashboard nav ul li:first-child {
  margin-top: 0;
}
#root .queue-dashboard nav ul li a,
.modal-open .queue-dashboard nav ul li a,
.MuiPopover-root .queue-dashboard nav ul li a {
  display: block;
  text-decoration: none;
}
#root .queue-dashboard nav ul li a span,
.modal-open .queue-dashboard nav ul li a span,
.MuiPopover-root .queue-dashboard nav ul li a span {
  display: block;
  font-size: 20px;
  color: #ffffff;
  padding: 5px 24px;
  border-radius: 0 36px 36px 0;
}
#root .queue-dashboard nav ul li a span.active,
.modal-open .queue-dashboard nav ul li a span.active,
.MuiPopover-root .queue-dashboard nav ul li a span.active {
  background-color: #ffffff;
  color: #004bed;
}
#root .queue-dashboard .page-body,
.modal-open .queue-dashboard .page-body,
.MuiPopover-root .queue-dashboard .page-body {
  padding: 0 24px 24px 24px;
  max-width: none !important;
  -webkit-transition: margin ease-in-out 0.4s;
  -moz-transition: margin ease-in-out 0.4s;
  -ms-transition: margin ease-in-out 0.4s;
  -o-transition: margin ease-in-out 0.4s;
}
@media only screen and (max-width : 1024px) {
  #root .queue-dashboard .page-body,
  .modal-open .queue-dashboard .page-body,
  .MuiPopover-root .queue-dashboard .page-body {
    padding-left: 24px;
  }
}
#root .queue-dashboard.show-nav nav,
.modal-open .queue-dashboard.show-nav nav,
.MuiPopover-root .queue-dashboard.show-nav nav {
  left: 0;
}
#root .queue-dashboard.show-nav .page-body,
.modal-open .queue-dashboard.show-nav .page-body,
.MuiPopover-root .queue-dashboard.show-nav .page-body {
  margin-left: 100vw;
}
#root .queue-dashboard header,
.modal-open .queue-dashboard header,
.MuiPopover-root .queue-dashboard header {
  height: 100px;
  width: 300px;
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#root .queue-dashboard header .queue-dashboard-logo,
.modal-open .queue-dashboard header .queue-dashboard-logo,
.MuiPopover-root .queue-dashboard header .queue-dashboard-logo {
  width: 200px;
  margin-left: 20px;
}
#root .queue-dashboard header .hamburger,
.modal-open .queue-dashboard header .hamburger,
.MuiPopover-root .queue-dashboard header .hamburger {
  margin-left: 20px;
  display: none;
}
@media only screen and (max-width : 1024px) {
  #root .queue-dashboard header .hamburger,
  .modal-open .queue-dashboard header .hamburger,
  .MuiPopover-root .queue-dashboard header .hamburger {
    display: inline-flex;
  }
}
#root .queue-dashboard .header-right,
.modal-open .queue-dashboard .header-right,
.MuiPopover-root .queue-dashboard .header-right {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
#root .queue-dashboard .avatar,
.modal-open .queue-dashboard .avatar,
.MuiPopover-root .queue-dashboard .avatar {
  width: 40px;
  height: 40px;
  padding: 2px;
  margin-left: 5px;
  border: solid 2px #24272b;
  border-radius: 20px;
  overflow: hidden;
}
#root .queue-dashboard .avatar img,
.modal-open .queue-dashboard .avatar img,
.MuiPopover-root .queue-dashboard .avatar img {
  border-radius: 20px;
  overflow: hidden;
}
#root .queue-dashboard .dataview-dropdown.no-icon .dropdown-toggle::after,
.modal-open .queue-dashboard .dataview-dropdown.no-icon .dropdown-toggle::after,
.MuiPopover-root .queue-dashboard .dataview-dropdown.no-icon .dropdown-toggle::after {
  content: none;
}
#root .queue-dashboard .dataview-dropdown .fa-bolt-lightning,
.modal-open .queue-dashboard .dataview-dropdown .fa-bolt-lightning,
.MuiPopover-root .queue-dashboard .dataview-dropdown .fa-bolt-lightning {
  color: #20bf55;
}
#root .queue-dashboard .dataview-dropdown .fa-clock,
.modal-open .queue-dashboard .dataview-dropdown .fa-clock,
.MuiPopover-root .queue-dashboard .dataview-dropdown .fa-clock {
  color: #11a9f0;
}
#root .queue-dashboard .dataview-dropdown .trigger,
.modal-open .queue-dashboard .dataview-dropdown .trigger,
.MuiPopover-root .queue-dashboard .dataview-dropdown .trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 0px 3px 16px 0px #24272b26;
  overflow: auto;
  margin-right: 10px;
}
#root .queue-dashboard .dataview-dropdown .trigger:hover,
.modal-open .queue-dashboard .dataview-dropdown .trigger:hover,
.MuiPopover-root .queue-dashboard .dataview-dropdown .trigger:hover {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15) !important;
  background-color: #ffffff !important;
  opacity: 0.75 !important;
}
#root .queue-dashboard .dataview-dropdown .trigger b,
.modal-open .queue-dashboard .dataview-dropdown .trigger b,
.MuiPopover-root .queue-dashboard .dataview-dropdown .trigger b {
  margin: 0 20px;
}
#root .queue-dashboard .dataview-dropdown .trigger .fa-bolt-lightning,
.modal-open .queue-dashboard .dataview-dropdown .trigger .fa-bolt-lightning,
.MuiPopover-root .queue-dashboard .dataview-dropdown .trigger .fa-bolt-lightning {
  color: #20bf55;
}
#root .queue-dashboard .dataview-dropdown .menu,
.modal-open .queue-dashboard .dataview-dropdown .menu,
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu {
  background-color: #ffffff;
  margin-top: 10px;
  box-shadow: 0px 3px 16px 0px #24272b26;
  border-radius: 20px;
  overflow: hidden;
}
#root .queue-dashboard .dataview-dropdown .menu .item,
.modal-open .queue-dashboard .dataview-dropdown .menu .item,
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
}
#root .queue-dashboard .dataview-dropdown .menu .item:not(:last-child),
.modal-open .queue-dashboard .dataview-dropdown .menu .item:not(:last-child),
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu .item:not(:last-child) {
  border-bottom: 1px solid #e5e6e7;
}
#root .queue-dashboard .dataview-dropdown .menu .item b,
.modal-open .queue-dashboard .dataview-dropdown .menu .item b,
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu .item b {
  margin-right: 15px;
}
#root .queue-dashboard .dataview-dropdown .menu .item i.fas,
.modal-open .queue-dashboard .dataview-dropdown .menu .item i.fas,
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu .item i.fas {
  margin-right: 5px;
}
#root .queue-dashboard .dataview-dropdown .menu .item input,
.modal-open .queue-dashboard .dataview-dropdown .menu .item input,
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu .item input {
  margin-right: 15px;
  border: none;
}
#root .queue-dashboard .dataview-dropdown .menu .item .fa-caret-right,
.modal-open .queue-dashboard .dataview-dropdown .menu .item .fa-caret-right,
.MuiPopover-root .queue-dashboard .dataview-dropdown .menu .item .fa-caret-right {
  margin-left: auto;
}
#root .queue-dashboard .user-chart,
.modal-open .queue-dashboard .user-chart,
.MuiPopover-root .queue-dashboard .user-chart {
  background: #f2f3f5;
  width: calc(100% - 48px);
  height: 100vh;
  position: relative;
}
#root .queue-dashboard .user-chart .user-dot,
.modal-open .queue-dashboard .user-chart .user-dot,
.MuiPopover-root .queue-dashboard .user-chart .user-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #e71d36;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
}
#root .queue-dashboard .user-chart .user-dot.connected,
.modal-open .queue-dashboard .user-chart .user-dot.connected,
.MuiPopover-root .queue-dashboard .user-chart .user-dot.connected {
  background-color: #20bf55;
}
#root .queue-dashboard .user-chart .user-dot.new,
.modal-open .queue-dashboard .user-chart .user-dot.new,
.MuiPopover-root .queue-dashboard .user-chart .user-dot.new {
  background-color: #facf43;
}
#root .queue-dashboard .user-chart .user-dot.stale,
.modal-open .queue-dashboard .user-chart .user-dot.stale,
.MuiPopover-root .queue-dashboard .user-chart .user-dot.stale {
  background-color: #b0b8bf;
}
#root .queue-dashboard .user-chart .user-dot.stale.new,
.modal-open .queue-dashboard .user-chart .user-dot.stale.new,
.MuiPopover-root .queue-dashboard .user-chart .user-dot.stale.new {
  background-color: #4a525a;
}
#root .queue-dashboard .user-chart .user-dot.pulse,
.modal-open .queue-dashboard .user-chart .user-dot.pulse,
.MuiPopover-root .queue-dashboard .user-chart .user-dot.pulse {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
#root .queue-dashboard .user-chart .vertical-line,
.modal-open .queue-dashboard .user-chart .vertical-line,
.MuiPopover-root .queue-dashboard .user-chart .vertical-line {
  position: absolute;
  width: 1px;
  background: black;
  height: 100%;
  left: calc(100%/7 - 1px);
}
#root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 1),
.modal-open .queue-dashboard .user-chart .vertical-line:nth-child(7n + 1),
.MuiPopover-root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 1) {
  left: calc(100%*1/7 - 1px);
}
#root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 2),
.modal-open .queue-dashboard .user-chart .vertical-line:nth-child(7n + 2),
.MuiPopover-root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 2) {
  left: calc(100%*2/7 - 1px);
}
#root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 3),
.modal-open .queue-dashboard .user-chart .vertical-line:nth-child(7n + 3),
.MuiPopover-root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 3) {
  left: calc(100%*3/7 - 1px);
}
#root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 4),
.modal-open .queue-dashboard .user-chart .vertical-line:nth-child(7n + 4),
.MuiPopover-root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 4) {
  left: calc(100%*4/7 - 1px);
}
#root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 5),
.modal-open .queue-dashboard .user-chart .vertical-line:nth-child(7n + 5),
.MuiPopover-root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 5) {
  left: calc(100%*5/7 - 1px);
}
#root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 6),
.modal-open .queue-dashboard .user-chart .vertical-line:nth-child(7n + 6),
.MuiPopover-root .queue-dashboard .user-chart .vertical-line:nth-child(7n + 6) {
  left: calc(100%*6/7 - 1px);
}
#root .queue-dashboard .live-graph,
.modal-open .queue-dashboard .live-graph,
.MuiPopover-root .queue-dashboard .live-graph {
  position: relative;
  height: 400px;
}
#root .queue-dashboard .live-graph .recharts-responsive-container,
.modal-open .queue-dashboard .live-graph .recharts-responsive-container,
.MuiPopover-root .queue-dashboard .live-graph .recharts-responsive-container {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
}
#root .queue-dashboard .live-graph .recharts-responsive-container:first-child,
.modal-open .queue-dashboard .live-graph .recharts-responsive-container:first-child,
.MuiPopover-root .queue-dashboard .live-graph .recharts-responsive-container:first-child {
  z-index: 5;
}
#root .queue-dashboard .live-graph .arrow,
.modal-open .queue-dashboard .live-graph .arrow,
.MuiPopover-root .queue-dashboard .live-graph .arrow {
  cursor: pointer;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  box-shadow: 0px 3px 16px 0px #24272b26;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all ease-in-out 0.05s;
  -moz-transition: all ease-in-out 0.05s;
  -ms-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
}
#root .queue-dashboard .live-graph .arrow:hover,
.modal-open .queue-dashboard .live-graph .arrow:hover,
.MuiPopover-root .queue-dashboard .live-graph .arrow:hover {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
#root .queue-dashboard .live-graph .arrow:active,
.modal-open .queue-dashboard .live-graph .arrow:active,
.MuiPopover-root .queue-dashboard .live-graph .arrow:active {
  opacity: 0.7;
}
#root .queue-dashboard .live-graph .arrow-left,
.modal-open .queue-dashboard .live-graph .arrow-left,
.MuiPopover-root .queue-dashboard .live-graph .arrow-left {
  size: 10px;
  z-index: 500;
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateX(3px);
  -moz-transform: translateX(3px);
  -ms-transform: translateX(3px);
  -o-transform: translateX(3px);
}
#root .queue-dashboard .live-graph .arrow-right,
.modal-open .queue-dashboard .live-graph .arrow-right,
.MuiPopover-root .queue-dashboard .live-graph .arrow-right {
  z-index: 500;
  position: absolute;
  right: -30px;
  top: 50%;
  -webkit-transform: translateX(-3px);
  -moz-transform: translateX(-3px);
  -ms-transform: translateX(-3px);
  -o-transform: translateX(-3px);
}
#root .queue-dashboard .live-graph .data-loading,
.modal-open .queue-dashboard .live-graph .data-loading,
.MuiPopover-root .queue-dashboard .live-graph .data-loading {
  -webkit-transition: all ease-in-out 0.05s;
  -moz-transition: all ease-in-out 0.05s;
  -ms-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
  position: absolute;
  z-index: 501;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#root .queue-dashboard .well h1,
.modal-open .queue-dashboard .well h1,
.MuiPopover-root .queue-dashboard .well h1 {
  font-size: 96px;
  color: #24272b;
  font-weight: 700;
}
#root .queue-dashboard .well h3,
.modal-open .queue-dashboard .well h3,
.MuiPopover-root .queue-dashboard .well h3 {
  font-size: 24px;
  color: #4a525a;
}
#root .queue-dashboard .well h5,
.modal-open .queue-dashboard .well h5,
.MuiPopover-root .queue-dashboard .well h5 {
  font-size: 18px;
  color: #b0b8bf;
}
#root .queue-dashboard .status-button,
.modal-open .queue-dashboard .status-button,
.MuiPopover-root .queue-dashboard .status-button {
  border: none;
  -webkit-appearance: none;
  box-shadow: 0px 3px 16px 0px #24272b26;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 2px 10px;
}
#root .queue-dashboard .status-button .status-button-dot,
.modal-open .queue-dashboard .status-button .status-button-dot,
.MuiPopover-root .queue-dashboard .status-button .status-button-dot {
  background-color: #b0b8bf;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
#root .queue-dashboard .status-button .status-button-dot.waiting,
.modal-open .queue-dashboard .status-button .status-button-dot.waiting,
.MuiPopover-root .queue-dashboard .status-button .status-button-dot.waiting {
  background-color: #facf43;
}
#root .queue-dashboard .status-button .status-button-dot.on,
.modal-open .queue-dashboard .status-button .status-button-dot.on,
.MuiPopover-root .queue-dashboard .status-button .status-button-dot.on {
  background-color: #20bf55;
}
#root .queue-dashboard .status-button .status-button-text,
.modal-open .queue-dashboard .status-button .status-button-text,
.MuiPopover-root .queue-dashboard .status-button .status-button-text {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
}
#root .flame-logo path,
.modal-open .flame-logo path,
.MuiPopover-root .flame-logo path {
  fill: #e5e6e7;
  -webkit-animation: flicker 1.5s infinite ease-in-out;
  -moz-animation: flicker 1.5s infinite ease-in-out;
  -o-animation: flicker 1.5s infinite ease-in-out;
  animation: flicker 1.5s infinite ease-in-out;
}
#root .flame-logo path:nth-child(3n + 0),
.modal-open .flame-logo path:nth-child(3n + 0),
.MuiPopover-root .flame-logo path:nth-child(3n + 0) {
  animation-delay: 0.5s;
}
#root .flame-logo path:nth-child(3n + 2),
.modal-open .flame-logo path:nth-child(3n + 2),
.MuiPopover-root .flame-logo path:nth-child(3n + 2) {
  animation-delay: 1s;
}
@-webkit-keyframes flicker {
  0% {
    fill: #e5e6e7;
  }
  25% {
    fill: #b0b8bf;
  }
  50% {
    fill: #e5e6e7;
  }
  100% {
    fill: #e5e6e7;
  }
}
@-moz-keyframes flicker {
  0% {
    fill: #e5e6e7;
  }
  25% {
    fill: #b0b8bf;
  }
  50% {
    fill: #e5e6e7;
  }
  100% {
    fill: #e5e6e7;
  }
}
@-o-keyframes flicker {
  0% {
    fill: #e5e6e7;
  }
  25% {
    fill: #b0b8bf;
  }
  50% {
    fill: #e5e6e7;
  }
  100% {
    fill: #e5e6e7;
  }
}
@keyframes flicker {
  0% {
    fill: #e5e6e7;
  }
  25% {
    fill: #b0b8bf;
  }
  50% {
    fill: #e5e6e7;
  }
  100% {
    fill: #e5e6e7;
  }
}
#root .footer-nav,
.modal-open .footer-nav,
.MuiPopover-root .footer-nav {
  display: flex;
  flex-direction: row;
  color: #ffffff !important;
  background-color: #24272b;
  width: 100%;
  position: relative;
}
@media only screen and (max-width : 768px) {
  #root #google_translate_element,
  .modal-open #google_translate_element,
  .MuiPopover-root #google_translate_element {
    margin-top: 0;
  }
}
#root .footer-left,
.modal-open .footer-left,
.MuiPopover-root .footer-left {
  flex-direction: column;
  width: calc(50% - 18px);
  padding-left: 20px;
}
#root .footer-left a,
.modal-open .footer-left a,
.MuiPopover-root .footer-left a {
  color: #ffffff !important;
  text-decoration: none;
}
#root .footer-logo-container,
.modal-open .footer-logo-container,
.MuiPopover-root .footer-logo-container {
  display: block;
  width: 50%;
}
#root .footer-logo-container img,
.modal-open .footer-logo-container img,
.MuiPopover-root .footer-logo-container img {
  margin-top: 16px;
  width: 171px !important;
}
#root .footer-right,
.modal-open .footer-right,
.MuiPopover-root .footer-right {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 100px;
  justify-content: flex-end;
  text-align: center;
  padding-right: 24px;
  flex-flow: row wrap;
}
#root .footer-right .account-wrap,
.modal-open .footer-right .account-wrap,
.MuiPopover-root .footer-right .account-wrap {
  flex: 0 0 100%;
  text-align: right;
  display: inline-flex;
  justify-content: flex-end;
  margin-top: 5px;
}
#root .footer-right .account-wrap > a,
.modal-open .footer-right .account-wrap > a,
.MuiPopover-root .footer-right .account-wrap > a {
  display: inline-flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  text-decoration: none;
}
#root .queue-view,
.modal-open .queue-view,
.MuiPopover-root .queue-view {
  min-height: calc(100vh - 93px);
  padding: 0 10px;
}
#root .queue-status,
.modal-open .queue-status,
.MuiPopover-root .queue-status {
  height: 5px;
  margin: 10px 0;
  background: #e5e6e7;
  border-radius: 5px;
  position: relative;
}
#root .queue-status .queue-status-indicator,
.modal-open .queue-status .queue-status-indicator,
.MuiPopover-root .queue-status .queue-status-indicator {
  position: absolute;
  height: 10px;
  border-radius: 5px;
  top: -2.5px;
  left: 0;
  background-color: #004bed;
  -webkit-transition: all ease-in-out 0.75s;
  -moz-transition: all ease-in-out 0.75s;
  -ms-transition: all ease-in-out 0.75s;
  -o-transition: all ease-in-out 0.75s;
}
#root .flame-circle,
.modal-open .flame-circle,
.MuiPopover-root .flame-circle {
  display: inline-block;
  box-shadow: 0px 3px 16px 0px #24272b26;
  background-color: #ffffff;
  width: 120px;
  height: 120px;
  border-radius: 120px;
}
#root .horizontal-bars,
.modal-open .horizontal-bars,
.MuiPopover-root .horizontal-bars {
  display: block;
  height: 30px;
}
#root .horizontal-bars > .horizontal-bar,
.modal-open .horizontal-bars > .horizontal-bar,
.MuiPopover-root .horizontal-bars > .horizontal-bar {
  -webkit-transition: background-color linear 1.5s;
  -moz-transition: background-color linear 1.5s;
  -ms-transition: background-color linear 1.5s;
  -o-transition: background-color linear 1.5s;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #ffffff;
}
#root .well.queue-on,
.modal-open .well.queue-on,
.MuiPopover-root .well.queue-on {
  background-color: #004bed;
}
#root .well.queue-on *,
.modal-open .well.queue-on *,
.MuiPopover-root .well.queue-on * {
  color: #ffffff;
}
#root .well.queue-on h4,
.modal-open .well.queue-on h4,
.MuiPopover-root .well.queue-on h4 {
  color: #ffffff;
}
#root .well.queue-on h5,
.modal-open .well.queue-on h5,
.MuiPopover-root .well.queue-on h5 {
  color: #ffffff;
}
#root .well.queue-on.paused,
.modal-open .well.queue-on.paused,
.MuiPopover-root .well.queue-on.paused {
  background-color: #e71d36;
}
#root .well.queue-forced,
.modal-open .well.queue-forced,
.MuiPopover-root .well.queue-forced {
  background-color: #20bf55;
}
#root .well.queue-forced *,
.modal-open .well.queue-forced *,
.MuiPopover-root .well.queue-forced * {
  color: #ffffff;
}
#root .well.queue-forced h4,
.modal-open .well.queue-forced h4,
.MuiPopover-root .well.queue-forced h4 {
  color: #ffffff;
}
#root .well.queue-forced h5,
.modal-open .well.queue-forced h5,
.MuiPopover-root .well.queue-forced h5 {
  color: #ffffff;
}
#root .well.queue-forced.paused,
.modal-open .well.queue-forced.paused,
.MuiPopover-root .well.queue-forced.paused {
  background-color: #e71d36;
}
#root .well.queue-auto,
.modal-open .well.queue-auto,
.MuiPopover-root .well.queue-auto {
  background: #ffffff;
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 50%, #004bed 50%, #004bed 100%);
}
#root .queue-status-maintenance i,
.modal-open .queue-status-maintenance i,
.MuiPopover-root .queue-status-maintenance i,
#root .queue-status-maintenance h4,
.modal-open .queue-status-maintenance h4,
.MuiPopover-root .queue-status-maintenance h4 {
  color: #e71d36;
}
#root .queue-status-auto i,
.modal-open .queue-status-auto i,
.MuiPopover-root .queue-status-auto i,
#root .queue-status-auto h4,
.modal-open .queue-status-auto h4,
.MuiPopover-root .queue-status-auto h4 {
  color: #004bed;
}
#root .queue-status-on i,
.modal-open .queue-status-on i,
.MuiPopover-root .queue-status-on i,
#root .queue-status-on h4,
.modal-open .queue-status-on h4,
.MuiPopover-root .queue-status-on h4 {
  color: #20bf55;
}
#root .queue-status-on.paused i,
.modal-open .queue-status-on.paused i,
.MuiPopover-root .queue-status-on.paused i,
#root .queue-status-on.paused h4,
.modal-open .queue-status-on.paused h4,
.MuiPopover-root .queue-status-on.paused h4 {
  color: #e71d36;
}
#root .queue-status-off i,
.modal-open .queue-status-off i,
.MuiPopover-root .queue-status-off i,
#root .queue-status-off h4,
.modal-open .queue-status-off h4,
.MuiPopover-root .queue-status-off h4 {
  color: #b0b8bf;
}
#root .settings-link,
.modal-open .settings-link,
.MuiPopover-root .settings-link {
  position: absolute;
  top: 23px;
  right: 75px;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -ms-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  opacity: 1;
}
#root .settings-link:hover,
.modal-open .settings-link:hover,
.MuiPopover-root .settings-link:hover {
  opacity: 0.75;
  cursor: pointer;
}
#root .attendee-visitors-color,
.modal-open .attendee-visitors-color,
.MuiPopover-root .attendee-visitors-color {
  color: #11a9f0 !important;
}
#root .admin-visitors-color,
.modal-open .admin-visitors-color,
.MuiPopover-root .admin-visitors-color {
  color: #0876e8 !important;
}
#root .queued-attendees-color,
.modal-open .queued-attendees-color,
.MuiPopover-root .queued-attendees-color {
  color: #0AC273 !important;
}
#root .queued-admins-color,
.modal-open .queued-admins-color,
.MuiPopover-root .queued-admins-color {
  color: #00371f !important;
}
#root .attendee-limit-color,
.modal-open .attendee-limit-color,
.MuiPopover-root .attendee-limit-color {
  color: #ffa800 !important;
}
#root .dashboard-blocks,
.modal-open .dashboard-blocks,
.MuiPopover-root .dashboard-blocks {
  display: flex;
  align-items: stretch;
}
body {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1;
  color: #24272b;
}
body #root {
  font-family: ff-basic-gothic-pro, sans-serif !important;
}
@media only screen and (max-width : 768px) {
  body {
    font-size: 14px;
  }
}
body:not(.set-inline) {
  background: #f7f7f7 !important;
}
.MuiTypography-root {
  font-family: ff-basic-gothic-pro, sans-serif !important;
  font-weight: 300 !important;
}
.MuiDialog-paper {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15) !important;
}
.MuiSkeleton-text {
  height: 26.66666667px !important;
}
.MuiPaper-elevation8 {
  box-shadow: 0px 3px 16px 0px #24272b26 !important;
}
.field-skeleton {
  margin-top: 10px !important;
  height: 32px !important;
  border-radius: 3px;
}
.tss-button-loading {
  height: 50px !important;
  margin-top: -10px !important;
}
.seat-popover .MuiPopover-paper {
  max-width: 400px;
  padding: 10px;
}
.seat-popover .MuiPopover-paper .btn {
  display: block;
  width: 100%;
}
.seat-popover .MuiPopover-paper .btn:not(:first-child) {
  margin-top: 5px;
}
.seat-popover .MuiPopover-paper .btn .mini {
  display: block;
  margin-top: -5px;
}
.seat-popover-info .MuiPopover-paper {
  max-width: 320px;
  padding: 10px;
  background-color: #134f97;
  color: #ffffff;
}
.seat-popover-info .MuiPopover-paper .btn {
  display: block;
  width: 100%;
}
.seat-popover-info .MuiPopover-paper .btn:not(:first-child) {
  margin-top: 5px;
}
.seat-popover-info .MuiPopover-paper .btn .mini {
  display: block;
  margin-top: -5px;
}
.form-slider {
  text-align: center;
}
.form-slider .MuiSlider-root {
  width: calc(100% - 24px);
  margin: auto;
}
.menu-popover .padded a {
  padding: 8px 12px;
  color: #24272b;
  display: block;
}
.menu-popover .padded a i {
  margin-right: 10px;
}
.menu-popover .dropdown-divider {
  margin: 0 10px;
}
html {
  width: 100%;
  height: 100%;
}
.tooltip-inner {
  background-color: #24272b !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  box-shadow: 0px 3px 16px 0px #24272b26;
  padding: 10px !important;
  position: relative;
  height: auto !important;
  fill-opacity: 1 !important;
}
.tooltip-inner .tooltip-small {
  position: relative;
  display: block;
  top: -8px !important;
}
#svg-tooltip,
#popover-basic > * {
  all: unset;
}
.bs-tooltip-bottom {
  opacity: 1 !important;
}
.tooltip.show {
  opacity: 1 !important;
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
}
.bs-tooltip-bottom .arrow::after {
  content: "";
  position: absolute;
  left: 100%;
  z-index: -1;
  border: 5px solid transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
}
.tooltip-header {
  display: inline-block;
  overflow: auto;
  height: auto;
  position: relative;
  top: -10px;
  z-index: 1;
  margin-left: -10px;
  margin-right: -10px;
  background: #4a525a !important;
  width: calc(100% + 10px * 2);
  color: #ffffff;
  padding: 5px;
  border-radius: 6px 6px 0 0;
}
.d-flex {
  display: flex;
}
.d-flex.equal-width {
  justify-content: space-between;
}
.d-flex.equal-width > * {
  flex-grow: 1;
  margin: 0 5px;
}
.d-flex.equal-width > *:first-child {
  margin-left: 0;
}
.d-flex.equal-width > *:last-child {
  margin-right: 0;
}
.d-flex.justify-space-between {
  justify-content: space-between;
}
.d-flex.align-center {
  align-items: center;
}
.modal {
  z-index: 1052 !important;
}
.modal .modal-body {
  padding: 24px !important;
  margin: 10px !important;
}
.modal.modal-padding-lg .modal-body {
  padding: 48px !important;
}
.modal .modal-header {
  border-bottom: none;
  padding: 24px;
  padding-bottom: 0;
}
.modal .modal-header .close {
  margin: 0 !important;
  padding: 0;
}
.modal .modal-header.titleless {
  display: block;
  height: 48px;
}
.modal .modal-header.titleless ~ .modal-body {
  padding-top: 0 !important;
}
.modal .modal-content {
  border: none;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  background-color: #ffffff;
}
.modal .close {
  color: #24272b;
}
.custom-control-label::after {
  font-family: "Font Awesome 6 Pro";
  font: var(--fa-font-solid);
  content: "\e28f";
  color: #facf43;
  font-size: 14px;
  line-height: 21px;
  left: calc(-2.75rem + 2px);
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  top: calc(0.15rem + -3px);
  border-radius: 1.5rem;
  background-color: #4a525a;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
}
.custom-control-label::before {
  height: 0.5rem;
  top: calc(0.5rem - 2px);
  background-color: rgba(4, 14, 53, 0.8) !important;
  border: none;
}
.custom-control-input:checked ~ .custom-control-label::after {
  content: "\f186";
  background-color: #4a525a;
  color: #11a9f0;
  -webkit-transform: translateX(1.2rem);
  transform: translateX(1.2rem);
}
.light-switch .custom-control-label::after {
  left: calc(-2.75rem + 2px) !important;
  width: calc(1.5rem - 4px) !important;
  height: calc(1.5rem - 4px) !important;
  top: calc(0.15rem + -3px) !important;
  border-radius: 1.5rem !important;
  background-color: #ffffff !important;
}
.light-switch .custom-control-label::before {
  height: 0.5rem;
  top: calc(0.5rem - 2px);
  background-color: #f2f3f5 !important;
  border: none;
}
.light-switch .custom-control-input:checked ~ .custom-control-label::after {
  content: "\f186" !important;
  background-color: #4a525a !important;
  color: #11a9f0 !important;
  -webkit-transform: translateX(1.2rem) !important;
  transform: translateX(1.2rem) !important;
}
.light-switch.label-buffer {
  margin-top: 32px !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
#cap-enclave + div {
  z-index: 99 !important;
}
/*# sourceMappingURL=main.css.map */